// AuthContext.js
import { createContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode"; // Add this import statement
import Loading from "../components/Loading";
import axios from "axios";

const AuthContext = createContext({});

export default AuthContext;

export const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [cartData, setCartData] = useState();

  const [heartData, setHeartData] = useState();
  const [cartamount, setCartAmount] = useState();
  const [checkToken, setCheckToken] = useState(false);
  const [myAuth, setMyAuth] = useState({
    authorized: null,
    sid: null,
    useremail: "",
    token: "",
    cartamount: null,
    heartamount: null,
    cartData: [], // Initialize cartData as an empty array
    heartData: [],
    memberType: "",
    editpwd: null,
    connectOld:null,
  });
  const { pathname } = useLocation();
  const storedToken = localStorage.getItem("userToken");
  const tokenExpiration = localStorage.getItem("tokenExpiration");
  const storeduserMid = localStorage.getItem("userMid");

  const verifyToken = (storedToken, storeduserMid) => {
    const mid = parseInt(storeduserMid);
    setIsLoading(true);
    axios
      .post(
        `/api/Member/MemberData`,
        { mid: mid },
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const memberData = response.data;
          console.log("verifyToken Member Data:", "memberData");

          setMyAuth((prevAuth) => ({
            ...prevAuth,
            authorized: true,
            sid: mid,
            token: storedToken,
            email: memberData.email,
            invoiceTitle: memberData.invoiceTitle,
            invoiceType: memberData.invoiceType,
            businessNo: memberData.uniformNo,
            memberName: memberData.memberName,
            mobile: memberData.mobile,
            recipientData: memberData.recipientData,
            memberType: memberData.memberType,
            connectOld: memberData.getbonus,
          }));
          getCartData(storedToken, mid);
          getHeartData(storedToken, mid);
          //token更新完以後要更新購物車資料
          console.log("檢視token成功，mid為", "mid");
        } else {
          console.log("Request failed:", "response");
          // Token is invalid, log out the user
          logout();
        }
      })
      .catch((error) => {
        console.error("檢視token錯誤，Error:", "error");
        logout();
      })
      .finally(() => {
        setIsLoading(false); // Mark loading as finished regardless of success or error
      });
  };
  const getCartData = (storedToken, storeduserMid) => {
    const mid = parseInt(storeduserMid);
    setIsLoading(true);
    axios
      .post(
        `/api/Order/GetshoppingCart`,
        { memberid: mid, orderItems: null },
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const loginCart = response.data;
          console.log("登入的loginCart:", "loginCart");
          console.log("登入的loginCart在這:", "loginCart.orderItems");
          setCartData(loginCart.orderItems); // 設置 cartData
          setCartAmount(loginCart.orderItems.length);
          console.log(
            "數字loginCart.orderItems.length",
            "loginCart.orderItems.length"
          );
          setMyAuth((prevAuth) => ({
            ...prevAuth,
            cartamount: loginCart.orderItems.length,
            cartData: loginCart.orderItems,
          }));
          // console.log('mid', mid);
        } else {
          console.log("Request failed:", "response");
          // Token is invalid, log out the user
          logout();
        }
      })
      .catch((error) => {
        console.error("Error:", "error");
      })
      .finally(() => {
        setIsLoading(false); // Mark loading as finished regardless of success or error
      });
  };
  const getHeartData = (storedToken, storeduserMid) => {
    const mid = parseInt(storeduserMid);
    const requestData = {
      mid: mid,
    };
    axios
      .post(`/api/Member/MemberFavorite`, requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const loginLike = response.data;
          console.log("登入的loginLike:", "loginLike");

          setHeartData(loginLike); // 設置 HeartData
          setMyAuth((prevAuth) => ({
            ...prevAuth,
            heartData: loginLike,
            heartamount: loginLike.length,
          }));
          console.log("mid", "mid");
        } else {
          console.log("Request failed:", "response");
          console.log("Request failed:", "requestData");
          // Token is invalid, log out the user
        }
      })
      .catch((error) => {
        console.error("Error:", "error");
      })
      .finally(() => {
        setIsLoading(false); // Mark loading as finished regardless of success or error
      });
  };

  //轉換頁面的時候會呼叫verifyToken
  useEffect(() => {
    console.log("路徑或是登入狀態改變");

    //都沒有的情況下
    if (!storedToken && !tokenExpiration && !storeduserMid) {
      setMyAuth((prevAuth) => ({
        ...prevAuth,
        authorized: false,
        sid: "",
      }));
      console.log("未登入的情況");
    } else if (!storedToken || !tokenExpiration || !storeduserMid) {
      //其中一個沒有代表有異常
      logout();
    }
    if (storedToken && tokenExpiration > Date.now() && storeduserMid) {
      verifyToken(storedToken, storeduserMid);
    } else if (storedToken && tokenExpiration < Date.now() && storeduserMid) {
      logout();
      // Mark loading as finished
      console.log("here");
    }
    setIsLoading(false);
  }, [
    myAuth.authorized,
    pathname,
    storedToken,
    storeduserMid,
    tokenExpiration,
  ]);

  const updateCartAmount = () => {
    console.log("呼叫了更新updateCartAmount");
    const storedToken = localStorage.getItem("userToken");
    const storeduserMid = localStorage.getItem("userMid");
    const mid = parseInt(storeduserMid);
    axios
      .post(
        `/api/Order/GetshoppingCart`,
        { memberid: mid, orderItems: null },
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const loginCart = response.data;
          // 設置 cartData
          setCartAmount(loginCart.orderItems.length);
          console.log(
            "數字loginCart.orderItems.length",
            "loginCart.orderItems.length"
          );
          setMyAuth((prevAuth) => ({
            ...prevAuth,
            cartamount: loginCart.orderItems.length,
          }));
          // console.log('mid', mid);
        } else {
          console.log("Request failed:", "response");
          // Token is invalid, log out the user
          logout();
        }
      })
      .catch((error) => {
        console.error("Error:", "error");
      })
      .finally(() => {
        setIsLoading(false); // Mark loading as finished regardless of success or error
      });
  };
  const updateMemberData = () => {
    const storedToken = localStorage.getItem("userToken");
    const storeduserMid = localStorage.getItem("userMid");
    const mid = parseInt(storeduserMid);

    axios
      .post(
        `/api/Member/MemberData`,
        { mid: mid },
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const memberData = response.data;
          console.log("Member Data:", "memberData");

          setMyAuth((prevAuth) => ({
            ...prevAuth,
            authorized: true,
            sid: mid,
            token: storedToken,
            email: memberData.email,
            invoiceTitle: memberData.invoiceTitle,
            invoiceType: memberData.invoiceType,
            businessNo: memberData.uniformNo,
            memberName: memberData.memberName,
            mobile: memberData.mobile,
            recipientData: memberData.recipientData,
            memberType: memberData.memberType,
          }));
          getCartData(storedToken, mid);
          getHeartData(storedToken, mid);
          //token更新完以後要更新購物車資料
          console.log("檢視token成功，mid為", "mid");
        } else {
          console.log("Request failed:", "response");
          // Token is invalid, log out the user
          logout();
        }
      })
      .catch((error) => {
        console.error("檢視token錯誤，Error:", "error");
        logout();
      })
      .finally(() => {
        setIsLoading(false); // Mark loading as finished regardless of success or error
      });
  };

  const updateCartData = () => {
    console.log("呼叫了更新updateCartData");
    const storedToken = localStorage.getItem("userToken");
    const storeduserMid = localStorage.getItem("userMid");
    const mid = parseInt(storeduserMid);
    axios
      .post(
        `/api/Order/GetshoppingCart`,
        { memberid: mid, orderItems: null },
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const loginCart = response.data;
          // 設置 cartData
          console.log("loginCart", "loginCart");
          setCartData(loginCart.orderItems); // 設置 cartData
          console.log(
            "數字loginCart.orderItems.length",
            "loginCart.orderItems.length"
          );
          setMyAuth((prevAuth) => ({
            ...prevAuth,
            cartData: loginCart.orderItems,
          }));
          // console.log('mid', mid);
        } else {
          console.log("Request failed:", "response");
          // Token is invalid, log out the user
          logout();
        }
      })
      .catch((error) => {
        console.error("Error:", "error");
      })
      .finally(() => {
        setIsLoading(false); // Mark loading as finished regardless of success or error
      });
  };

  const login = async (phoneNumber, password, location) => {
    // setIsLoading(true);
    try {
      const response = await axios.post(
        "/api/User/Login",
        {
          // Use Axios to send POST request
          Account: phoneNumber,
          Password: password,
          Membertype: location, // Assuming it's for local member type
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log('response',response)
      if (response.status === 200) {
        cartDataTologin(response.data);
      } else {
        // Handle login failure
        console.log("Login failed");
        return false;
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during login:", "error.response.data.message");
      setIsLoading(false);
      return {
        success: false,
        message: error.response.data.message,
      };
    }
  };
  const cartDataTologin = (data) => {
    setIsLoading(true);
    console.log("data", "data");
    const mid = parseInt(data.mid);
    // console.log('data', data);
    setMyAuth({
      authorized: true,
      sid: mid,
      token: data.userToken, // Include the token here
    });

    localStorage.setItem("userToken", data.userToken);

    const decodedToken = jwt_decode(data.userToken);
    const tokenExpirationTimestamp = decodedToken.exp * 1000;
    localStorage.setItem("tokenExpiration", tokenExpirationTimestamp);

    // console.log('User Token:', data.userToken);
    // console.log('User mid:', data.mid);
    localStorage.setItem("userToken", data.userToken);
    localStorage.setItem("userMid", mid);
    // Redirect user to the previous path or homepage
    verifyToken(data.userToken, mid);
    getHeartData(data.userToken, mid);
   

    console.log("登入狀態", "mid");
    const existingCartItems = JSON.parse(localStorage.getItem("cart")) || [];
    console.log("existingCartItems", "existingCartItems");

    // 检查是否有购物车项，只有在存在购物车项时才触发POST请求
    if (existingCartItems.length > 0) {
      const requestData = {
        memberid: mid,
        orderItems: existingCartItems,
      };

      axios
        .post("/api/Order/addOrderitem", requestData, {
          headers: {
            Authorization: `Bearer ${data.userToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // 请求成功处理
          console.log("已加到購物車", "response.data");
          console.log("加到購物車的資料", "requestData");
          getCartData(data.userToken, mid);

          // 清空localStorage中的cart
          localStorage.removeItem("cart");
        })
        .catch((error) => {
          // 请求失败处理
          console.log("失敗的", "error.data.message");
          console.error("加入購物車失敗：", "error");
          console.log("requestData", "requestData");
          return {
            success: false,
            message: error.data.message,
          };
        });
    } else {
      // 如果购物车为空，不触发POST请求
      console.log("購物車為空，不觸發POST請求");
      getCartData(data.userToken, mid);
    }
    loginNavigate(data.epwd);
  };
  const loginNavigate = (epwd) => {
    const prevPath = sessionStorage.getItem("prevPath");
    console.log("epwd","epwd")
    setMyAuth((prevAuth) => ({
      ...prevAuth,
      editpwd: epwd,
    }));
    if (epwd === true) {
      
      navigate("/resetmemberpwd");

    } else {

      if (prevPath === "/checkoutstep1") {
        navigate("/cart");
      } else if (prevPath) {
        navigate(prevPath);
      } else {
        navigate("/");
      }
    }
  };
  const logout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("tokenExpiration");
    localStorage.removeItem("userMid");
    localStorage.removeItem("stepTwoStoreData");
    localStorage.removeItem("promoData");
    sessionStorage.removeItem("shopid");
    sessionStorage.removeItem("areatype");

    setMyAuth({
      authorized: false,
      sid: "",
      useremail: "",
      token: "",
      cartamount: 0,
      heartamount: 0,
    });
    //     // navigate("/");
  };

  //監聽消失的情況
  useEffect(() => {
    if (myAuth.authorized) {
      const onStorageChange = (e) => {
        if (
          e.key === "userMid" ||
          e.key === "userToken" ||
          e.key === "tokenExpiration"
        ) {
          if (!e.newValue) {
            console.log("userMid 或 userToken 被清除");
            window.location.reload();
          }
        }
      };
      window.addEventListener("storage", onStorageChange);

      return () => {
        window.removeEventListener("storage", onStorageChange);
      };
    }
  }, [myAuth.authorized]);

  //檢驗token是否過期
  const checkExpiration = () => {
    return new Promise((resolve, reject) => {
      const expiration = localStorage.getItem("tokenExpiration");
      const now = Date.now();

      if (expiration && now >= parseInt(expiration)) {
        console.log("Token 已過期");
        localStorage.removeItem("token");
        reject(new Error("Token 已過期"));
      } else {
        checkLogin(storedToken, storeduserMid)
          .then(() => resolve())
          .catch((error) => reject(error));
      }
    });
  };

  const checkLogin = async (storedToken, storeduserMid) => {
    const mid = parseInt(storeduserMid);

    try {
      const response = await axios.post(
        "/api/Member/MemberData",
        {
          mid: mid,
        },
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        console.log("檢視 token 成功，mid 為", "mid");
      } else {
        // 處理登入失敗
        console.log("Request failed:", "response");
        window.location.reload();
        throw new Error("Request failed");
      }
    } catch (error) {
      window.location.reload();
      console.error("檢視 token 錯誤，Error:", "error");
      throw error;
    }
  };
  useEffect(() => {
    if (checkToken) {
      setCheckToken(false);

      checkExpiration();
      const interval = setInterval(() => {
        checkExpiration();
      }, 1000 * 60 * 5); // 每 5 分鐘檢查一次

      return () => {
        clearInterval(interval);
        setCheckToken(true);
      };
    }
  }, [checkToken]);
  return (
    <AuthContext.Provider
      value={{
        myAuth,
        setMyAuth,
        updateMemberData,
        login,
        logout,
        updateCartAmount,
        updateCartData,
        getCartData,
        getHeartData,
        cartDataTologin,
        verifyToken,
        checkExpiration,
      }}
    >
      {isLoading ? <Loading /> : children}
    </AuthContext.Provider>
  );
};
