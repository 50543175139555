//ProductCard.js
import React, { useState, useContext } from "react";
import AuthContext from "../Context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import Popup from "./Popup";
import axios from "axios";
function getTagByProdIcon(prodIcon) {
  switch (prodIcon) {
    case 1:
      return (
        // New
        <div className="product-tag bg-transparent border-0 px-0">
       <svg xmlns="http://www.w3.org/2000/svg" width="124" height="54" viewBox="0 0 124 54" fill="none">
  <path d="M35.6151 6.60449L38.8201 37.0955L32.0521 37.8065L17.2911 19.8455L19.3191 39.1455L16.3481 39.4575L13.1431 8.96649L18.7501 8.37649L34.8361 27.7575L32.6441 6.91649L35.6151 6.60449Z" fill="#E60012"/>
  <path d="M60.455 3.99316L60.777 7.05416L49.853 8.20116L50.822 17.4272L60.005 16.4622L60.326 19.5222L51.145 20.4872L52.415 32.5722L65.192 31.2292L65.515 34.2892L43.892 36.5622L40.686 6.07116L60.455 3.99316Z" fill="#E60012"/>
  <path d="M98.452 0L93.348 31.363L87.584 31.969L81.591 15.774L79.029 32.87L73.265 33.475L62.197 3.811L71.088 2.876L77.656 20.885L80.336 1.903L85.541 1.356L92.049 19.44L94.968 0.365L98.452 0Z" fill="#E60012"/>
  <path d="M0 53.3126C33.812 37.6436 86.19 32.4186 123.366 34.2556C118.179 35.0826 112.991 35.6716 107.81 36.2926C71.728 40.8316 35.373 44.6066 0 53.3126Z" fill="#E60012"/>
</svg>
      </div>
      );
    case 2:
       // Hot
      return (
        <div className="product-tag bg-transparent border-0 px-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="124" height="54" viewBox="0 0 124 54" fill="none">
  <path d="M96.509 0L96.829 3.061L89.346 3.847L92.231 31.277L82.847 32.264L79.965 4.833L72.436 5.625L72.114 2.564L96.509 0Z" fill="#E60012"/>
  <path d="M0 53.108C33.812 37.439 86.19 32.214 123.366 34.051C118.179 34.878 112.991 35.467 107.81 36.088C71.728 40.627 35.373 44.402 0 53.108Z" fill="#E60012"/>
  <path d="M57.83 3.52275C66.705 2.58975 71.696 7.38075 72.801 17.8947C73.906 28.4087 69.939 34.1397 60.9 35.0897C51.861 36.0397 46.776 31.1388 45.646 20.3857C44.566 10.1108 48.627 4.48975 57.83 3.52275ZM57.84 5.98375C56.172 6.15975 55.141 7.19675 54.746 9.09875C54.351 10.9998 54.428 14.5557 54.976 19.7677C55.524 24.9807 56.188 28.4758 56.97 30.2528C57.751 32.0298 58.975 32.8307 60.644 32.6547C62.311 32.4797 63.343 31.4417 63.737 29.5407C64.133 27.6397 64.057 24.0838 63.509 18.8708C62.962 13.6738 62.298 10.1827 61.515 8.39875C60.733 6.61275 59.508 5.80875 57.84 5.98375Z" fill="#E60012"/>
  <path d="M25.721 22.9329L27.317 38.0999L18.47 39.0289L15.265 8.5389L24.112 7.6089L25.401 19.8729L32.615 19.1149L31.326 6.8509L40.173 5.9209L43.376 36.4119L34.531 37.3419L32.937 22.1739L25.721 22.9329Z" fill="#E60012"/>
</svg>
        </div>
      );
    case 3:
      return (
         // Sale
         <div className="product-tag bg-transparent border-0 px-0">
        <svg xmlns="http://www.w3.org/2000/svg" width="124" height="54" viewBox="0 0 124 54" fill="none">
  <path d="M29.1411 16.1974L25.1111 17.3884C25.2031 16.5514 25.2201 15.8564 25.1621 15.3054C24.7901 11.7614 22.4521 10.2154 18.1481 10.6684C14.7521 11.0244 13.1831 12.4244 13.4401 14.8664C13.6321 16.6984 15.6961 18.0704 19.6311 18.9814C27.2231 20.7284 31.2781 24.0664 31.7951 28.9944C32.0991 31.8844 31.3161 34.3114 29.4441 36.2774C27.5741 38.2434 24.9321 39.4054 21.5221 39.7634C13.9721 40.5564 9.88906 38.0274 9.27506 32.1754C9.22406 31.6984 9.20006 31.1064 9.20106 30.3984L13.3691 29.0124C13.1771 30.3424 13.1311 31.4834 13.2321 32.4364C13.6291 36.2194 15.9951 37.8824 20.3281 37.4264C23.7971 37.0624 25.3901 35.5174 25.1031 32.7924C24.8901 30.7674 22.6251 29.2054 18.3101 28.1084C12.0771 26.5354 8.70506 23.3284 8.19806 18.4894C7.91006 15.7494 8.67806 13.4364 10.5051 11.5504C12.3311 9.66436 14.8761 8.55036 18.1381 8.20736C24.8391 7.50236 28.5061 10.1654 29.1411 16.1974Z" fill="#E60012"/>
  <path d="M47.2761 5.66211L58.9691 35.2611L50.0791 36.1961L47.5751 29.7741L38.3931 30.7391L37.1451 37.5551L33.6821 37.9191L39.5031 6.48011L47.2761 5.66211ZM38.9431 27.5881L46.4261 26.8011L41.4091 13.6651L38.9431 27.5881Z" fill="#E60012"/>
  <path d="M67.0001 3.58984L69.8831 31.0208L82.4141 29.7038L82.7361 32.7638L61.3581 35.0108L58.1541 4.51984L67.0001 3.58984Z" fill="#E60012"/>
  <path d="M101.155 0L101.475 3.061L90.552 4.208L91.522 13.434L100.703 12.469L101.025 15.529L91.843 16.495L93.114 28.579L105.891 27.236L106.214 30.296L84.591 32.569L81.385 2.078L101.155 0Z" fill="#E60012"/>
  <path d="M0 53.5967C33.812 37.9277 86.19 32.7027 123.366 34.5397C118.179 35.3667 112.991 35.9557 107.81 36.5767C71.728 41.1157 35.373 44.8907 0 53.5967Z" fill="#E60012"/>
</svg>
       </div>
      );
    default:
      return null; // 當 prodIcon 不是 1、2、3 時 回傳 null
  }
}
function ProductCard({
  prodId,
  prodImage,
  prodName,
  prodSlug,
  selectedheart,
  prodIcon,
}) {
  const { myAuth, getHeartData, logout } = useContext(AuthContext);
  // console.log("heartData", myAuth.heartData);
  // popup
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const navigate = useNavigate();

  //  console.log("prodIcon",prodIcon);渲染對應的行銷tag

  const parsedProdIcon = parseInt(prodIcon) || 0;
  const tag = getTagByProdIcon(parsedProdIcon);
  const handleHeartClick = (prodId) => {
    
    if (myAuth.authorized) {
      console.log("AuthContext", "myAuth.sid");
      console.log("prodId", "prodId");

      const mid = parseInt(myAuth.sid);
      const pid = parseInt(prodId);

      const requestData = {
        mid: mid,
        pid: pid,
      };
      const storedToken = myAuth.token;

      if (selectedheart) {
        console.log("已有數據");
        deletefavorite(mid, requestData, storedToken);
      } else {
        // 觸發 addfavorite 函數
        addfavorite(mid, requestData, storedToken);
      }
    } else {
      setIsPopupOpen(true);
    }
  };

  const addfavorite = (mid, requestData, storedToken) => {
    axios
      .post(`/api/Member/AddFavorite`, requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("response Data:", "response.data");
          getHeartData(storedToken, mid);
        } else {
          console.log("Request failed:", "response");
          // Token is invalid, log out the user
        }
      })
      .catch((error) => {
        console.error("Error:", "error");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      })
      .finally(() => {
        // setIsLoading(false); // Mark loading as finished regardless of success or error
      });
  };
  const deletefavorite = (mid, requestData, storedToken) => {
    axios
      .post(`/api/Member/DeleteFavorite`, requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("response Data:", "response.data");
          getHeartData(storedToken, mid);
        } else {
          console.log("Request failed:", "response");
          // Token is invalid, log out the user
        }
      })
      .catch((error) => {
        console.error("Error:", "error");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      })
      .finally(() => {
        // setIsLoading(false); // Mark loading as finished regardless of success or error
      });
  };
  return (
    <>
      {isPopupOpen && (
        <Popup
          onNevigate={() => {
            setIsPopupOpen(false);
            navigate("/login");
          }}
          onClose={() => {
            setIsPopupOpen(false);
          }}
          icon={"notice"}
          title={"登入才可加入收藏"}
          orangebtn={"前往登入"}
          greenbtn={"暫不登入"}
        />
      )}
      <div className="col product-card">
        {tag}
        <Link to={`/product/${prodSlug}`}>
          <img src={prodImage} className="w-100" alt={prodName} />
        </Link>
        <div className="product-title">
          <h6>{prodName}</h6>

          <div className="heart" onClick={() => handleHeartClick(prodId)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="21"
              viewBox="0 0 23 21"
              fill="none"
              className={selectedheart ? "selected" : "default"}
            >
              <g clipPath="url(#clip0_33_5063)">
                <path
                  d="M11.6414 19.5L2.72959 11.209C-2.11377 6.23443 5.00597 -3.31677 11.6414 4.41042C18.2768 -3.31677 25.3642 6.2676 20.5531 11.209L11.6414 19.5Z"
                  stroke="#364F38"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_33_5063">
                  <rect
                    width="22.73"
                    height="20"
                    fill="white"
                    transform="translate(0.269531 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(ProductCard);
