import React, { useState, useEffect, useMemo } from "react";
import axios from "axios"; // Import axios
import Loading from "../Loading";
import ProductFirstSelect from "../product/ProductFirstSelect";
import ProductSecondSelect from "../product/ProductSecondSelect";
import ProductTitle from "../product/ProductTitle";
import ProductPrice from "../product/ProductPrice";

function OnePageProdDetail({ prodId, onClose, prodImg }) {
  const [productData, setProductData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  console.log("prodId", "prodId");
  const secondSelectComponent = useMemo(() => {
    if (productData?.specName && productData.specName[1]) {
      return (
        <ProductSecondSelect
          specname={productData.specName[1].specname}
          onePage={true}
        />
      );
    }
    return null;
  }, [productData]);

  const getProdData = () => {
    const requestData = { Onepageprodid: prodId };
    axios
      .post(`/api/Onepage/GetOnepageProdDetail`, requestData)
      .then((response) => {
        // Handle the response data here
        console.log("GetOnepageProdDetail Product Details:", "response.data");
        setProductData(response.data);
        console.log("productData", "productData"); //非同步請求
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching product details:", "error");
      });
  };
  useEffect(() => {
    if (prodId) {
      getProdData();
      console.log("prodId", "prodId");
      // window.scrollTo(0, 0);
    }
  }, [prodId]);
  // if (isLoading) {
  //   return <Loading />;
  // }
  return (
    <div className="onepage-pop" onClick={onClose}>
      <div className="pop-frame  py-4 py-xxl-5 px-xxl-4">
        <div className="pop-container" onClick={(e) => e.stopPropagation()}>
          <div className="overflow-wrap ">
            {isLoading ? (
              <Loading height={"h-auto"} />
            ) : (
              <div className="product-detail container pop-content">
                {productData?.specName && (
                  <>
                    {/* <h5 className="primary-deep2">{productData.prodname}</h5> */}
                    <ProductTitle title={productData.prodName} />

                    <ProductFirstSelect
                      specname={productData.specName[0].specname}
                      option={productData.specOption}
                      tosetprice={productData.specName[1] ? false : true}
                      onePage={true}
                    />
                  </>
                )}
                {secondSelectComponent}
                <div className="price-block">
                  {productData && (
                    <ProductPrice
                      title={productData.prodName}
                      img={prodImg}
                      productDataId={prodId}
                      onePage={true}
                      onePageClose={onClose}
                    />
                  )}
                </div>

                {/* <h5 className="primary-deep2">{productData.prodname}</h5> */}
                {/* <div onClick={onClose}>close</div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnePageProdDetail;
