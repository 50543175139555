//RegisterLocalSection.js
import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../Context/AuthContext";
import axios from "axios";
import Popup from "../sharedComponents/Popup";
import PwdLink from "./PwdLink";
import Loading from "../components/Loading";
import Nodata from "./Nodata";
import TextPopup from "./TextPopup";
import CheckOutNa from "./CheckOutNa";

function RegisterLocalSection({ title, buttondescript }) {
  const { login } = useContext(AuthContext);
  const location = useLocation();

  const [name, setName] = useState("");
  const [nameOnBlur, setNameBlur] = useState(false);

  const [email, setEmail] = useState("");
  const [emailOnblur, setEmailonBlur] = useState(false);

  const [phone, setPhone] = useState("");
  const [phoneOnBlur, setphoneBlur] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordOnblur, setPasswordBlur] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordOnBlur, setConfirmPasswordBlur] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(true);

  const [captcha, setCaptcha] = useState("");
  const [captchaOnblur, setCaptchaBlur] = useState(false);
  const [captchaData, setCaptchaData] = useState(null);
  const [isSameCaptcha, setIsSameCaptcha] = useState(true);
  //寄送SendVerificationCode出現的錯誤訊息
  const [captchaError, setCaptchaError] = useState(null);
  //查看密碼的password
  const [showPassword, setShowPassword] = useState(false);

  const [formCheck, setFormCheck] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); // State to track if the form is submitted
  //手機是否為唯一值
  const [phoneuniq, setPhoneuniq] = useState(false);
  //是否發送過檢查手機號碼
  const [phonesent, setPhonesent] = useState(false);

  const [isRegisterPopupOpen, setIsRegisterPopupOpen] = useState(false);

  //已經寄送過驗證
  const [verifysent, setVerifysent] = useState(false);
  const [countdown, setCountdown] = useState(60);

  // 下一步的disable
  const [isNextDisabled, setNextDisabled] = useState(false);

  // 檢查錯誤
  const [sendCaptchaError, setSendCaptchaError] = useState(false);
  const [checkError, setCheckError] = useState(false);

  const navigate = useNavigate();

  const handlePhoneNumberChange = (e) => {
    setPhone(e.target.value);
    setIsSubmitted(false);
    setPhonesent(false);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleCaptchaChange = (e) => {
    setCaptcha(e.target.value);
  };

  const togglePasswordVisibility = (e) => {
    e.preventDefault(); // 阻止默認行為
    setShowPassword(!showPassword);
  };
  //檢查手機是否為 唯一值
  const sendPhoneNumber = () => {
    const requestData = { Mobile: phone, MemberType: "L" };
    axios
      .post("api/User/Register/Check", requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setPhoneuniq(true);
        setPhonesent(true);
        handleCaptchaSend();
        console.log("Form Register Check submitted:", "response.data");
      })
      .catch((error) => {
        console.log("Error checking availability");
        if (error) {
          console.log("Mobile number or Email is already registered.");
          setPhoneuniq(false);
          setPhonesent(true);
          console.log(phonesent, !phoneuniq, isSubmitted);
          window.scrollTo(0, 0);
        }
      });
  };

  // 檢查格式，若格式正確就檢查手機是否為唯一值，
  const handlePhoneCheck = async (e) => {
    e.preventDefault();
    //如果狀態是verifysent(代表已經寄過)就不寄信
    if (verifysent) {
      return;
    }

    setIsSubmitted(true);

   

    if (
      !isValidPhoneNumber(phone) ||
      !isValidEmail(email) ||
      !isValidName(name) ||
      !isValidPassword(password) ||
      confirmPasswordError
    ) {
      let firstInvalidInputRef = null;
      if (!isValidPhoneNumber(phone) || !phone) {
        firstInvalidInputRef = document.getElementById("phone-number");
        console.log("firstInvalidInputRef")
      } else if (!isValidEmail(email) || !email) {
        firstInvalidInputRef = document.getElementById("email");
      } else if (!isValidName(name) || !name) {
        firstInvalidInputRef = document.getElementById("username");
      } else if (
        !isValidPassword(password) ||
        !password 
      ) {
        firstInvalidInputRef = document.getElementById("register-pwd");
      }else if (
        confirmPasswordError ||
        !confirmPassword
      ) {
        firstInvalidInputRef = document.getElementById("confirm-pwd");
      }else if (
        !isSameCaptcha ||
        !captcha 
      ) {
        firstInvalidInputRef = document.getElementById("captcha");
      }

      if (firstInvalidInputRef) {
        console.log("firstInvalidInputRef" , firstInvalidInputRef)
        firstInvalidInputRef.scrollIntoView({
          behavior: 'smooth', 
          block: 'center'
        });      }
      setSendCaptchaError(true);
      return;
    }else{
      setSendCaptchaError(false);
    }

    console.log("格式合法:", "phone, password, email, name");

    sendPhoneNumber();
  };
  //如果手機沒有被註冊過才發送驗證碼
  const handleCaptchaSend = () => {
    const requestData = { mobile: phone.toString() };
    if (isNextDisabled) {
      console.log("連點禁止");
      return;
    }
    setNextDisabled(true)
    axios
      .post("/api/User/SendVerificationCode", requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const responseData = response.data;
        console.log("回傳的SendVerificationCode", "responseData");
        console.log("傳過去的資料SendVerificationCode", "requestData");
        console.log("verificationCode", "responseData.verificationCode");
        setCaptchaData(responseData.verificationCode);
        setVerifysent(true);
      })
      .catch((error) => {
        console.error("SendVerificationCode有錯誤：", "error");
        console.log("傳過去的SendVerificationCode有錯誤", "requestData");
        console.error(
          "寄送SendVerificationCode出現的錯誤訊息",
          "error.response.data.message"
        );
        setCaptchaError(error.response.data.message);
      })
      .finally(() => {
        setNextDisabled(false);
      });
  };

  const handleFormSubmit = async (e) => {
    //如果phonesent為false就不驗證。
    //如果手機號碼不是唯一值就不寄信
    e.preventDefault();
    setphoneBlur(true);
    setEmailonBlur(true);
    setNameBlur(true);
    setPasswordBlur(true);
    setConfirmPasswordBlur(true);
    setCaptchaBlur(true);
    if (
      !isValidPhoneNumber(phone) ||
      !isValidEmail(email) ||
      !isValidName(name) ||
      !isValidPassword(password) ||
      confirmPasswordError
    ) {
      let firstInvalidInputRef = null;
      if (!isValidPhoneNumber(phone) || !phone ||!phoneuniq) {
        firstInvalidInputRef = document.getElementById("phone-number");
        console.log("firstInvalidInputRef")
      } else if (!isValidEmail(email) || !email) {
        firstInvalidInputRef = document.getElementById("email");
      } else if (!isValidName(name) || !name) {
        firstInvalidInputRef = document.getElementById("username");
      } else if (
        !isValidPassword(password) ||
        !password 
      ) {
        firstInvalidInputRef = document.getElementById("register-pwd");
      }else if (
        confirmPasswordError ||
        !confirmPassword
      ) {
        firstInvalidInputRef = document.getElementById("confirm-pwd");
      }else if (
        !isSameCaptcha ||
        !captcha 
      ) {
        firstInvalidInputRef = document.getElementById("captcha");
      }

      if (firstInvalidInputRef) {
        console.log("firstInvalidInputRef" , firstInvalidInputRef)
        firstInvalidInputRef.scrollIntoView({
          behavior: 'smooth', 
          block: 'center'
        });      }
        setCheckError(true);
      return;
      
    } else {
      setCheckError(false);
    }

    if (isNextDisabled) {
      console.log("連點禁止");
      return;
    }else{
      setNextDisabled(true);
    }

  
    //驗證碼是否一樣?
    if (captcha !== captchaData.toString()) {
      console.log("captcha", "captcha");
      console.log("captchaData", "captchaData");
      setIsSameCaptcha(false);
      return;
    }

    setIsSubmitted(true);
    console.log("check");
    if (phoneuniq) {
      try {
        const registerResponse = await axios.post("api/User/Register", {
          Mobile: escapeHTML(phone),
          Email: escapeHTML(email),
          Password: escapeHTML(password),
          MemberName: escapeHTML(name),
          MemberType: "L",
        });
        if (location.pathname !== "/checkoutstep1") {
          setIsRegisterPopupOpen(true);
        }
        if (location.pathname === "/checkoutstep1") {
          try {
            const loginResult = await login(phone, password, "L"); // 等待登录完成

            if (!loginResult) {
              // 登录失败，打开弹出窗口
              console.log("出現意外錯誤");
            }
          } catch (error) {
            console.error("Error during login:", "error");
            // 处理登录时的错误情况
          }
          console.log("登入完成");
        }
        // Handle registration response (registerResponse.data)
        console.log("Form submitted:", "registerResponse.data");
      } catch (registerError) {
        // Handle registration error
        console.error("Error submitting registration:", "registerError");
      } finally {
        setNextDisabled(false);
        window.scrollTo(0, 0);
      }
      console.log("phoneuniq", phoneuniq);
    }
  };

  const isValidPhoneNumber = (phone) => {
    const phoneRegex = /^09\d{8}$/;
    return phoneRegex.test(phone);
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };
  const isValidName = (name) => {
    if (typeof name === "string" || name instanceof String) {
      const nameRegex = /^[A-Za-z0-9\u4e00-\u9fa5]+$/;
      // 确保字符串不为空
      return name.trim() !== "" && nameRegex.test(name);
    }
    return false; // 如果 name 不是字符串，返回 false
  };
  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,20}$/;
    return passwordRegex.test(password);
  };
  const isValidCaptcha = (captcha) => {
    return captcha === captchaData;
  };
  const escapeHTML = (unsafeText) => {
    return unsafeText.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  };

  const handleQuickFill = () => {
    // const quickFillData = {
    //   phone: "1234567890",
    //   email: "aa1@gmail.com",
    //   password: "Password123",
    //   name: "Nick",
    //   confirmPassword: "Password123",
    //   captcha: "123456789",
    // };

    // setPhone(quickFillData.phone);
    // setEmail(quickFillData.email);
    // setPassword(quickFillData.password);
    // setName(quickFillData.name);
    // setConfirmPassword(quickFillData.confirmPassword);
    // setConfirmPasswordError(false); // Reset the confirmPassword error
    // setCaptcha(quickFillData.captcha);
    console.log("click", "handleQuickFill");
  };
  //檢驗再次輸入密碼和輸入密碼是否相同
  useEffect(() => {
    if (
      password === confirmPassword &&
      password !== "" &&
      confirmPassword !== ""
    ) {
      setConfirmPasswordError(false);
      console.log("密碼沒錯");
    } else {
      setConfirmPasswordError(true);
      console.log("密碼不相同");
    }
  }, [password, confirmPassword]);

  useEffect(() => {
    if (!phoneuniq) {
      window.scrollTo(0, 0);
    }
  }, [phoneuniq]);

  //應該在檢查phoneuniq之後才可以觸發倒數計時
  //阻擋重複發送請求
  useEffect(() => {
    if (verifysent) {
      const countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown > 1) {
            return prevCountdown - 1;
          } else {
            clearInterval(countdownInterval);
            setVerifysent(false); // 倒數計時結束
            return 60; // 重置為60
          }
        });
      }, 1000);

      return () => {
        clearInterval(countdownInterval);
      };
    } else {
      setCountdown(60);
    }
  }, [verifysent]);
  //當發送完驗證碼之後，如果手機號碼更動，uniq要變為false，都要再重新檢查過
  useEffect(() => {
    setPhoneuniq(false);
    setPhonesent(false);
    setCaptchaError(null);
    console.log("檢查");
  }, [phone, email]);

  //重複的表單

  const renderInputField = (
    id,
    type,
    name,
    classname,
    placeholder,
    value,
    onChange,
    pattern,
    onBlur = null,
    onClick = null,
    minLength,
    maxLength
  ) => (
    <input
      type={type}
      id={id}
      name={name}
      pattern={pattern}
      className={classname}
      // placeholder={placeholder}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      onClick={onClick}
      minLength={minLength}
      maxLength={maxLength}
    />
  );
  const errorInput = (hasData, isValid, id, errormessage, show, notshow) => (
    <div
      className={`error-message ${
        hasData && !isValid ? show || "visible" : notshow || "invisible"
      } d-flex pb-0`}
      id={id}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="19"
        viewBox="0 0 21 19"
        fill="none"
        className=""
      >
        <path
          d="M0.98903 18.5C0.805697 18.5 0.63903 18.454 0.48903 18.362C0.33903 18.27 0.222364 18.1493 0.139031 18C0.0556972 17.85 0.00969715 17.6873 0.00103049 17.512C-0.00763618 17.3367 0.0383638 17.166 0.139031 17L9.38903 1C9.48903 0.833333 9.61836 0.708333 9.77703 0.625C9.9357 0.541667 10.098 0.5 10.264 0.5C10.4307 0.5 10.5934 0.541667 10.752 0.625C10.9107 0.708333 11.0397 0.833333 11.139 1L20.389 17C20.489 17.1667 20.535 17.3377 20.527 17.513C20.519 17.6883 20.473 17.8507 20.389 18C20.3057 18.15 20.189 18.271 20.039 18.363C19.889 18.455 19.7224 18.5007 19.539 18.5H0.98903ZM2.71403 16.5H17.814L10.264 3.5L2.71403 16.5ZM10.264 15.5C10.5474 15.5 10.785 15.404 10.977 15.212C11.169 15.02 11.2647 14.7827 11.264 14.5C11.264 14.2167 11.168 13.979 10.976 13.787C10.784 13.595 10.5467 13.4993 10.264 13.5C9.9807 13.5 9.74303 13.596 9.55103 13.788C9.35903 13.98 9.26336 14.2173 9.26403 14.5C9.26403 14.7833 9.36003 15.021 9.55203 15.213C9.74403 15.405 9.98136 15.5007 10.264 15.5ZM10.264 12.5C10.5474 12.5 10.785 12.404 10.977 12.212C11.169 12.02 11.2647 11.7827 11.264 11.5V8.5C11.264 8.21667 11.168 7.979 10.976 7.787C10.784 7.595 10.5467 7.49933 10.264 7.5C9.9807 7.5 9.74303 7.596 9.55103 7.788C9.35903 7.98 9.26336 8.21733 9.26403 8.5V11.5C9.26403 11.7833 9.36003 12.021 9.55203 12.213C9.74403 12.405 9.98136 12.5007 10.264 12.5Z"
          fill="#DC3545"
        />
      </svg>
      <p className={`ps-1 danger`}>{errormessage}</p>
    </div>
  );

  return (
    <>
      {isRegisterPopupOpen && (
        <Popup
          onNevigate={() => {
            setIsRegisterPopupOpen(false);
            navigate("/login");
          }}
          onClose={() => {
            setIsRegisterPopupOpen(false);
          }}
          icon={"check"}
          title={"已註冊成功"}
          orangebtn={"前往登入"}
          greenbtn={""}
        />
      )}
      <div className="login-title pt-3 pb-2 pt-xxl-3 pb-xxl-2">
        {title && (
          <h5 onClick={handleQuickFill} className="pb-2">
            {title}
          </h5>
        )}
        {!title && (
          <>
            <h4 onClick={handleQuickFill}>會員帳號註冊</h4>
            <div className="d-flex pt-2 pb-3">
              <h6>若您已是會員，請直接</h6>
              <Link to="/login">
                <h6 className="ps-1 primary-deep text-decoration-underline">
                  登入
                </h6>
              </Link>
              <h6>。</h6>
            </div>
          </>
        )}
      </div>
      <form className="login-form pb-3 d-flex flex-column gap-3" onSubmit={handleFormSubmit} noValidate>
        <div>
          <label htmlFor="phone-number" className="form-label d-block">
            <h6 className="primary-deep2">手機號碼(必填)</h6>
          </label>
          {renderInputField(
            "phone-number",
            "phone-number",
            "phone-number",
            "form-input",
            "輸入手機號碼(0912345678)",
            phone,
            handlePhoneNumberChange,
            "[0-9]{10}",
            () => {
              setphoneBlur(true);
            },
            () => {
              setphoneBlur(false);
            },
            "10",
            "10",
            "d-block",
            "d-none"
          )}
          {errorInput(
            isValidPhoneNumber(phone) && phonesent,
            phoneuniq,
            "error-phone-uniq",
            "該手機號碼已被註冊，無法發送驗證碼",
            "d-block",
            "d-none"
          )}
          {errorInput(
            phoneOnBlur || sendCaptchaError || checkError,
            isValidPhoneNumber(phone),
            "error-phone-number",
            "請輸入正確的格式(例如：0912345678)",
            "d-block",
            "d-none"
          )}
        </div>
<div>
  
          <label htmlFor="email" className="form-label d-block">
            <h6 className="primary-deep2">信箱(必填)</h6>
          </label>
          {renderInputField(
            "email",
            "email",
            "email",
            "form-input",
            "請輸入常用信箱",
            email,
            handleEmailChange,
            "",
            () => {
              setEmailonBlur(true);
            },
            () => {
              setEmailonBlur(false);
              setIsSubmitted(false);
            },
            3,
            254
          )}
  
          {errorInput(
            emailOnblur || email || sendCaptchaError || checkError,
            isValidEmail(email),
            "same-email",
            "請輸入正確的信箱",
            "d-block",
            "d-none"
          )}
</div>
        <div>
          <label htmlFor="username" className="form-label d-block">
            <h6 className="primary-deep2">姓名(必填)</h6>
          </label>
          {renderInputField(
            "username",
            "text",
            "username",
            "form-input",
            "輸入姓名",
            name,
            handleNameChange,
            "",
            () => {
              setNameBlur(true);
            },
            () => {
              setNameBlur(false);
            },
            1,
            50
          )}
          {errorInput(
            nameOnBlur || name || sendCaptchaError || checkError,
            isValidName(name),
            "error-username",
            "請輸入正確的名稱，不得為空且不得包含特殊符號",
            "d-block",
            "d-none"
          )}
        </div>

        <div>
          <label htmlFor="register-pwd" id="user-pwd" className="form-label">
            <h6 className="primary-deep2">密碼(必填)</h6>
          </label>
          <div className="d-flex">
            {renderInputField(
              "register-pwd",
              showPassword ? "text" : "password",
              "register-pwd",
              "form-pwd",
              "請輸入密碼(格式為英文字母大小寫加上數字,共8~20位英數字)",
              password,
              handlePasswordChange,
              "",
              () => {
                setPasswordBlur(true);
              },
              () => {
                setPasswordBlur(false);
              },
              "8",
              "20"
            )}
            <div className="btn see-pwd ">
              <button
                className="btn checkbox-icon check showpwd"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.3267 0L0 1.32686L3.32283 4.64929C1.86598 5.81447 0.715658 7.30499 0 8.99996C1.73 13.0973 6 16 11 16C12.1278 16 13.2185 15.8523 14.2513 15.5765L16.9518 18.2766L18.2785 16.9497L1.3267 0ZM12.6749 14.0002L10.4219 11.7475C9.84146 11.6411 9.30305 11.3759 8.87868 10.9799C8.40503 10.5378 8.10612 9.96312 8.02343 9.34933L4.68517 6.01147C3.63979 6.83061 2.78481 7.84738 2.18 8.99996C2.98825 10.5402 4.24331 11.838 5.80248 12.7457C7.36165 13.6533 9.1624 14.1345 11 14.1345C11.5637 14.1345 12.124 14.0892 12.6749 14.0002ZM10.2905 6.27939L13.8592 9.84771C13.9517 9.57602 14 9.29031 14 8.99996C14 8.25735 13.6839 7.54516 13.1213 7.02006C12.5587 6.49496 11.7956 6.19996 11 6.19996C10.7589 6.19996 10.5209 6.22704 10.2905 6.27939ZM19.82 8.99996C19.0728 10.424 17.9436 11.6407 16.5453 12.5335L17.92 13.9081C19.7365 12.6789 21.1645 10.9788 22 8.99996C20.27 4.90262 16 1.99996 11 1.99996C9.50062 1.99996 8.06689 2.26098 6.74761 2.73697L8.24441 4.2336C9.13525 3.99055 10.0628 3.86539 11 3.86539C12.8376 3.86539 14.6383 4.34658 16.1975 5.25425C17.7567 6.16193 19.0117 7.45967 19.82 8.99996Z"
                      fill="#D9D9D9"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                  >
                    <path
                      d="M11 6.2C11.7956 6.2 12.5587 6.495 13.1213 7.0201C13.6839 7.5452 14 8.25739 14 9C14 9.74261 13.6839 10.4548 13.1213 10.9799C12.5587 11.505 11.7956 11.8 11 11.8C10.2044 11.8 9.44129 11.505 8.87868 10.9799C8.31607 10.4548 8 9.74261 8 9C8 8.25739 8.31607 7.5452 8.87868 7.0201C9.44129 6.495 10.2044 6.2 11 6.2ZM11 2C16 2 20.27 4.90267 22 9C20.27 13.0973 16 16 11 16C6 16 1.73 13.0973 0 9C1.73 4.90267 6 2 11 2ZM2.18 9C2.98825 10.5403 4.24331 11.838 5.80248 12.7457C7.36165 13.6534 9.1624 14.1346 11 14.1346C12.8376 14.1346 14.6383 13.6534 16.1975 12.7457C17.7567 11.838 19.0117 10.5403 19.82 9C19.0117 7.45971 17.7567 6.16197 16.1975 5.2543C14.6383 4.34662 12.8376 3.86544 11 3.86544C9.1624 3.86544 7.36165 4.34662 5.80248 5.2543C4.24331 6.16197 2.98825 7.45971 2.18 9Z"
                      fill="#D9D9D9"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <div className="pt-1 secondary-deep">
            <p>英文字母大小寫加上數字,共8~20位英數字</p>
          </div>
          {errorInput(
            passwordOnblur || password || sendCaptchaError || checkError,
            isValidPassword(password),
            "error-pwd",
            "請輸入正確的密碼(英文字母大小寫加上數字,共8~20位英數字)",
            "d-block",
            "d-none"
          )}
        </div>
        <div>
          <label htmlFor="confirm-pwd" className="form-label d-block">
            <h6 className="primary-deep2">再次輸入密碼(必填)</h6>
          </label>
          {renderInputField(
            "confirm-pwd",
            "password",
            "confirm-pwd",
            "form-input",
            "請再次輸入密碼",
            confirmPassword,
            handleConfirmPasswordChange,
            "",
            () => {
              setConfirmPasswordBlur(true);
            },
            () => {
              setConfirmPasswordBlur(true);
            }
          )}
          {errorInput(
            confirmPasswordOnBlur || sendCaptchaError || checkError,
            !confirmPasswordError,
            "error-confirm-pwd",
            "再次輸入密碼和密碼不相同",
            "d-block",
            "d-none"
          )}
        </div>
        <div>
          
          <div className="capcha-box">
            <div className="capcha-foriput">
              <label htmlFor="captcha" className="form-label d-block">
                <h6 className="primary-deep2">驗證碼(必填)</h6>
              </label>
              {renderInputField(
                "captcha",
                "text",
                "captcha",
                "form-input",
                "輸入驗證碼",
                captcha ,
                handleCaptchaChange,
                "",
                () => {
                  setCaptchaBlur(true);
                },
                () => {
                  setCaptchaBlur(false);
                  setFormCheck(false);
                  setIsSameCaptcha(true);
                }
              )}
            </div>
            <div className="capcha-photo pt-2">
              <button
                onClick={handlePhoneCheck}
                className="btn primary-btn"
                disabled={verifysent}
              >
                <p className="p2">
                  {verifysent ? `發送驗證碼(${countdown})` : "發送驗證碼"}
                </p>
              </button>
            </div>
          </div>
          {/* <Nodata bgclass={"primary-deep3 "} icon={"noicon"} link={"nodescript"} descript={"收不到驗證碼?"}linkDescript={"點此不登入也可繼續結帳"}/> */}
          <TextPopup temp={"sms"} textstyle={"danger"} />
          {errorInput(
            captchaOnblur || (phonesent || sendCaptchaError  || checkError),
            isSameCaptcha ,
            "error-capcha",
            "請輸入正確的手機驗證碼",
            "d-block",
            "d-none"
          )}
          {errorInput(
            captchaOnblur || (phonesent || sendCaptchaError  || checkError),
            captcha ,
            "error-capcha",
            "請輸入正確的手機驗證碼",
            "d-block",
            "d-none"
          )}
          {errorInput(
            isValidPhoneNumber(phone) && phoneOnBlur,
            !captchaError,
            "error-capcha-limit",
            captchaError,
            "d-block",
            "d-none"
          )}
          {errorInput(
            isValidPhoneNumber(phone) && phonesent,
            phoneuniq,
            "error-phone-uniq",
            "該手機號碼已被註冊，無法發送驗證碼",
            "d-block",
            "d-none"
          )}
          {sendCaptchaError && (
            <div
              className={`pt-0 ${
                !isValidPhoneNumber(phone) ||
                !isValidEmail(email) ||
                !isValidName(name) ||
                !isValidPassword(password) ||
                confirmPasswordError
                  ? "d-block w-100"
                  : "d-none"
              }`}
            >
              <Nodata
                icon={"dangernotice"}
                dangerclass={"ps-1 p1"}
                dangerdescript={`未填寫正確的${
                  isValidPhoneNumber(phone)
                    ? ""
                    : !isValidPhoneNumber(phone) &&
                      isValidEmail(email) &&
                      isValidName(name) &&
                      isValidPassword(password) &&
                      !confirmPasswordError
                    ? "手機"
                    : "手機、"
                }${
                  isValidEmail(email)
                    ? ""
                    : !isValidEmail(email) &&
                      isValidName(name) &&
                      isValidPassword(password) &&
                      !confirmPasswordError
                    ? "信箱"
                    : "信箱、"
                }
                  ${
                    isValidName(name)
                      ? ""
                      : !isValidName(name) &&
                        isValidPassword(password) &&
                        !confirmPasswordError
                      ? "姓名"
                      : "姓名、"
                  }
                  ${
                    isValidPassword(password)
                      ? ""
                      : !isValidPassword(password) && !confirmPasswordError
                      ? "密碼"
                      : "密碼、"
                  }
                  ${
                    !confirmPasswordError
                      ? ""
                      : !!confirmPasswordError
                      ? "再次輸入密碼"
                      : ""
                  }
                  ，無法發送驗證碼`}
                bgclass={"bg-white"}
              />
            </div>
          )}
        </div>

        <div className="login-btn pt-2 ">
          <div className="d-flex flex-column gap-1 w-100">
            <button
              className={`${isRegisterPopupOpen ? "disabled" : ""} btn ${
                buttondescript ? "primary-btn-big2" : "primary-btn2"
              }  `}
              type="submit"
              // disabled={
              //   !phoneuniq ||
              //   isRegisterPopupOpen ||
              //   !captcha ||
              //   !isValidPhoneNumber(phone) ||
              //   !isValidEmail(email) ||
              //   !isValidName(name) ||
              //   !isValidPassword(password) ||
              //   confirmPasswordError ||
              //   !phonesent ||
              //   !verifysent ||
              //   !isSameCaptcha
              // }
            >
              {buttondescript && (
                <h6 className="font-weight-bold ">{buttondescript}</h6>
              )}
              {!buttondescript && "註冊"}
            </button>
            <div
              className={`pt-0 ${
                !isValidPhoneNumber(phone) ||
                !isValidEmail(email) ||
                !isValidName(name) ||
                !isValidPassword(password) ||
                !!confirmPasswordError ||
                !captcha
                  ? "d-block w-100"
                  : "d-none"
              }`}
            >
              {/* <Nodata
                icon={"dangernotice"}
                dangerclass={"ps-1"}
                dangerdescript={`請填寫正確的${
                  isValidPhoneNumber(phone)
                    ? ""
                    : !isValidPhoneNumber(phone) &&
                      isValidEmail(email) &&
                      isValidName(name) &&
                      isValidPassword(password) &&
                      !confirmPasswordError &&
                      !isSameCaptcha
                    ? "手機"
                    : "手機、"
                }${
                  isValidEmail(email)
                    ? ""
                    : !isValidEmail(email) &&
                      isValidName(name) &&
                      isValidPassword(password) &&
                      !confirmPasswordError &&
                      !captcha
                    ? "信箱"
                    : "信箱、"
                }
                ${
                  isValidName(name)
                    ? ""
                    : !isValidName(name) &&
                      isValidPassword(password) &&
                      !confirmPasswordError &&
                      !captcha
                    ? "姓名"
                    : "姓名、"
                }
                ${
                  isValidPassword(password)
                    ? ""
                    : !isValidPassword(password) &&
                      !confirmPasswordError &&
                      !captcha
                    ? "密碼"
                    : "密碼、"
                }
                ${
                  !confirmPasswordError
                    ? ""
                    : !!confirmPasswordError && !captcha
                    ? "再次輸入密碼"
                    : "再次輸入密碼、"
                }
                ${captcha ? "" : !captcha ? "驗證碼" : ""}`}
                bgclass={"bg-white"} 
              /> */}
            </div>
          </div>

          {/* <PwdLink state={"L"} /> */}
          {title && <CheckOutNa title={title} />}
        </div>
      </form>
    </>
  );
}

export default RegisterLocalSection;
