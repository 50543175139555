import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/notfound');
  }, [navigate]);

  return (
    <div className="container px-2 px-xxl-0 h-80vh">
      <div>
        <h3 className='primary-deep2'>該頁面已不存在</h3>
      </div>
      <Link to="/">
        <button className="btn primary-btn p-1 mt-3">
          <h6 className="mb-0">返回首頁</h6>
        </button>
      </Link>
    </div>
  );
};

export default NotFound;
