import React, { useEffect, useState } from "react";
import { Link,useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setFilteredTag } from "../redux/actions/action";
const Breadcrumbs = () => {
  const [pathnames, setPathnames] = useState([]);
  const location = useLocation();
  const { blogCateSlug, blogSlug } = useParams();

  console.log("searchWord")
  const navigate = useNavigate();
//redux儲存的filteredTag
const dispatch = useDispatch();
const filteredTag = useSelector(state => state.collection.filteredTag);
  // 映射路由路徑和對應的面包屑文本
  const breadcrumbMap = {
    "/": "",
    "/product": "產品詳細資訊",
    "/blogs": "最新消息",
    "/login": "國內會員登入",
    "/foreignlogin": "國外會員登入",
    "/register": "國內會員註冊",
    "/foreignregister": "國外會員註冊",
    "/contactus": "聯絡我們",
    "/notfound": "該頁面不存在",
    "/collections": "商品",
    // "/productdetails": "商品",
    "/member": "會員",
    "/member/order": "訂單資料",
    "/member/collection": "我的收藏",
    "/member/coupon": "折價券",
    "/member/point": "紅利點數",
    "/member/data": "會員資料",
    "/member/password": "修改密碼", 
    "/member/oldmember": "登入舊會員", 
    "/faqs": "常見問題",
    "/checkout": "結帳",
    "/checkoutstep1": "結帳",
    "/samples": "樣品索取",
    "/forgotpwd": "重設密碼",
    "/allcollections": "產品分類",
    "/finishorder": "結帳完成",
    "/thirdpartyregister": "第三方註冊",
    "/oldmemeberlogin":"舊會員登入",
    "/resetmemberpwd":"重新修改密碼",
    "/forgotoldmember":"舊會員忘記密碼",
    "/checkoutnotauth":"非會員結帳",
    "/notauthorder":"未登入訂單資料",
    "/cart":"購物車",
    "/search": "搜尋結果",
  };
  const handleBreadcrumbClick = (adjustedRouteTo) => {
    if (adjustedRouteTo === "/product") {
      // 如果是 "/product"，設定 filteredTag 並導航到 "/collections"
      dispatch(setFilteredTag([]));
      navigate("/collections");
    } else {
      // 其他情況正常導航
      navigate(adjustedRouteTo);
    }
  };
  // 在 useEffect 内部初始化 originPathnames
  useEffect(() => {
    const originPathnames = location.pathname.split("/").filter((x) => x);
    const sanitizedPathnames = originPathnames.filter(
      (pathname) =>
        pathname !== "pages" &&
        pathname !== "activity" && 
        pathname !== "ecoupon"&&
        pathname !== "promo"&&
        pathname !== "discount" &&
        pathname !== "search"
      //       (pathname) => pathname !== "pages" && pathname !== "collections"
    );
    
    setPathnames(sanitizedPathnames);
  }, [location.pathname, blogCateSlug, blogSlug]);

  console.log("Location pathname:", location.pathname);
  console.log("Pathnames:", pathnames);

  return (
    <div className="first-section container-md px-0">
      <div className="breadcumb d-flex align-items-center ps-2 ps-xxl-0">
        <Link to="/">
          <p>首頁</p>
        </Link>
        <p>/</p>
        {pathnames.map((name, index) => {
          console.log("name", name)
      const decodedName = decodeURIComponent(name);
      const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
      const isLast = index === pathnames.length - 1;
      const adjustedRouteTo = routeTo === "/product" ? "/collections" : routeTo;
      const breadcrumbText =
        breadcrumbMap[adjustedRouteTo] || decodedName;

        return isLast ? (
          <React.Fragment key={index}>
          <p className="mb-0">
            {!location.pathname.includes("/search/") ? breadcrumbText : `搜尋結果：${breadcrumbText}`}
          </p>
        </React.Fragment>

      ) : (
<React.Fragment key={index}>

<div className="cursor-pointer" onClick={() => handleBreadcrumbClick(adjustedRouteTo)}><p>{breadcrumbText}</p></div><p>/</p>
</React.Fragment>
      );
    })}
    
      </div>
    </div>
  );
};

export default Breadcrumbs;
