//App.js
import React, { useEffect, useContext, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import store from "./redux/store/store";
import AuthContext from "./Context/AuthContext";
import CheckoutStep4 from "./components/checkout/CheckoutStep4";
import Layout from "./components/Layout";
import Home from "./components/Home";
import ProductDetails from "./components/product/ProductDetails";
import NewsCate from "./components/NewsCate";
import NewsDetail from "./components/NewsDetail";
import Login from "./components/Login";

import Contactus from "./components/Contactus";

import Loading from "./components/Loading";
import Page from "./components/Page";
import NotFound from "./components/NotFound";
import Register from "./components/Register";
import AllCollections from "./components/AllCollections";
import Collection from "./components/Collection";
import { AuthContextProvider } from "./Context/AuthContext";
import Member from "./components/Member";
import "./custom.css";
// import TestRedux from './test/TestRedux';
import TestProdRedux from "./test/TestProdRedux";
import Testpopup from "./test/Testpopup";
import TestDoubledata from "./test/TestDoubledata";
import HistoryPage from "./test/HistoryPage";
import TestFAQ from "./test/TestFAQ";
import Faq from "./components/Faq";
// import Test from './test/Test';
import Checkout from "./components/checkout/Checkout";
import Sample from "./components/Sample";
import CheckoutStep1 from "./components/checkout/CheckoutStep1";
import ForgotPwd from "./components/ForgotPwd";
import ResetOldMemberPwd from "./sharedComponents/ResetOldMemberPwd";

import Logistics from "./components/Logistics";
import TryState from "./test/TryState";

import ThirdPartyRegister from "./sharedComponents/ThirdPartyRegister";
import ThirdPartyFinish from "./sharedComponents/ThirdPartyFinish";
import OldMemberLogin from "./sharedComponents/OldMemberLogin";
import ManualPwd from "./components/ManualPwd";

import CouponCollection from "./components/CouponCollection";
import OnePage from "./components/onepage/OnePage";
import OnePageOrder from "./components/onepage/OnePageOrder";
import Invoice from "./components/invoice/Invoice";
import UnauthOrder from "./components/checkoutnotauth/UnauthOrder";
import Cart from "./components/Cart";
function App() {
  const { myAuth } = useContext(AuthContext);
  const location = useLocation();
  // const [metaData, setMetaData] = useState({
  //   keywords: "",
  //   description: "",
  //   storeName: "",
  //   ogUrl: "",
  //   urltitle: "",
  // });

  console.log("重新刷新app ");

  //更新網站名稱
//   useEffect(() => {
//     fetch(`api/Home/StoreData`)
//       .then((response) => {
//         if (!response.ok) {
//           return null;
//         }
//         return response.json();
//       })
//       .then((data) => {
//         if (data) {
//           setMetaData({
//             keywords: data.metakeyword || "",
//             description: data.metadesc || "",
//             storeName: data.storename || "",
//             ogUrl: data.selfdomain || "",
//             urltitle: data.urltitle || "",
//           });
//         }
      
//  })
//       .catch(() => {
//         console.error("Error fetching data from API");
//       });
//   }, []);

  // 更新 meta keywords

  return (
    <AuthContextProvider>
      <Provider store={store}>
        <Layout>
          <ScrollToTop />
          <Routes>
          <Route path="*" element={<CustomNotFound />} />
            {/* Place NotFound at the beginning */}
            <Route exact path="/" element={<Home />} />
            <Route path="/product/:productSlug" element={<ProductDetails />} />
            <Route path="/blogs" element={<NewsCate />} />
            <Route
              path="/blogs/:blogCateSlug/:blogSlug"
              element={<NewsDetail />}
            />
            <Route path="/blogs/:blogCateSlug" element={<NewsCate />} />
            {/* Only allow access to /login and /foreignlogin if not authenticated */}
            <Route
              path="/login"
              element={
                <RequireNotAuth>
                  <Login />
                </RequireNotAuth>
              }
            />
            <Route path="/onepage/:onepageSlug" element={<OnePage />} />
            <Route path="/onepagepreview/:onepagePreviewSlug" element={<OnePage />} />
            <Route path="/onepageorder" element={<OnePageOrder />} />
            <Route path="/invoice/:invoiceSlug" element={<Invoice />} />
            <Route
              path="/foreignlogin"
              element={
                <RequireNotAuth>
                  <Login />
                </RequireNotAuth>
              }
            />

            {/* Only allow access to /register and /foreignregister if not authenticated */}
            <Route
              path="/register"
              element={
                <RequireNotAuth>
                  <Register />
                </RequireNotAuth>
              }
            />
            <Route
              path="/foreignregister"
              element={
                <RequireNotAuth>
                  <Register />
                </RequireNotAuth>
              }
            />
            <Route
              path="/thirdpartyregister"
              element={
                <RequireNotAuth>
                  <ThirdPartyRegister />
                </RequireNotAuth>
              }
            />
            <Route
              path="/thirdpartyfinish"
              element={
                <RequireNotAuth>
                  <ThirdPartyFinish />
                </RequireNotAuth>
              }
            />
            <Route
              path="/oldmemeberlogin"
              element={
                <RequireNotAuth>
                  <OldMemberLogin />
                </RequireNotAuth>
              }
            />
            <Route
              path="/activity/:couponType/:couponSlug"
              element={<CouponCollection />}
            />
                        <Route
              path="/search/:searchWord"
              element={<CouponCollection />}
            />
             <Route path="/cart" element={<Cart />} />
            <Route path="/contactus" element={<Contactus />} />
            {/* <Route path="/test" element={<Testpopup />} /> */}
            {/* <Route path="/testdoubledata" element={<TestDoubledata />} /> */}
            <Route path="/pages/:pageSlug" element={<Page />} />
            <Route
              path="/collections/:collectionsSlug"
              element={<Collection />}
            />
            <Route
              path="/collections/"
              element={<Navigate to="/collections/全部" replace />}
            />

            <Route path="/allcollections" element={<AllCollections />} />
            {/* <Route path="/historypage" element={<HistoryPage />} /> */}
            <Route path="/testredux/:productSlug" element={<TestProdRedux />} />
            <Route path="/testfaq" element={<TestFAQ />} />
            <Route path="/faqs" element={<Faq />} />

            {/* <Route path='/member' element={<MemberGuard />}>
            <Route index element={<Member />} />
          </Route> */}
            <Route
              path="/member/*"
              element={<MemberGuard state={location.pathname} />}
            />
            <Route
              path="/resetmemberpwd"
              element={
                <RequireAuth>
                  <ManualPwd />
                </RequireAuth>
              }
            />
            <Route
              path="/checkoutstep1"
              element={
                <RequireNotAuth>
                  <CheckoutStep1 />
                </RequireNotAuth>
              }
            />
            <Route path="/checkout" element={<CheckoutWrapper />}>
              <Route index element={<Checkout />} />
            </Route>
            <Route
              path="/checkoutnotauth"
              element={
                <RequireNotAuth>
                  <Checkout />
                </RequireNotAuth>
              }
            />

             <Route path="/notauthorder" element={<UnauthOrder />} />
            <Route path="/finishorder" element={<CheckoutStep4 />} />

            <Route path="/samples" element={<Sample />} />
            {/* <Route path='/testredux' element={<TestRedux />} /> */}
            <Route
              path="/forgotpwd"
              element={
                <RequireNotAuth>
                  <ForgotPwd />
                </RequireNotAuth>
              }
            />
            <Route
              path="/forgotoldmember"
              element={
               
                  <ResetOldMemberPwd />
                
              }
            />
          
            <Route path="/Logistics" element={<Logistics />} />
            <Route path="/Trystate" element={<TryState />} />
          </Routes>
        </Layout>
      </Provider>
    </AuthContextProvider>
  );
}
//例外不渲染NotFound的組件
function CustomNotFound() {
  const location = useLocation();
  if (location.pathname.includes("HiTRUST/B2C_Auth")) {
    return null;
  }

  return <NotFound />; 
}

// Guard for the /member route
function MemberGuard({ state }) {
  const { myAuth } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  if (myAuth.authorized !== null) {
    if (myAuth.authorized) {
      console.log("MemberGuard refresh state");
      return <Member state={state} />;
    } else {
      console.log("MemberGuard refresh Navigate");
      return <Navigate to="/login" />;
    }
  }
}
function CheckoutWrapper() {
  const { myAuth, updateCartAmount } = useContext(AuthContext);

  const [cartlength, setCartLength] = useState(null); // 设置初始值为 null
  const [isLoading, setIsLoading] = useState(true);
  const selectedOption = useSelector((state) => state.product.productId);

  const localcartData = localStorage.getItem("cart");
  const { pathname } = useLocation();

  // 初次載入此頁面要先判斷是否有購物車的數量，更新購物車數量
  useEffect(() => {
    setIsLoading(true);
    if (myAuth.authorized !== null) {
      if (myAuth.authorized) {
        updateCartAmount();
        setCartLength(myAuth.cartamount);
        console.log("selectedOption", "selectedOption");
        if (selectedOption) {
          if (myAuth.cartamount > 0) {
            setIsLoading(false);

          } else {
            setIsLoading(true);

          }
        } else {
          setIsLoading(false);
        }
      } else {
        
        if(localcartData=== null){
          setCartLength(0);
          setIsLoading(false);
        }else{
          setCartLength(localcartData.length);
          setIsLoading(false);
        }

      }
    }
  }, [myAuth.authorized, pathname, cartlength, myAuth.cartamount, myAuth.cartData]);


  if (isLoading || cartlength===null) {
    <Loading />;
    return;
  } else {
    if (cartlength > 0 && myAuth.authorized) {
      console.log("有抓到購物車和會員資料");
      return <Checkout />;
    } else if (cartlength > 0 && !myAuth.authorized) {
      console.log("有抓到購物車沒有會員資料");
      return <Checkout />;
      // return <Navigate to="/checkoutstep1" />;
    } else if (cartlength === 0 && myAuth.authorized) {
      // 更新这里的条件
      console.log("沒抓到購物車資料但是已登入");
      return <Navigate to="/member/order" />;
    } else if (cartlength === 0 || !myAuth.authorized) {
      // 更新这里的条件
      console.log("沒抓到會員和購物車資料");
      // return <Checkout />;
      return <Navigate to="/checkoutstep1" />;
    }else{
      console.log("例外情況")
    }
  }
}

function RequireNotAuth({ children }) {
  const { myAuth } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);

  if (myAuth.authorized !== null) {
    if (myAuth.authorized) {
      // If authenticated, navigate away from the restricted route
      return <Navigate to="/member" replace />;
    } else {
      // If not authenticated, render the children
      console.log("RequireNotAuth refresh");
      return children;
    }
  }
}
function RequireAuth({ children }) {
  const { myAuth } = useContext(AuthContext);
  if (myAuth.authorized !== null) {
    if (myAuth.authorized) {
      // If authenticated, navigate away from the restricted route
      console.log("RequireAuth refresh");
      return children;
    } else {
      // If not authenticated, render the children
      return <Navigate to="/login" replace />;
    }
  }
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App;
