import React, { useState, useContext, useEffect } from "react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Zoom,
  Controller,
} from "swiper/modules";
// Direct React component imports

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

// 使用必要的Swiper模組
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function ImageSlider({ img }) {
  const [swiper, setSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activePopIndex, setActivePopIndex] = useState(0);

  const [mainSwiper, setMainSwiper] = useState(null);
  const [popupSwiper, setPopupSwiper] = useState(null);

  // 放大功能
  const [state, setState] = useState({
    backgroundImage: "",
    backgroundPosition: "0% 0%",
  });
  const [showPopup, setShowPopup] = useState(false);

  const handleMouseMove = (e, img) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setState({
      backgroundImage: `url(${img})`,
      backgroundPosition: `${x}% ${y}%`,
    });
  };

  //第一張及最後一張禁用
  const isPrevDisabled = activeIndex === 0;
  const isNextDisabled = activeIndex === img.length - 1;

  const isPopPrevDisabled = activePopIndex === 0;
  const isPopNextDisabled = activePopIndex === img.length - 1;
  const openPopup = () => {
    setShowPopup(true);
    console.log("activeIndex","activeIndex");
    setActivePopIndex(activeIndex);
  };

  const closePopup = (e) => {
    // Check if the click event originated from the overlapping area
    const isClickInsidePopup = e.target.closest(".product-img-popup");
    console.log("popupSwiper", "popupSwiper");
    console.log("mainSwiper", "mainSwiper");
    // setMainSwiper(popupSwiper);
    // Close the popup only if the click is outside the overlapping area
    if (!isClickInsidePopup) {
      setShowPopup(false);
    }
  };
  // useEffect(() => {
  //   console.log("swiper", swiper);
  // }, [swiper]);
  return (
    <>
      <div className="slider-box container product-img-container ">
        <div className="d-block d-xxl-sticky">
          <Swiper
            // install Swiper modules
            modules={[
              Navigation,
              Pagination,
              Scrollbar,
              A11y,
              Zoom,
              Controller,
            ]}
            spaceBetween={50}
            slidesPerView={1}
            navigation={{
              prevEl: ".prev",
              nextEl: ".next",
            }}
            pagination={{ clickable: true, el: ".swiper-pagination" }}
            onSwiper={(swiper) => setMainSwiper(swiper)}
            controller={{ control: popupSwiper }}
            zoom={true}
            onSlideChange={() => console.log("slide change")}
            onActiveIndexChange={(swiper) => {
              setActiveIndex(swiper.activeIndex);
            }}
          >
            <div
              className="position-absolute bottom-0 start-0 pb-3 z-index-30 d-none d-xxl-block"
              onClick={openPopup}
            >
              <div className="swiper-magnify p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M19.8176 19.4416L14.3081 13.8781C15.4879 12.4636 16.1981 10.6396 16.1981 8.64771C16.1981 4.15292 12.5869 0.509308 8.10867 0.509308C3.6304 0.509308 0 4.1529 0 8.64738C0 13.1419 3.63044 16.7855 8.10867 16.7855C10.1528 16.7855 12.0151 16.0243 13.4366 14.7717L18.9364 20.3262C19.1797 20.5703 19.5743 20.5703 19.8173 20.3262C20.0609 20.0818 20.0609 19.686 19.8176 19.4416L19.8176 19.4416ZM8.09815 15.5139C4.33036 15.5139 1.24592 12.4182 1.24592 8.63679C1.24592 4.85534 4.33036 1.75972 8.09815 1.75972C11.8659 1.75972 14.9504 4.85534 14.9504 8.63679C14.9504 12.4182 11.8659 15.5139 8.09815 15.5139ZM11.2128 8.0116H8.72108V5.51085C8.72108 5.16575 8.44201 4.88566 8.09815 4.88566C7.7543 4.88566 7.47522 5.16575 7.47522 5.51085V8.0116H4.9835C4.63964 8.0116 4.36057 8.29169 4.36057 8.63679C4.36057 8.9819 4.63964 9.26198 4.9835 9.26198H7.47522V11.7627C7.47522 12.1078 7.7543 12.3879 8.09815 12.3879C8.44201 12.3879 8.72108 12.1078 8.72108 11.7627V9.26198H11.2128C11.5567 9.26198 11.8357 8.9819 11.8357 8.63679C11.8357 8.29169 11.557 8.0116 11.2128 8.0116Z"
                    fill="#364F38"
                  />
                </svg>
              </div>
            </div>
            {img.map((image, index) => (
              <div key={index}>
                <SwiperSlide key={`slide-${index}`}>
                  {/* {index} */}

                  <figure
                    onMouseMove={(e) => handleMouseMove(e, image)}
                    style={state}
                    onClick={openPopup}
                    className="cursor-pointer "
                  >
                    <img className="product-img " src={image} alt="Zoomed" />
                  </figure>
                </SwiperSlide>
              </div>
            ))}

            <div className="container h-100percent d-flex justify-content-center align-items-center gap-2 gap-xxl-3 pt-2">
              <div
                className={`d-none d-xxl-flex cursor-pointer ${
                  isPrevDisabled ? "disabled" : ""
                }`}
                onClick={() => {
                  mainSwiper.slidePrev();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="14"
                  viewBox="0 0 17 14"
                  fill="none"
                  className="swiper-btn"
                >
                  <path
                    d="M8.07737 6.875C8.07737 7.03943 8.1082 7.19892 8.16986 7.35348C8.23152 7.50803 8.31373 7.63629 8.4165 7.73823L14.0891 13.4109C14.3152 13.637 14.603 13.75 14.9524 13.75C15.3018 13.75 15.5895 13.637 15.8156 13.4109C16.0417 13.1848 16.1547 12.897 16.1547 12.5476C16.1547 12.1982 16.0417 11.9105 15.8156 11.6844L11.0062 6.875L15.8156 2.06558C16.0417 1.8395 16.1547 1.55176 16.1547 1.20236C16.1547 0.852953 16.0417 0.565212 15.8156 0.339128C15.5895 0.113044 15.3018 5.38583e-07 14.9524 5.2331e-07C14.603 5.08037e-07 14.3152 0.113044 14.0891 0.339128L8.4165 6.01177C8.29318 6.13509 8.20562 6.26869 8.15383 6.41256C8.10203 6.55643 8.07655 6.71058 8.07737 6.875Z"
                    fill="#364F38"
                  />
                  <path
                    d="M7.92069e-06 6.875C7.9135e-06 7.03942 0.030838 7.19892 0.0924972 7.35347C0.154156 7.50803 0.236369 7.63629 0.339134 7.73823L6.01178 13.4109C6.23786 13.637 6.52561 13.75 6.87501 13.75C7.22441 13.75 7.51215 13.637 7.73824 13.4109C7.96432 13.1848 8.07736 12.897 8.07736 12.5476C8.07736 12.1982 7.96432 11.9105 7.73824 11.6844L2.92882 6.875L7.73824 2.06558C7.96432 1.8395 8.07736 1.55176 8.07736 1.20235C8.07736 0.852952 7.96432 0.56521 7.73824 0.339126C7.51215 0.113042 7.22441 -7.22737e-07 6.87501 -7.3801e-07C6.52561 -7.53283e-07 6.23786 0.113042 6.01178 0.339126L0.339134 6.01177C0.215816 6.13509 0.128259 6.26868 0.076465 6.41256C0.0246712 6.55643 -0.000814195 6.71058 7.92069e-06 6.875Z"
                    fill="#364F38"
                  />
                </svg>
              </div>
              <div className="img-number gap-1">
                {img.map((_, index) => (
                  <div
                    key={index}
                    className="  d-flex align-items-center"
                    onClick={() => mainSwiper.slideTo(index)}
                  >
                    <div
                      className={`point ${
                        activeIndex === index ? "select" : ""
                      }`}
                    ></div>
                  </div>
                ))}
              </div>
              <div
                className={`d-none d-xxl-flex cursor-pointer ${
                  isNextDisabled ? "disabled" : ""
                }`}
                onClick={() => {
                  mainSwiper.slideNext();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="14"
                  viewBox="0 0 17 14"
                  fill="none"
                  className="swiper-btn"
                >
                  <path
                    d="M8.07735 6.875C8.07735 7.03943 8.04652 7.19892 7.98486 7.35348C7.9232 7.50803 7.84099 7.63629 7.73823 7.73823L2.06558 13.4109C1.8395 13.637 1.55176 13.75 1.20235 13.75C0.852951 13.75 0.565209 13.637 0.339125 13.4109C0.113041 13.1848 -3.72837e-08 12.897 -5.25566e-08 12.5476C-6.78294e-08 12.1982 0.113041 11.9105 0.339125 11.6844L5.14854 6.875L0.339125 2.06558C0.113041 1.8395 -5.33202e-07 1.55176 -5.48475e-07 1.20236C-5.63748e-07 0.852953 0.113041 0.565212 0.339124 0.339128C0.565208 0.113044 0.852951 5.38583e-07 1.20235 5.2331e-07C1.55175 5.08037e-07 1.8395 0.113044 2.06558 0.339128L7.73823 6.01177C7.86155 6.13509 7.9491 6.26869 8.0009 6.41256C8.05269 6.55643 8.07818 6.71058 8.07735 6.875Z"
                    fill="#364F38"
                  />
                  <path
                    d="M16.1547 6.875C16.1547 7.03942 16.1239 7.19892 16.0622 7.35347C16.0006 7.50803 15.9184 7.63629 15.8156 7.73823L10.1429 13.4109C9.91686 13.637 9.62912 13.75 9.27972 13.75C8.93031 13.75 8.64257 13.637 8.41649 13.4109C8.1904 13.1848 8.07736 12.897 8.07736 12.5476C8.07736 12.1982 8.1904 11.9105 8.41649 11.6844L13.2259 6.875L8.41649 2.06558C8.1904 1.8395 8.07736 1.55176 8.07736 1.20235C8.07736 0.852952 8.1904 0.56521 8.41649 0.339126C8.64257 0.113042 8.93031 -7.22737e-07 9.27972 -7.3801e-07C9.62912 -7.53283e-07 9.91686 0.113042 10.1429 0.339126L15.8156 6.01177C15.9389 6.13509 16.0265 6.26868 16.0783 6.41256C16.1301 6.55643 16.1555 6.71058 16.1547 6.875Z"
                    fill="#364F38"
                  />
                </svg>
              </div>
            </div>
          </Swiper>
        </div>
        {showPopup && (
          <div
            className="d-none d-xxl-block product-img-popupbg"
            onClick={closePopup}
          >
            <div className="d-sticky">
              <div
                className="position-absolute top-0 end-0 pb-3 "
                onClick={closePopup}
              >
                <div className="swiper-close p-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M13.5704 0.437361C13.474 0.340678 13.3594 0.263973 13.2332 0.211638C13.107 0.159302 12.9718 0.132363 12.8352 0.132363C12.6986 0.132363 12.5633 0.159302 12.4372 0.211638C12.311 0.263973 12.1964 0.340678 12.0999 0.437361L7 5.52685L1.90008 0.426932C1.80353 0.330375 1.6889 0.253783 1.56274 0.201527C1.43659 0.149271 1.30137 0.122375 1.16482 0.122375C1.02827 0.122375 0.893055 0.149271 0.766898 0.201527C0.640741 0.253783 0.526112 0.330375 0.429556 0.426932C0.333 0.523488 0.256407 0.638117 0.204151 0.764274C0.151895 0.89043 0.125 1.02564 0.125 1.1622C0.125 1.29875 0.151895 1.43396 0.204151 1.56012C0.256407 1.68627 0.333 1.8009 0.429556 1.89746L5.52947 6.99738L0.429556 12.0973C0.333 12.1938 0.256407 12.3085 0.204151 12.4346C0.151895 12.5608 0.125 12.696 0.125 12.8326C0.125 12.9691 0.151895 13.1043 0.204151 13.2305C0.256407 13.3566 0.333 13.4713 0.429556 13.5678C0.526112 13.6644 0.640741 13.741 0.766898 13.7932C0.893055 13.8455 1.02827 13.8724 1.16482 13.8724C1.30137 13.8724 1.43659 13.8455 1.56274 13.7932C1.6889 13.741 1.80353 13.6644 1.90008 13.5678L7 8.4679L12.0999 13.5678C12.1965 13.6644 12.3111 13.741 12.4373 13.7932C12.5634 13.8455 12.6986 13.8724 12.8352 13.8724C12.9717 13.8724 13.1069 13.8455 13.2331 13.7932C13.3593 13.741 13.4739 13.6644 13.5704 13.5678C13.667 13.4713 13.7436 13.3566 13.7958 13.2305C13.8481 13.1043 13.875 12.9691 13.875 12.8326C13.875 12.696 13.8481 12.5608 13.7958 12.4346C13.7436 12.3085 13.667 12.1938 13.5704 12.0973L8.47053 6.99738L13.5704 1.89746C13.9668 1.50115 13.9668 0.833674 13.5704 0.437361Z"
                      fill="#364F38"
                    />
                  </svg>
                </div>
              </div>
              <div className="product-img-popup">
                <div className="container h-100percent d-flex gap-1 gap-xxl-3  justify-content-center align-items-center">
                  <div
                    className={`d-block cursor-pointer ${
                      isPopPrevDisabled ? "disabled" : ""
                    }`}
                    onClick={() => {
                      popupSwiper.slidePrev();
                      // mainSwiper.slidePrev();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 17 14"
                      fill="none"
                      className="popup-swiper-btn"
                    >
                      <path
                        d="M8.07737 6.875C8.07737 7.03943 8.1082 7.19892 8.16986 7.35348C8.23152 7.50803 8.31373 7.63629 8.4165 7.73823L14.0891 13.4109C14.3152 13.637 14.603 13.75 14.9524 13.75C15.3018 13.75 15.5895 13.637 15.8156 13.4109C16.0417 13.1848 16.1547 12.897 16.1547 12.5476C16.1547 12.1982 16.0417 11.9105 15.8156 11.6844L11.0062 6.875L15.8156 2.06558C16.0417 1.8395 16.1547 1.55176 16.1547 1.20236C16.1547 0.852953 16.0417 0.565212 15.8156 0.339128C15.5895 0.113044 15.3018 5.38583e-07 14.9524 5.2331e-07C14.603 5.08037e-07 14.3152 0.113044 14.0891 0.339128L8.4165 6.01177C8.29318 6.13509 8.20562 6.26869 8.15383 6.41256C8.10203 6.55643 8.07655 6.71058 8.07737 6.875Z"
                        fill="#364F38"
                      />
                      <path
                        d="M7.92069e-06 6.875C7.9135e-06 7.03942 0.030838 7.19892 0.0924972 7.35347C0.154156 7.50803 0.236369 7.63629 0.339134 7.73823L6.01178 13.4109C6.23786 13.637 6.52561 13.75 6.87501 13.75C7.22441 13.75 7.51215 13.637 7.73824 13.4109C7.96432 13.1848 8.07736 12.897 8.07736 12.5476C8.07736 12.1982 7.96432 11.9105 7.73824 11.6844L2.92882 6.875L7.73824 2.06558C7.96432 1.8395 8.07736 1.55176 8.07736 1.20235C8.07736 0.852952 7.96432 0.56521 7.73824 0.339126C7.51215 0.113042 7.22441 -7.22737e-07 6.87501 -7.3801e-07C6.52561 -7.53283e-07 6.23786 0.113042 6.01178 0.339126L0.339134 6.01177C0.215816 6.13509 0.128259 6.26868 0.076465 6.41256C0.0246712 6.55643 -0.000814195 6.71058 7.92069e-06 6.875Z"
                        fill="#364F38"
                      />
                    </svg>
                  </div>

                  <Swiper
                    // install Swiper modules
                    modules={[Navigation, Pagination, Scrollbar, A11y, Zoom]}
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation={{
                      prevEl: ".prev",
                      nextEl: ".next",
                    }}
                    pagination={{ clickable: true, el: ".swiper-pagination" }}
                    onSwiper={(swiper) => setPopupSwiper(swiper)}
                    controller={{ control: mainSwiper }}
                    zoom={true}
                    onSlideChange={() => console.log("slide change")}
                    onActiveIndexChange={(swiper) => {
                      setActivePopIndex(swiper.activeIndex);
                    }}
                  >
                    <div className="popup-img-box">
                      {img.map((image, index) => (
                        <div key={index}>
                          <SwiperSlide key={`slide-${index}`}>
                            {/* {index} */}

                            <img className="popup-img" src={image} />
                          </SwiperSlide>
                        </div>
                      ))}
                    </div>

                    <div className="d-none container d-flex justify-content-center align-items-center gap-2 gap-xxl-3 pt-2">
                      <div className="img-number gap-1 gap-xxl-3 ">
                        {img.map((_, index) => (
                          <div
                            key={index}
                            className=" h-100percent d-flex align-items-center"
                            onClick={() => mainSwiper.slideTo(index)}
                          >
                            <div
                              className={`point ${
                                activePopIndex === index ? "select" : ""
                              }`}
                            ></div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Swiper>
                  <div
                    className={`d-block cursor-pointer ${
                      isPopNextDisabled ? "disabled" : ""
                    }`}
                    onClick={() => {
                      popupSwiper.slideNext();
                      // mainSwiper.slidePrev();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 17 14"
                      fill="none"
                      className="popup-swiper-btn"
                    >
                      <path
                        d="M8.07735 6.875C8.07735 7.03943 8.04652 7.19892 7.98486 7.35348C7.9232 7.50803 7.84099 7.63629 7.73823 7.73823L2.06558 13.4109C1.8395 13.637 1.55176 13.75 1.20235 13.75C0.852951 13.75 0.565209 13.637 0.339125 13.4109C0.113041 13.1848 -3.72837e-08 12.897 -5.25566e-08 12.5476C-6.78294e-08 12.1982 0.113041 11.9105 0.339125 11.6844L5.14854 6.875L0.339125 2.06558C0.113041 1.8395 -5.33202e-07 1.55176 -5.48475e-07 1.20236C-5.63748e-07 0.852953 0.113041 0.565212 0.339124 0.339128C0.565208 0.113044 0.852951 5.38583e-07 1.20235 5.2331e-07C1.55175 5.08037e-07 1.8395 0.113044 2.06558 0.339128L7.73823 6.01177C7.86155 6.13509 7.9491 6.26869 8.0009 6.41256C8.05269 6.55643 8.07818 6.71058 8.07735 6.875Z"
                        fill="#364F38"
                      />
                      <path
                        d="M16.1547 6.875C16.1547 7.03942 16.1239 7.19892 16.0622 7.35347C16.0006 7.50803 15.9184 7.63629 15.8156 7.73823L10.1429 13.4109C9.91686 13.637 9.62912 13.75 9.27972 13.75C8.93031 13.75 8.64257 13.637 8.41649 13.4109C8.1904 13.1848 8.07736 12.897 8.07736 12.5476C8.07736 12.1982 8.1904 11.9105 8.41649 11.6844L13.2259 6.875L8.41649 2.06558C8.1904 1.8395 8.07736 1.55176 8.07736 1.20235C8.07736 0.852952 8.1904 0.56521 8.41649 0.339126C8.64257 0.113042 8.93031 -7.22737e-07 9.27972 -7.3801e-07C9.62912 -7.53283e-07 9.91686 0.113042 10.1429 0.339126L15.8156 6.01177C15.9389 6.13509 16.0265 6.26868 16.0783 6.41256C16.1301 6.55643 16.1555 6.71058 16.1547 6.875Z"
                        fill="#364F38"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ImageSlider;
