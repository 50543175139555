import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
function WordSearch({ searchControl }) {
  const [keyWord, setKeyWord] = useState("");
  const [unsafeWord, setUnsafeWord] = useState(false);
  const navigate = useNavigate();

  const maxKeywordLength = 50; // 最大關鍵字長度限制
  const handleChange = (event) => {
    // 更新關鍵字狀態
    const keyword = event.target.value.slice(0, maxKeywordLength); // 切割關鍵字以符合最大長度限制
    const allowedPattern = /[!@#$%^&*()_+\-=\\\/\[\]\{\}\|,.\;<>:'"?]+/;
    const filteredKeyword = keyword.replace(allowedPattern, "").trim();
    setKeyWord(filteredKeyword);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      startSearch();
    }
  };

  const startSearch = () => {
    // 使用正則表達式過濾特殊符號
    const allowedPattern = /[!@#$%^&*()_+\-=\\\/\[\]\{\}\|,.\;<>:'"?]+/;
    const filteredKeyword = keyWord.replace(allowedPattern, "").trim();
    if (filteredKeyword) {
      navigate(`/search/${filteredKeyword}`);
    } else {
      console.log("請輸入有效的關鍵字");
      setUnsafeWord(true)
    }
  };

  return (
<>

<div className="word-section  d-flex w-100">

<input
  type="text"
  className="form-input word-search-bg border-0 w-100"
  value={keyWord}
  onChange={handleChange}
  onKeyDown={handleKeyDown} // 監聽鍵盤事件
/>
<div className="btn-group">
  <div className="search-icon" onClick={startSearch}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <g clipPath="url(#clip0_4873_28909)">
        <path
          d="M21.6818 24.1245L14.0455 16.4772C13.3636 17.0235 12.5795 17.4559 11.6932 17.7745C10.8068 18.0932 9.86364 18.2525 8.86364 18.2525C6.38636 18.2525 4.29 17.3931 2.57455 15.6743C0.859091 13.9555 0.000909091 11.8561 0 9.37624C0 6.89545 0.858182 4.7961 2.57455 3.07821C4.29091 1.36031 6.38727 0.50091 8.86364 0.5C11.3409 0.5 13.4373 1.3594 15.1527 3.07821C16.8682 4.79701 17.7264 6.89636 17.7273 9.37624C17.7273 10.3777 17.5682 11.3222 17.25 12.2098C16.9318 13.0974 16.5 13.8826 15.9545 14.5654L23.625 22.2468C23.875 22.4972 24 22.8044 24 23.1686C24 23.5327 23.8636 23.8514 23.5909 24.1245C23.3409 24.3748 23.0227 24.5 22.6364 24.5C22.25 24.5 21.9318 24.3748 21.6818 24.1245ZM8.86364 15.5213C10.5682 15.5213 12.0173 14.9237 13.2109 13.7283C14.4045 12.533 15.0009 11.0823 15 9.37624C15 7.66927 14.4032 6.21812 13.2095 5.02279C12.0159 3.82745 10.5673 3.23024 8.86364 3.23115C7.15909 3.23115 5.71 3.82882 4.51636 5.02415C3.32273 6.21949 2.72636 7.67018 2.72727 9.37624C2.72727 11.0832 3.32409 12.5344 4.51773 13.7297C5.71136 14.925 7.16 15.5222 8.86364 15.5213Z"
          fill="#007B91"
        />
      </g>
      <defs>
        <clipPath id="clip0_4873_28909">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>

  <div className="close" onClick={searchControl}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <g clipPath="url(#clip0_4873_28914)">
        <path
          d="M23.4684 1.04979C23.3 0.881037 23.1 0.747152 22.8797 0.655803C22.6595 0.564453 22.4235 0.517433 22.185 0.517433C21.9466 0.517433 21.7106 0.564453 21.4903 0.655803C21.2701 0.747152 21.0701 0.881037 20.9017 1.04979L12 9.93326L3.09833 1.03159C2.92979 0.863054 2.72972 0.729365 2.50951 0.638155C2.28931 0.546945 2.0533 0.5 1.81496 0.5C1.57662 0.5 1.3406 0.546945 1.1204 0.638155C0.900203 0.729365 0.700123 0.863054 0.531589 1.03159C0.363054 1.20012 0.229365 1.4002 0.138155 1.6204C0.0469448 1.8406 -1.7758e-09 2.07662 0 2.31496C1.7758e-09 2.5533 0.0469448 2.78931 0.138155 3.00951C0.229365 3.22972 0.363054 3.42979 0.531589 3.59833L9.43326 12.5L0.531589 21.4017C0.363054 21.5702 0.229365 21.7703 0.138155 21.9905C0.0469448 22.2107 0 22.4467 0 22.685C0 22.9234 0.0469448 23.1594 0.138155 23.3796C0.229365 23.5998 0.363054 23.7999 0.531589 23.9684C0.700123 24.1369 0.900203 24.2706 1.1204 24.3618C1.3406 24.4531 1.57662 24.5 1.81496 24.5C2.0533 24.5 2.28931 24.4531 2.50951 24.3618C2.72972 24.2706 2.92979 24.1369 3.09833 23.9684L12 15.0667L20.9017 23.9684C21.0702 24.1369 21.2703 24.2706 21.4905 24.3618C21.7107 24.4531 21.9467 24.5 22.185 24.5C22.4234 24.5 22.6594 24.4531 22.8796 24.3618C23.0998 24.2706 23.2999 24.1369 23.4684 23.9684C23.6369 23.7999 23.7706 23.5998 23.8618 23.3796C23.9531 23.1594 24 22.9234 24 22.685C24 22.4467 23.9531 22.2107 23.8618 21.9905C23.7706 21.7703 23.6369 21.5702 23.4684 21.4017L14.5667 12.5L23.4684 3.59833C24.1602 2.90658 24.1602 1.74154 23.4684 1.04979Z"
          fill="#C0D4DA"
        />
      </g>
      <defs>
        <clipPath id="clip0_4873_28914">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
</div>
</div>

</>
   
  );
}

export default WordSearch;
