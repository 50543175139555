//OrderDetailPopup.js
import React, { useState, useRef, useEffect, useContext } from "react";
import Loading from "../components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import AuthContext from "../Context/AuthContext";
// import OrderDetailList from "./OrderDetailList";
import Cartlist from "./Cartlist";
import useFormattedDate from "./useFormattedDate";
import Promolist from "./Promolist";

function OrderDetailPopup({ onClose, onNevigate, orderpopupinfo, orderAgain }) {
  const { myAuth, logout } = useContext(AuthContext);
  const mid = parseInt(myAuth.sid);
  const storedToken = myAuth.token;
  const [orderInfo, setOrderInfo] = useState([]);
  const [orderdetailData, setOrderdetailData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showmoreShiping, setShowmoreShipping] = useState(true);
  const [homeShipData, setHomeShipData] = useState(null);
  const [cvsShipData, setCvsShipData] = useState(null);
  const [atmData, setATMData] = useState(null);
  //自取地址
  const [storePickUp, setStorePickUp] = useState("");
  //地址格式轉換
  const [addressData, setAddressData] = useState(null);
  const [formattedAddressData, setFormattedAddressData] = useState(null);
  const [formatedReceiveShop, setFormatedReceiveShop] = useState([]);

  
  const orderNo = orderpopupinfo.orderNo;
  const {
    formattedDate: orderFormattedDate,
    formattedTime: orderFormattedTime,
  } = useFormattedDate(orderInfo.orderDate);

  const {
    formattedDate: shippingFormattedDate,
    formattedTime: shippingFormattedTime,
  } = useFormattedDate(orderInfo.shippingDate);

  console.log("orderNo", "orderNo");

  const getStoreData = () => {
    axios
      .get("api/Home/StoreData")
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;

          console.log("撈取商家資料", "data");
          setStorePickUp(data.storePickup);
        }
      })
      .catch(() => {
        console.error("Error fetching data from API");
      });
  };
  console.log("formatedReceiveShop", "formatedReceiveShop");
  // 定義一個 URL 驗證函數
  const isValidURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };
  useEffect(() => {
    if (orderpopupinfo) {
      // console.log("orderinfo", orderNo)
      const requestData = {
        mid: mid,
        orderNo: orderNo.toString(),
      };
      axios
        .post("/api/Member/MemberOrderDetail", requestData, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("發送MemberOrderDetail的回應", response.data);
          console.log("發送MemberOrderDetail資訊:", "requestData");
          // setIsLoading(false);
          setOrderdetailData(response.data.orderItems);

          setOrderInfo(response.data.orderData);
          setAddressData(response.data.orderData.recipientAddr);
          if (response.data.orderData.receiveShop) {
            // 原始的 receiveShop 字符串
            const receiveShopString = response.data.orderData.receiveShop;

            // 分割字符串
            const receiveShopParts = receiveShopString.split("///");

            // 创建格式化的对象
            const formatedReceiveShop = {
              cvsCode: receiveShopParts[0],
              shopCode: receiveShopParts[1],
              shopName: receiveShopParts[2],
              shopAddress: receiveShopParts[3],
            };

            console.log("格式化後的數據", "formatedReceiveShop");
            setFormatedReceiveShop(formatedReceiveShop);
          } else {
            console.log("receiveShop為null。");
          }
          setIsLoading(false);
          // setOrderdetailData(response.data);
        })
        .catch((error) => {
          console.error("發送MemberOrderDetail的回應有錯誤：", "error");
          console.log("發送MemberOrderDetail資訊有錯誤", "requestData");
          if (error.response && error.response.status === 401) {
            // 如果是 401，觸發 logout()
            logout();
          }
        });
      getStoreData();
    }
  }, []);

  useEffect(() => {
    if (addressData) {
      console.log("addressData", "addressData");
      const addressParts = addressData.split("///");
      const postcode = addressParts[0];
      const country = addressParts[1];
      const township = addressParts[2];
      const address = addressParts[3];

      const formattedData = {
        postcode,
        country,
        township,
        address,
      };

      console.log("格式化後的數據", "formattedData");
      setFormattedAddressData(formattedData);
    }
  }, [addressData]);
  useEffect(() => {
    // 格式化shippingNo
    console.log("orderInfo", "orderInfo");
    if (orderInfo && orderInfo.shippingNo) {
      console.log("orderInfo.shippingNo", "orderInfo.shippingNo");
      const HomeShipPart = orderInfo.shippingNo.split("///");
      const shipName = HomeShipPart[0];
      const shipLink = HomeShipPart[1];
      const shipNumber = HomeShipPart[2];

      const formattedData = {
        shipName,
        shipLink,
        shipNumber,
      };

      console.log("格式化後的數據", "formattedData");
      setHomeShipData(formattedData);
    } else if (orderInfo && orderInfo.cvsData) {
      console.log("orderInfo.cvsData", "orderInfo.cvsData");
      const CvsShip = orderInfo.cvsData.split("///");
      let shipCode;

      if(CvsShip.length === 5) {
        // 如果有5段数据,shipCode取第3和第4段
        shipCode = CvsShip[3] + CvsShip[4]; 
      } else {
        // 如果没有第5段数据,shipCode取第3段
        shipCode = CvsShip[3];
      }
      
      const formattedData = {
        shipNumber: CvsShip[0],
        shipCode,
        shipStatus: CvsShip[2]
      };

      console.log("格式化後的數據", "formattedData");
      setCvsShipData(formattedData);
    } else {
      console.log("沒有shippingNo");
    }
    // 格式化shippingNo atmBack
    if (orderInfo && orderInfo.atmBack) {
      console.log("orderInfo.atmBack", "orderInfo.atmBack");
      const ATMPart = orderInfo.atmBack.split("///");
      const atmDate = ATMPart[0];
      const atmCode = ATMPart[1];

      const formattedData = {
        atmDate,
        atmCode,
      };

      console.log("atmBack", "formattedData");
      setATMData(formattedData);
    } else {
      console.log("沒有atmBack");
    }
  }, [orderInfo]);

  const handleShowShipping = () => {
    setShowmoreShipping(!showmoreShiping); // Use the current state value (showmoreShiping) instead of passing a parameter
  };

  // if (isLoading) {
  //   return <Loading />;
  // }
  return (
    <div className="popup-cart">
      <div className="popup-select">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <div className="page-title">
              <div className="d-flex">
                <h5>訂單編號</h5>
                <h5 className="ps-1">{orderInfo.orderNo}</h5>
              </div>
              <button className="btn" onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                  className="d-block d-xxl-none"
                >
                  <path
                    d="M13.5704 0.939986C13.474 0.843302 13.3594 0.766598 13.2332 0.714262C13.107 0.661926 12.9718 0.634987 12.8352 0.634987C12.6986 0.634987 12.5633 0.661926 12.4372 0.714262C12.311 0.766598 12.1964 0.843302 12.0999 0.939986L7 6.02947L1.90008 0.929556C1.80353 0.833 1.6889 0.756407 1.56274 0.704151C1.43659 0.651895 1.30137 0.625 1.16482 0.625C1.02827 0.625 0.893055 0.651895 0.766898 0.704151C0.640741 0.756407 0.526112 0.833 0.429556 0.929556C0.333 1.02611 0.256407 1.14074 0.204151 1.2669C0.151895 1.39305 0.125 1.52827 0.125 1.66482C0.125 1.80137 0.151895 1.93659 0.204151 2.06274C0.256407 2.1889 0.333 2.30353 0.429556 2.40008L5.52947 7.5L0.429556 12.5999C0.333 12.6965 0.256407 12.8111 0.204151 12.9373C0.151895 13.0634 0.125 13.1986 0.125 13.3352C0.125 13.4717 0.151895 13.6069 0.204151 13.7331C0.256407 13.8593 0.333 13.9739 0.429556 14.0704C0.526112 14.167 0.640741 14.2436 0.766898 14.2958C0.893055 14.3481 1.02827 14.375 1.16482 14.375C1.30137 14.375 1.43659 14.3481 1.56274 14.2958C1.6889 14.2436 1.80353 14.167 1.90008 14.0704L7 8.97053L12.0999 14.0704C12.1965 14.167 12.3111 14.2436 12.4373 14.2958C12.5634 14.3481 12.6986 14.375 12.8352 14.375C12.9717 14.375 13.1069 14.3481 13.2331 14.2958C13.3593 14.2436 13.4739 14.167 13.5704 14.0704C13.667 13.9739 13.7436 13.8593 13.7958 13.7331C13.8481 13.6069 13.875 13.4717 13.875 13.3352C13.875 13.1986 13.8481 13.0634 13.7958 12.9373C13.7436 12.8111 13.667 12.6965 13.5704 12.5999L8.47053 7.5L13.5704 2.40008C13.9668 2.00377 13.9668 1.3363 13.5704 0.939986Z"
                    fill="white"
                    fillOpacity="0.7"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  className="d-none d-xxl-block"
                >
                  <path
                    d="M24.4463 0.572701C24.2708 0.396914 24.0625 0.25745 23.8331 0.162295C23.6037 0.067139 23.3578 0.0181589 23.1094 0.0181589C22.8611 0.0181589 22.6152 0.067139 22.3858 0.162295C22.1564 0.25745 21.948 0.396914 21.7726 0.572701L12.5 9.82631L3.22743 0.553738C3.05187 0.378182 2.84345 0.238922 2.61408 0.143912C2.3847 0.0489008 2.13886 1.84979e-09 1.89058 0C1.64231 -1.84979e-09 1.39646 0.0489009 1.16709 0.143912C0.937711 0.238922 0.729295 0.378182 0.553738 0.553738C0.378182 0.729295 0.238922 0.937711 0.143912 1.16709C0.0489009 1.39646 -1.84979e-09 1.64231 0 1.89058C1.84979e-09 2.13886 0.0489008 2.3847 0.143912 2.61408C0.238922 2.84345 0.378182 3.05187 0.553738 3.22743L9.82631 12.5L0.553738 21.7726C0.378182 21.9481 0.238922 22.1565 0.143912 22.3859C0.0489009 22.6153 0 22.8611 0 23.1094C0 23.3577 0.0489009 23.6035 0.143912 23.8329C0.238922 24.0623 0.378182 24.2707 0.553738 24.4463C0.729295 24.6218 0.937711 24.7611 1.16709 24.8561C1.39646 24.9511 1.64231 25 1.89058 25C2.13886 25 2.3847 24.9511 2.61408 24.8561C2.84345 24.7611 3.05187 24.6218 3.22743 24.4463L12.5 15.1737L21.7726 24.4463C21.9481 24.6218 22.1565 24.7611 22.3859 24.8561C22.6153 24.9511 22.8611 25 23.1094 25C23.3577 25 23.6035 24.9511 23.8329 24.8561C24.0623 24.7611 24.2707 24.6218 24.4463 24.4463C24.6218 24.2707 24.7611 24.0623 24.8561 23.8329C24.9511 23.6035 25 23.3577 25 23.1094C25 22.8611 24.9511 22.6153 24.8561 22.3859C24.7611 22.1565 24.6218 21.9481 24.4463 21.7726L15.1737 12.5L24.4463 3.22743C25.1668 2.50686 25.1668 1.29327 24.4463 0.572701Z"
                    fill="#364F38"
                  />
                </svg>
              </button>
            </div>
            <div className="page-content-title">
              <h6>商品明細</h6>
              <h6>價格 / 單價</h6>
              <h6>數量</h6>
              <h6>小計</h6>
              <h6>加購</h6>
            </div>
            <div className="page-content">
              <div className="overflow-wrap">
                <div className="overflow">
                  {orderdetailData &&
                    orderdetailData.map((item, index) => (
                      <Cartlist
                        key={index}
                        cartitem={item}
                        DeleteShow={"d-none"}
                      />
                    ))}
                  {orderInfo.promoGiftData && orderInfo.promoGiftData.map((item, index) => (<Promolist key={index} promolist={item}/>))}

                </div>
              </div>
            </div>
            <div className="popup-footer">
              <div className="d-flex">
                <div className="d-flex gap-3">
                  <div
                    className=" btn edit px-0 py-0 pb-2"
                    onClick={handleShowShipping}
                  >
                    <p className="text-bold text-decoration-underline order-number primary-deep3 d-block d-xxl-none">
                      {showmoreShiping
                        ? "顯示更多寄送資訊 "
                        : "顯示較少寄送資訊 "}
                    </p>
                    <p className="text-bold text-decoration-underline order-number primary-deep3  d-none d-xxl-block">
                      {showmoreShiping
                        ? "顯示較少寄送資訊 "
                        : "顯示更多寄送資訊 "}
                    </p>
                  </div>
                </div>
              </div>

              <div
                className={` ${
                  showmoreShiping ? "d-none d-xxl-block" : "d-block  d-xxl-none"
                }  w-100`}
              >
                <div className="row g-0 pb-0 d-block d-xxl-flex ">
                  <div className="col d-flex d-xxl-block">
                    <div className="d-block">
                      <h6 className="text-bold">送貨資訊</h6>
                      <div className="row g-0 row-cols-1">
                        <div className="col d-flex align-items-center ">
                          <p className="pe-1 text-nowrap">送貨方式：</p>
                          <div className="pe-2 text-wrap d-flex gap-2 align-items-center ">
                            {orderInfo.receiveShop ? (
                              <>
                                <p className="order-number ">
                                  {orderInfo.shippingMethod}
                                  {cvsShipData ? "" : ""}
                                </p>
                                
                              </>
                            ) : (
                              <>
                                <p className=" order-number ">
                                  {orderInfo.shippingMethod}
                                </p>

                                {homeShipData &&
                                  isValidURL(homeShipData.shipLink) && (
                                    <a
                                      href={homeShipData.shipLink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="btn  secondary-btn-4 px-3 py-1 py-xxl-0 rounded"
                                    >
                                      {homeShipData.shipName}
                                    </a>
                                  )}
                              </>
                            )}
                          </div>
                        </div>
                        {orderInfo.receiveShop && cvsShipData && cvsShipData.shipStatus ? (
                          <div className="col d-flex ">
                            <p className="pe-1 text-nowrap">物流狀態：</p>
                            <div className="pe-2  d-flex gap-2 align-items-center ">
                            <p className=" order-number ">
                                  {cvsShipData.shipStatus}
                                </p>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                         {orderInfo.receiveShop && cvsShipData && cvsShipData.shipCode ? (
                          <div className="col d-flex align-items-center ">
                            <p className="pe-1 text-nowrap">查詢編號：</p>
                            <div className="pe-2 text-wrap d-flex gap-2 align-items-center ">
                            <p className=" order-number ">
                                  {cvsShipData.shipCode}
                                </p>
                            </div>
                            {/* <div className="btn  secondary-btn-4 px-3 py-1 py-xxl-0 rounded"  onClick={() => {
                            navigator.clipboard.writeText(cvsShipData.shipCode)
                          }}><p className=" order-number p1">複製</p></div> */}
                          </div>
                        ) : (
                          <></>
                        )}

                        {orderInfo.shippingDate && (
                          <div className="col d-flex ">
                            <p className="pe-1 text-nowrap">出貨日期：</p>
                            <p className="pe-2 text-nowrap">
                              {shippingFormattedDate}
                            </p>
                          </div>
                        )}
                        <div className="col d-flex ">
                          {homeShipData && (
                            <>
                              <p className="pe-1 text-nowrap">單據號碼：</p>
                              <p className="pe-2 text-nowrap">
                                {homeShipData.shipNumber}
                              </p>
                            </>
                          )}
                        </div>
                        <div className="col d-flex ">
                          <p className="pe-1 text-nowrap">收件人姓名：</p>
                          <p className="pe-2 text-nowrap">
                            {orderInfo.recipientName}
                          </p>
                        </div>
                        <div className="col d-flex ">
                          <p className="pe-1 text-nowrap">收件人手機：</p>
                          <p className="pe-2 text-nowrap">
                            {orderInfo.recipientMobile}
                          </p>
                        </div>
                        {orderInfo.recipientTel && (
                          <div className="col d-flex ">
                            <p className="pe-1 text-nowrap">收件人電話：</p>
                            <p className="pe-2 text-nowrap">
                              {orderInfo.recipientTel}
                            </p>
                          </div>
                        )}

                        {formattedAddressData && (
                          <div className="col d-flex ">
                            <p className="pe-1 text-nowrap">收件人地址：</p>
                            <p className="pe-0 flex-wrap">
                              {formattedAddressData.postcode}
                              {formattedAddressData.country}
                              {formattedAddressData.township}
                              {formattedAddressData.address}
                            </p>
                          </div>
                        )}
                        {formatedReceiveShop &&
                          Object.keys(formatedReceiveShop).length > 0 && (
                            <>
                              <div className="col d-flex">
                                <p className="pe-1 text-nowrap">超商店名：</p>
                                <p className="pe-0 flex-wrap">
                                  {formatedReceiveShop.shopName}
                                </p>
                              </div>
                              <div className="col d-flex">
                                <p className="pe-1 text-nowrap">超商地址：</p>
                                <p className="pe-0 flex-wrap">
                                  {formatedReceiveShop.shopAddress}
                                </p>
                              </div>
                            </>
                          )}
                        {orderInfo.shippingId === "7" && storePickUp !== "" && (
                          <div className="col d-flex ">
                            <p className="pe-1 text-nowrap">自取地址：</p>
                            <p className="pe-2 text-nowrap">{storePickUp}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col  d-flex d-xxl-block">
                    <div className="d-block">
                      <h6 className="text-bold">訂單資訊</h6>
                      <div className="row g-0 row-cols-1">
                        <div className="col d-flex ">
                          <p className="pe-1 text-nowrap">訂單編號：</p>
                          <p className="pe-2 text-nowrap">
                            {orderInfo.orderNo}
                          </p>
                        </div>

                        {orderInfo.orderDate && (
                          <div className="col d-flex ">
                            <p className="pe-1 text-nowrap">訂單日期：</p>
                            <div className="d-flex flex-wrap">
                            <p className="pe-2 text-nowrap">
                              {orderFormattedDate}
                            </p>
                            <p className="pe-2 text-nowrap">
                              {orderFormattedTime}
                            </p>
                            </div>
                           
                          </div>
                        )}

                        <div className="col d-flex ">
                          <p className="pe-1 text-nowrap">訂單狀態：</p>
                          <p className="pe-2 text-nowrap">
                            {orderInfo.orderStatus}
                          </p>
                        </div>
                        <div className="col d-flex ">
                          <p className="pe-1 text-nowrap">訂單備註：</p>
                          <p className="pe-2 text-nowrap">
                            {orderInfo.orderRemark
                              ? orderInfo.orderRemark
                              : "無"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col  d-flex d-xxl-block">
                    <div className="d-block">
                      <h6 className="text-bold">付款資訊</h6>
                      <div className="row g-0 row-cols-1">
                        <div className="col d-flex ">
                          <p className="pe-1 text-nowrap">付款方式：</p>
                          <p className="pe-2 text-nowrap">
                            {orderInfo.paymentMethod}
                          </p>
                        </div>
                        <div className="col d-flex ">
                          <p className="pe-1 text-nowrap">付款狀態：</p>
                          <p className="pe-2 text-nowrap">
                            {orderInfo.paymentStatus}
                          </p>
                        </div>
                        {orderInfo.atmBack && atmData && (
                          <>
                            <div className="col d-flex ">
                              <p className="pe-1 text-nowrap">轉帳日期：</p>
                              <p className="pe-2 text-nowrap">
                                {atmData.atmDate}
                              </p>
                            </div>
                            <div className="col d-flex ">
                              <p className="pe-1 text-nowrap">
                                轉帳帳號後5碼：
                              </p>
                              <p className="pe-2 text-nowrap">
                                {atmData.atmCode}
                              </p>
                            </div>
                          </>
                        )}

                        {/* <div className="col d-flex ">
                          <p className="pe-1 text-nowrap">轉帳帳號後5碼：</p>
                          <p className="pe-2 text-nowrap">45863</p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="footer-bottom g-0 row row-cols-1 row-cols-xxl-2">
                <div className="product-sum col d-flex justify-content-between justify-content-xxl-start gap-0 gap-xxl-3 align-items-center">
                  <div className="d-flex primary-deep2 ">
                    <p className="">{orderdetailData.length}</p>
                    <p>項商品</p>
                  </div>
                  <div className="d-flex">
                    <h5>訂單金額 ：</h5>
                    <h5 className="danger">
                      ${orderInfo.orderPrice.toLocaleString()}
                    </h5>
                  </div>
                </div>

                <div className="col pt-2 px-0 ps-0">
                  <div className="d-flex px-0 ps-xxl-5 gap-10px justify-content-between  justify-content-xxl-end">
                    <div className="ps-0 w-100">
                      <div
                        className=" btn   py-2 primary-btn3 text-nowrap  "
                        onClick={() => {
                          onClose();
                        }}
                      >
                        <p>返回訂單資料</p>
                      </div>
                    </div>

                    <div className="pe-0 w-100">
                      <div
                        className="btn   py-2  primary-btn3 text-nowrap "
                        onClick={() => {
                          orderAgain();
                        }}
                      >
                        <p>再次訂購</p>
                      </div>
                    </div>
                    {orderInfo.orderStatus === "待處理" &&
                      orderInfo.paymentStatus === "1" && (
                        <div className="ps-0 w-100">
                          <div
                            className="btn   py-2  secondary-btn-5 text-nowrap "
                            onClick={() => {
                              orderAgain();
                            }}
                          >
                            <p>取消訂單</p>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                {/* <div className="coupon-button-group col">
                 
                 
                </div> */}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default OrderDetailPopup;
