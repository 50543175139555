import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Nodata from "../../sharedComponents/Nodata";
import RenderHtml from "../../sharedComponents/RenderHtml";
import Cartlist from "../../sharedComponents/Cartlist";
import useFormattedDate from "../../sharedComponents/useFormattedDate";
import Popup from "../../sharedComponents/Popup";
function UnauthOrder() {
  const [noPage, setNoPage] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const [storeData, setStoreData] = useState(null);
  const [orderdetailData, setOrderdetailData] = useState(null);
  const [homeShipData, setHomeShipData] = useState(null);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const ordernoValue = queryParams.get("orderno");
  const tokenValue = queryParams.get("token");
  const getOrderData = () => {
    const requestData = { orderno: ordernoValue, token: tokenValue };
    axios
      .post("api/Order/finishOrderNonM", requestData)
      .then((response) => {
        console.log("finishOrderNonM:", "response.data");
        setOrderData(response.data.orderQuery);
        setStoreData(response.data.storeData);
        setOrderdetailData(response.data.orderItems);
      })
      .catch((error) => {
        console.error("Error finishOrderNonM fetching data:", "error");
        // Log a message for when products are not found
        setNoPage(true);
        console.log("requestData", "requestData");
        if (error.response && error.response.status === 404) {
          console.log("error.response");
        }
      });
  };


  const {
    formattedDate: orderFormattedDate,
    formattedTime: orderFormattedTime,
  } = useFormattedDate(orderData?.orderDate);

  const {
    formattedDate: shippingFormattedDate,
    formattedTime: shippingFormattedTime,
  } = useFormattedDate(orderData?.shippingDate);
  
  // 定義一個 URL 驗證函數
  const isValidURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };
 
  useEffect(() => {
    if (ordernoValue && tokenValue) {
      console.log("ordernoValue", "ordernoValue");
      console.log("tokenValue", "tokenValue");
      getOrderData();
    }
  }, [ordernoValue, tokenValue]);
  // 格式化運送資料
  useEffect(()=>{
    if (orderData && orderData.shippingNo) {
      console.log("orderData.shippingNo", "orderData.shippingNo");
      const HomeShipPart = orderData.shippingNo.split("///");
      const shipName = HomeShipPart[0];
      const shipLink = HomeShipPart[1];
      const shipNumber = HomeShipPart[2];

      const formattedData = {
        shipName,
        shipLink,
        shipNumber,
      };

      console.log("格式化後的數據", "formattedData");
      setHomeShipData(formattedData);
    } 
  },[orderData])

  return (
    <>
      {noPage && !orderData && (
        <div className="onepage">
          <div className="container-fluid px-0">
            <Popup
              onNevigate={() => {
                setNoPage(false);
                navigate("/");
              }}
              onClose={() => {
                setNoPage(false);
              }}
              icon={"notice"}
              title={"查無此訂單"}
              orangebtn={"回到首頁"}
              greenbtn={""}
            />
          </div>
        </div>
      )}
      {orderData && (
        <div className="onepage notauth">
          <div className="container pt-5 checkout-4 justify-content-center onepage-order">
            <div className="checkout-select">
              <div className="order-info">
                <div className="order-check">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                  >
                    <path
                      d="M40 0C17.9087 0 0 17.9087 0 40C0 62.0913 17.9087 80 40 80C62.0913 80 80 62.0913 80 40C80 17.9087 62.0913 0 40 0ZM40 8.6914C57.2913 8.6914 71.3037 22.7131 71.3037 40C71.3037 57.287 57.2913 71.3037 40 71.3037C22.7087 71.3037 8.69627 57.287 8.69627 40C8.69633 22.7131 22.7087 8.6914 40 8.6914ZM55.3271 21.0059L32.1435 44.1943L24.624 36.6748L17.2461 44.0479L24.7656 51.5674L32.1923 58.9941L39.5655 51.6162L62.7539 28.4326L55.3271 21.0059Z"
                      fill="#C0DAC3"
                    />
                  </svg>
                  <h6 className="primary-deep">感謝您的訂購，訂單已成立！</h6>
                </div>
                <div className="d-flex flex-column gap-10px ">
                  <div className="order-detail">
                    {orderData.orderNo && (
                      <div className="content">
                        <div className="title">
                          <p>訂單編號</p>
                          <p>：</p>
                        </div>
                        <div className="descript-container">
                          <p className="p1">{orderData.orderNo}</p>
                        </div>
                      </div>
                    )}
                    {orderData.paymentMethod && (
                      <div className="content">
                        <div className="title">
                          <p>付款方式</p>
                          <p>：</p>
                        </div>
                        <div className="descript-container">
                          <p>{orderData.paymentMethod}</p>
                        </div>
                      </div>
                    )}

                    {orderData.paymentStatus && (
                      <div className="content">
                        <div className="title">
                          <p>付款狀態</p>
                          <p>：</p>
                        </div>
                        <div className="descript-container">
                          <p>{orderData.paymentStatus}</p>
                        </div>
                      </div>
                    )}
                     {orderData.orderStatus && (
                      <div className="content">
                        <div className="title">
                          <p>訂單狀態</p>
                          <p>：</p>
                        </div>
                        <div className="descript-container">
                        <p
                          className={`${
                            orderData.orderStatus === "5" ? "danger" : ""
                          }`}
                        >
                          {orderData.orderStatus}
                        </p>
                        {orderData.managerNote && orderData.managerNote !== "" && <h6 className="p text-muted">({orderData.managerNote})</h6>}

                        </div>
                      </div>
                    )}
                      {orderData.shippingDate && (
                      <div className="content">
                        <div className="title">
                          <p>出貨日期</p>
                          <p>：</p>
                        </div>
                        <div className="descript-container">
                        <p> {shippingFormattedDate}</p>
                        </div>
                      </div>
                    )}
                     {/* {orderData.shippingNo && homeShipData && (
                      <div className="content">
                        <div className="title">
                          <p>物流編號</p>
                          <p>：</p>
                        </div>
                        <div className="descript-container">
                        <p>  {homeShipData.shipNumber}</p>
                        </div>
                      </div>
                    )}
                     {orderData.shippingNo && homeShipData &&(
                      <div className="content">
                        <div className="title">
                          <p>物流查詢</p>
                          <p>：</p>
                        </div>
                        <div className="descript-container">
                        {homeShipData &&
                                  isValidURL(homeShipData.shipLink) && (
                                    <a
                                      href={homeShipData.shipLink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="btn  secondary-btn-4 px-3 py-1 py-xxl-0 rounded"
                                    >
                                      {homeShipData.shipName}
                                     
                                    </a>
                                  )}
                        </div>
                      </div>
                    )} */}
                    {orderData.recipientName && (
                      <div className="content">
                        <div className="title">
                          <p>收件人姓名</p>
                          <p>：</p>
                        </div>
                        <div className="descript-container">
                          <p>
                            {orderData.recipientName.length > 1
                              ? orderData.recipientName[0] +
                                "*".repeat(orderData.recipientName.length - 1)
                              : orderData.recipientName}
                          </p>{" "}
                        </div>
                      </div>
                    )}
                    {orderData.recipientMobile && (
                      <div className="content">
                        <div className="title">
                          <p>連絡電話</p>
                          <p>：</p>
                        </div>
                        <div className="descript-container">
                          <p>
                            {orderData.recipientMobile.replace(
                              /(\d{2}).+(\d{4})/g,
                              "$1******$2"
                            )}
                          </p>
                        </div>
                      </div>
                    )}

                    {orderData.orderPrice && (
                      <div className="content">
                        <div className="title">
                          <p>總金額</p>
                          <p>：</p>
                        </div>
                        <div className="descript-container">
                          <p>${orderData.orderPrice.toLocaleString()}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="popup-footer">
                <div className="pt-5">
                  <div className="footer-bottom ">
                    {/* {orderData.paymentId === "2" && storeData && (
                    <>
                      <p className="danger">
                        ※請於兩天內繳款，否則訂單將取消。
                      </p>
                      <p className="primary-deep2">轉帳完成後：</p>
                      <p>
                        透過「會員專區」{">"}
                        「訂單資料」的「回傳匯款資料」功能將「匯款日期、轉帳帳號後5碼」回傳至本公司，以便確認付款資訊，如有任何問題歡迎來電洽詢。
                      </p>
                    </>
                  )} */}
                    <div className="d-flex">
                      <p>服務電話：</p>
                      <p>{storeData.contactTel}</p>
                    </div>
                    <div className="d-flex">
                      <p>電子信箱：</p>
                      <p>{storeData.contactEmail}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="checkout-select py-5 container-fluid checkout-container">
              <div className="page-content">
                <div className="section-title">
                  <div className="title">
                    <h5>訂單內商品清單</h5>
                  </div>
                </div>
                <div className="overflow-wrap">
                  <div className="overflow">
                    <div className="checkout-content pe-2 pb-0">
                      {orderdetailData &&
                        orderdetailData.map((item, index) => (
                          <Cartlist key={index} cartitem={item} />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="popup-footer order-price">
                <div className="footer-top">
                  {orderData.orderPrice && (
                    <div className="product-sum">
                      <h5 className="d-none d-xxl-block">訂單內合計金額：</h5>
                      <h5 className="d-block d-xxl-none">訂單內合計金額：</h5>
                      <h5>${(orderData.orderPrice - orderData.otherfee - orderData.shippingfee -orderData.orderTax).toLocaleString()}</h5>
                    </div>
                  )}
                  {orderData.otherfee && (
                    <div className="product-sum">
                      <h5>手續費：</h5>
                      <h5>${orderData.otherfee.toLocaleString()}</h5>
                    </div>
                  )}
                  {orderData.shippingfee && (
                    <div className="product-sum">
                      <h5>運費：</h5>
                      <h5>${orderData.shippingfee.toLocaleString()}</h5>
                    </div>
                  )}
                </div>
                <div className="footer-top">
                  {orderData.orderTax && (
                    <div className="product-sum">
                      <h5 className="d-none d-xxl-block">稅金：</h5>
                      <h5 className="d-block d-xxl-none">稅金：</h5>
                      <h5>${orderData.orderTax.toLocaleString()}</h5>
                    </div>
                  )}
                  {orderData.orderPrice && (
                    <div className="product-sum">
                      <h5>總金額（含稅）：</h5>
                      <h5>${orderData.orderPrice.toLocaleString()}</h5>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default UnauthOrder;
