import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom";
function Setpath() {
    //判斷網址
    const location = useLocation();
  const currentPath = location.pathname;
  console.log("currentPath", "currentPath");
  useEffect(()=>{
    sessionStorage.setItem("prevPath", currentPath);
  },[])
  return 
    <></>
  
}

export default Setpath