//PopupCheckbox
import React, { useContext, useState } from "react";
import AuthContext from "../Context/AuthContext";
import axios from "axios";

function PopupCheckbox({ cancelId, onClose, checkboxData, onCancelOrder }) {
  const [isselectId, setIsselectId] = useState();
  const [isselectname, setIsselectName] = useState(null);

  const { myAuth } = useContext(AuthContext); //登入狀態

  const mid = myAuth.sid;

  console.log("cancelId", "cancelId");
  console.log("checkboxData", "checkboxData");
  const handleToggleCheckbox = (Name) => {
    setIsselectName(Name);
  };

  return (
    <>
      <div className="popup-full-bg">
        <div className="popup-checkbox">
          <div className="popup-content mx-4 mx-xxl-0">
            <div className="popup-icon text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="61"
                height="62"
                viewBox="0 0 61 62"
                fill="none"
              >
                <g clipPath="url(#clip0_333_10370)">
                  <path
                    d="M30.5 0.451172C24.5666 0.451172 18.7664 2.23813 13.8329 5.58608C8.89943 8.93403 5.05426 13.6926 2.78363 19.26C0.512999 24.8275 -0.0811016 30.9537 1.07646 36.8641C2.23401 42.7744 5.09124 48.2035 9.28681 52.4646C13.4824 56.7257 18.8279 59.6276 24.6473 60.8032C30.4667 61.9789 36.4987 61.3755 41.9805 59.0694C47.4623 56.7633 52.1477 52.858 55.4441 47.8475C58.7405 42.8369 60.5 36.9461 60.5 30.9199C60.5 22.8391 57.3393 15.0893 51.7132 9.37526C46.0871 3.66126 38.4565 0.451172 30.5 0.451172ZM33.5 49.2012H27.5V43.1074H33.5V49.2012ZM33.5 37.0137H27.5V12.6387H33.5V37.0137Z"
                    fill="#FF5C26"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_333_10370">
                    <rect
                      width="60"
                      height="60.9375"
                      fill="white"
                      transform="translate(0.5 0.451172)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="popup-title text-center">
              <h3>訂單取消原因</h3>
            </div>
            <div className="d-xxl-flex gap-10px justify-content-center flex-wrap">
              {checkboxData &&
                checkboxData.map((value, index) => (
                  <div className="popup-check d-flex" key={index}>
                    <div
                      className="btn checkbox-button d-flex"
                      onClick={() => handleToggleCheckbox(value.option)}
                    >
                      <div
                        className={`checkbox-icon default ${
                          isselectname === value.option ? "d-none" : "d-block"
                        }`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                        >
                          <path
                            d="M2.5 18C1.95 18 1.479 17.804 1.087 17.412C0.695002 17.02 0.499335 16.5493 0.500002 16V2C0.500002 1.45 0.696002 0.979002 1.088 0.587002C1.48 0.195002 1.95067 -0.000664969 2.5 1.69779e-06H16.5C17.05 1.69779e-06 17.521 0.196002 17.913 0.588002C18.305 0.980002 18.5007 1.45067 18.5 2V16C18.5 16.55 18.304 17.021 17.912 17.413C17.52 17.805 17.0493 18.0007 16.5 18H2.5ZM2.5 16H16.5V2H2.5V16Z"
                            fill="#FF5C26"
                          />
                        </svg>
                      </div>
                      <div
                        className={`checkbox-icon check ${
                          isselectname === value.option ? "d-block" : "d-none"
                        }`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                        >
                          <path
                            d="M8.1 13.2L15.15 6.15L13.75 4.75L8.1 10.4L5.25 7.55L3.85 8.95L8.1 13.2ZM2.5 18C1.95 18 1.479 17.804 1.087 17.412C0.695002 17.02 0.499335 16.5493 0.500002 16V2C0.500002 1.45 0.696002 0.979002 1.088 0.587002C1.48 0.195002 1.95067 -0.000664969 2.5 1.69779e-06H16.5C17.05 1.69779e-06 17.521 0.196002 17.913 0.588002C18.305 0.980002 18.5007 1.45067 18.5 2V16C18.5 16.55 18.304 17.021 17.912 17.413C17.52 17.805 17.0493 18.0007 16.5 18H2.5ZM2.5 16H16.5V2H2.5V16Z"
                            fill="#FF5C26"
                          />
                        </svg>
                      </div>
                    </div>
                    <h6 className="pt-0 ps-1">
                      {/* {skuProcessing[processingKey].processingName} */}
                      {value.option}
                    </h6>
                  </div>
                ))}
            </div>
          </div>
          <div className="container d-flex justify-content-center gap-10percent">
            <div className="btn search-btn2 " onClick={onClose}>
              <h6>返回訂單資料</h6>
            </div>
            <div
              className={`btn primary-btn ${isselectname ? "" : "disabled"}`}
              onClick={() => {
                onCancelOrder(isselectname);
              }}
            >
              <h6>取消訂單</h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PopupCheckbox;
