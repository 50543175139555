//LoginLocalSection.js
import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../Context/AuthContext";
import Popup from "../sharedComponents/Popup";
import ThirdPartyLogin from "./ThirdPartyLogin";
import PwdLink from "./PwdLink";
import axios from "axios";
import Loading from "../components/Loading";
import Nodata from "./Nodata";

function LoginLocalSection({ title, NoregistLink }) {
  const { login } = useContext(AuthContext);
  const { myAuth } = useContext(AuthContext);
  const location = useLocation();

  const [isloading, setIsLoading] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneOnBlur, setPhoneBlur] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordOnblur, setPasswordBlur] = useState(false);

  const [captcha, setCaptcha] = useState("");
  const [captchaOnblur, setCaptchaBlur] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); // State to track if the form is submitted
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [captchaData, setCaptchaData] = useState({});

  const [loginFail, setLoginFail] = useState("");
  // 檢查錯誤
  const [checkError, setCheckError] = useState(false);
  const navigate = useNavigate();

  const getCaptcha = () => {
    axios
      .get(`/api/User/Captcha`, {
        headers: {},
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("Captcha Data:", "response.data");
          setCaptchaData(response.data);
        } else {
          console.log("Captcha failed:", "response");
          // Token is invalid, log out the user
        }
      })
      .catch((error) => {
        console.error("Error:", "error");
      })
      .finally(() => {
        // setIsLoading(false); // Mark loading as finished regardless of success or error
      });
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    setLoginFail("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setLoginFail("");
  };

  const handleCaptchaChange = (e) => {
    setCaptcha(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true); // Mark the form as submitted
    setPhoneBlur(true);
    setPasswordBlur(true);
    setCaptchaBlur(true);
    // Perform format validation only if the form is submitted
    // if (!isValidCaptcha(captcha)) {
    //   // getCaptcha();
    //   return;
    // }
    
    if (
      !isValidPhoneNumber(phoneNumber) ||
      !isValidPassword(password) ||
      !isValidCaptcha(captcha)
    ) {
      // getCaptcha();
      console.log("不合法格式");
      let firstInvalidInputRef = null;
      if (!isValidPhoneNumber(phoneNumber) || !phoneNumber) {
        firstInvalidInputRef = document.getElementById("local-phone-number");
        
      } else if (!isValidPassword(password) || !password) {
        firstInvalidInputRef = document.getElementById("member-pwd");
      } else if (!isValidCaptcha(captcha) || !captcha) {
        firstInvalidInputRef = document.getElementById("local-captcha");
      } 

      if (firstInvalidInputRef) {
        console.log("firstInvalidInputRef" , "firstInvalidInputRef")
        firstInvalidInputRef.scrollIntoView({
          behavior: 'smooth', 
          block: 'center'
        });      }
      setCheckError(true);
      return;
    } else {
      setCheckError(false);
    }
    // console.log("登入")
    try {
      const loginResult = await login(phoneNumber, password, "L"); // 等待登录完成

      if (!loginResult.success) {
        console.log("false test", "loginResult");
        setIsPopupOpen(true);
        getCaptcha();
        setCaptcha("");
        setLoginFail(loginResult.message);
        setIsSubmitted(false);
        console.log("Login failed: " + "loginResult.message");
        //登入失敗才打開popup
      } else {
        //登入成功回傳的loginResult會是undefined
        //登入成功不做處理
        console.log("loginResult", "loginResult");
      }
    } catch (error) {
      console.error("在local的Error during login:", "error");
      // 登入例外的錯誤狀況
    }
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^09\d{8}$/;
    return phoneRegex.test(phoneNumber);
  };

  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,20}$/;

    return passwordRegex.test(password);
  };

  const isValidCaptcha = (captcha) => {
    return captcha === captchaData.code;
  };
  const handleQuickFill = () => {
    console.log("handleQuickFill click");
    // const quickFillData = {
    //   phoneNumber: "0912345678",
    //   password: "Password123",
    //   captcha: "123456789",
    // };
    // setPhoneNumber(quickFillData.phoneNumber);
    // setPassword(quickFillData.password);
    // setCaptcha(quickFillData.captcha);
  };
  useEffect(() => {
    getCaptcha();
  }, []);

  //重複的表單

  const renderInputField = (
    id,
    type,
    name,
    classname,
    placeholder,
    value,
    onChange,
    onBlur = null,
    onClick = null,
    minLength,
    maxLength
  ) => (
    <input
      type={type}
      id={id}
      name={name}
      className={classname}
      // placeholder={placeholder}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      onClick={onClick}
      minLength={minLength}
      maxLength={maxLength}
    />
  );
  const errorInput = (hasData, isValid, id, errormessage, show, notshow) => (
    <div
      className={`error-message ${
        hasData && !isValid ? show || "visible" : notshow || "invisible"
      } d-flex pb-0`}
      id={id}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="19"
        viewBox="0 0 21 19"
        fill="none"
      >
        <path
          d="M0.98903 18.5C0.805697 18.5 0.63903 18.454 0.48903 18.362C0.33903 18.27 0.222364 18.1493 0.139031 18C0.0556972 17.85 0.00969715 17.6873 0.00103049 17.512C-0.00763618 17.3367 0.0383638 17.166 0.139031 17L9.38903 1C9.48903 0.833333 9.61836 0.708333 9.77703 0.625C9.9357 0.541667 10.098 0.5 10.264 0.5C10.4307 0.5 10.5934 0.541667 10.752 0.625C10.9107 0.708333 11.0397 0.833333 11.139 1L20.389 17C20.489 17.1667 20.535 17.3377 20.527 17.513C20.519 17.6883 20.473 17.8507 20.389 18C20.3057 18.15 20.189 18.271 20.039 18.363C19.889 18.455 19.7224 18.5007 19.539 18.5H0.98903ZM2.71403 16.5H17.814L10.264 3.5L2.71403 16.5ZM10.264 15.5C10.5474 15.5 10.785 15.404 10.977 15.212C11.169 15.02 11.2647 14.7827 11.264 14.5C11.264 14.2167 11.168 13.979 10.976 13.787C10.784 13.595 10.5467 13.4993 10.264 13.5C9.9807 13.5 9.74303 13.596 9.55103 13.788C9.35903 13.98 9.26336 14.2173 9.26403 14.5C9.26403 14.7833 9.36003 15.021 9.55203 15.213C9.74403 15.405 9.98136 15.5007 10.264 15.5ZM10.264 12.5C10.5474 12.5 10.785 12.404 10.977 12.212C11.169 12.02 11.2647 11.7827 11.264 11.5V8.5C11.264 8.21667 11.168 7.979 10.976 7.787C10.784 7.595 10.5467 7.49933 10.264 7.5C9.9807 7.5 9.74303 7.596 9.55103 7.788C9.35903 7.98 9.26336 8.21733 9.26403 8.5V11.5C9.26403 11.7833 9.36003 12.021 9.55203 12.213C9.74403 12.405 9.98136 12.5007 10.264 12.5Z"
          fill="#DC3545"
        />
      </svg>
      <p className="ps-1 danger">{errormessage}</p>
    </div>
  );
  if (!captchaData || !captchaData.imageData) {
    <Loading />;
  }
  return (
    <>
      {isPopupOpen && (
        <Popup
          onNevigate={() => {
            setIsPopupOpen(false);
            setCaptchaBlur(false);
          }}
          onClose={() => {
            setIsPopupOpen(false);
          }}
          icon={"notice"}
          title={loginFail}
          orangebtn={"重新登入"}
          greenbtn={""}
        />
      )}
      <div className="login-title pt-3 pb-2 pt-xxl-3 pb-xxl-2 ">
        {title && <h5 onClick={handleQuickFill}>轉移舊會員資料(紅利點數)</h5>}
        {!title && <h4 onClick={handleQuickFill}>轉移舊會員資料(紅利點數)</h4>}

        <div className="pt-2 pb-3">
          <h6 className="d-inline">
            若已轉移舊會員資料請直接使用下方的會員登入
          </h6>
          <div className="pt-3">
            <Link
              to="/oldmemeberlogin"
              className={`btn ${title ? "primary-btn-big":"primary-btn6"} w-100 d-flex justify-content-center align-items-center gap-10px`}
            >
              
              <h6 className={` ${title ? "font-weight-bold ":"p1 py-1"}`}>轉移舊會員資料</h6>
            </Link>
          </div>
        </div>
      </div>
      <div className="login-title border-bottom-light pt-2 pb-3 pt-xxl-3 pb-xxl-3">
        {title && (
          <>
            <h5 className="">{title}</h5>
            <h6 className="pt-2">使用快速登入</h6>
          </>
        )}
        {!title && <h4>使用快速登入</h4>}
        <ThirdPartyLogin />
      </div>
      <div className="login-title pt-3 pb-2 pt-xxl-3 pb-xxl-3">
        {title && <h5 onClick={handleQuickFill}>會員帳號登入</h5>}
        {!title && <h5 onClick={handleQuickFill}>會員帳號登入</h5>}
        {location.pathname !== "/checkoutstep1" && (
          <div className="d-flex flex-wrap pt-2 pb-3">
            <h6 className="d-inline">若您還不是會員，請先</h6>
            <Link to="/register">
              <h6 className="primary-deep text-decoration-underline">
                免費加入會員
              </h6>
            </Link>
            <h6>。</h6>
          </div>
        )}
      </div>

      <form className="login-form pb-3 d-flex flex-column gap-3" onSubmit={handleFormSubmit}>
        <div>
          <label htmlFor="local-phone-number" className="form-label d-block">
            <h6 className="primary-deep2">帳號(手機號碼)</h6>
          </label>
          {renderInputField(
            "local-phone-number",
            "phone-number",
            "tel",
            "form-input",
            "輸入手機號碼(0912345678)",
            phoneNumber,
            handlePhoneNumberChange,
            () => {
              setPhoneBlur(true);
            },
            () => {
              setPhoneBlur(false);
            },
            10,
            10
          )}
          {errorInput(
            phoneOnBlur || phoneNumber,
            !loginFail,
            "error-username",
            loginFail,
            "d-block",
            "d-none"
          )}
          {errorInput(
            phoneOnBlur || phoneNumber,
            isValidPhoneNumber(phoneNumber),
            "error-username",
            "請輸入正確的格式(例如：0912345678)",
            "d-block",
            "d-none"
          )}
        </div>
        <div>
          <label htmlFor="member-pwd" id="user-pwd" className="form-label">
            <h6 className="primary-deep2">密碼</h6>
          </label>

          <div className="d-flex">
            {renderInputField(
              "member-pwd",
              showPassword ? "text" : "password",
              "phone-number",
              "form-pwd",
              "請輸入密碼(格式為英文字母大小寫加上數字,共8~20位英數字)",
              password,
              handlePasswordChange,
              () => {
                setPasswordBlur(true);
              },
              () => {
                setPasswordBlur(false);
              },
              8,
              20
            )}

            <div className="btn see-pwd ">
              <div
                className="btn checkbox-icon check showpwd"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.3267 0L0 1.32686L3.32283 4.64929C1.86598 5.81447 0.715658 7.30499 0 8.99996C1.73 13.0973 6 16 11 16C12.1278 16 13.2185 15.8523 14.2513 15.5765L16.9518 18.2766L18.2785 16.9497L1.3267 0ZM12.6749 14.0002L10.4219 11.7475C9.84146 11.6411 9.30305 11.3759 8.87868 10.9799C8.40503 10.5378 8.10612 9.96312 8.02343 9.34933L4.68517 6.01147C3.63979 6.83061 2.78481 7.84738 2.18 8.99996C2.98825 10.5402 4.24331 11.838 5.80248 12.7457C7.36165 13.6533 9.1624 14.1345 11 14.1345C11.5637 14.1345 12.124 14.0892 12.6749 14.0002ZM10.2905 6.27939L13.8592 9.84771C13.9517 9.57602 14 9.29031 14 8.99996C14 8.25735 13.6839 7.54516 13.1213 7.02006C12.5587 6.49496 11.7956 6.19996 11 6.19996C10.7589 6.19996 10.5209 6.22704 10.2905 6.27939ZM19.82 8.99996C19.0728 10.424 17.9436 11.6407 16.5453 12.5335L17.92 13.9081C19.7365 12.6789 21.1645 10.9788 22 8.99996C20.27 4.90262 16 1.99996 11 1.99996C9.50062 1.99996 8.06689 2.26098 6.74761 2.73697L8.24441 4.2336C9.13525 3.99055 10.0628 3.86539 11 3.86539C12.8376 3.86539 14.6383 4.34658 16.1975 5.25425C17.7567 6.16193 19.0117 7.45967 19.82 8.99996Z"
                      fill="#D9D9D9"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                  >
                    <path
                      d="M11 6.2C11.7956 6.2 12.5587 6.495 13.1213 7.0201C13.6839 7.5452 14 8.25739 14 9C14 9.74261 13.6839 10.4548 13.1213 10.9799C12.5587 11.505 11.7956 11.8 11 11.8C10.2044 11.8 9.44129 11.505 8.87868 10.9799C8.31607 10.4548 8 9.74261 8 9C8 8.25739 8.31607 7.5452 8.87868 7.0201C9.44129 6.495 10.2044 6.2 11 6.2ZM11 2C16 2 20.27 4.90267 22 9C20.27 13.0973 16 16 11 16C6 16 1.73 13.0973 0 9C1.73 4.90267 6 2 11 2ZM2.18 9C2.98825 10.5403 4.24331 11.838 5.80248 12.7457C7.36165 13.6534 9.1624 14.1346 11 14.1346C12.8376 14.1346 14.6383 13.6534 16.1975 12.7457C17.7567 11.838 19.0117 10.5403 19.82 9C19.0117 7.45971 17.7567 6.16197 16.1975 5.2543C14.6383 4.34662 12.8376 3.86544 11 3.86544C9.1624 3.86544 7.36165 4.34662 5.80248 5.2543C4.24331 6.16197 2.98825 7.45971 2.18 9Z"
                      fill="#D9D9D9"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>
          {errorInput(
            passwordOnblur || password,
            !loginFail,
            "error-username",
            loginFail,
            "d-block",
            "d-none"
          )}
          {errorInput(
            passwordOnblur || password,
            isValidPassword(password),
            "error-pwd",
            "請輸入正確的密碼(英文字母大小寫加上數字,共8~20位英數字 )",
            "d-block",
            "d-none"
          )}
        </div>

        <div>
          <div className="captcha-box">
            <div className="captcha-foriput">
              <label htmlFor="local-captcha" className="form-label d-block">
                <h6 className="primary-deep2">圖形驗證碼</h6>
              </label>
              {renderInputField(
                "local-captcha",
                "text",
                "captcha",
                "form-input",
                "輸入圖形驗證碼",
                captcha,
                handleCaptchaChange,
                () => {
                  setCaptchaBlur(true);
                },
                () => {
                  setCaptchaBlur(false);
                },
                5,
                5
              )}
            </div>
            {captchaData.imageData && (
              <div
                className="captcha-photo pt-2 cursor-pointer"
                onClick={() => getCaptcha()}
              >
                <img src={captchaData.imageData} alt="圖形驗證碼" />
              </div>
            )}
          </div>
          <div className="pt-1 secondary-deep2 ">
            <p>點擊圖片可刷新驗證碼</p>
          </div>
          {errorInput(
            (captcha || captchaOnblur) && !isPopupOpen,
            isValidCaptcha(captcha),
            "error-captcha",
            "請輸入正確的圖形驗證碼",
            "d-block",
            "d-none"
          )}
        </div>
        <div className="login-btn pt-0 pt-xl-2">
          <button
            className={`btn w-100 ${title ? "primary-btn-big2":"primary-btn2"}`}
            type="submit"
            // disabled={!isValidPhoneNumber(phoneNumber) || !isValidPassword(password) || !captcha}
          >
            {title && <h6 className="font-weight-bold ">登入</h6>}
           {!title && (<>登入</>)} 
          </button>
          <div className="w-100">
            <div className={`pt-0 ${loginFail ? "d-block w-100" : "d-none"}`}>
              <Nodata
                icon={"dangernotice"}
                dangerclass={"ps-1 p1"}
                dangerdescript={loginFail}
                bgclass={"bg-white"}
              />
            </div>
            {checkError && (
              <div
                className={`pt-0 ${
                  !isValidPhoneNumber(phoneNumber) ||
                  !isValidPassword(password) ||
                  !isValidCaptcha(captcha)
                    ? "d-block w-100"
                    : "d-none"
                }`}
              >
                <Nodata
                  icon={"dangernotice"}
                  dangerclass={"ps-1 p1"}
                  dangerdescript={`請填寫正確的
                ${
                  isValidPhoneNumber(phoneNumber)
                    ? ""
                    : !isValidPhoneNumber(phoneNumber) &&
                      isValidPassword(password) &&
                      isValidCaptcha(captcha)
                    ? "帳號"
                    : "帳號、"
                }
                ${
                  isValidPassword(password)
                    ? ""
                    : !isValidPassword(password) && isValidCaptcha(captcha)
                    ? "密碼"
                    : "密碼、"
                }
                ${
                  isValidCaptcha(captcha)
                    ? ""
                    : !isValidCaptcha(captcha)
                    ? "圖形驗證碼"
                    : ""
                }`}
                  bgclass={"bg-white"}
                />
              </div>
            )}
          </div>

          <PwdLink state={"L"} />
        </div>
      </form>
    </>
  );
}

export default LoginLocalSection;
