//MemberDataShipping
import { useContext, useState, useEffect } from "react";
import Loading from "../Loading";
import NamePhoneLabel from "../../sharedComponents/NamePhoneLabel";
import TWZipCode from "../../sharedComponents/TWZipCode";
import AuthContext from "../../Context/AuthContext";
import Nodata from "../../sharedComponents/Nodata";
import axios from "axios";
function MemberDataShipping() {
  const [isLoading, setIsLoading] = useState(false)
  const [addressData, setAddressData] = useState([]);
  // const [addressData, setAddressData] = useState([
  //   {
  //     name: "顏儀靖",
  //     phone: "0920204896",
  //     telephone: "0245896341",
  //     postcode: "251",
  //     country: "新北市",
  //     township: "淡水區",
  //     address: "新興街96號",
  //   },
  //   {
  //     name: "宋靖",
  //     phone: "0925491396",
  //     telephone: "0224516341",
  //     postcode: "830",
  //     country: "高雄市",
  //     township: "鳳山區",
  //     address: "新興街96號",
  //   },
  // ]);
  const { myAuth, updateMemberData, logout } = useContext(AuthContext); //登入狀態
  const [postcode, setPostcode] = useState();

  const [editingAddressIndex, setEditingAddressIndex] = useState(-1); // 編輯狀態
  const [isAddingAddress, setIsAddingAddress] = useState(false); // 新增模式狀態
  //父層接收到的姓名電話手機
  const [reciptName, setReciptName] = useState("");
  const [reciptPhone, setReciptPhone] = useState("");
  const [reciptTele, setReciptTele] = useState("");
  //父層接收到的地址
  const [reciptePostcode, setReciptePostcode] = useState("");
  const [recipteCountry, setRecipteCountry] = useState("");
  const [recipteTownship, setRecipteTownship] = useState("");
  const [recipteAddress, setRecipteAddress] = useState("");
  //是否將地址設定為預設?
  const [isPreset, setIsPreset] = useState(false);
  const [hasPresetChange, setHasPresetChange] = useState(false);
  //發送表單的檢查欄位
  const [namePhoneValid, setNamePhoneValid] = useState(false);
  const [addressValid, setAddressValid] = useState(false);
  //會員資訊
  const mid = parseInt(myAuth.sid);
  const storedToken = myAuth.token;
  const memberAddress = myAuth.recipientData;
  // const handleItemDataChange = (key, value) => {
  //   const updatedAddressData = [...addressData];
  //   updatedAddressData[selectedAddressIndex][key] = value;
  //   setAddressData(updatedAddressData);
  // };

//還原回預設值
  const toOrigin = ()=>{
    setEditingAddressIndex(-1);// 編輯狀態
    setIsAddingAddress(false)// 新增模式狀態
    setIsPreset(false) //是否將地址設定為預設?
    setNamePhoneValid(false)
    setAddressValid(false)
  }
    //設定預設地址的值
  const handlePrevset = (datapreset, preset) => {
    const newPresetValue = !preset;
    setIsPreset(newPresetValue);
  
    if (datapreset !== newPresetValue) {
      setHasPresetChange(true);
    } else {
      setHasPresetChange(false);
    }
  };
  
  const handleEditClick = (index, preset) => {
    if (editingAddressIndex === index) {
      //原本為編輯狀態就切換回檢視狀態，將資料還原回預設值
      toOrigin();
    } else {
      //進入編輯狀態，要將新增狀態設定為false
      setEditingAddressIndex(index);
      setIsPreset(preset);
      setIsAddingAddress(false);
    }
  };
  const handleAddAddressClick = () => {
     // 切換新增模式狀態，並將Preset設定成預設值
     //如果原本的新增狀態為true就要將新增模式關閉，並將Preset設定成預設值
     if(isAddingAddress){
      setIsPreset(false);
     }else{
      setEditingAddressIndex(-1);
     }
      //如果原本的新增狀態為false就要將新增模式開啟，並將EditingAddressIndex設定成預設值-1
    setIsAddingAddress(!isAddingAddress);
    
  };
  const handleEditData = (e, id) => {
    e.preventDefault();
    const presetno = isPreset ? 1 : 0;
    const requestData = {
      rid: id,
      mid: mid,
      preset: presetno, // 使用当前 isPreset 的值
      name: reciptName,
      mobile: reciptPhone,
      tel: reciptTele,
      addr: `${reciptePostcode}///${recipteCountry}///${recipteTownship}///${recipteAddress}`,
    };
  
    axios
      .post("/api/Member/EditMemberRecipients", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("回傳的EditMemberRecipients", "response.data");
        console.log("EditMemberRecipients傳過去的資料", "requestData");
        toOrigin();
        updateMemberData();
        setIsLoading(true);
      })
      .catch((error) => {
        console.error("EditMemberRecipients有錯誤：", "error");
        console.log("傳過去的資料有錯誤EditMemberRecipients", "requestData");
        console.log("傳過去的資料有錯誤EditMemberRecipients", "storedToken");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      });
  };
  const handleAddNewData = (e) => {
    e.preventDefault();
    const presetno = isPreset ? 1 : 0;
    const requestData = {
      mid: mid,
      preset: presetno, // 將目前的 isPreset 設定為true或是false
      name: reciptName,
      mobile: reciptPhone,
      tel: reciptTele,
      addr: `${reciptePostcode}///${recipteCountry}///${recipteTownship}///${recipteAddress}`,
    };
    axios
      .post("/api/Member/AddMemberRecipients", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("回傳的AddMemberRecipients", "response.data");
        console.log("AddMemberRecipients傳過去的資料", "requestData");
        toOrigin();
        updateMemberData();
        setIsLoading(true);
      })
      .catch((error) => {
        console.error("AddMemberRecipients有錯誤：", "error");
        console.log("傳過去的資料有錯誤AddMemberRecipients", "requestData");
        console.log("傳過去的資料有錯誤storedToken", "storedToken");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      });
  };
  //刪除收件資料
  const handleDeleteData = (e, id) => {
    e.preventDefault();
    const requestData = {
      rid: id,
      mid: mid,
     };
  
    axios
      .post("/api/Member/DeleteMemberRecipients", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("回傳的DeleteMemberRecipients", "response.data");
        console.log("DeleteMemberRecipients傳過去的資料", "requestData");
        toOrigin();
        updateMemberData();
        setIsLoading(true);
      })
      .catch((error) => {
        console.error("DeleteMemberRecipients有錯誤：", "error");
        console.log("傳過去的資料有錯誤DeleteMemberRecipients", "requestData");
        console.log("傳過去的資料有錯誤DeleteMemberRecipients", "storedToken");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      });
  };
  //初次載入時取得會員地址資料
  useEffect(() => {
    
    console.log("memberAddress", "memberAddress");
    if(memberAddress){
      const formattedAddresses = memberAddress.map((data) => {
        // Split the 'addr' string into parts
        const [postcode, country, township, addr] = data.addr.split("///");
        return {
          id: data.id,
          name: data.name,
          phone: data.mobile,
          telephone: data.tel,
          preset: data.preset,
          postcode,
          country,
          township,
          address: addr,
        };
      });
      console.log("formattedAddresses", "formattedAddresses");
      setAddressData(formattedAddresses);
    }
   
  }, []);
  //有改變時再次取得會員地址資料
  useEffect(() => {
    
    console.log("memberAddress", "memberAddress");
    if(memberAddress){
      const formattedAddresses = memberAddress.map((data) => {
        // Split the 'addr' string into parts
        const [postcode, country, township, addr] = data.addr.split("///");
        return {
          id: data.id,
          name: data.name,
          phone: data.mobile,
          telephone: data.tel,
          preset: data.preset,
          postcode,
          country,
          township,
          address: addr,
        };
      });
      console.log("有改變時再次取得會員地址資料", "formattedAddresses");
      setAddressData(formattedAddresses);
      setIsLoading(false)
    }
   
  }, [myAuth]);
  //編輯狀態時先設定名稱等條件
  useEffect(() => {
    //預設值
    if (editingAddressIndex !== -1) {
      console.log("選擇的addressData", "addressData[editingAddressIndex].name");
      setReciptName(addressData[editingAddressIndex].name);
      setReciptPhone(addressData[editingAddressIndex].phone);
      setReciptTele(addressData[editingAddressIndex].telephone);
      setReciptePostcode(addressData[editingAddressIndex].postcode);
      setRecipteCountry(addressData[editingAddressIndex].country);
      setRecipteTownship(addressData[editingAddressIndex].township);
      setRecipteAddress(addressData[editingAddressIndex].address);
    }

  }, [editingAddressIndex]);
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[memberAddress])


  if(isLoading) {
    <Loading/>;
    return
  }
  return (
    <>
      <div className="login-title pt-4 pb-2 pt-xxl-3 pb-xxl-2">
        <h5>收件地址</h5>
      </div>

      <div className="d-flex flex-column gap-3">
      {addressData && addressData.length === 0 && (
  <Nodata blockdescript={"目前沒有歷史收件地址"} blockmemo={"點擊下方按鈕新增地址"}/>
)}
        {addressData &&
            addressData.map((item, index) => (
        <div className="address-origin-data" key={index}>
          <div className="address-data d-block d-xxl-flex">
            <div key={index} className="data">
              <div className="d-flex"> <h6>收件地址{index + 1}</h6>{item.preset && <p className="secondary-border d-block">預設地址</p>}</div>
        
              <div className="d-flex">
        
                <p>
                  {item.name} {item.phone} {item.postcode}
                  {item.country}
                  {item.township}
                  {item.address}
                </p>
              </div>
            </div>
            <div className="d-flex gap-2">
              <div
                className={`btn btn-sm ${
                  editingAddressIndex === index ? "editing" : "edit"
                }`}
                onClick={() => handleEditClick(index, item.preset)}
              >
                <h6>
                  {editingAddressIndex === index ? "取消編輯" : "編輯"}
                </h6>
              </div>
              <div className="btn btn-sm delete" onClick={(e) => handleDeleteData(e, item.id)}>
                <h6>刪除</h6>
              </div>
            </div>
          </div>
          {editingAddressIndex === index && (
            <form
              className="member-form"
              onSubmit={(e) => handleEditData(e, item.id)}
            >
              <NamePhoneLabel
                itemName={item.name}
                itemPhone={item.phone}
                itemTelephone={item.telephone}
                onNamePhoneChange={(name, phone, telephone) => {
                  setReciptName(name);
                  setReciptPhone(phone);
                  setReciptTele(telephone);
                }}
                isNamePhoneValid={(isValid) => {
                  setNamePhoneValid(isValid);
                }}
              />
              <label htmlFor="username" className="form-label d-block">
                <h6 className="primary-deep2">收件地址</h6>
              </label>
              <TWZipCode
                initPostcode={item.postcode}
                onPostcodeChange={(country, township, postcode) => {
                  setReciptePostcode(postcode);
                  setRecipteCountry(country);
                  setRecipteTownship(township);
                }}
                onAddressChange={(address) => {
                  setRecipteAddress(address);
                }}
                itemAddress={item.address}
                isTWZipCodeValid={(isValid) => {
                  setAddressValid(isValid);
                }}
              />
              <div className="form-label d-block">
                <h6 className="primary-deep2">設定</h6>
              </div>
              <div className="btn px-0 d-flex" onClick={() => handlePrevset(item.preset, isPreset)}>
                <div
                  className={`checkbox-icon default ${
                    isPreset === true ? "d-none" : "d-block"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                  >
                    <path
                      d="M2.5 18C1.95 18 1.479 17.804 1.087 17.412C0.695002 17.02 0.499335 16.5493 0.500002 16V2C0.500002 1.45 0.696002 0.979002 1.088 0.587002C1.48 0.195002 1.95067 -0.000664969 2.5 1.69779e-06H16.5C17.05 1.69779e-06 17.521 0.196002 17.913 0.588002C18.305 0.980002 18.5007 1.45067 18.5 2V16C18.5 16.55 18.304 17.021 17.912 17.413C17.52 17.805 17.0493 18.0007 16.5 18H2.5ZM2.5 16H16.5V2H2.5V16Z"
                      fill="#364F38"
                    />
                  </svg>
                </div>
                <div
                  className={`checkbox-icon check ${
                    isPreset === true ? "d-block" : "d-none"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                  >
                    <path
                      d="M8.1 13.2L15.15 6.15L13.75 4.75L8.1 10.4L5.25 7.55L3.85 8.95L8.1 13.2ZM2.5 18C1.95 18 1.479 17.804 1.087 17.412C0.695002 17.02 0.499335 16.5493 0.500002 16V2C0.500002 1.45 0.696002 0.979002 1.088 0.587002C1.48 0.195002 1.95067 -0.000664969 2.5 1.69779e-06H16.5C17.05 1.69779e-06 17.521 0.196002 17.913 0.588002C18.305 0.980002 18.5007 1.45067 18.5 2V16C18.5 16.55 18.304 17.021 17.912 17.413C17.52 17.805 17.0493 18.0007 16.5 18H2.5ZM2.5 16H16.5V2H2.5V16Z"
                    fill="#364F38"
                  />
                   </svg>
                </div>
                <h6 className="pt-0 ps-1 primary-deep2">設定為預設地址</h6>
              </div>
              <div className="pt-5 pb-3 border-bottom-light">
                <button
                  className="btn primary-btn2 w-100"
                  disabled={!namePhoneValid && !addressValid && !hasPresetChange}
                >
                  <h6 className="py-1">儲存變更</h6>
                </button>
              </div>
            </form>
          )}
        </div>
            ))}
      </div>
      <div className="d-flex flex-column gap-3">
        <div className="address-data add-address-data w-100">
          <button
            className="btn edit w-100 d-flex justify-content-center align-items-center gap-10px"
            onClick={handleAddAddressClick}
          >
            {isAddingAddress ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.0711 3.42893C15.1957 1.55357 12.6522 0.5 10 0.5C7.34784 0.5 4.8043 1.55357 2.92893 3.42893C1.05357 5.3043 0 7.84784 0 10.5C0 13.1522 1.05357 15.6957 2.92893 17.5711C4.8043 19.4464 7.34784 20.5 10 20.5C12.6522 20.5 15.1957 19.4464 17.0711 17.5711C18.9464 15.6957 20 13.1522 20 10.5C20 7.84784 18.9464 5.3043 17.0711 3.42893ZM5.39 9.3275H8.8275H11.3275H14.765C15.0965 9.3275 15.4145 9.4592 15.6489 9.69362C15.8833 9.92804 16.015 10.246 16.015 10.5775C16.015 10.909 15.8833 11.227 15.6489 11.4614C15.4145 11.6958 15.0965 11.8275 14.765 11.8275H11.3275H8.8275H5.39C5.05848 11.8275 4.74054 11.6958 4.50612 11.4614C4.2717 11.227 4.14 10.909 4.14 10.5775C4.14 10.246 4.2717 9.92804 4.50612 9.69362C4.74054 9.4592 5.05848 9.3275 5.39 9.3275Z"
                  fill="#6A7C6C"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <path
                  d="M10.5 0.5C13.1522 0.5 15.6957 1.55357 17.5711 3.42893C19.4464 5.3043 20.5 7.84784 20.5 10.5C20.5 13.1522 19.4464 15.6957 17.5711 17.5711C15.6957 19.4464 13.1522 20.5 10.5 20.5C7.84784 20.5 5.3043 19.4464 3.42893 17.5711C1.55357 15.6957 0.5 13.1522 0.5 10.5C0.5 7.84784 1.55357 5.3043 3.42893 3.42893C5.3043 1.55357 7.84784 0.5 10.5 0.5ZM11.8275 5.89C11.8275 5.55848 11.6958 5.24054 11.4614 5.00612C11.227 4.7717 10.909 4.64 10.5775 4.64C10.246 4.64 9.92804 4.7717 9.69362 5.00612C9.4592 5.24054 9.3275 5.55848 9.3275 5.89V9.3275H5.89C5.55848 9.3275 5.24054 9.4592 5.00612 9.69362C4.7717 9.92804 4.64 10.246 4.64 10.5775C4.64 10.909 4.7717 11.227 5.00612 11.4614C5.24054 11.6958 5.55848 11.8275 5.89 11.8275H9.3275V15.265C9.3275 15.5965 9.4592 15.9145 9.69362 16.1489C9.92804 16.3833 10.246 16.515 10.5775 16.515C10.909 16.515 11.227 16.3833 11.4614 16.1489C11.6958 15.9145 11.8275 15.5965 11.8275 15.265V11.8275H15.265C15.5965 11.8275 15.9145 11.6958 16.1489 11.4614C16.3833 11.227 16.515 10.909 16.515 10.5775C16.515 10.246 16.3833 9.92804 16.1489 9.69362C15.9145 9.4592 15.5965 9.3275 15.265 9.3275H11.8275V5.89Z"
                  fill="#6A7C6C"
                />
              </svg>
            )}
            <h6 className="py-1">
              {isAddingAddress ? "取消新增收件地址" : "新增收件地址"}
            </h6>
          </button>
        </div>
        {isAddingAddress && (
          <form className="member-form" onSubmit={handleAddNewData}>
            <NamePhoneLabel
              itemName=""
              itemPhone=""
              itemTelephone=""
              onNamePhoneChange={(name, phone, telephone) => {
                console.log("改變的子層"," name, phone, telephone");
                setReciptName(name);
                setReciptPhone(phone);
                setReciptTele(telephone);
              }}
              isNamePhoneValid={(isValid) => {
                console.log("父層接受到的格式", "isValid");
                setNamePhoneValid(isValid);
              }}
            />
            <label htmlFor="username" className="form-label d-block">
              <h6 className="primary-deep2">收件地址*</h6>
            </label>
            <TWZipCode
              initPostcode={postcode}
              onPostcodeChange={(country, township, postcode) => {
                setReciptePostcode(postcode);
                setRecipteCountry(country);
                setRecipteTownship(township);
              }}
              onAddressChange={(address) => {
                console.log("address", "address");
                setRecipteAddress(address);
              }}
              itemAddress=""
              isTWZipCodeValid={(isValid) => {
                console.log("父層接受到的地址格式", "isValid");
                setAddressValid(isValid);
              }}
            />
            <div className="form-label d-block">
                <h6 className="primary-deep2">設定</h6>
              </div>
              <div className="btn px-0 d-flex" onClick={() => handlePrevset(false, isPreset)}>
                <div
                  className={`checkbox-icon default ${
                    isPreset === true ? "d-none" : "d-block"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                  >
                    <path
                      d="M2.5 18C1.95 18 1.479 17.804 1.087 17.412C0.695002 17.02 0.499335 16.5493 0.500002 16V2C0.500002 1.45 0.696002 0.979002 1.088 0.587002C1.48 0.195002 1.95067 -0.000664969 2.5 1.69779e-06H16.5C17.05 1.69779e-06 17.521 0.196002 17.913 0.588002C18.305 0.980002 18.5007 1.45067 18.5 2V16C18.5 16.55 18.304 17.021 17.912 17.413C17.52 17.805 17.0493 18.0007 16.5 18H2.5ZM2.5 16H16.5V2H2.5V16Z"
                      fill="#364F38"
                    />
                  </svg>
                </div>
                <div
                  className={`checkbox-icon check ${
                    isPreset === true ? "d-block" : "d-none"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                  >
                    <path
                      d="M8.1 13.2L15.15 6.15L13.75 4.75L8.1 10.4L5.25 7.55L3.85 8.95L8.1 13.2ZM2.5 18C1.95 18 1.479 17.804 1.087 17.412C0.695002 17.02 0.499335 16.5493 0.500002 16V2C0.500002 1.45 0.696002 0.979002 1.088 0.587002C1.48 0.195002 1.95067 -0.000664969 2.5 1.69779e-06H16.5C17.05 1.69779e-06 17.521 0.196002 17.913 0.588002C18.305 0.980002 18.5007 1.45067 18.5 2V16C18.5 16.55 18.304 17.021 17.912 17.413C17.52 17.805 17.0493 18.0007 16.5 18H2.5ZM2.5 16H16.5V2H2.5V16Z"
                    fill="#364F38"
                  />
                   </svg>
                </div>
                <h6 className="pt-0 ps-1 primary-deep2">設定為預設地址</h6>
              </div>

            <div className=" pt-5 pb-3 border-bottom-light ">
              <button
                className="btn primary-btn2 w-100"
                disabled={!namePhoneValid || !addressValid}
              >
                <h6 className="py-1">新增地址</h6>
              </button>
            </div>
          </form>
        )}
      </div>
    </>
  );
}

export default MemberDataShipping;
