import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import Popup from "../../sharedComponents/Popup";
import axios from "axios";
function ProductLike({ productDataId, hearttype }) {
  const [heartType, setHeartType] = useState("");
  const { myAuth, getHeartData } = useContext(AuthContext);
  const [isLiked, setIsLiked] = useState(false);

  // popup
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const navigate = useNavigate();

  //按下收藏
  const handleHeartClick = () => {
    if (myAuth.authorized) {
      // console.log("AuthContext", myAuth.sid);
      // console.log("productDataId", productDataId);

      const mid = parseInt(myAuth.sid);
      const pid = parseInt(productDataId);

      const requestData = {
        mid: mid,
        pid: pid,
      };
      const storedToken = myAuth.token;
      const hasMatchingPid = myAuth.heartData.some((item) => item.pid === pid);
      if (hasMatchingPid) {
        console.log("已經有數據");
        deletefavorite(mid, requestData, storedToken);
      } else {
        console.log("沒有數據");
        // 触发 addfavorite 函数
        addfavorite(mid, requestData, storedToken);
      }
    } else {
      setIsPopupOpen(true);
    }
  };
  //加入收藏
  const addfavorite = (mid, requestData, storedToken) => {
    axios
      .post(`/api/Member/AddFavorite`, requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("response Data:", "response.data");
          getHeartData(storedToken, mid);
        } else {
          console.log("Request failed:", "response");
          // Token is invalid, log out the user
        }
      })
      .catch((error) => {
        console.error("Error:", "error");
      })
      .finally(() => {
        // setIsLoading(false); // Mark loading as finished regardless of success or error
      });
  };
  //刪除收藏
  const deletefavorite = (mid, requestData, storedToken) => {
    axios
      .post(`/api/Member/DeleteFavorite`, requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("response Data:", "response.data");
          getHeartData(storedToken, mid);
        } else {
          console.log("Request failed:", "response");
          // Token is invalid, log out the user
        }
      })
      .catch((error) => {
        console.error("Error:", "error");
      })
      .finally(() => {
        // setIsLoading(false); // Mark loading as finished regardless of success or error
      });
  };

  useEffect(() => {}, []);
  useEffect(() => {
    //在元件載入時檢查是否有匹配的 pid，並設置 isLiked
    if (myAuth.authorized && myAuth.heartData) {
      const pid = parseInt(productDataId);
      const hasMatchingPid = myAuth.heartData.some((item) => item.pid === pid);
      setIsLiked(hasMatchingPid);
    }
    //在元件載入時設置收藏型別
    if (hearttype) {
      setHeartType(hearttype);
    }
  }, [myAuth.heartData, productDataId, hearttype]);
  return (
    <>
      {isPopupOpen && (
        <Popup
          onNevigate={() => {
            setIsPopupOpen(false);
            navigate("/login");
          }}
          onClose={() => {
            setIsPopupOpen(false);
          }}
          icon={"notice"}
          title={"登入才可加入收藏"}
          orangebtn={"前往登入"}
          greenbtn={"暫不登入"}
        />
      )}
      {/* {heartType && <div>{heartType}</div>} */}
      {heartType && heartType === "button" && (
        <div className={`btn  px-0 ${isLiked ? "primary-btn5  primary-btn5-select" : "primary-btn5 "}`} onClick={() => handleHeartClick(productDataId)}>
          <h5 className="cartbtn ">{`${isLiked ? "已收藏" : "加入收藏"}`}</h5>
        </div>
      )}
      {heartType && heartType === "icon" && (
        <div className="heart" onClick={() => handleHeartClick(productDataId)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="32"
            viewBox="0 0 37 32"
            fill="none"
            className={isLiked ? "selected" : "default"}
          >
            <path
              d="M18.5089 30.4L4.00231 17.1344C-3.88173 9.17507 7.70781 -6.10686 18.5089 6.25664C29.3101 -6.10686 40.8471 9.22813 33.0156 17.1344L18.5089 30.4Z"
              stroke="#364F38"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
    </>
  );
}

export default ProductLike;
