import React from 'react'
import { Link } from 'react-router-dom'
function TryState() {
  return (
    <>
<div className="container">
    <div>
    <Link to="/finishorder" className='btn primary-btn' state={"F"}>TryState"F"</Link>

    </div>
    <div>
    <Link to="/finishorder"  className='btn primary-btn' state={"L"}>TryState"L"</Link>

    </div>
    <div>
    <Link to="/finishorder"  className='btn primary-btn' state={""}>State""</Link>

    </div>

</div>
    </>
  )
}

export default TryState