import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import heroImage from "../image/newshero.JPG";
import NewsCard from "../sharedComponents/NewsCard";
import Pagination from "../sharedComponents/Pagination";
import Nodata from "../sharedComponents/Nodata";
import axios from "axios";
import Loading from "./Loading";

const NewsCate = () => {
  const [selectedCate, setSelectedCate] = useState("全部");
  const [currentPage, setCurrentPage] = useState(1);
  const [cateData, setCateData] = useState([]);
  const [filteredBlogData, setFilteredBlogData] = useState([]);
  const [isCategoryValid, setIsCategoryValid] = useState(true); // New state for category validity

  const [isLoading, setIsLoading] = useState(false);

  const [blogImg, setBlogImg] = useState("");
  const { blogCateSlug } = useParams();
  const navigate = useNavigate();
  const location = useLocation(); // Hook to get current location

  // const fetchData = async () => {
  //   setIsLoading(true);
  //   try {
  //     let response;
  //     if (selectedCate === '全部') {
  //       response = await axios.get(`api/Blog/cate/slug/`);
  //     } else if (isCategoryValid) {  
  //       response = await axios.get(`api/Blog/cate/slug/${selectedCate}`);
  //     } else {
  //       setFilteredBlogData([]);
  //       setIsLoading(false);
  //       return;
  //     }

  //     setFilteredBlogData(response.data);
  //   } catch (error) {
  //     if (error.response.status !== 404) {
  //       // only console error if not 404
  //       console.error('Error fetching data:', error);  
  //     }
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }
  useEffect(() => {
    axios
      .get("api/Home/StoreData")
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
      
          setBlogImg(data.blogImage);
        }
      })
      .catch(() => {
        console.error("Error fetching data from API");
      });
  }, []);

  useEffect(() => {
    const storedCategoryId = sessionStorage.getItem("categoryId");
    if (storedCategoryId) {
      setSelectedCate(storedCategoryId);
    } else if (!blogCateSlug) {
      setSelectedCate("全部");
    } else {
      setSelectedCate(blogCateSlug);
    }
  }, [blogCateSlug]);

  useEffect(() => {
    if (location.pathname !== "/blogs" ) {
      // Check if the category slug exists in cateData
      const categoryExists = cateData.some(
        (item) => item.slug === blogCateSlug
      );
      if(categoryExists){
        setIsCategoryValid(categoryExists);
      }else{
      setIsCategoryValid(false)
      navigate("/blogs");
    }
     
    }
    if (location.pathname === "/blogs") {
      // Check if the category slug exists in cateData

      setSelectedCate("全部");
    }
  }, [blogCateSlug, cateData, location.pathname]);


  const handleCategoryClick = (cateId) => {
    sessionStorage.setItem("categoryId", cateId);
    setSelectedCate(cateId);
    setCurrentPage(1);

    const newUrl = `/blogs/${cateId}`;
    window.history.replaceState(null, "", newUrl);
    navigate(newUrl);
  };
  const itemsPerPage = 12;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentBlogData = filteredBlogData.slice(startIndex, endIndex);

  const maxPage = Math.ceil(filteredBlogData.length / itemsPerPage);
  const isPreviousDisabled = currentPage === 1;
  const isNextDisabled =
    currentPage === maxPage || currentBlogData.length < itemsPerPage;

    useEffect(() => {
      setIsLoading(true);
      console.log("loading")
      axios.get('api/Blog/cate/slug/')
        .then((response) => {
          const data = response.data;
          const filteredData =
            selectedCate === '全部'
              ? data
              : data.filter((blogData) => blogData.blogCate_Slug === selectedCate);
          setFilteredBlogData(filteredData);
        })
        .catch((error) => {
          // console.error("Error fetching data from API:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, [selectedCate]);
  // useEffect(() => {
  //   fetch(`api/Blog/cate/slug/`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // console.log(data,"測試");
  //       const tryData = data.filter(
  //         (blogData) => blogData.blogCate_Slug === "活動訊息"
  //       );
  //       //  console.log(tryData);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data from API:", error);
  //     });
  // }, [selectedCate]);
  useEffect(() => {
    axios.get('api/Blog/cate/')
      .then((response) => {
        setCateData(response.data);
        // console.log("cateid", response.data);
      })
      .catch((error) => {
        console.error('Error fetching data from API:');
      });
  }, []);
  if (isLoading) {
    return <Loading />;
  }
  return (
    <React.Fragment>
      <div className="container">
        <div className="container-md news-hero px-0 px-md-auto">
          {blogImg ? <img src={blogImg} alt="" /> : <Loading />}
        </div>

        <div className="container px-0">
          <div className="news-cate">
            <button
              className={`btn news-cate-btn ${
                selectedCate === "全部" ? "selected" : ""
              }`}
              onClick={() => handleCategoryClick("全部")}
            >
              全部
            </button>
            {cateData.map((item) => (
              <button
                key={item.slug}
                className={`btn news-cate-btn ${
                  selectedCate.trim() === item.slug.trim() ? "selected" : ""
                }`}
                onClick={() => handleCategoryClick(item.slug)}
              >
                {item.catename}
              </button>
            ))}
          </div>
        </div>
        <div className="news-cate-content">
          {isLoading ? ( // 根據 isLoading 判斷是否顯示 Loading 元件
            <Loading />
          ) : (
            <div className="news">
              {filteredBlogData.length > 0 ? (
                currentBlogData.map((blogData, index) => (
                  <NewsCard
                    key={index}
                    blogTitle={blogData.blog_Title}
                    blogDescription={blogData.blog_Content}
                    blogDate={blogData.blog_PublishTime}
                    imageUrl={blogData.blog_MainImg}
                    blogSlug={blogData.blog_Slug}
                    blogCateSlug={blogData.blogCate_Slug}
                  />
                ))
              ) : (
                <div className="news">
                  <div className="news-card w-100">
                  <Nodata
                descript={"篩選的範圍沒有資料"}
                link={"../../blogs"}
                linkDescript={"查看全部最新消息"}
              />
                  </div>

                </div>
                
              )}
            </div>
          )}
        </div>
        <div className="container px-md-0">
          <div className="pagination justify-content-center primary-deep">
            <Pagination
              currentPage={currentPage}
              totalPages={maxPage}
              onPageChange={setCurrentPage}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewsCate;
