import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
function ShareInput({
  content,
  isInputValid,
  changeParentInput,
  initValue,
  labeltitle,
  noteText,
  show,
  notshow,
  autocomplete,
  texterror,
  textdanger,
  formcheck,
  placeholder,
  checkData,
}) {
  const [inputvalue, setInputValue] = useState("");
  const [inputOnBlur, setInputOnBlur] = useState(false);
  // 看見密碼
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = (e) => {
    e.preventDefault(); // 阻止默認行為
    setShowPassword(!showPassword);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };
  const isValidName = (name) => {
    const nameRegex = /^[A-Za-z0-9\u4e00-\u9fa5]+$/;
    return name.trim() !== "" && nameRegex.test(name);
  };
  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,20}$/;
    return passwordRegex.test(password);
  };
  const isValidConfirmPassword = (password) => {
    return password === checkData;
  };
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    changeParentInput(newValue);
    isInputValid(validateInput(content, newValue));
  };

  const focusInput = (content) => {
    const inputId = getInputId(content);

    if (!InputValidForFocus(inputId)) {
      const inputElement = document.getElementById(inputId);
      inputElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const getInputId = (content) => {
    switch (content) {
      case "email":
      case "login-email":
        return "email";
      case "name":
      case "login-name":
        return "name";
      case "password":
      case "login-password":
        return "password";
        case "confirmpwd":
          case "login-confirmpwd":
            return "confirmpwd";
      default:
        return "";
    }
  };

  const InputValidForFocus = (inputId) => {
    switch (inputId) {
      case "email":
        return isValidEmail(inputvalue);
      case "name":
        return isValidName(inputvalue);
      case "password":
        return isValidPassword(inputvalue);
        case "confirmpwd":
          return isValidConfirmPassword(inputvalue);
      default:
        return true;
    }
  };

  const validateInput  = (content, value) => {
    switch (content) {
      case "email":
        return isValidEmail(value);
      case "name":
        return isValidName(value);
      case "password":
        return isValidPassword(value);
        case "confirmpwd":
          return isValidConfirmPassword(value);
      default:
        return true;
    }
  };


  // useEffect(() => {
  //   if ((content === "email" || content === "login-email") && initValue) {
  //     setInputValue(initValue);
  //   }
  //   if ((content === "name" || content === "login-name") && initValue) {
  //     setInputValue(initValue);
  //   }
  //   if ((content === "password" || content === "login-password") && initValue) {
  //     setInputValue(initValue);
  //   }
  //   if (
  //     (content === "confirmpwd" || content === "login-confirmpwd") &&
  //     initValue
  //   ) {
  //     setInputValue(initValue);
  //   }
  // }, [content, initValue]);
  useEffect(() => {
    setInputValue(initValue); // Update input value when initValue changes
  }, [initValue]);

  useEffect(() => {
    if ((content === "email" || content === "login-email") && inputvalue) {
      isValidEmail(inputvalue)
    }
    if ((content === "name" || content === "login-name") && inputvalue) {
      isValidName(inputvalue)
    }
    if ((content === "password" || content === "login-password") && inputvalue) {
      isValidPassword(inputvalue);
    }
    if (
      (content === "confirmpwd" || content === "login-confirmpwd") &&
      inputvalue
    ) {
      isValidConfirmPassword(inputvalue);
    }
  }, [content, inputvalue]);

  useEffect(() => {
    if (inputvalue && content) {
      isInputValid(validateInput(content, inputvalue));
    }
  }, [content, inputvalue]);

  useEffect(() => {
    if (formcheck) {
      setInputOnBlur(true);
      focusInput(content);
    }
  }, [formcheck]);

  //重複的表單
  const errorIcon = (error) => {
    if ((error = "error")) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="19"
          viewBox="0 0 21 19"
          fill="none"
        >
          <path
            d="M0.98903 18.5C0.805697 18.5 0.63903 18.454 0.48903 18.362C0.33903 18.27 0.222364 18.1493 0.139031 18C0.0556972 17.85 0.00969715 17.6873 0.00103049 17.512C-0.00763618 17.3367 0.0383638 17.166 0.139031 17L9.38903 1C9.48903 0.833333 9.61836 0.708333 9.77703 0.625C9.9357 0.541667 10.098 0.5 10.264 0.5C10.4307 0.5 10.5934 0.541667 10.752 0.625C10.9107 0.708333 11.0397 0.833333 11.139 1L20.389 17C20.489 17.1667 20.535 17.3377 20.527 17.513C20.519 17.6883 20.473 17.8507 20.389 18C20.3057 18.15 20.189 18.271 20.039 18.363C19.889 18.455 19.7224 18.5007 19.539 18.5H0.98903ZM2.71403 16.5H17.814L10.264 3.5L2.71403 16.5ZM10.264 15.5C10.5474 15.5 10.785 15.404 10.977 15.212C11.169 15.02 11.2647 14.7827 11.264 14.5C11.264 14.2167 11.168 13.979 10.976 13.787C10.784 13.595 10.5467 13.4993 10.264 13.5C9.9807 13.5 9.74303 13.596 9.55103 13.788C9.35903 13.98 9.26336 14.2173 9.26403 14.5C9.26403 14.7833 9.36003 15.021 9.55203 15.213C9.74403 15.405 9.98136 15.5007 10.264 15.5ZM10.264 12.5C10.5474 12.5 10.785 12.404 10.977 12.212C11.169 12.02 11.2647 11.7827 11.264 11.5V8.5C11.264 8.21667 11.168 7.979 10.976 7.787C10.784 7.595 10.5467 7.49933 10.264 7.5C9.9807 7.5 9.74303 7.596 9.55103 7.788C9.35903 7.98 9.26336 8.21733 9.26403 8.5V11.5C9.26403 11.7833 9.36003 12.021 9.55203 12.213C9.74403 12.405 9.98136 12.5007 10.264 12.5Z"
            fill="#DC3545"
          />
        </svg>
      );
    }
  };

  return (
    <>
      {(content === "login-email" || content === "email") && (
        <div>
          <label htmlFor={content} className="form-label d-block">
            {/*  icon SVG here */}
            <h6 className="primary-deep2">
              {labeltitle ? labeltitle : "信箱(必填)"}
            </h6>
          </label>
          <input
            type="email"
            id={content}
            name={content}
            className="form-input"
            placeholder={placeholder ? placeholder : "請輸入常用信箱"}
            value={inputvalue}
            onChange={handleInputChange}
            onBlur={() => setInputOnBlur(true)}
            onClick={() => setInputOnBlur(false)}
            minLength={3}
            maxLength={254}
            autoComplete={autocomplete || "no-autofill"}
          />
          {/* <p>initValue:{initValue}</p> */}
          {noteText && <p className="secondary-deep">{noteText}</p>}
          {inputOnBlur && !isValidEmail(inputvalue) && !texterror && (
            <div className="error-message d-flex pb-0">
              {errorIcon("error")}
              <p className="ps-1 danger">
                {textdanger ? textdanger : "請輸入正確的信箱"}
              </p>
            </div>
          )}
          {inputOnBlur && !isValidEmail(inputvalue) && texterror && (
            <div className="error-message d-flex pb-0">
              {errorIcon("error")}
              <p className="ps-1 black-light">{texterror}</p>
            </div>
          )}
        </div>
      )}
      {(content === "login-name" || content === "name") && (
        <div>
          <label htmlFor={content} className="form-label d-block">
            {/*  icon SVG here */}
            <h6 className="primary-deep2">
              {labeltitle ? labeltitle : "姓名(必填)"}
            </h6>
          </label>
          <input
            type={content}
            id={content}
            name={content}
            className="form-input"
            placeholder={placeholder ? placeholder : "請輸入姓名"}
            value={inputvalue}
            onChange={handleInputChange}
            onBlur={() => setInputOnBlur(true)}
            onClick={() => setInputOnBlur(false)}
            minLength={1}
            maxLength={60}
            autoComplete={autocomplete || "no-autofill"}
          />
          <p>initValue:{initValue}</p>
          {noteText && <p className="secondary-deep">{noteText}</p>}
          {inputOnBlur && !isValidName(inputvalue) && !texterror && (
            <div className="error-message d-flex pb-0">
              {errorIcon("error")}
              <p className="ps-1 danger">
                {textdanger ? textdanger : "請輸入正確的名稱，不得包含特殊符號"}
              </p>
            </div>
          )}
          {inputOnBlur && !isValidName(inputvalue) && texterror && (
            <div className="error-message d-flex pb-0">
              {errorIcon("error")}
              <p className="ps-1 black-light">{texterror}</p>
            </div>
          )}
        </div>
      )}
      {(content === "login-password" || content === "password") && (
        <div>
          <label htmlFor={content} className="form-label d-block">
            {/*  icon SVG here */}
            <h6 className="primary-deep2">
              {labeltitle ? labeltitle : "密碼(必填)"}
            </h6>
          </label>
          <div className="d-flex">
            <input
              type={showPassword ? "text" : "password"}
              id={content}
              name={content}
              className="form-pwd"
              placeholder={
                placeholder
                  ? placeholder
                  : "請輸入密碼(格式為英文字母大小寫加上數字,共8~20位英數字)"
              }
             value={inputvalue}
              onChange={handleInputChange}
              onBlur={() => setInputOnBlur(true)}
              onClick={() => setInputOnBlur(false)}
              minLength={8}
              maxLength={20}
              autoComplete={autocomplete || "no-autofill"}
            />
            <div className="btn see-pwd ">
              <button
                className="btn checkbox-icon check showpwd"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.3267 0L0 1.32686L3.32283 4.64929C1.86598 5.81447 0.715658 7.30499 0 8.99996C1.73 13.0973 6 16 11 16C12.1278 16 13.2185 15.8523 14.2513 15.5765L16.9518 18.2766L18.2785 16.9497L1.3267 0ZM12.6749 14.0002L10.4219 11.7475C9.84146 11.6411 9.30305 11.3759 8.87868 10.9799C8.40503 10.5378 8.10612 9.96312 8.02343 9.34933L4.68517 6.01147C3.63979 6.83061 2.78481 7.84738 2.18 8.99996C2.98825 10.5402 4.24331 11.838 5.80248 12.7457C7.36165 13.6533 9.1624 14.1345 11 14.1345C11.5637 14.1345 12.124 14.0892 12.6749 14.0002ZM10.2905 6.27939L13.8592 9.84771C13.9517 9.57602 14 9.29031 14 8.99996C14 8.25735 13.6839 7.54516 13.1213 7.02006C12.5587 6.49496 11.7956 6.19996 11 6.19996C10.7589 6.19996 10.5209 6.22704 10.2905 6.27939ZM19.82 8.99996C19.0728 10.424 17.9436 11.6407 16.5453 12.5335L17.92 13.9081C19.7365 12.6789 21.1645 10.9788 22 8.99996C20.27 4.90262 16 1.99996 11 1.99996C9.50062 1.99996 8.06689 2.26098 6.74761 2.73697L8.24441 4.2336C9.13525 3.99055 10.0628 3.86539 11 3.86539C12.8376 3.86539 14.6383 4.34658 16.1975 5.25425C17.7567 6.16193 19.0117 7.45967 19.82 8.99996Z"
                      fill="#D9D9D9"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                  >
                    <path
                      d="M11 6.2C11.7956 6.2 12.5587 6.495 13.1213 7.0201C13.6839 7.5452 14 8.25739 14 9C14 9.74261 13.6839 10.4548 13.1213 10.9799C12.5587 11.505 11.7956 11.8 11 11.8C10.2044 11.8 9.44129 11.505 8.87868 10.9799C8.31607 10.4548 8 9.74261 8 9C8 8.25739 8.31607 7.5452 8.87868 7.0201C9.44129 6.495 10.2044 6.2 11 6.2ZM11 2C16 2 20.27 4.90267 22 9C20.27 13.0973 16 16 11 16C6 16 1.73 13.0973 0 9C1.73 4.90267 6 2 11 2ZM2.18 9C2.98825 10.5403 4.24331 11.838 5.80248 12.7457C7.36165 13.6534 9.1624 14.1346 11 14.1346C12.8376 14.1346 14.6383 13.6534 16.1975 12.7457C17.7567 11.838 19.0117 10.5403 19.82 9C19.0117 7.45971 17.7567 6.16197 16.1975 5.2543C14.6383 4.34662 12.8376 3.86544 11 3.86544C9.1624 3.86544 7.36165 4.34662 5.80248 5.2543C4.24331 6.16197 2.98825 7.45971 2.18 9Z"
                      fill="#D9D9D9"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <p>initValue:{initValue}</p>
          {noteText && <p className="secondary-deep">{noteText}</p>}
          {inputOnBlur && !isValidPassword(inputvalue) && !texterror && (
            <div className="error-message d-flex pb-0">
              {errorIcon("error")}
              <p className="ps-1 danger">
                {textdanger ? textdanger : "請輸入正確的密碼"}
              </p>
            </div>
          )}
          {inputOnBlur && !isValidPassword(inputvalue) && texterror && (
            <div className="error-message d-flex pb-0">
              {errorIcon("error")}
              <p className="ps-1 black-light">{texterror}</p>
            </div>
          )}
        </div>
      )}
      {(content === "login-confirmpwd" || content === "confirmpwd") && (
        <div>
          <label htmlFor={content} className="form-label d-block">
            {/*  icon SVG here */}
            <h6 className="primary-deep2">
              {labeltitle ? labeltitle : "再次輸入密碼(必填)"}
            </h6>
          </label>
          <input
            type="password"
            id={content}
            name={content}
            className="form-input"
            placeholder={placeholder ? placeholder : "請再次輸入密碼"}
           value={inputvalue}
            onChange={handleInputChange}
            onBlur={() => setInputOnBlur(true)}
            onClick={() => setInputOnBlur(false)}
            minLength={8}
            maxLength={20}
            autoComplete={autocomplete || "no-autofill"}
          />
          <p>initValue:{initValue}</p>
          {noteText && <p className="secondary-deep">{noteText}</p>}
          {inputOnBlur && !isValidConfirmPassword(inputvalue) && !texterror && (
            <div className="error-message d-flex pb-0">
              {errorIcon("error")}
              <p className="ps-1 danger">
                {textdanger ? textdanger : "再次輸入密碼和密碼不相同"}
              </p>
            </div>
          )}
          {inputOnBlur && !isValidConfirmPassword(inputvalue) && texterror && (
            <div className="error-message d-flex pb-0">
              {errorIcon("error")}
              <p className="ps-1 black-light">{texterror}</p>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ShareInput;
