//NamePhoneLabel.js
import { useEffect, useState } from "react";

export default function NamePhoneLabel({
  itemName = "",
  itemPhone = "",
  itemTelePhone = "",
  onNamePhoneChange = (name, phone, telephone) => {},
  isNamePhoneValid = (isValid) => {},
  noChangeOk = null,
  phonestyle = "",
  nametitle = "",
  phonetitle = "",
  telephonetitle = "",
  musthavephone = false,
  errornone = null,
}) {
  // 郵遞區號使用字串(數字字串)
  //表單格式
  const [name, setName] = useState("");
  const [hasNameChanged, setHasNameChanged] = useState(false); // 追蹤名稱是否已經修改
  const [phone, setPhone] = useState("");
  const [hasPhoneChanged, setHasPhoneChanged] = useState(false);
  const [telephone, setTelePhone] = useState("");
  const [hasTelePhoneChanged, setHasTelePhoneChanged] = useState(false); //名稱
  const [nameOnBlur, setNameBlur] = useState(false);
  const [phoneOnBlur, setphoneBlur] = useState(false);
  const [teleOnBlur, setTeleBlur] = useState(false);
  const handleNameChange = (e) => {
    const newValue = e.target.value;
    setName(newValue);
    console.log("changename");

    if (newValue !== itemName) {
      console.log("已經修改姓名請記得儲存");
      setHasNameChanged(true); // 名稱已經修改
      // onNamePhoneChange(name, phone, telephone);
    } else {
      setHasNameChanged(false); // 名稱還原成原始值
    }
  };

  const handleQuickFillName = () => {
    // 只有在名稱已經修改的情況下才填充名稱
    if (!hasNameChanged) {
      setName(itemName || "");
    }
  };
  //手機
  const handlePhoneChange = (e) => {
    const newValue = e.target.value;
    setPhone(newValue);
    console.log("handlePhoneChange");
    if (newValue !== itemPhone) {
      console.log("已經修改手機號碼請記得儲存");
      setHasPhoneChanged(true); // 名稱已經修改
    } else {
      setHasPhoneChanged(false); // 名稱還原成原始值
    }
  };

  const handleQuickFillPhone = () => {
    // 只有在名稱已經修改的情況下才填充名稱
    if (!hasPhoneChanged) {
      setPhone(itemPhone || "");
    }
  };

  //電話
  const handleTelePhoneChange = (e) => {
    const newValue = e.target.value;
    setTelePhone(newValue);
    console.log("handlePhoneChange");
    if (newValue !== itemTelePhone) {
      console.log("已經修改電話號碼請記得儲存");
      setHasTelePhoneChanged(true); // 名稱已經修改
    } else {
      setHasTelePhoneChanged(false); // 名稱還原成原始值
    }
  };
  const handleQuickFillTelePhone = () => {
    // 只有在名稱已經修改的情況下才填充名稱
    if (!hasTelePhoneChanged) {
      setTelePhone(itemTelePhone || "");
    }
  };

  //格式檢查
  const isValidName = (name) => {
    const nameRegex = /^[\u4e00-\u9fa5]{2,5}$|^[A-Za-z]{1,10}$/;
    return name.trim() !== "" && nameRegex.test(name);
  };
  const isValidPhoneNumber = (phone) => {
    const phoneRegex = /^09[0-9]{8}$/;
    return phoneRegex.test(phone);
  };
  const isValidTelephone = (telephone) => {
    const regex = /^[0-9\(\)\-#]*$/;

    if (musthavephone) {
      //電話為必填
      if (telephone !== "") {
        return regex.test(telephone);
      } else {
        return false;
      }
    } else {
      //電話不為必填
      if (telephone === "") {
        return true;
      } else {
        return regex.test(telephone);
      }
    }
  };
// useEffect(()=>{
//   console.log("電話格式",isValidTelephone(telephone))
// },[telephone])
  //回傳驗證的格式給父層
  useEffect(() => {
    if (phonestyle === "d-none") {
      if (!telephone || !name) {
        isNamePhoneValid(false);
        console.log("回傳驗證的格式給父層isNamePhoneValid", false);
      } else if (
        telephone &&
        name &&
        isValidName(name) &&
        isValidTelephone(telephone)
      ) {
        isNamePhoneValid(true);
        console.log("回傳驗證的格式給父層isNamePhoneValid", true);
      } else {
        isNamePhoneValid(false);
        console.log("回傳驗證的格式給父層isNamePhoneValid", false);
      }
    } else {
      if (
        !isValidName(name) ||
        !isValidPhoneNumber(phone) ||
        !isValidTelephone(telephone)
      ) {
        isNamePhoneValid(false);
        console.log("回傳驗證的格式給父層isNamePhoneValid", false);
      }
      if (
        isValidName(name) &&
        isValidPhoneNumber(phone) &&
        isValidTelephone(telephone) &&
        (hasNameChanged || hasPhoneChanged || hasTelePhoneChanged || noChangeOk)
      ) {
        isNamePhoneValid(true);
        console.log("回傳驗證的格式給父層isNamePhoneValid", true);
      } else {
        isNamePhoneValid(false);
        console.log("回傳驗證的格式給父層isNamePhoneValid", false);
      }
    }
  }, [
    isValidName,
    isValidPhoneNumber,
    isValidTelephone,
    itemName,
    itemPhone,
    itemTelePhone,
  ]);

  //重複的表單

  const renderInputField = (
    id,
    name,
    placeholder,
    value,
    onChange,
    pattern = "",
    onBlur = null,
    onClick = null,
    minLength,
    maxLength
  ) => (
    <input
      type="text"
      id={id}
      name={name}
      className={`form-input ${value ? "prev-input-data" : ""} `}
      pattern={pattern}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      onClick={onClick}
      onBlur={onBlur}
      minLength={minLength}
      maxLength={maxLength}
    />
  );
  const errorInput = (hasChanged, isValid, id, errormessage) => (
    errornone === true ? (
      <div
      className={`error-message ${
        hasChanged && !isValid ? "d-block" : "d-none"
      } d-flex pb-0`}
      id={id}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="19"
        viewBox="0 0 21 19"
        fill="none"
        className="pt-1"
      >
        <path
          d="M0.98903 18.5C0.805697 18.5 0.63903 18.454 0.48903 18.362C0.33903 18.27 0.222364 18.1493 0.139031 18C0.0556972 17.85 0.00969715 17.6873 0.00103049 17.512C-0.00763618 17.3367 0.0383638 17.166 0.139031 17L9.38903 1C9.48903 0.833333 9.61836 0.708333 9.77703 0.625C9.9357 0.541667 10.098 0.5 10.264 0.5C10.4307 0.5 10.5934 0.541667 10.752 0.625C10.9107 0.708333 11.0397 0.833333 11.139 1L20.389 17C20.489 17.1667 20.535 17.3377 20.527 17.513C20.519 17.6883 20.473 17.8507 20.389 18C20.3057 18.15 20.189 18.271 20.039 18.363C19.889 18.455 19.7224 18.5007 19.539 18.5H0.98903ZM2.71403 16.5H17.814L10.264 3.5L2.71403 16.5ZM10.264 15.5C10.5474 15.5 10.785 15.404 10.977 15.212C11.169 15.02 11.2647 14.7827 11.264 14.5C11.264 14.2167 11.168 13.979 10.976 13.787C10.784 13.595 10.5467 13.4993 10.264 13.5C9.9807 13.5 9.74303 13.596 9.55103 13.788C9.35903 13.98 9.26336 14.2173 9.26403 14.5C9.26403 14.7833 9.36003 15.021 9.55203 15.213C9.74403 15.405 9.98136 15.5007 10.264 15.5ZM10.264 12.5C10.5474 12.5 10.785 12.404 10.977 12.212C11.169 12.02 11.2647 11.7827 11.264 11.5V8.5C11.264 8.21667 11.168 7.979 10.976 7.787C10.784 7.595 10.5467 7.49933 10.264 7.5C9.9807 7.5 9.74303 7.596 9.55103 7.788C9.35903 7.98 9.26336 8.21733 9.26403 8.5V11.5C9.26403 11.7833 9.36003 12.021 9.55203 12.213C9.74403 12.405 9.98136 12.5007 10.264 12.5Z"
          fill="#DC3545"
        />
      </svg>
      <p className="ps-1 danger">{errormessage}</p>
    </div>
    ):(
      <div
      className={`error-message ${
        hasChanged && !isValid ? "visible" : "invisible"
      } d-flex pb-0`}
      id={id}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="19"
        viewBox="0 0 21 19"
        fill="none"
        className="pt-1"
      >
        <path
          d="M0.98903 18.5C0.805697 18.5 0.63903 18.454 0.48903 18.362C0.33903 18.27 0.222364 18.1493 0.139031 18C0.0556972 17.85 0.00969715 17.6873 0.00103049 17.512C-0.00763618 17.3367 0.0383638 17.166 0.139031 17L9.38903 1C9.48903 0.833333 9.61836 0.708333 9.77703 0.625C9.9357 0.541667 10.098 0.5 10.264 0.5C10.4307 0.5 10.5934 0.541667 10.752 0.625C10.9107 0.708333 11.0397 0.833333 11.139 1L20.389 17C20.489 17.1667 20.535 17.3377 20.527 17.513C20.519 17.6883 20.473 17.8507 20.389 18C20.3057 18.15 20.189 18.271 20.039 18.363C19.889 18.455 19.7224 18.5007 19.539 18.5H0.98903ZM2.71403 16.5H17.814L10.264 3.5L2.71403 16.5ZM10.264 15.5C10.5474 15.5 10.785 15.404 10.977 15.212C11.169 15.02 11.2647 14.7827 11.264 14.5C11.264 14.2167 11.168 13.979 10.976 13.787C10.784 13.595 10.5467 13.4993 10.264 13.5C9.9807 13.5 9.74303 13.596 9.55103 13.788C9.35903 13.98 9.26336 14.2173 9.26403 14.5C9.26403 14.7833 9.36003 15.021 9.55203 15.213C9.74403 15.405 9.98136 15.5007 10.264 15.5ZM10.264 12.5C10.5474 12.5 10.785 12.404 10.977 12.212C11.169 12.02 11.2647 11.7827 11.264 11.5V8.5C11.264 8.21667 11.168 7.979 10.976 7.787C10.784 7.595 10.5467 7.49933 10.264 7.5C9.9807 7.5 9.74303 7.596 9.55103 7.788C9.35903 7.98 9.26336 8.21733 9.26403 8.5V11.5C9.26403 11.7833 9.36003 12.021 9.55203 12.213C9.74403 12.405 9.98136 12.5007 10.264 12.5Z"
          fill="#DC3545"
        />
      </svg>
      <p className="ps-1 danger">{errormessage}</p>
    </div>
    )
    
  );

  //改變的資訊傳回父層
  useEffect(() => {
    onNamePhoneChange(name, phone, telephone);
  }, [name, phone, telephone]);

  //初始填入，預設值
  useEffect(() => {
    if (!hasNameChanged) {
      setName(itemName);
    }
    if (!hasPhoneChanged) {
      setPhone(itemPhone);
    }
    if (!hasTelePhoneChanged) {
      // const isNumeric = /^\d+$/.test(itemTelePhone);
      // if (isNumeric) {
      //   setTelePhone(itemTelePhone);
      // }
      setTelePhone(itemTelePhone);
    }
  }, [itemName, itemPhone, itemTelePhone]);
  return (
    <>
      <label htmlFor="username" className="form-label d-block pt-0 pt-xxl-4 ">
        <h6 className="primary-deep2">
          {nametitle ? nametitle : "收件人姓名(必填)"}
        </h6>
      </label>

      <div className="try">
        {renderInputField(
          "username",
          "username",
          itemName || `請輸入${nametitle ? nametitle : "收件人"}`,
          name,
          handleNameChange,
          "^[\u4e00-\u9fa5]{2,5}$|^[A-Za-z]{1,10}$",
          () => {
            setNameBlur(true);
          },
          () => {
            setNameBlur(false);
          },
          2,
          50
        )}
      </div>

      {errorInput(
        nameOnBlur,
        isValidName(name),
        "error-username",
        `${nametitle ? nametitle : "收件人"}不得為空，中文請輸入2~5字，英文請輸入4~10字，不可輸入數字或特殊符號`
      )}
      {phonestyle !== "d-none" ? (
        <>
          <label htmlFor="phone-number" className={`form-label d-block ${errornone ? "pt-3":""}`}>
            <h6 className="primary-deep2">
              {phonetitle ? phonetitle : "聯絡手機(必填)"}
            </h6>
          </label>

          {renderInputField(
            "phone-number",
            "phone-number",
            itemPhone || "輸入常用手機號碼(0912345678)",
            phone,
            handlePhoneChange,
            "[0-9]{10}",
            () => {
              setphoneBlur(true);
            },
            () => {
              setphoneBlur(false);
            },
            10,
            10
          )}


          {errorInput(
            phoneOnBlur,
            isValidPhoneNumber(phone),
            "error-phone-number",
            "請輸入正確的格式(例如：0912345678)"
          )}
        </>
      ) : (
        <></>
      )}

      <label htmlFor="telephone-number" className={`form-label d-block  ${errornone ? "pt-3":""}`}>
        <h6 className="primary-deep2">
          {telephonetitle ? telephonetitle : "聯絡市話"}
        </h6>
      </label>
      {renderInputField(
        "telephone-number",
        "telephone-number",
        itemTelePhone || "輸入常用聯絡市話(0212345678)",
        telephone,
        handleTelePhoneChange,
        null,
        () => {
          setTeleBlur(true);
        },
        () => {
          setTeleBlur(false);
        },
        0,
        20
      )}
      {errorInput(
        teleOnBlur,
        isValidTelephone(telephone),
        "error-telephone-number",
        "最多不得超過20字，特殊符號僅允許()-#"
      )}
    </>
  );
}
