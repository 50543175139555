import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Loading from './Loading';

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}.${month}.${day}`;
};

function Page() {
  const { pageSlug } = useParams();
  const [apiData, setApiData] = useState(null);
  console.log("pageSlug", pageSlug)
  const decodedName = decodeURIComponent(pageSlug.trim());

  useEffect(() => {
    fetch(`api/Home/Pages/${decodedName}`)
      .then(response => {
        if (!response.ok) {
          // Handle "Not Found" case by navigating to NotFound page
          //window.location.href = '/notfound';
          return null;
        }
        return response.json();
      })
      .then(data => {
        if (data) {
          // Modify the HTML content to add rel="noopener noreferrer" to <a> links with target="_blank"
          const modifiedData = {
            ...data,
            pages_Content: data.pages_Content.replace(
              /<a([^>]*)target=["']_blank["']/gi, 
              '<a$1target="_blank" rel="noopener noreferrer"'
            ),
          };

          setApiData(modifiedData);
          console.log('Data from API:', "modifiedData");
          console.log('Data from API:', "modifiedData.pages_Content");
        }
      })
      .catch(error => {
        console.error('Error fetching data from API:', "error");
      });
  }, [pageSlug]);

  if (!apiData) {
    return <Loading />;
  }

   // 使用默认的 DOMPurify 配置来净化 HTML 内容
   const sanitizedHTML = DOMPurify.sanitize(apiData.pages_Content, {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['src', 'allowfullscreen', 'frameborder', 'target']
  });
  const formattedDate = formatDate(apiData.pages_PublishTime) || 'Loading...';
  return (
    <React.Fragment>
      <div className="container page px-2 px-xxl-0">
        <h4>{apiData.pages_Title}</h4>
        <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
      </div>
    </React.Fragment>
  );
}

export default Page;
