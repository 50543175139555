import { useContext, useEffect, useState } from "react";
import axios from "axios";
import AuthContext from "../Context/AuthContext";
function ReturnRemittance({
  orderno,
  onClose,
  icon,
  title,
  orangebtn,
  greenbtn,
  onNevigate,
}) {
  const { myAuth, logout } = useContext(AuthContext); //登入狀態

  const [remitNo, setRemitNo] = useState("");
  const [RemitOnBlur, setRemitOnBlur] = useState(false);
  //選擇日期
  const [selectedDate, setselectedDate] = useState("");
  const [dateOnBlur, setDateOnBlur] = useState(false);
  const [remittedSucess, setRemitSucess] = useState(false);
//倒數
const [countdown, setCountdown] = useState(3);
  //輸入
  const handleRemitNoChange = (e) => {
    const newValue = e.target.value;
    setRemitNo(newValue);
  };
  const handleDateChange = (e) => {
    const newValue = e.target.value;
    setselectedDate(newValue);
    console.log("date", "newValue");
  };
  const isValidRemitNo = (remitNo) => {
    // 8-12 位数字

    if (remitNo !== "") {
      return true;
    } else {
      return false;
    }
  };
  const isValidRemitDate = (selectedDate) => {
    // 8-12 位数字

    if (selectedDate !== "") {
      return true;
    } else {
      return false;
    }
  };

  //回傳付款資料
  const sendRemittance = () => {
    if (
      !remitNo ||
      !selectedDate ||
      remitNo.trim() === "" ||
      selectedDate.trim() === "" ||
      !isValidRemitNo(remitNo) ||
      !isValidRemitDate(selectedDate)
    ) {
      return;
    }
    console.log("orderNo", "orderno");
    const formattedDate = selectedDate.split("-").join("/");
    const storedToken = myAuth.token;
    const requestData = {
      mid: myAuth.sid,
      orderNo: orderno,
      ATMCode: remitNo,
      ATMDate: formattedDate,
    };
    console.log("requestData", "requestData");
    axios
      .post("/api/Member/ReturnATMcode", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("回傳的ReturnATMcode", "response.data");
        console.log("回傳的ReturnATMcode", "requestData");

        setRemitSucess(true);
        // 关闭取消订单弹出框
        // onNevigate()
      })
      .catch((error) => {
        console.error("ReturnATMcode有錯誤：", "error");
        console.log("ReturnATMcode有錯誤", "requestData");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      });
  };
  //符號的渲染
  let iconElement = null;
  if (icon === "notice") {
    iconElement = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="61"
        height="62"
        viewBox="0 0 61 62"
        fill="none"
      >
        <g clipPath="url(#clip0_333_10370)">
          <path
            d="M30.5 0.451172C24.5666 0.451172 18.7664 2.23813 13.8329 5.58608C8.89943 8.93403 5.05426 13.6926 2.78363 19.26C0.512999 24.8275 -0.0811016 30.9537 1.07646 36.8641C2.23401 42.7744 5.09124 48.2035 9.28681 52.4646C13.4824 56.7257 18.8279 59.6276 24.6473 60.8032C30.4667 61.9789 36.4987 61.3755 41.9805 59.0694C47.4623 56.7633 52.1477 52.858 55.4441 47.8475C58.7405 42.8369 60.5 36.9461 60.5 30.9199C60.5 22.8391 57.3393 15.0893 51.7132 9.37526C46.0871 3.66126 38.4565 0.451172 30.5 0.451172ZM33.5 49.2012H27.5V43.1074H33.5V49.2012ZM33.5 37.0137H27.5V12.6387H33.5V37.0137Z"
            fill="#FF5C26"
          />
        </g>
        <defs>
          <clipPath id="clip0_333_10370">
            <rect
              width="60"
              height="60.9375"
              fill="white"
              transform="translate(0.5 0.451172)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (icon === "check") {
    iconElement = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
      >
        <path
          d="M40 0C17.9087 0 0 17.9087 0 40C0 62.0913 17.9087 80 40 80C62.0913 80 80 62.0913 80 40C80 17.9087 62.0913 0 40 0ZM40 8.6914C57.2913 8.6914 71.3037 22.7131 71.3037 40C71.3037 57.287 57.2913 71.3037 40 71.3037C22.7087 71.3037 8.69627 57.287 8.69627 40C8.69633 22.7131 22.7087 8.6914 40 8.6914ZM55.3271 21.0059L32.1435 44.1943L24.624 36.6748L17.2461 44.0479L24.7656 51.5674L32.1923 58.9941L39.5655 51.6162L62.7539 28.4326L55.3271 21.0059Z"
          fill="#FF5C26"
        />
      </svg>
    );
  }
  // useEffect(() => {
  //   console.log("orderno", orderno);
  // }, []);

  // useEffect(() => {
  //   console.log("icon", icon);
  // }, [icon]);
  useEffect(() => {
    if (remittedSucess) {
      const timer = setTimeout(() => {
        setCountdown((prevCount) => prevCount - 1);
      }, 1000);

      return () => {
        // Cleanup the timer when component unmounts or when remittedSuccess becomes false
        clearTimeout(timer);
      };
    }
  }, [remittedSucess, countdown]);
  useEffect(() => {
    if (countdown === 0) {
      // Trigger the parent component's function when countdown reaches 0
      onNevigate();
    }
  }, [countdown])
  //重複的表單
  const renderInputField = (
    id,
    name,
    type,
    placeholder,
    value,
    onChange,
    pattern = "",
    onBlur = null,
    onClick = null,
    minLength,
    maxLength
  ) => (
    <input
      type={type}
      id={id}
      name={name}
      className={`form-input w-50`}
      pattern={pattern}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      onClick={onClick}
      onBlur={onBlur}
      minLength={minLength}
      maxLength={maxLength}
    />
  );
  const errorInput = (hasChanged, isValid, id, errormessage) => (
    <div
      className={`error-message ${
        hasChanged && !isValid ? "d-block" : "d-none"
      } d-flex pb-0`}
      id={id}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="19"
        viewBox="0 0 21 19"
        fill="none"
        className="pt-1"
      >
        <path
          d="M0.98903 18.5C0.805697 18.5 0.63903 18.454 0.48903 18.362C0.33903 18.27 0.222364 18.1493 0.139031 18C0.0556972 17.85 0.00969715 17.6873 0.00103049 17.512C-0.00763618 17.3367 0.0383638 17.166 0.139031 17L9.38903 1C9.48903 0.833333 9.61836 0.708333 9.77703 0.625C9.9357 0.541667 10.098 0.5 10.264 0.5C10.4307 0.5 10.5934 0.541667 10.752 0.625C10.9107 0.708333 11.0397 0.833333 11.139 1L20.389 17C20.489 17.1667 20.535 17.3377 20.527 17.513C20.519 17.6883 20.473 17.8507 20.389 18C20.3057 18.15 20.189 18.271 20.039 18.363C19.889 18.455 19.7224 18.5007 19.539 18.5H0.98903ZM2.71403 16.5H17.814L10.264 3.5L2.71403 16.5ZM10.264 15.5C10.5474 15.5 10.785 15.404 10.977 15.212C11.169 15.02 11.2647 14.7827 11.264 14.5C11.264 14.2167 11.168 13.979 10.976 13.787C10.784 13.595 10.5467 13.4993 10.264 13.5C9.9807 13.5 9.74303 13.596 9.55103 13.788C9.35903 13.98 9.26336 14.2173 9.26403 14.5C9.26403 14.7833 9.36003 15.021 9.55203 15.213C9.74403 15.405 9.98136 15.5007 10.264 15.5ZM10.264 12.5C10.5474 12.5 10.785 12.404 10.977 12.212C11.169 12.02 11.2647 11.7827 11.264 11.5V8.5C11.264 8.21667 11.168 7.979 10.976 7.787C10.784 7.595 10.5467 7.49933 10.264 7.5C9.9807 7.5 9.74303 7.596 9.55103 7.788C9.35903 7.98 9.26336 8.21733 9.26403 8.5V11.5C9.26403 11.7833 9.36003 12.021 9.55203 12.213C9.74403 12.405 9.98136 12.5007 10.264 12.5Z"
          fill="#DC3545"
        />
      </svg>
      <p className="ps-1 danger">{errormessage}</p>
    </div>
  );
  return (
    <div className="popup-full-bg">
      <div className="popup-checkbox">
        {!remittedSucess &&  <div className="popup-content">
          <div className="popup-icon text-center">{iconElement}</div>
          <div className="popup-title text-center mx-3 mx-xxl-0">
            <h5>{title}</h5>
          </div>
          <div className="d-flex flex-column gap-2">
            <div className="w-100">
              <div className="d-flex align-items-center justify-content-center">
                <label
                  htmlFor="remittanceNo"
                  className={`form-label d-block  w-auto text-nowrap mb-0`}
                >
                  <p className="primary-deep2 ">轉帳後五碼：</p>
                </label>
                {renderInputField(
                  "remittanceNo",
                  "remittanceNo",
                  "text",
                  "輸入轉帳後五碼",
                  remitNo,
                  handleRemitNoChange,
                  null,
                  () => {
                    setRemitOnBlur(true);
                  },
                  () => {
                    setRemitOnBlur(false);
                  },
                  0,
                  5
                )}
              </div>

              {errorInput(
                RemitOnBlur,
                isValidRemitNo(remitNo),
                "error-remittanceNo",
                "此欄位不得為空"
              )}
            </div>
            <div className="w-100">
              <div className="d-flex align-items-center justify-content-center">
                <label
                  htmlFor="selectedDate"
                  className={`form-label d-block  w-auto text-nowrap mb-0`}
                >
                  <p className="primary-deep2 ">轉帳的日期：</p>
                </label>
                {renderInputField(
                  "selectedDate",
                  "selectedDate",
                  "date",
                  "請選擇一個日期",
                  selectedDate,
                  handleDateChange,
                  null,
                  () => {
                    setDateOnBlur(true);
                  },
                  () => {
                    setDateOnBlur(false);
                  }
                )}
              </div>
              {errorInput(
                dateOnBlur,
                isValidRemitDate(selectedDate),
                "error-selectedDate",
                "此欄位不得為空"
              )}
            </div>
          </div>
        </div>}
        {remittedSucess &&  <div className="popup-content">
          <div className="popup-icon text-center"><svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
      >
        <path
          d="M40 0C17.9087 0 0 17.9087 0 40C0 62.0913 17.9087 80 40 80C62.0913 80 80 62.0913 80 40C80 17.9087 62.0913 0 40 0ZM40 8.6914C57.2913 8.6914 71.3037 22.7131 71.3037 40C71.3037 57.287 57.2913 71.3037 40 71.3037C22.7087 71.3037 8.69627 57.287 8.69627 40C8.69633 22.7131 22.7087 8.6914 40 8.6914ZM55.3271 21.0059L32.1435 44.1943L24.624 36.6748L17.2461 44.0479L24.7656 51.5674L32.1923 58.9941L39.5655 51.6162L62.7539 28.4326L55.3271 21.0059Z"
          fill="#FF5C26"
        />
      </svg></div>
          <div className="popup-title text-center mx-3 mx-xxl-0">
            <h5>已成功送出</h5>
            <p>{countdown} 秒後自動關閉畫面</p>
          </div>

        </div>}
        <div
          className={`container d-flex justify-content-center justify-content-center ${
            orangebtn !== "" && greenbtn !== "" && !remittedSucess
              ? "btn-group"
              : ""
          }`}
        >
          {orangebtn !== "" && !remittedSucess && (
            <div
              className={`btn  search-btn2 rounded ${
                !remitNo ||
                !selectedDate ||
                !isValidRemitNo(remitNo) ||
                !isValidRemitDate(selectedDate)
                  ? "disabled"
                  : ""
              }`}
              onClick={() => {
                sendRemittance();
              }}
            >
              <h6>{orangebtn}</h6>
            </div>
          )}
          {greenbtn !== "" && !remittedSucess && (
            <button className="btn  primary-btn rounded  " onClick={onClose}>
              <h6>{greenbtn}</h6>
            </button>
          )}
          {remittedSucess && (
            <button className="btn  search-btn2 rounded" onClick={onNevigate}>
              <h6>好的</h6>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ReturnRemittance;
