import React, { useEffect, useState } from 'react';
import newsHeroImage from '../image/Frame 15.png';
import { useLocation, useParams } from 'react-router-dom';
import axios from "axios";
import DOMPurify from 'dompurify';
import Loading from './Loading';


const formatDate = (dateString) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}.${month}.${day}`;
};
const filterContent = (content) => {
  if(!content) return '';
  return content.replace(/&nbsp;/g, ' ');  
}

const NewsDetail = () => {
  // const location = useLocation();
  // const state = location.state;
  const { blogCateSlug, blogSlug } = useParams();

  const [isLoading, setIsLoading] =useState(true);
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    axios.get(`api/Blog/slug/${blogSlug}`)
      .then(response => {
        setApiData(response.data);
        sessionStorage.setItem("categoryId", response.data.blogCateSlug);
      })
      .catch(error => {
        console.error('Error fetching data from API:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [blogSlug]);



  const sanitizedHTML = DOMPurify.sanitize(apiData ? apiData.blog_Content : '');
  
  const formattedDate = apiData ? formatDate(apiData.blog_PublishTime) : 'Loading...';

if(!apiData || isLoading){
  return <Loading/>;
}
  return (
    <React.Fragment>
      <div className="container-md news-hero px-0 px-md-auto">
        <img src={apiData.blog_MainImg} alt="" />
      </div>
      <div className="container news-detail pt-5 px-2">
        <h4>{apiData.blog_Title}</h4>
        <p>{formattedDate} (文章時間)</p>
        <div dangerouslySetInnerHTML={{ __html:  filterContent(sanitizedHTML) }} />
      </div>
    </React.Fragment>
  );
};

export default NewsDetail;
