//ProductDetails
import React, { useState, useEffect, useMemo } from "react";

import { useLocation, useParams } from "react-router-dom";
import axios from "axios"; // Import axios
import ProductTitle from "./ProductTitle";
import ProductFirstSelect from "./ProductFirstSelect";
import ProductSecondSelect from "./ProductSecondSelect";
import ProductPrice from "./ProductPrice";
import ProductDescript from "./ProductDescript";
import ImageSlider from "../../sharedComponents/ImageSlider";
import Loading from "../Loading";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedOption,
  setSelectedOptionSecond,
  setSelectedOptionForPrice,
  setSelectedOptionName,
} from "../../redux/actions/action";
function ProductDetails() {
  console.log("重新刷新ProductDetails");
  const { productSlug } = useParams();
  const [isloading, setIsLoading] = useState(true);
  const [productData, setProductData] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const secondSelectComponent = useMemo(() => {
    if (productData?.specName && productData.specName[1]) {
      return (
        <ProductSecondSelect specname={productData.specName[1].specname} />
      );
    }
    return null;
  }, [productData]);

  // meta資料
  const metaDescript = document.querySelector('meta[name="description"]');
  const ogDescriptionElement = document.querySelector(
    'meta[property="og:description"]'
  );

  //初次載入回到最上層，同時清空預設值
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setSelectedOptionForPrice(null));
    dispatch(setSelectedOptionSecond(null));
    dispatch(setSelectedOption(null));
    dispatch(setSelectedOptionName(null));
  }, []);

  //頁面轉換

  useEffect(() => {
    setIsLoading(true);
    console.log("productSlug change");
  }, [productSlug]);

  useEffect(() => {
    // Send a GET request to the backend API with the productSlug
    axios
      .get(`/api/Product/ProductDetail/${productSlug}`)
      .then((response) => {
        // Handle the response data here
        console.log("Product Details:", response.data);
        setProductData(response.data);
        console.log("productData", "productData"); //非同步請求
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching product details:", "error");
      });
  }, [productSlug]);

  useEffect(() => {
    if (productData) {
      const productDataId = productData.prodData.id;
      const viewDataKey = "viewdata";
      const existingViewData = sessionStorage.getItem(viewDataKey);

      if (!existingViewData || !existingViewData.includes(productDataId)) {
        // 如果不存在或不包含 productDataId，将其添加到 viewdata 中
        const newViewData = existingViewData
          ? `${existingViewData},${productDataId}`
          : productDataId;
        sessionStorage.setItem(viewDataKey, newViewData);
        console.log("沒有設定過productDataId發送請求", "productDataId");

        // 发送 POST 请求到 api/Product/ProductViewLog
        const requestData = {
          prodid: productDataId,
        };
        axios
          .post("api/Product/ProductViewLog", requestData, {
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            if (response.status === 200) {
              console.log("發送請求成功ProductViewLog", "response");
            } else {
              console.log("Request failed:", "response");
            }
          })
          .catch((error) => {
            console.error("Error:", "error");
            console.log("requestData失敗", "requestData");
          });
      } else {
        console.log("已經有設定過productDataId不發送請求", productDataId);
      }
    }
  }, [productData]);

  // 更改metadata
  useEffect(() => {
    if (productData) {
      const metaDescript = document.querySelector('meta[name="description"]');
      const ogDescriptionElement = document.querySelector('meta[property="og:description"]');
  
      if (metaDescript) {
        metaDescript.setAttribute("content", productData.prodData.metadesc);
      } else {
        console.error('Meta description element not found');
      }
  
      if (ogDescriptionElement) {
        ogDescriptionElement.setAttribute("content", productData.prodData.metadesc);
      } else {
        console.error('OG description element not found');
      }
  
      console.log(productData);
    }
  }, [productData]);

  if (!productSlug || !productData || isloading) {
    return (
      <div className="first-section">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="container px-0">
        <div className="product-detail">
          {productData && productData.prodImg && (
            // <ProductImageSlider
            //   img={productData.prodImg}
            //   productDataId={productData.prodData.id}
            // />
            <ImageSlider img={productData.prodImg} />
          )}
          <div className="container">
            {/* Always render ProductFirstSelect */}
            {productData?.specName && (
              <>
                <ProductTitle title={productData.prodData.prodname} />
                <ProductFirstSelect
                  specname={productData.specName[0].specname}
                  option={productData.specOption}
                  tosetprice={productData.specName[1] ? false : true}
                />
              </>
            )}

            {/* Conditional rendering based on the existence of ProductSecondSelect */}
            {secondSelectComponent}

            {productData && (
              <ProductPrice
                title={productSlug}
                productDataId={productData.prodData.id}
                img={productData.prodImg[0]}
              />
            )}
          </div>
        </div>
        {productData && <ProductDescript productData={productData.prodData} />}
      </div>
    </>
  );
}

export default ProductDetails;
