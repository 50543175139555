//login.js
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import Popup from "../sharedComponents/Popup";
import axios from "axios";
import LoginLocalSection from "../sharedComponents/LoginLocalSection";
import LoginForeignSection from "../sharedComponents/LoginForeignSection";
import Loading from "./Loading";
function Login() {
  const [loginImg, setLoginImg] = useState("")
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const location = useLocation();
  console.log("location","location");

console.log("login refresh")
  //圖片
  useEffect(() => {
    axios
      .get("/api/Home/StoreData")
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return null;
        }
      })
      .then((data) => {
        if (data) {
          setLoginImg(data.loginImage);
          console.log("contactImg", "data");
        }
      })
      .catch((error) => {
        console.error("Error fetching data from API:", "error");
      });
  }, [location]);


 // 根据路由路径决定按钮和登录区域
  let loginButton = null;
  let loginSection = null;

  if (location.pathname === "/foreignlogin") {
    loginButton = (
      <>
        <Link to="/login" className="btn news-cate-btn">
          國內會員登入
        </Link>
        <div className="btn news-cate-btn selected">國外會員登入</div>
      </>
    );
    loginSection = <LoginForeignSection />;
  } else if (location.pathname === "/login") {
    loginButton = (
      <>
        <div className="btn news-cate-btn selected">國內會員登入</div>
        <Link to="/foreignlogin" className="btn news-cate-btn ">
          國外會員登入
        </Link>
      </>
    );
    loginSection = <LoginLocalSection />;
  }
if(!loginImg){
return<Loading/>
}
  return (
    <>
    {/* {isPopupOpen && (
      <Popup
        onNevigate={() => {
          setIsPopupOpen(false);

        }}
        onClose={() => {
          setIsPopupOpen(false);
        }}
        icon={'notice'}
        title={'帳號或密碼錯誤'}
        orangebtn={'重新修改'}
        greenbtn={''}
      />
    )} */}
      <div className="container px-0">
        <div className="login-detail">
          <div className="container login-img-container ">
            <div className="d-xxl-sticky pb-3">
              <div className="login-img">
                <img src={loginImg} className="w-100" alt="" />
              </div>
            </div>
          </div>
          <div className="container login-content">
            <div className="container px-0">
              <div className="news-cate pt-0">{loginButton}</div>
            </div>
            {loginSection}
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
