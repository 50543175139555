//Logistics.js
import React from 'react';
import axios from 'axios';

function MyForm() {
    const createHiddenInput = (form, name, value) => {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = name;
        hiddenField.value = value;
        form.appendChild(hiddenField);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const form = document.createElement('form');
        form.action = 'https://logistics.ecpay.com.tw/Express/map';
        form.method = 'POST';
        form.style.display = 'none';
        createHiddenInput(form, "MerchantID", "3395689");
        createHiddenInput(form, "LogisticsType", "CVS");
        createHiddenInput(form, "LogisticsSubType", "UNIMARTC2C");
        createHiddenInput(form, "IsCollection", "N");
        createHiddenInput(form, "ServerReplyURL", `${window.location.protocol}//${window.location.host}/api/Order/Logistics`);

        document.body.appendChild(form);
        form.submit();
    };

    const getData = (e) => {
        axios.get(`${window.location.protocol}//${window.location.host}/api/Order/CVSInfo`)
            .then(response => {
                console.log("response.data");
            })
            .catch(error => {
                console.error('Error:', "error");
            });
    }

    return (
        <div>
            <button onClick={handleSubmit}>提交表单</button>
            <button onClick={getData}>点击我获取数据</button>
        </div>
    );
}

export default MyForm;
