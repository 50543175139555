import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../Context/AuthContext";
import Loading from "../components/Loading";
import Popup from "./Popup";
import Nodata from "./Nodata";

function ResetOldMemberPwd({}) {
  const [islogin, setIslogin] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  //舊會員
  const [account, setAccount] = useState("");
  const [accountOnBlur, setAccountBlur] = useState(false);
  const [accountNotFound, setAccountNotFound] = useState(false);
  const [accountError, setAccountError] = useState(null);

  //firstCaptcha
  const [firstCaptcha, setFirstCaptcha] = useState("");
  const [firstCaptchaOnblur, setFirstCaptchaBlur] = useState(false);
  const [firstCaptchaData, setFirstCaptchaData] = useState(null);
  const [isSameFirstCaptcha, setIsSameFirstCaptcha] = useState(true);
  const [verifyfirstCaptcha, setVerifyFirstCaptcha] = useState(false);

  //寄送SendVerificationCode出現的錯誤訊息
  const [captchaError, setCaptchaError] = useState(null);

  // 下一步的disable
  const [isNextDisabled, setNextDisabled] = useState(false);
  //驗證
  const [verifysent, setVerifysent] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [isRegisterPopupOpen, setIsRegisterPopupOpen] = useState(false);

  //註冊的錯誤訊息
  const [successMsg, setSuccessMsg] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  // 連點的popup
  const [isPopupOpen, setIsPopup] =useState(false)

  const { myAuth } = useContext(AuthContext);

  //檢查格式是否合法
  const isValidAccount = (account) => {
    // 驗證手機號碼或電子郵件地址的正規表達式
    // const phoneRegex = /^09\d{8}$/; // 手機號碼格式
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/; // 電子郵件地址格式

    // 檢查是否符合手機號碼或電子郵件地址的格式
    // return phoneRegex.test(account) || emailRegex.test(account);
    return emailRegex.test(account);
  };

  //監聽input value change
  const handleAccountChange = (e) => {
    setAccount(e.target.value);
    setAccountNotFound(false);
    // setPhoneError(null);
  };
  const handleFirstCaptchaChange = (e) => {
    setFirstCaptcha(e.target.value);
    setVerifyFirstCaptcha(false);
    setIsSameFirstCaptcha(true);
    console.log("firstcaptchaonblur", "firstCaptchaOnblur");
    console.log("issamecaptcha", "isSameFirstCaptcha");
  };

  //確認取得的captcha
  const getCaptcha = () => {
    axios
      .get(`/api/User/Captcha`, {
        headers: {},
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("Captcha Data:", "response.data");
          setFirstCaptchaData(response.data);
        } else {
          console.log("Captcha failed:", "response");
          // Token is invalid, log out the user
        }
      })
      .catch((error) => {
        console.error("Error:", "error");
      })
      .finally(() => {
        // setIsLoading(false); // Mark loading as finished regardless of success or error
      });
  };

  //發送步驟一
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isNextDisabled) {
      console.log("連點禁止");
      return;
    }
    if (verifysent) {
      console.log("未到60秒");
      setIsPopup(true);
      return;
    }

    // if (accountError) {
    //   console.log("accountError", accountError);
    //   return;
    // }
    setAccountBlur(true);
    setFirstCaptchaBlur(true);
    if (!isValidAccount(account) || accountNotFound) {
      // getCaptcha();
      return;
    }
    //驗證碼是否一樣?
    if (firstCaptcha !== firstCaptchaData.code.toString()) {
      console.log("captcha", "firstCaptcha");
      console.log("captchaData", "firstCaptchaData");
      setIsSameFirstCaptcha(false);

      return;
    }
    const requestData = {
      Account: account.toString(),
    };
    axios
      .post("/api/User/oldMemberForgotpwd", requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const responseData = response.data;
        console.log("回傳的oldMemberForgotpwd", "responseData.message");
        setVerifysent(true);
        setSuccessMsg(responseData.message);
        setIsRegisterPopupOpen(true);
        console.log("傳過去的資料oldMemberForgotpwd", "requestData");
      })
      .catch((error) => {
        setVerifyFirstCaptcha(true);
        setAccountNotFound(true);
        setFirstCaptcha("");
        getCaptcha();

        setErrorMsg(error.response.data.message);
        console.error("oldMemberForgotpwd有錯誤：", "error");
        console.log("傳過去的oldMemberForgotpwd有錯誤", "requestData");
        console.error(
          "寄送oldMemberForgotpwd出現的錯誤訊息",
          "error.response.data.message"
        );
      })
      .finally(() => {
        setNextDisabled(false);
      });
  };
  //阻擋重複發送請求
  useEffect(() => {
    if (verifysent) {
      const countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown > 1) {
            return prevCountdown - 1;
          } else {
            clearInterval(countdownInterval);
            setVerifysent(false); // 倒數計時結束
            return 60; // 重置為60
          }
        });
      }, 1000);

      return () => {
        clearInterval(countdownInterval);
      };
    } else {
      setCountdown(60);
    }
  }, [verifysent]);

  //初始載入
  useEffect(() => {
    getCaptcha();
  }, []);
  useEffect(() => {
    if (myAuth.authorized) {
      setIsLoading(false);
      setIslogin(true);
    } else {
      setIslogin(false);
    }
  }, [myAuth.authorized]);
  //重複的表單

  const renderInputField = (
    id,
    type,
    name,
    classname,
    placeholder,
    value,
    onChange,
    onBlur = null,
    onClick = null,
    minLength,
    maxLength,
    readonly
  ) => (
    <input
      type={type}
      id={id}
      name={name}
      className={classname}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      onClick={onClick}
      minLength={minLength}
      maxLength={maxLength}
      readOnly={readonly}
    />
  );
  const errorInput = (hasData, isValid, id, errormessage, show, notshow) => (
    <div
      className={`error-message ${
        hasData && !isValid ? show || "visible" : notshow || "invisible"
      } d-flex pb-0`}
      id={id}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="19"
        viewBox="0 0 21 19"
        fill="none"
        className="pt-1"
      >
        <path
          d="M0.98903 18.5C0.805697 18.5 0.63903 18.454 0.48903 18.362C0.33903 18.27 0.222364 18.1493 0.139031 18C0.0556972 17.85 0.00969715 17.6873 0.00103049 17.512C-0.00763618 17.3367 0.0383638 17.166 0.139031 17L9.38903 1C9.48903 0.833333 9.61836 0.708333 9.77703 0.625C9.9357 0.541667 10.098 0.5 10.264 0.5C10.4307 0.5 10.5934 0.541667 10.752 0.625C10.9107 0.708333 11.0397 0.833333 11.139 1L20.389 17C20.489 17.1667 20.535 17.3377 20.527 17.513C20.519 17.6883 20.473 17.8507 20.389 18C20.3057 18.15 20.189 18.271 20.039 18.363C19.889 18.455 19.7224 18.5007 19.539 18.5H0.98903ZM2.71403 16.5H17.814L10.264 3.5L2.71403 16.5ZM10.264 15.5C10.5474 15.5 10.785 15.404 10.977 15.212C11.169 15.02 11.2647 14.7827 11.264 14.5C11.264 14.2167 11.168 13.979 10.976 13.787C10.784 13.595 10.5467 13.4993 10.264 13.5C9.9807 13.5 9.74303 13.596 9.55103 13.788C9.35903 13.98 9.26336 14.2173 9.26403 14.5C9.26403 14.7833 9.36003 15.021 9.55203 15.213C9.74403 15.405 9.98136 15.5007 10.264 15.5ZM10.264 12.5C10.5474 12.5 10.785 12.404 10.977 12.212C11.169 12.02 11.2647 11.7827 11.264 11.5V8.5C11.264 8.21667 11.168 7.979 10.976 7.787C10.784 7.595 10.5467 7.49933 10.264 7.5C9.9807 7.5 9.74303 7.596 9.55103 7.788C9.35903 7.98 9.26336 8.21733 9.26403 8.5V11.5C9.26403 11.7833 9.36003 12.021 9.55203 12.213C9.74403 12.405 9.98136 12.5007 10.264 12.5Z"
          fill="#DC3545"
        />
      </svg>
      <p className="ps-1 danger">{errormessage}</p>
    </div>
  );

  if (!firstCaptchaData || !firstCaptchaData.imageData || islogin === null) {
    return <Loading />;
  }
  // if (!captchaData || !captchaData.imageData) {
  //   <Loading />;
  // }
  return (
    <div className="container forgotpwd">
      {isRegisterPopupOpen && (
        <Popup
          onNevigate={() => {
            setIsRegisterPopupOpen(false);
          }}
          onClose={() => {
            setIsRegisterPopupOpen(false);
          }}
          icon={"check"}
          title={`${successMsg}`}
          orangebtn={"好的"}
          greenbtn={""}
        />
      )}
      {isPopupOpen && (
        <Popup
          onNevigate={() => {
           setIsPopup(false)
          }}
          onClose={() => {
            setIsPopup(false)
          }}
          icon={"notice"}
          title={`${countdown}後才可再次發送`}
          orangebtn={"好的"}
          greenbtn={""}
        />
      )}


      <div className=" row px-xxl-0 d-flex justify-content-center">
        <div className="col col-lg-6 col-xxl-5 section-card p-4 d-flex flex-column gap-4">
          <div className="section-title text-center black border-bottom-light pb-2">
            <h5>找回密碼（舊會員）</h5>
          </div>
          <div className="reset-pwd">
            <form
              className="d-flex flex-column gap-4"
              onSubmit={handleFormSubmit}
            >
              <div>
                <label htmlFor="account" className="form-label d-block">
                  <h6 className="primary-deep2">帳號(輸入電子郵件)</h6>
                </label>
                {renderInputField(
                  "account",
                  "text",
                  "text",
                  "form-input",
                  "輸入電子郵件",
                  account,
                  handleAccountChange,
                  () => {
                    setAccountBlur(true);
                  },
                  () => {
                    setAccountBlur(false);
                  },
                  3,
                  254,
                  false
                )}
                {errorInput(
                  account || accountOnBlur,
                  isValidAccount(account),
                  "error-account",
                  "請輸入正確的手機號碼或電子郵件格式",
                  " d-block",
                  "d-none"
                )}
                {errorInput(
                  isValidAccount(account) && accountError,
                  !accountOnBlur,
                  "error-account-avaliable",
                  accountError,
                  "d-block",
                  "d-none"
                )}
                {errorInput(
                  account || accountOnBlur,
                  !accountNotFound,
                  "error-account",
                  errorMsg,
                  " d-block",
                  "d-none"
                )}
              </div>

              {
                <>
                  <div className={` ${islogin ? "d-block" : ""}`}>
                    <div className="capcha-box">
                      <div className="capcha-foriput">
                        <label
                          htmlFor="local-firstCaptcha"
                          className="form-label d-block"
                        >
                          <h6 className="primary-deep2">圖形驗證碼</h6>
                        </label>
                        {renderInputField(
                          "local-firstCaptcha",
                          "text",
                          "firstCaptcha",
                          "form-input",
                          "輸入圖形驗證碼",
                          firstCaptcha,
                          handleFirstCaptchaChange,
                          () => {
                            setFirstCaptchaBlur(true);
                          },
                          () => {
                            setFirstCaptchaBlur(false);
                          }
                        )}
                      </div>
                      {firstCaptchaData.imageData && (
                        <div className="capcha-photo pt-2">
                          <img
                            src={firstCaptchaData.imageData}
                            alt="圖形驗證碼"
                          />
                        </div>
                      )}
                    </div>
                    {errorInput(
                      firstCaptcha || firstCaptchaOnblur,
                      isSameFirstCaptcha,
                      "error-capcha",
                      "請輸入正確的圖形驗證碼",
                      " d-block",
                      "d-none"
                    )}
                  </div>
                </>
              }

              <div>
                <button
                  className={`${"d-block"} btn primary-btn2 ${
                    islogin ? "form-input" : "w-100"
                  }`}
                  type="submit"
                >
                  {verifysent ? `再次發送(${countdown})` : "取回密碼"}
                </button>
                <div
                  className={`pt-0 ${
                    !isValidAccount(account) ||
                    !firstCaptcha ||
                    !isSameFirstCaptcha
                      ? "d-block w-100"
                      : "d-none"
                  }`}
                >
                  <Nodata
                    icon={"dangernotice"}
                    dangerclass={"ps-1"}
                    dangerdescript={`尚未填寫正確的
                                      ${
                                        isValidAccount(account)
                                          ? ""
                                          : !isValidAccount(account) &&
                                            firstCaptcha &&
                                            isSameFirstCaptcha
                                          ? "帳號"
                                          : "帳號、"
                                      }
                                      ${
                                        firstCaptcha && isSameFirstCaptcha
                                          ? ""
                                          : "圖形驗證碼"
                                      }`}
                    bgclass={"bg-white"}
                  />
                </div>
                {errorInput(
                  account || accountOnBlur,
                  !accountNotFound,
                  "error-account",
                  errorMsg,
                  " d-block",
                  "d-none"
                )}
                <div className="pt-1 secondary-deep">
                  <p>
                    *如果您忘記密碼、忘記註冊時使用的E-mai位址，或註冊時使用的E-mail位址已經不再使用，我們建議您創建一個新帳戶。
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetOldMemberPwd;
