import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import AuthContext from "../Context/AuthContext";
import PopupGreen from "./PopupGreen";
import Loading from "../components/Loading";
function BankaccountBtn() {

  const [bankPopup, setBankPopup] = useState(false);
  const [isLoading, setIsLoading] =useState(false);
  const [bankData, setBankData] =useState(null);
  const handleBankPopup = () => {
    setBankPopup(true);
    // console.log("按下");
  };
  const { myAuth, logout } = useContext(AuthContext);
  const getBankData = ()=>{
    setIsLoading(true)
    const storedToken = myAuth.token;
    const requestData = { mid: myAuth.sid };
    axios
    .post(`/api/Member/GetBankAccount`, requestData, {
      headers: {
        Authorization: `Bearer ${storedToken}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        console.log("response Data:", "response.data");
        setBankData(response.data);
      } else {
        console.log("Request failed:", "response");
        // Token is invalid, log out the user
      }
    })
    .catch((error) => {
      console.error("Error:", "error");
    //   if (error.response && error.response.status === 401) {
    //     // 如果是 401，觸發 logout()
    //     logout();
    //   }
    })
    .finally(() => {
      setIsLoading(false); // Mark loading as finished regardless of success or error
    });
  }
  useEffect(()=>{
    

    getBankData()
  },[])

  if(isLoading){
return <Loading height={"d-inline-block h-auto"} width={"w-auto"}/>
}

  return (
    <>  {bankPopup && (
        <PopupGreen
          onNevigate={() => {
            setBankPopup(false);
          }}
          onClose={() => {
            setBankPopup(false);
          }}
          icon={""}
          title={"銀行轉帳帳號資訊"}
          orangebtn={""}
          greenbtn={"關閉"}
          htmlMsg={bankData.bankAccount}
        />
      )}

      <div
        className="btn primary-btn4 px-1 py-1"
        onClick={() => handleBankPopup()}
      >
        查詢銀行轉帳帳號
      </div>
    </>
  );
}

export default BankaccountBtn;
