//CheckoutStep2.js
import { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
// import { CheckoutProvider, useCheckout } from "../../Context/CheckoutContext";
import Checkoutshipping from "../../sharedComponents/Checkoutshipping";
import Nodata from "../../sharedComponents/Nodata";
import AuthContext from "../../Context/AuthContext";
import CouponSelect from "./CouponSelect";
import InvoiceSelect from "../../sharedComponents/InvoiceSelect";
import NamePhoneLabel from "../../sharedComponents/NamePhoneLabel";
import CheckoutShop from "./CheckoutShop";
import Loading from "../Loading";
import ShareInput from "../../sharedComponents/ShareInput";

function CheckoutStep2({ handleNextStep, storePickUp, saveOrderItems }) {
  const { myAuth, logout } = useContext(AuthContext); //登入狀態

  const [isLoading, setIsLoading] = useState(true);
  const [selectedshipOption, setSelectedShipOption] = useState(null);
  const [paymentOption, setPaymentOption] = useState(null);
  // 登入的購物車資料
  const [cartTotalData, setCartTotalData] = useState(null);
  // 未登入的購物車資料
  const localStorageCartData = localStorage.getItem("cart");
  const [hasProcessing, setHasProcessing] = useState(null);
  //資料庫撈出的初始資訊
  const [rawData, setRawData] = useState([]);

  //資料庫撈出的超商資訊
  const [cvsData, setCvsData] = useState(null);

  //轉換後的資料格式
  const [addressData, setAddressData] = useState([]);
  //本島和離島的地址邏輯
  const [mainRawData, setMainRawData] = useState([]);
  const [outerRawData, setOuterRawData] = useState([]);

  // 下一步的disable
  const [isNextDisabled, setNextDisabled] = useState(false);

  const outertwzip = [
    "209",
    "210",
    "211",
    "212",
    "290",
    "817",
    "819",
    "880",
    "881",
    "882",
    "883",
    "884",
    "885",
    "890",
    "891",
    "892",
    "893",
    "894",
    "896",
    "929",
    "951",
    "952",
  ];

  //使用者地址資料的編輯
  const [selectedOption, setSelectedOption] = useState("");

  const [editingAddressIndex, setEditingAddressIndex] = useState(-1); // 編輯狀態

  //發票類型設定
  const [invoiceType, setInvoiceType] = useState("");

  //運費相關
  const [shipCost, setShipCost] = useState("");
  const [shipName, setShipName] = useState("");

  //運送選項
  const [shippingOptions, setShippingOptions] = useState(null);
  const [selectedShipArea, setSelectedShipArea] = useState(null);
  const [selectedAreaType, setSelectedAreaType] = useState(null);
  //收費選項
  const [newcashondelivery, setNewcashondelivery] = useState(null);
  const [cashOnDelivery, setCashOnDelivery] = useState(null);
  const [cashOnDeliveryShow, setCashOnDeliveryShow] = useState(null);
  const [feeRuleArray, setFeeArray] = useState(null);
  const [priceRule, setPriceRule] = useState(null);
  //三聯式的資料
  const [businessNumber, setBusinessNumber] = useState("");
  const [invoiceTitle, setInvoiceTitle] = useState("");
  //備註
  const [orderMemo, setOrderMemo] = useState("");

  const handleOrderMemoChange = (e) => {
    const inputValue = e.target.value;
    // 使用正則表達式驗證輸入，只允許字母、數字、空格和常見標點符號
    // const sanitizedValue = inputValue.replace(
    //   /[^A-Za-z0-9\u4e00-\u9fa5\u3105-\u3129\-_]/g,
    //   ""
    // );

    setOrderMemo(inputValue);
  };
  const escapeHTML = (unsafeText) => {
    return unsafeText.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  };
  //發送表單的檢查和相關欄位
  const [inputDiscount, setInputDiscount] = useState({});
  const [invoiceData, setInvoiceData] = useState({});
  const [namePhoneValid, setNamePhoneValid] = useState(false);
  const [reciptName, setReciptName] = useState("");
  const [reciptPhone, setReciptPhone] = useState("");
  const [reciptTele, setReciptTele] = useState("");
  const [reciptePostcode, setReciptePostcode] = useState("");
  const [recipteCountry, setRecipteCountry] = useState("");
  const [recipteTownship, setRecipteTownship] = useState("");
  const [recipteAddress, setRecipteAddress] = useState("");
  const [isInvoiceValid, setIsInvoiceValid] = useState(false);

  //只有在有selectedShipArea的時候才判斷
  const [addressValid, setAddressValid] = useState(true);

  // 未登入的email格式
  const [email, setEmail] = useState("");
  const [isEmailValid, setEmailValid] = useState(false);

  // 檢查錯誤
  const [checkError, setCheckError] = useState(false);
  //取得之前儲存的shopid

  const shopid = sessionStorage.getItem("shopid");
  const shopareatype = sessionStorage.getItem("areatype");

  //會員資訊
  const auth = myAuth.authorized;
  console.log("auth", "auth");
  const mid = parseInt(myAuth.sid);
  const storedToken = myAuth.token;
  const memberAddress = myAuth.recipientData || [];
  console.log("memberAddress");
  //付款方式的預設值
  const paymentoptions = [
    { label: "信用卡付款", value: "1" },
    {
      label: "銀行轉帳付款",
      danger: "匯費勿內扣，如扣匯費就無法出貨",
      value: "2",
    },
  ];
  //未登入付款方式的預設值
  const unauthpaymentoptions = [{ label: "信用卡付款", value: "1" }];
  console.log("storedata", "storePickUp");

  //由第三步點擊上一步必須填入儲存在localStorage的資料
  // const prevData = null;
  const prevData = JSON.parse(localStorage.getItem("stepTwoStoreData"));

  const getshoppingCart = () => {
    //console.log("登入取得購物車", "myAuth.sid");
    const requestData = { mid: mid };
    axios
      .post("/api/Order/GetShipping", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("回傳的member order", "response.data");
        console.log("傳過去的資料", "requestData");
        // setOrderdata(response.data);
        setShippingOptions(response.data.shippinglist);
        setCartTotalData(response.data.total);
        // setIsLoading(false);
        // console.log("回傳的member shipArea", response.data.shippinglist[0].shipArea);

        // setOrderdata([]);//沒有資料
      })
      .catch((error) => {
        console.error("member order有錯誤：", error);
        console.log("傳過去的資料有錯誤", "requestData");
        console.log("傳過去的資料有錯誤storedToken", "storedToken");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      });
  };

  const getProcessingData = () => {
    const requestData = { mid: mid };
    axios
      .post("/api/Order/CkProcessing", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("回傳的CkProcessing", "response.data");
        console.log("CkProcessing傳過去的資料", "requestData");
        setHasProcessing(false);
      })
      .catch((error) => {
        // console.error("CkProcessing有錯誤：", error);
        console.log("傳過去的CkProcessing資料有錯誤", "requestData");

        if (error.response && error.response.status === 400) {
          console.log("觸發400");
          setHasProcessing(true);
          // 有加購商品
        }
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      });
  };

  const getnotauthCart = () => {
    console.log("getnotauthCart");
    if (localStorageCartData) {
      try {
        const cartItems = JSON.parse(localStorageCartData);
        const requestData = {
          memberid: 0,
          orderItems: cartItems, // 将解析后的数据放入数组
        };
        axios
          .post(`/api/Order/GetShippingNonM`, requestData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              const localCart = response.data;
              setShippingOptions(response.data.shippinglist);
              console.log("未登入的unauthcart:", "localCart");
              console.log("未登入的unauthcartItems:", "cartItems");

              setCartTotalData(localCart);
            } else {
              console.log("Request failed:", "response");
              // Token is invalid, log out the user
              // console.log("未登入的unauthcartItems:", cartItems);
              // console.log("未登入的requestData:", requestData);
            }
          })
          .catch((error) => {
            console.error("Error:", "error");
            console.error("requestData:", "requestData");
          })
          .finally(() => {
            setIsLoading(false); // Mark loading as finished regardless of success or error
          });
      } catch (error) {
        console.error("Error parsing localStorageCartData:", "error");
        // 不加401
      }

      setIsLoading(false);
    }
  };
  const getnotauthProcessing = () => {
    console.log("getnotauthProcessing");
  };
  //取得歷史收件資料
  const setMemberHistoryReceived = () => {
    console.log("memberAddress", "memberAddress");
    if (auth) {
      if (memberAddress.length !== 0) {
        // setEditingAddressIndex(-1);
        // setSelectedOption("");
        const outerData = [];
        const mainData = [];

        memberAddress.forEach((address) => {
          const { addr } = address;
          const zipCode = addr.split("///")[0];

          const dataItem = {
            name: address.name,
            phone: address.mobile,
            telephone: address.tel,
            preset: address.preset,
            address: addr,
          };

          if (outertwzip.includes(zipCode)) {
            outerData.push(dataItem);
          } else {
            mainData.push(dataItem);
          }
        });

        console.log("setOuterRawData", "outerData");
        console.log("setMainRawData", "mainData");

        setOuterRawData(outerData);
        setMainRawData(mainData);
      }
    } else if (auth) {
      console.log("auth", "auth");
    }
  };
  //設定儲存在local的收件資料
  const setLocalData = () => {
    if (prevData) {
      console.log("**設定prevData");
      setSelectedShipOption(prevData.shippingId);
      setSelectedShipArea(prevData.shipArea);
      setSelectedAreaType(prevData.shipAreaType.toString());

      // setPaymentOption(prevData.paymentId);

      setReciptName(prevData.recipientName);
      setReciptPhone(prevData.recipientMobile);
      setReciptTele(prevData.recipientTel);
      setReciptePostcode(prevData.postcode);
      setRecipteCountry(prevData.country);
      setRecipteTownship(prevData.township);
      setRecipteAddress(prevData.address);
      setCvsData(prevData.recipientShop);
      setOrderMemo(prevData.orderRemark);
      setEmail(prevData.recipinetEmailForNonM);

      console.log("prevData.country", "prevData.country");
      // console.log("orderRemark",prevData.orderRemark)
      // console.log("prevData.recipientShop",prevData.recipientShop)
      // console.log("prevData.recipientShop",prevData.recipientShop)
      // setNewcashondelivery(prevData.cashondelivery)
    }
  };

  //設定超商的資料
  const setConvenienceStore = () => {
    console.log("設定超商的資料", "shopid");
    console.log("設定超商的type", "shopareatype");
    const shopidAsNumber = parseInt(shopid, 10);
    const shoptypeAsNumber = parseInt(shopareatype, 10);
    if (shopid && shopareatype) {
      setSelectedShipOption(shopidAsNumber);
      setSelectedAreaType(shoptypeAsNumber);
      axios
        .get(`/api/Order/CVSInfo`, {
          headers: {},
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("CVSInfo:", "response.data");
            if (response.data.cvsInfo) {
              setCvsData(response.data);
            } else {
              setCvsData(null);
            }
          } else {
            console.log("CVSInfo failed:", "response");
            // Token is invalid, log out the user
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          if (error.response && error.response.status === 401) {
            // 如果是 401，觸發 logout()
            logout();
          }
        })
        .finally(() => {
          // setIsLoading(false); // Mark loading as finished regardless of success or error
        });
    }
  };
  //取得歷史收件資料的預設值
  const presetTrueItem = memberAddress.find(
    (address) => address.preset === true
  );
  //設定歷史收件資料的預設值
  const setPrestData = () => {
    console.log("shippingOptions是運送方式的選項", "shippingOptions");
    if (presetTrueItem && shippingOptions) {
      let selectedType = null;
      let shipoption = 0;
      let shipName = "";
      let shiparea = null;
      let shippingid = null;

      if (mainRawData && mainRawData.some((item) => item.preset === true)) {
        console.log("預設值在sampleRawData");
        shipoption = 1;
        selectedType = "2";
        shipName = "宅配(本島)";
        shiparea = shippingOptions[0].shipArea;
        shippingid = 1;
      } else if (
        outerRawData &&
        outerRawData.some((item) => item.preset === true)
      ) {
        console.log("預設值在outerRawData");
        shipoption = 2;
        selectedType = "3";
        shipName = "宅配(外島)";
        shiparea = shippingOptions[1].shipArea;
        shippingid = 2;
      }

      let presetItems = mainRawData;

      if (shipoption === 2) {
        presetItems = outerRawData;
      }

      const selectedpresetDataShippingId = shippingOptions.find(
        (option) => option.id === shippingid
      );

      let newCashOnDelivery = null;
      if (selectedpresetDataShippingId) {
        console.log(
          "Selected Cash on Delivery:",
          "selectedpresetDataShippingId.cashondelivery"
        );
        newCashOnDelivery = JSON.parse(
          selectedpresetDataShippingId.cashondelivery
        );
      } else {
        console.log(
          "Cash on Delivery option not found for the selected shippingId."
        );
      }
      setNewcashondelivery(newCashOnDelivery);
      setSelectedShipOption(shipoption);
      setSelectedShipArea(shiparea);
      setSelectedAreaType(selectedType);
      setShipName(shipName);
    } else {
      console.log("都沒有");
      setIsLoading(false);
      setReciptePostcode("");
      setRecipteCountry("");
      setRecipteTownship("");
      setRecipteAddress("");
    }
  };
  //在selectedAreaType!==null的時候，設定編輯狀態若為-1就清除資料
  const setAddressOptionAndIndex = (index) => {
    if (index === -1) {
      setSelectedOption(-1);
      setEditingAddressIndex(-1);
      console.log("切換的編輯狀態-1");
      setIsLoading(false);
    } else if (index === 0) {
      setSelectedOption(0);
      setEditingAddressIndex(0);
      console.log("切換的編輯狀態0");
    }
  };
  //清除資料
  const clearData = () => {
    setReciptName("");
    setReciptPhone("");
    setReciptTele("");
    setReciptePostcode("");
    setRecipteCountry("");
    setRecipteTownship("");
    setRecipteAddress("");
    setSelectedShipArea(null);
    setSelectedAreaType(null);
    localStorage.removeItem("stepTwoStoreData");
    console.log("清除");
  };

  //進入地址編輯模式
  const handleEditClick = (index) => {
    setEditingAddressIndex(index);
    console.log("編輯", "index");
  };

  //根據使用者選擇的運送方式決定渲染門市或是地址
  const handleShipOptionChange = (
    option,
    areaType,
    shipArea,
    cashondelivery,
    shipCost,
    shipName
  ) => {
    setSelectedShipOption(option);
    setSelectedShipArea(shipArea);
    setSelectedAreaType(areaType);
    setShipCost(shipCost);
    setShipName(shipName);

    // console.log("按下的selectedshipOption", option);
    console.log("按下的selectedShipArea", shipArea);
    // console.log("按下的selectedAreaType", areaType);
    // console.log("按下的shipCost", shipCost);
    // console.log("按下的shipName", shipName);
    // console.log("newcashondelivery", newcashondelivery);
    // 轉譯字串
    const newcashondelivery = JSON.parse(cashondelivery);
    setNewcashondelivery(newcashondelivery);
  };

  //下一步
  const handleNextButtonClick = () => {
    console.log("click");
    if (!auth) {
      if (
        !namePhoneValid ||
        !paymentOption ||
        paymentOption === null ||
        !isInvoiceValid ||
        !addressValid ||
        !isEmailValid
      ) {
        console.log("不合法格式");
        setCheckError(true);
        return;
      } else {
        setCheckError(false);
      }
    } else if (auth) {
      if (
        !namePhoneValid ||
        !paymentOption ||
        paymentOption === null ||
        !isInvoiceValid ||
        !addressValid
      ) {
        console.log("不合法格式");
        setCheckError(true);
        return;
      } else {
        setCheckError(false);
      }
    }

    if (isNextDisabled) {
      console.log("連點禁止");
      return;
    }
    if (auth) {
      setNextDisabled(true);
    } else if (!auth) {
      if (isEmailValid && email) {
        setNextDisabled(true);
        console.log("no email data");
      } else {
        return;
      }
    } else {
      return;
    }

    setIsLoading(true);
    console.log("paymentOption", "paymentOption");
    console.log("selectedAreaType", "selectedAreaType");
    // Define the base request data
    const baseRequestData = {
      mid: mid,
      shippingId: selectedshipOption.toString(),
      paymentId: paymentOption.toString(),
      recipientName: reciptName,
      recipientMobile: reciptPhone,
      recipientTel: reciptTele,
      orderRemark: escapeHTML(orderMemo),
    };

    // Check selectedAreaType to determine recipientAddr or recipientShop
    if (selectedAreaType === "2" || selectedAreaType === "3") {
      baseRequestData.recipientAddr = `${reciptePostcode}///${recipteCountry}///${recipteTownship}///${recipteAddress}`;
      if (
        !reciptePostcode ||
        !recipteCountry ||
        !recipteTownship ||
        !recipteAddress
      ) {
        // console.log(
        //   reciptePostcode,
        //   recipteCountry,
        //   recipteTownship,
        //   recipteAddress
        // );
        // console.log(
        //   reciptePostcode,
        //   recipteCountry,
        //   recipteTownship,
        //   recipteAddress
        // );
        return;
      }
    } else if (selectedAreaType === "8") {
    } else {
      baseRequestData.recipientShop = cvsData.cvsInfo;
    }
    // 若登入會有mid欄位
    if (auth) {
      baseRequestData.mid = mid;
    }
    // 若未登入會有email欄位
    if (!auth) {
      baseRequestData.mid = 0;
      baseRequestData.recipinetEmailForNonM = email;
    }
    // Merge inputDiscount and invoiceData into requestData if they have values
    const requestData =
      Object.keys(inputDiscount).length > 0
        ? { ...baseRequestData, ...inputDiscount, ...invoiceData }
        : { ...baseRequestData, ...invoiceData };

    // Define stepTwoStoreData
    let stepTwoStoreData = {
      shippingId: selectedshipOption,
      shipArea: selectedShipArea,
      shipAreaType: selectedAreaType,
      paymentId: paymentOption.toString(),
      recipientName: reciptName,
      recipientMobile: reciptPhone,
      recipientTel: reciptTele,
      postcode: reciptePostcode,
      country: recipteCountry,
      township: recipteTownship,
      address: recipteAddress,
      // cashondelivery: cashOnDelivery,
      recipientAddr: `${reciptePostcode}///${recipteCountry}///${recipteTownship}///${recipteAddress}`,
      recipientShop: cvsData,
      orderRemark: orderMemo,
    };

    // Check selectedAreaType to determine recipientAddr or recipientShop

    // Add Discount to stepTwoStoreData if inputDiscount has a value

    // 移除discount，讓第三步來處理discount
    // if (Object.keys(inputDiscount).length > 0) {
    //   stepTwoStoreData.Discount = inputDiscount;
    // }
    // 若登入會有mid欄位
    if (auth) {
      stepTwoStoreData.mid = mid;
    }
    // 若未登入會有email欄位
    if (!auth) {
      stepTwoStoreData.mid = 0;
      stepTwoStoreData.recipinetEmailForNonM = email;
    }
    // Merge invoiceData into stepTwoStoreData
    stepTwoStoreData = {
      ...stepTwoStoreData,
      invoiceData: invoiceData,
    };
    console.log("requestData", "requestData");
    console.log("stepTwoStoreData", "stepTwoStoreData");
    if (auth) {
      console.log("登入的資料發送");
      axios
        .post("/api/Order/OrderCheckOut", requestData, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("發送步驟二資訊的回應", "response.data");
          console.log("發送步驟二資訊:", "requestData");
          localStorage.setItem(
            "stepTwoStoreData",
            JSON.stringify(stepTwoStoreData)
          );
          // clearData();
          //由步驟三再發送一次請求
          sessionStorage.removeItem("shopid");
          sessionStorage.removeItem("areatype");
          handleNextStep();
        })
        .catch((error) => {
          console.error("步驟二資訊回傳有錯誤：", "error");
          console.log("發送步驟二資訊有錯誤", "requestData");
          if (error.response && error.response.status === 401) {
            // 如果是 401，觸發 logout()
            logout();
          }
        })
        .finally(() => {
          setNextDisabled(false);
          setIsLoading(false);
        });
    } else if (!auth) {
      console.log("未登入的資料發送");
      const cartItems = JSON.parse(localStorageCartData);
      const unauthrequestData = {
        ...requestData,
        orderItems: cartItems, // 将解析后的数据放入数组
      };
      axios
        .post("/api/Order/OrderCheckOutNonM", unauthrequestData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("發送步驟二資訊的回應", "response.data");
          console.log("發送步驟二資訊:", "unauthrequestData");
          saveOrderItems(cartItems);
          // clearData();
          //由步驟三再發送一次請求
          sessionStorage.removeItem("shopid");
          sessionStorage.removeItem("areatype");
          handleNextStep();
        })
        .catch((error) => {
          console.error("步驟二資訊回傳有錯誤：", error);
          console.log("發送步驟二資訊有錯誤", "unauthrequestData");
          // 不會有401
          // if (error.response && error.response.status === 401) {
          //   // 如果是 401，觸發 logout()
          //   logout();
          // }
        })
        .finally(() => {
          setNextDisabled(false);
          setIsLoading(false);
          localStorage.setItem(
            "stepTwoStoreData",
            JSON.stringify(stepTwoStoreData)
          );
        });
    }
  };

  //初次載入取得購物車、會員歷史收件資料、加購商品
  useEffect(() => {
    window.scrollTo(0, 0);
    if (auth) {
      if (mid) {
        getshoppingCart();
        getProcessingData();
        //TODO:初次載入不拉API，載入之後才考慮要不要拉setMemberHistoryReceived();
        //TODO:memberAddress會在連接的物流API有資料的情況下不進行設定
      }
    } else if (auth === false) {
      getnotauthCart();
    }
  }, [auth, mid]);

  //如果有超商資料就不進行setLocalData
  useEffect(() => {
    setIsLoading(true);

    if (shopid && shopareatype) {
      setConvenienceStore();
      console.log("****setConvenienceStore***");
    } else {
      setMemberHistoryReceived();
      console.log("****setlocal***");
      setLocalData();
    }
  }, [shopid, shopareatype]);

  //會員地址的預設值，在沒有prevData的時候制定預設值選擇本島或外島，也沒有選擇selectedAreaType
  useEffect(() => {
    if (!prevData && selectedAreaType === null && !cvsData) {
      setPrestData();
    }
    console.log("selectedAreaType", "selectedAreaType");
  }, [shippingOptions, mainRawData, outerRawData]);
  //   useEffect(()=>{
  //     if (selectedshipOption !== null) {
  // console.log("改變的selectedshipOption",selectedshipOption)
  //     }
  //   },[selectedshipOption])
  useEffect(() => {
    //處理shopdata
    if (cvsData && !prevData && selectedshipOption !== shopid) {
      //同時也應該清除掉前一次取得的超商資料
      sessionStorage.removeItem("shopid");
      sessionStorage.removeItem("areatype");
      setCvsData(null);
    }
    //處理前一次資料
    if (selectedshipOption !== null && prevData) {
      const shippingId = selectedshipOption.toString();
      const prevId = prevData.shippingId.toString();

      if (shippingId !== prevId) {
        console.log("清除shippingId", "shippingId");
        console.log("清除prevData的shippingId", "prevId");
        setIsLoading(true);
        localStorage.removeItem("stepTwoStoreData");
        setMemberHistoryReceived();
        setCvsData(null);
      } else {
        console.log("prevData's shippingId is the same");
        console.log("***shippingOptions", "shippingOptions");
        console.log("shippingId", "shippingId");
        console.log("prevId", "prevId");
      }
    }
  }, [selectedshipOption]);

  useEffect(() => {
    //在初始狀態之後，如果selectedAreaType就不會是null

    if (selectedAreaType !== null) {
      //在設定了預設值或是prevData之後，selectedAreaType都一定不為null
      //此時將判斷是離島或是本島，若是其他資料就設定為-1
      setIsLoading(true);
      if (selectedAreaType === "2") {
        setRawData(mainRawData);
        if (!prevData && mainRawData.length > 0) {
          //沒有prevData的時候才使用預設值
          setAddressOptionAndIndex(0);
        } else {
          setAddressOptionAndIndex(-1);
        }
      }
      if (selectedAreaType === "3") {
        setRawData(outerRawData);
        if (!prevData && outerRawData.length > 0) {
          //沒有prevData的時候才使用預設值
          setAddressOptionAndIndex(0);
        } else {
          setAddressOptionAndIndex(-1);
        }
      }
      //若類別不為2而且不為3，同時還要清除自訂義的地址資訊，因為要使用的會是超商資料
      if (selectedAreaType !== "2" && selectedAreaType !== "3") {
        setAddressOptionAndIndex(-1);
        setReciptePostcode("");
        setRecipteCountry("");
        setRecipteTownship("");
        setRecipteAddress("");
      }
    }
  }, [selectedAreaType]);
  //針對地址格式驗證，當沒有selectedShipArea的時候就一律返回true
  useEffect(() => {
    if (selectedShipArea) {
      if (
        !reciptePostcode ||
        !recipteCountry ||
        !recipteTownship ||
        !recipteAddress
      ) {
        console.log("**setAddressValid");
        setAddressValid(false);
        // console.log(
        //   reciptePostcode,
        //   recipteCountry,
        //   recipteTownship,
        //   recipteAddress
        // );
      } else if (
        reciptePostcode &&
        recipteCountry &&
        recipteTownship &&
        recipteAddress
      ) {
        console.log("**setAddressValid");
        setAddressValid(true);
      } else {
        console.log("**setAddressValid");
        setAddressValid(false);
      }
    } else {
      console.log("**setAddressValid");
      setAddressValid(true);
    }
  }, [
    selectedShipArea,
    reciptePostcode,
    recipteCountry,
    recipteTownship,
    recipteAddress,
    shippingOptions,
  ]);

  //將上一步驟設定的setRawData進行格式化
  useEffect(() => {
    if (rawData) {
      const formattedData = rawData.map((item) => {
        const addressParts = item.address.split("///");
        const postcode = addressParts[0];
        const country = addressParts[1];
        const township = addressParts[2];
        const address = addressParts[3];

        return {
          name: item.name,
          phone: item.phone,
          telephone: item.telephone,
          postcode,
          country,
          township,
          address,
        };
      });

      console.log("格式化後的資料", "formattedData");
      setAddressData(formattedData);
      //AddressData會是格式化後的資料
    }
  }, [rawData]);

  useEffect(() => {
    if (newcashondelivery) {
      const enable = newcashondelivery.enable;
      if (newcashondelivery.enable) {
        const feeRuleArray = Object.entries(newcashondelivery.feeRule).map(
          ([key, value]) => ({
            key,
            value,
          })
        );
        console.log("feeRuleArray", "feeRuleArray");
        setFeeArray(feeRuleArray);
        setPriceRule(newcashondelivery.priceRule);
        console.log("feeRuleArray[0]", "feeRuleArray[0]");
      } else {
        setFeeArray(null);
        setPriceRule(null);
      }

      console.log("enable", "enable");
      setCashOnDelivery(newcashondelivery);
      setCashOnDeliveryShow(enable);
    }
  }, [newcashondelivery]);

  const handlePaymentOptionChange = (option) => {
    setPaymentOption(option);
    console.log("*付款方式", "option");
  };

  // useEffect(()=>{
  //   console.log("paymentOption",paymentOption)
  // },[paymentOption])

  useEffect(() => {
    //如果editingAddressIndex不為-1(代表有選中的下拉式選單有資料)
    if (editingAddressIndex !== -1 && addressData.length > 0) {
      // setSelectedOption(0);
      console.log("選擇的addressData", "addressData[editingAddressIndex].name");
      setReciptName(addressData[editingAddressIndex].name);
      setReciptPhone(addressData[editingAddressIndex].phone);
      setReciptTele(addressData[editingAddressIndex].telephone);
      setReciptePostcode(addressData[editingAddressIndex].postcode);
      setRecipteCountry(addressData[editingAddressIndex].country);
      setRecipteTownship(addressData[editingAddressIndex].township);
      setRecipteAddress(addressData[editingAddressIndex].address);
      setIsLoading(false);
    } else {
      if (!prevData) {
        setReciptePostcode("");
        setRecipteCountry("");
        setRecipteTownship("");
        setRecipteAddress("");
      }
    }
  }, [editingAddressIndex, addressData]);

  useEffect(() => {
    //如果有cvsData應該要重新設定cashondelivery
    if (shopid && shopareatype && shippingOptions) {
      const shopidAsNumber = parseInt(shopid, 10);
      const selectedprevDataShippingId = shippingOptions.find(
        (option) => option.id === shopidAsNumber
      );

      if (selectedprevDataShippingId) {
        console.log(
          "Selected Cash on Delivery:",
          "selectedprevDataShippingId.cashondelivery"
        );
        const newcashondelivery = JSON.parse(
          selectedprevDataShippingId.cashondelivery
        );
        setNewcashondelivery(newcashondelivery);
      } else {
        console.log(
          "Cash on Delivery option not found for the selected shippingId."
        );
      }
      console.log("shippingOptions", "shippingOptions");
      return;
    }

    //如果有prevData和shippingOptions應該要重新設定cashondelivery
    if (prevData && shippingOptions && !shopid && !shopareatype) {
      // console.log("shippingOptions", shippingOptions);
      // console.log("prevData.cashondelivery", prevData.cashondelivery);
      // console.log("prevData.shippingId", prevData.shippingId);
      // 使用 prevData.shippingId 作為索引從 shippingOptions 中獲取 cashondelivery 對象
      const selectedprevDataShippingId = shippingOptions.find(
        (option) => option.id === prevData.shippingId
      );

      if (selectedprevDataShippingId && !selectedprevDataShippingId.disable) {
        clearData();
        return;
      }

      if (selectedprevDataShippingId) {
        console.log(
          "Selected Cash on Delivery:",
          "selectedprevDataShippingId.cashondelivery"
        );
        const newcashondelivery = JSON.parse(
          selectedprevDataShippingId.cashondelivery
        );

        setNewcashondelivery(newcashondelivery);
      } else {
        console.log(
          "Cash on Delivery option not found for the selected shippingId."
        );
      }
      console.log("shippingOptions", "shippingOptions");
      //
      // handleShipOptionChange(prevData.shippingId,  prevData.shipAreaType, prevData.shipArea, )

      // handleShipOptionChange(prevData.shippingId, prevData.shipAreaType, prevData.shipArea, )
      // console.log("shippingOptions[0].cashondelivery", shippingOptions[0].cashondelivery)
    }
  }, [shippingOptions, shopareatype, shopid]);

  //查看discount
  // useEffect(() => {
  //   console.log("接收到的discount", "inputDiscount");
  // }, [inputDiscount]);
  useEffect(() => {
    if (invoiceType === "2") {
      setInvoiceData({ invoiceType: invoiceType });
    }
    if (invoiceType === "3") {
      setInvoiceData({
        invoiceType: invoiceType,
        invoiceTitle: invoiceTitle,
        invoiceNo: businessNumber,
      });
    }
  }, [invoiceType, businessNumber, invoiceTitle]);
  useEffect(() => {
    console.log("hasProcessing", "hasProcessing");
  }, [hasProcessing]);

  //重複的表單

  const renderInputField = (
    id,
    type,
    name,
    classname,
    placeholder,
    value,
    onChange,
    pattern,
    onBlur = null,
    onClick = null,
    minLength,
    maxLength
  ) => (
    <input
      type={type}
      id={id}
      name={name}
      pattern={pattern}
      className={classname}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      onClick={onClick}
      minLength={minLength}
      maxLength={maxLength}
    />
  );
  const errorInput = (hasData, isValid, id, errormessage, show, notshow) => (
    <div
      className={`error-message ${
        hasData && !isValid ? show || "visible" : notshow || "invisible"
      } d-flex pb-0`}
      id={id}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="19"
        viewBox="0 0 21 19"
        fill="none"
        className="pt-1"
      >
        <path
          d="M0.98903 18.5C0.805697 18.5 0.63903 18.454 0.48903 18.362C0.33903 18.27 0.222364 18.1493 0.139031 18C0.0556972 17.85 0.00969715 17.6873 0.00103049 17.512C-0.00763618 17.3367 0.0383638 17.166 0.139031 17L9.38903 1C9.48903 0.833333 9.61836 0.708333 9.77703 0.625C9.9357 0.541667 10.098 0.5 10.264 0.5C10.4307 0.5 10.5934 0.541667 10.752 0.625C10.9107 0.708333 11.0397 0.833333 11.139 1L20.389 17C20.489 17.1667 20.535 17.3377 20.527 17.513C20.519 17.6883 20.473 17.8507 20.389 18C20.3057 18.15 20.189 18.271 20.039 18.363C19.889 18.455 19.7224 18.5007 19.539 18.5H0.98903ZM2.71403 16.5H17.814L10.264 3.5L2.71403 16.5ZM10.264 15.5C10.5474 15.5 10.785 15.404 10.977 15.212C11.169 15.02 11.2647 14.7827 11.264 14.5C11.264 14.2167 11.168 13.979 10.976 13.787C10.784 13.595 10.5467 13.4993 10.264 13.5C9.9807 13.5 9.74303 13.596 9.55103 13.788C9.35903 13.98 9.26336 14.2173 9.26403 14.5C9.26403 14.7833 9.36003 15.021 9.55203 15.213C9.74403 15.405 9.98136 15.5007 10.264 15.5ZM10.264 12.5C10.5474 12.5 10.785 12.404 10.977 12.212C11.169 12.02 11.2647 11.7827 11.264 11.5V8.5C11.264 8.21667 11.168 7.979 10.976 7.787C10.784 7.595 10.5467 7.49933 10.264 7.5C9.9807 7.5 9.74303 7.596 9.55103 7.788C9.35903 7.98 9.26336 8.21733 9.26403 8.5V11.5C9.26403 11.7833 9.36003 12.021 9.55203 12.213C9.74403 12.405 9.98136 12.5007 10.264 12.5Z"
          fill="#DC3545"
        />
      </svg>
      <p className={`ps-1 danger`}>{errormessage}</p>
    </div>
  );
  // loading的判斷

  if (
    isLoading ||
    !shippingOptions ||
    !cartTotalData ||
    (auth && hasProcessing === null)
  ) {
    return <Loading />;
  }

  return (
    <>
      <form className="login-form checkout2" action="">
        <div className="login-detail">
          <div className="container px-0 login-content">
            <div className="content-section">
              <div className="login-title pt-3 pb-2 pt-xxl-3 pb-xxl-2">
                <h5>運送方式(必填)</h5>
              </div>
              <div className="d-flex checkbox-container">
                {shippingOptions &&
                  shippingOptions.map((option) => (
                    <div
                      key={option.id}
                      className={`d-flex checkbox-group primary-deep2 gap-10px align-items-end mb-1 ${
                        option.disable ? "cursor-pointer" : "disabled"
                      }`}
                      onClick={() =>
                        option.disable &&
                        handleShipOptionChange(
                          option.id,
                          option.areaType,
                          option.shipArea,
                          option.cashondelivery,
                          option.shipCost,
                          option.shipName
                        )
                      }
                    >
                      <div
                        className={`btn p-0 checkbox-${
                          selectedshipOption === option.id
                            ? "selected"
                            : "button"
                        }`}
                      >
                        <div className="checkbox-icon default">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                          >
                            <path
                              d="M2 18.1819C1.45 18.1819 0.979002 17.9859 0.587002 17.5939C0.195002 17.2019 -0.000664969 16.7312 1.69779e-06 16.1819V2.18189C1.69779e-06 1.63189 0.196002 1.16089 0.588002 0.768886C0.980002 0.376886 1.45067 0.18122 2 0.181886H16C16.55 0.181886 17.021 0.377887 17.413 0.769886C17.805 1.16189 18.0007 1.63255 18 2.18189V16.1819C18 16.7319 17.804 17.2029 17.412 17.5949C17.02 17.9869 16.5493 18.1826 16 18.1819H2ZM2 16.1819H16V2.18189H2V16.1819Z"
                              fill="#364F38"
                            />
                          </svg>
                        </div>
                        <div className="checkbox-icon check">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                          >
                            <path
                              d="M7.6 13.3819L14.65 6.33189L13.25 4.93189L7.6 10.5819L4.75 7.73189L3.35 9.13189L7.6 13.3819ZM2 18.1819C1.45 18.1819 0.979002 17.9859 0.587002 17.5939C0.195002 17.2019 -0.000664969 16.7312 1.69779e-06 16.1819V2.18189C1.69779e-06 1.63189 0.196002 1.16089 0.588002 0.768886C0.980002 0.376886 1.45067 0.18122 2 0.181886H16C16.55 0.181886 17.021 0.377887 17.413 0.769886C17.805 1.16189 18.0007 1.63255 18 2.18189V16.1819C18 16.7319 17.804 17.2029 17.412 17.5949C17.02 17.9869 16.5493 18.1826 16 18.1819H2ZM2 16.1819H16V2.18189H2V16.1819Z"
                              fill="#364F38"
                            />
                          </svg>
                        </div>
                      </div>
                      <h6 className="pt-0 ">{option.shipName}</h6>
                    </div>
                  ))}
              </div>
            </div>

            {/* {cartTotalData && cartTotalData.totalOrdetItemWeight > 20 && (
              <div className="pe-5">
                <Nodata descript={"超過20公斤不可選擇宅配(外島)"} />
              </div>
            )}
            {auth &&
              shippingOptions &&
              (shippingOptions.some((o) => o.id === 3 && o.disable) === false ||
                shippingOptions.some((o) => o.id === 4 && o.disable) ===
                  false ||
                shippingOptions.some((o) => o.id === 5 && o.disable) ===
                  false ||
                shippingOptions.some((o) => o.id === 6 && o.disable) ===
                  false) && (
                <div className="pe-5">
                  <Nodata
                    descript={"含氣閥商品或是總重量超過5公斤不可選擇超商取貨"}
                    bgclass={" nodata "}
                  />
                </div>
              )} */}

            <div className="content-section pe-xl-5">
              {selectedShipArea &&
                addressData &&
                auth &&
                !cvsData &&
                memberAddress && (
                  <div className="py-3 py-xxl-0">
                    <label
                      htmlFor="addressHistory"
                      className="form-label d-block "
                    >
                      <h6 className="primary-deep2">紀錄</h6>
                    </label>
                    <select
                      id="addressHistory"
                      className="form-select"
                      value={selectedOption}
                      onChange={(e) => {
                        setSelectedOption(e.target.value);
                        handleEditClick(parseInt(e.target.value)); // 傳遞選擇的索引作為參數
                      }}
                    >
                      <option
                        className="form-select-option"
                        value="-1"
                        disabled
                      >
                        --會員收件地址--
                      </option>
                      {addressData.map((item, index) => (
                        <option
                          key={index}
                          className="form-select-option"
                          value={index.toString()} // 這裡的值應該是索引而不是`${item.index}`
                        >
                          {item.name} {item.phone} {item.postcode}
                          {item.country}
                          {item.township}
                          {item.address}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              {/* {selectedAreaType === null && (
                <Nodata descript={"請先選擇運送方式"} />
              )} */}

              {selectedAreaType !== null &&
                selectedShipArea === null &&
                selectedshipOption !== 7 && (
                  <>
                    <CheckoutShop
                      shop={selectedshipOption}
                      type={selectedAreaType}
                      cvsData={cvsData}
                    />
                  </>
                )}

              {addressData.map((item, index) => (
                <div key={`editname${index}`}>
                  {editingAddressIndex === index && (
                    <>
                      <NamePhoneLabel
                        itemName={item.name}
                        itemPhone={item.phone}
                        itemTelePhone={item.telephone}
                        onNamePhoneChange={(name, phone, telephone) => {
                          console.log("改變的子層", "name, phone, telephone");
                          setReciptName(name);
                          setReciptPhone(phone);
                          setReciptTele(telephone);
                        }}
                        errornone={true}
                        isNamePhoneValid={(isValid) => {
                          console.log("父層接受到的格式", "isValid");
                          setNamePhoneValid(isValid);
                          //TODO:if isvalid won't send form data
                        }}
                        noChangeOk={true}
                      />
                    </>
                  )}
                </div>
              ))}

              {/* 此處會不斷rerender */}
              {selectedAreaType !== null &&
                editingAddressIndex === -1 &&
                prevData && (
                  <>
                    <NamePhoneLabel
                      itemName={prevData.recipientName}
                      itemPhone={prevData.recipientMobile}
                      itemTelePhone={prevData.recipientTel}
                      isNamePhoneValid={(isValid) => {
                        console.log("父層接受到的格式", "isValid");
                        setNamePhoneValid(isValid);
                      }}
                      errornone={true}
                      noChangeOk={true}
                      onNamePhoneChange={(name, phone, telephone) => {
                        console.log("改變的子層", "name, phone, telephone");
                        setReciptName(name);
                        setReciptPhone(phone);
                        setReciptTele(telephone);
                      }}
                    />
                  </>
                )}

              {selectedAreaType !== null &&
                editingAddressIndex === -1 &&
                selectedShipArea !== null &&
                !prevData && (
                  <>
                    <NamePhoneLabel
                      itemName={""}
                      itemPhone={""}
                      itemTelePhone={""}
                      isNamePhoneValid={(isValid) => {
                        console.log("父層接受到的格式", "isValid");
                        setNamePhoneValid(isValid);
                      }}
                      errornone={true}
                      onNamePhoneChange={(name, phone, telephone) => {
                        console.log("改變的子層", "name, phone, telephone");
                        setReciptName(name);
                        setReciptPhone(phone);
                        setReciptTele(telephone);
                      }}
                      noChangeOk={true}
                    />
                  </>
                )}
              {/* TODO:以下是超商取貨的資訊，所以應該先擋起來 */}
              {selectedAreaType !== null &&
                editingAddressIndex === -1 &&
                selectedShipArea === null &&
                !prevData &&
                cvsData && (
                  <>
                    <NamePhoneLabel
                      itemName={""}
                      itemPhone={""}
                      itemTelePhone={""}
                      isNamePhoneValid={(isValid) => {
                        console.log("父層接受到的格式", "isValid");
                        setNamePhoneValid(isValid);
                      }}
                      errornone={true}
                      onNamePhoneChange={(name, phone, telephone) => {
                        console.log("改變的子層", "name, phone, telephone");
                        setReciptName(name);
                        setReciptPhone(phone);
                        setReciptTele(telephone);
                      }}
                      noChangeOk={true}
                    />
                  </>
                )}
              {/* 自取 */}
              {selectedAreaType === "8" &&
                selectedshipOption === 7 &&
                editingAddressIndex === -1 &&
                selectedShipArea === null &&
                !prevData &&
                !cvsData && (
                  <>
                    <div className="primary-deep2 py-3 py-xxl-0">
                      <h6>自取地址：{storePickUp}</h6>
                    </div>

                    <NamePhoneLabel
                      itemName={""}
                      itemPhone={""}
                      itemTelePhone={""}
                      isNamePhoneValid={(isValid) => {
                        console.log("父層接受到的格式", "isValid");
                        setNamePhoneValid(isValid);
                      }}
                      errornone={true}
                      onNamePhoneChange={(name, phone, telephone) => {
                        console.log("改變的子層", "name, phone, telephone");
                        setReciptName(name);
                        setReciptPhone(phone);
                        setReciptTele(telephone);
                      }}
                      noChangeOk={true}
                    />
                  </>
                )}
              {/* <NamePhoneLabel itemName={item.name}/>
              </>))} */}

              {selectedShipArea && (
                <>
                  <div className="form-label d-block pt-3">
                    <h6 className="primary-deep2">收件地址(必填)</h6>
                  </div>
                  {addressData.map((item, index) => (
                    <div key={`editname${index}`}>
                      {editingAddressIndex === index && (
                        <Checkoutshipping
                          initPostcode={item.postcode}
                          onPostcodeChange={(country, township, postcode) => {
                            console.log("country, township, postcode");
                            setReciptePostcode(postcode);
                            setRecipteCountry(country);
                            setRecipteTownship(township);
                          }}
                          onAddressChange={(address) => {
                            console.log("address", "address");
                            setRecipteAddress(address);
                          }}
                          itemAddress={item.address}
                          selectedAreaType={selectedAreaType}
                          isTWZipCodeValid={(isValid) => {
                            console.log("父層接受到的地址格式", "isValid");
                            setAddressValid(isValid);
                          }}
                          noChangeOk={true}
                        />
                      )}
                    </div>
                  ))}
                  {editingAddressIndex === -1 && prevData && (
                    <Checkoutshipping
                      initPostcode={prevData.postcode}
                      onPostcodeChange={(country, township, postcode) => {
                        console.log("country, township, postcode");
                        // console.log(country, township, postcode);
                        setReciptePostcode(postcode);
                        setRecipteCountry(country);
                        setRecipteTownship(township);
                      }}
                      itemAddress={prevData.address}
                      onAddressChange={(address) => {
                        // console.log("address", "address");
                        setRecipteAddress(address);
                      }}
                      selectedAreaType={selectedAreaType}
                      isTWZipCodeValid={(isValid) => {
                        console.log("父層接受到的地址格式", isValid);
                        setAddressValid(isValid);
                      }}
                      noChangeOk={true}
                    />
                  )}
                  {editingAddressIndex === -1 && !prevData && (
                    <Checkoutshipping
                      initPostcode={""}
                      onPostcodeChange={(country, township, postcode) => {
                        console.log("country, township, postcode");
                        setReciptePostcode(postcode);
                        setRecipteCountry(country);
                        setRecipteTownship(township);
                      }}
                      itemAddress={""}
                      onAddressChange={(address) => {
                        console.log("address", "address");
                        setRecipteAddress(address);
                      }}
                      selectedAreaType={selectedAreaType}
                      isTWZipCodeValid={(isValid) => {
                        console.log("父層接受到的地址格式", isValid);
                        setAddressValid(isValid);
                      }}
                      noChangeOk={true}
                    />
                  )}
                </>
              )}
            </div>

            {cashOnDelivery &&
              (selectedShipArea ||
                (selectedShipArea === null && cvsData) ||
                (selectedShipArea === null && selectedAreaType === "8")) && (
                <div className="content-section">
                  <div className="login-title pt-3 pb-2 pt-xxl-3 pb-xxl-2">
                    <h5>付款方式(必填)</h5>
                  </div>
                  {/* 登入的付款 */}
                  {auth && (
                    <div className="checkbox-container">
                      {paymentoptions.map((option) => (
                        <div key={option.value}>
                          <div
                            className="d-flex cursor-pointer primary-deep2 gap-10px align-items-end mb-1"
                            onClick={() =>
                              handlePaymentOptionChange(option.value)
                            }
                          >
                            <div
                              className={`btn p-0 checkbox-${
                                paymentOption === option.value
                                  ? "selected"
                                  : "button"
                              } `}
                            >
                              <div className="checkbox-icon default">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="19"
                                  viewBox="0 0 18 19"
                                  fill="none"
                                >
                                  <path
                                    d="M2 18.1819C1.45 18.1819 0.979002 17.9859 0.587002 17.5939C0.195002 17.2019 -0.000664969 16.7312 1.69779e-06 16.1819V2.18189C1.69779e-06 1.63189 0.196002 1.16089 0.588002 0.768886C0.980002 0.376886 1.45067 0.18122 2 0.181886H16C16.55 0.181886 17.021 0.377887 17.413 0.769886C17.805 1.16189 18.0007 1.63255 18 2.18189V16.1819C18 16.7319 17.804 17.2029 17.412 17.5949C17.02 17.9869 16.5493 18.1826 16 18.1819H2ZM2 16.1819H16V2.18189H2V16.1819Z"
                                    fill="#364F38"
                                  />
                                </svg>
                              </div>
                              <div className="checkbox-icon check">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="19"
                                  viewBox="0 0 18 19"
                                  fill="none"
                                >
                                  <path
                                    d="M7.6 13.3819L14.65 6.33189L13.25 4.93189L7.6 10.5819L4.75 7.73189L3.35 9.13189L7.6 13.3819ZM2 18.1819C1.45 18.1819 0.979002 17.9859 0.587002 17.5939C0.195002 17.2019 -0.000664969 16.7312 1.69779e-06 16.1819V2.18189C1.69779e-06 1.63189 0.196002 1.16089 0.588002 0.768886C0.980002 0.376886 1.45067 0.18122 2 0.181886H16C16.55 0.181886 17.021 0.377887 17.413 0.769886C17.805 1.16189 18.0007 1.63255 18 2.18189V16.1819C18 16.7319 17.804 17.2029 17.412 17.5949C17.02 17.9869 16.5493 18.1826 16 18.1819H2ZM2 16.1819H16V2.18189H2V16.1819Z"
                                    fill="#364F38"
                                  />
                                </svg>
                              </div>
                            </div>
                            <h6 className="pt-0 pt-xxl-1">{option.label}</h6>
                          </div>
                          {option.danger ? (
                            <div className="pb-1">
                              <Nodata
                                icon={"dangernotice"}
                                dangerclass={"ps-1 p1"}
                                dangerdescript={option.danger}
                                bgclass={"bg-white"}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {option.dangerSecond ? (
                            <div className="pb-1">
                              <Nodata
                                icon={"dangernotice"}
                                dangerclass={"ps-1 p1"}
                                dangerdescript={option.dangerSecond}
                                bgclass={"bg-white"}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}

                      {cashOnDeliveryShow &&
                      auth &&
                      cartTotalData &&
                      feeRuleArray &&
                      feeRuleArray[0] ? (
                        <>
                          <div
                            className={`d-flex primary-deep2 cursor-pointer gap-10px align-items-end mb-1 ${
                              cartTotalData.totalOrdetItemPrice >
                                parseInt(priceRule.lower) && !hasProcessing
                                ? ""
                                : "disabled"
                            }
                          `}
                            onClick={() => {
                              if (
                                cartTotalData.totalOrdetItemPrice >
                                  parseInt(priceRule.lower) &&
                                !hasProcessing
                              ) {
                                handlePaymentOptionChange(3);
                              }
                            }}
                          >
                            <div
                              className={`btn p-0 checkbox-${
                                paymentOption === 3 ? "selected" : "button"
                              } `}
                            >
                              <div className="checkbox-icon default">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="19"
                                  viewBox="0 0 18 19"
                                  fill="none"
                                >
                                  <path
                                    d="M2 18.1819C1.45 18.1819 0.979002 17.9859 0.587002 17.5939C0.195002 17.2019 -0.000664969 16.7312 1.69779e-06 16.1819V2.18189C1.69779e-06 1.63189 0.196002 1.16089 0.588002 0.768886C0.980002 0.376886 1.45067 0.18122 2 0.181886H16C16.55 0.181886 17.021 0.377887 17.413 0.769886C17.805 1.16189 18.0007 1.63255 18 2.18189V16.1819C18 16.7319 17.804 17.2029 17.412 17.5949C17.02 17.9869 16.5493 18.1826 16 18.1819H2ZM2 16.1819H16V2.18189H2V16.1819Z"
                                    fill="#364F38"
                                  />
                                </svg>
                              </div>
                              <div className="checkbox-icon check">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="19"
                                  viewBox="0 0 18 19"
                                  fill="none"
                                >
                                  <path
                                    d="M7.6 13.3819L14.65 6.33189L13.25 4.93189L7.6 10.5819L4.75 7.73189L3.35 9.13189L7.6 13.3819ZM2 18.1819C1.45 18.1819 0.979002 17.9859 0.587002 17.5939C0.195002 17.2019 -0.000664969 16.7312 1.69779e-06 16.1819V2.18189C1.69779e-06 1.63189 0.196002 1.16089 0.588002 0.768886C0.980002 0.376886 1.45067 0.18122 2 0.181886H16C16.55 0.181886 17.021 0.377887 17.413 0.769886C17.805 1.16189 18.0007 1.63255 18 2.18189V16.1819C18 16.7319 17.804 17.2029 17.412 17.5949C17.02 17.9869 16.5493 18.1826 16 18.1819H2ZM2 16.1819H16V2.18189H2V16.1819Z"
                                    fill="#364F38"
                                  />
                                </svg>
                              </div>
                            </div>
                            <h6
                              className={`pt-0 pt-xxl-1 ${
                                cartTotalData.totalOrdetItemPrice >
                                  parseInt(priceRule.lower) && !hasProcessing
                                  ? ""
                                  : "disabled"
                              }`}
                            >
                              貨到付款
                            </h6>
                          </div>
                          {hasProcessing && (
                            <div className="pt-1">
                              <Nodata
                                icon={"dangernotice"}
                                dangerclass={"ps-1 p1"}
                                dangerdescript={`含氣閥商品不可選擇貨到付款。`}
                                bgclass={"bg-white"}
                              />
                            </div>
                          )}
                          {/* <h6 className="danger ps-0 ps-xxl-4">
                            ※訂購金額滿{priceRule.lower}元才可選擇貨到付款。
                          </h6> */}
                          {cartTotalData.totalOrdetItemPrice <
                            parseInt(priceRule.lower) &&
                            !hasProcessing && (
                              <div className="pt-1">
                                <Nodata
                                  icon={"dangernotice"}
                                  dangerclass={"ps-1 p1"}
                                  dangerdescript={`訂購金額滿${priceRule.lower}元才可選擇貨到付款。`}
                                  bgclass={"bg-white"}
                                />
                              </div>
                            )}

                          {/* <p className="danger ps-0 ps-xxl-4 pe-xxl-5">
                            （金額{feeRuleArray[0].key.replace(/[<>]/g, "")}
                            元以下手續費{feeRuleArray[0].value}元，
                            {feeRuleArray[0].key.replace(/[<>]/g, "")}~
                            {feeRuleArray[2].key.replace(/[<>]/g, "")}元手續費
                            {feeRuleArray[1].value}元，
                            {feeRuleArray[2].key.replace(/[<>]/g, "")}以上手續費
                            {feeRuleArray[2].value}元）
                          </p> */}
                        </>
                      ) : null}
                    </div>
                  )}
                  {/* 未登入的付款 */}
                  {auth === false && (
                    <div className="checkbox-container">
                      {unauthpaymentoptions.map((option) => (
                        <div key={option.value}>
                          <div
                            className="d-flex cursor-pointer primary-deep2 gap-10px align-items-end mb-1"
                            onClick={() =>
                              handlePaymentOptionChange(option.value)
                            }
                          >
                            <div
                              className={`btn p-0 checkbox-${
                                paymentOption === option.value
                                  ? "selected"
                                  : "button"
                              } `}
                            >
                              <div className="checkbox-icon default">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="19"
                                  viewBox="0 0 18 19"
                                  fill="none"
                                >
                                  <path
                                    d="M2 18.1819C1.45 18.1819 0.979002 17.9859 0.587002 17.5939C0.195002 17.2019 -0.000664969 16.7312 1.69779e-06 16.1819V2.18189C1.69779e-06 1.63189 0.196002 1.16089 0.588002 0.768886C0.980002 0.376886 1.45067 0.18122 2 0.181886H16C16.55 0.181886 17.021 0.377887 17.413 0.769886C17.805 1.16189 18.0007 1.63255 18 2.18189V16.1819C18 16.7319 17.804 17.2029 17.412 17.5949C17.02 17.9869 16.5493 18.1826 16 18.1819H2ZM2 16.1819H16V2.18189H2V16.1819Z"
                                    fill="#364F38"
                                  />
                                </svg>
                              </div>
                              <div className="checkbox-icon check">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="19"
                                  viewBox="0 0 18 19"
                                  fill="none"
                                >
                                  <path
                                    d="M7.6 13.3819L14.65 6.33189L13.25 4.93189L7.6 10.5819L4.75 7.73189L3.35 9.13189L7.6 13.3819ZM2 18.1819C1.45 18.1819 0.979002 17.9859 0.587002 17.5939C0.195002 17.2019 -0.000664969 16.7312 1.69779e-06 16.1819V2.18189C1.69779e-06 1.63189 0.196002 1.16089 0.588002 0.768886C0.980002 0.376886 1.45067 0.18122 2 0.181886H16C16.55 0.181886 17.021 0.377887 17.413 0.769886C17.805 1.16189 18.0007 1.63255 18 2.18189V16.1819C18 16.7319 17.804 17.2029 17.412 17.5949C17.02 17.9869 16.5493 18.1826 16 18.1819H2ZM2 16.1819H16V2.18189H2V16.1819Z"
                                    fill="#364F38"
                                  />
                                </svg>
                              </div>
                            </div>
                            <h6 className="pt-0 pt-xxl-1">{option.label}</h6>
                          </div>
                          {option.danger ? (
                            <h6 className="p danger ps-0 ps-xxl-4">
                              {option.danger}
                            </h6>
                          ) : (
                            ""
                          )}
                          {option.dangerSecond ? (
                            <p className="danger ps-0 ps-xxl-4 pe-xxl-5">
                              {option.dangerSecond}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                  {/* {!paymentOption && (
                    <Nodata
                      icon={"dangernotice"}
                      dangerclass={"ps-1 p2"}
                      dangerdescript={"請選擇付款方式"}
                      bgclass={"bg-white"}
                    />
                  )} */}
                </div>
              )}
          </div>
          <div className="container px-0 login-content">
            {/* {cartTotalData && auth && (
              <CouponSelect
                cartPrice={cartTotalData.totalOrdetItemPrice}
                initCouponselect={""}
                onCouponChange={(index, data) => {
                  console.log("index, data");
                  setInputDiscount(data);
                }}
              />
            )} */}
            <div className="container px-0 ps-xxl-5">
              <div className="login-title pt-3 pb-2 pt-xxl-3 pb-xxl-2">
                <h5>發票資料</h5>
              </div>
              <InvoiceSelect
                onInvoiceChange={(
                  newInvoiceType,
                  newBusinessNumber,
                  newInvoiceTitle
                ) => {
                  setInvoiceType(newInvoiceType);
                  // console.log("newInvoiceType", newInvoiceType);
                  if (newInvoiceType == "3") {
                    // console.log("newBusinessNumber", newBusinessNumber);
                    // console.log("newInvoiceTitle", newInvoiceTitle);
                    setBusinessNumber(newBusinessNumber);
                    setInvoiceTitle(newInvoiceTitle);
                  }
                }}
                isInvoiceValid={(isValid) => {
                  console.log("父層接受到的格式isInvoiceValid", "isValid");
                  setIsInvoiceValid(isValid);
                }}
                noChangeOk={true}
              />
              <div className="login-title pt-3 pb-2 pt-xxl-3 pb-xxl-2">
                <h5>備註</h5>
              </div>

              <textarea
                name="form-ordermemo"
                id="ordermemo"
                placeholder="請輸入備註訊息"
                className="form-input"
                cols="30"
                rows="5"
                value={orderMemo}
                onChange={handleOrderMemoChange}
              ></textarea>
              {!auth && (
                <>
                  <div className="login-title pt-3 pb-2 pt-xxl-3 pb-xxl-2">
                    <h5>信箱(必填)</h5>
                  </div>
                  <ShareInput
                    content={"email"}
                    isInputValid={(valid) => {
                      setEmailValid(valid);
                      console.log("測試InputValidvalid", valid);
                    }}
                    changeParentInput={(value) => {
                      setEmail(value);
                    }}
                    initValue={prevData && prevData.recipinetEmailForNonM || ""}
                    noteText={"請確認電子郵件是否正確，以利訂單成功通知"}
                  />
                </>
              )}
            </div>
            {auth && (
              <div className="container px-0 ps-xxl-5">
                <div className="login-btn pt-5">
                  <div
                    className={`btn primary-btn-big2 w-100 `}
                    onClick={handleNextButtonClick}
                  >
                    <h6 className="font-weight-bold ">前往結帳</h6>
                  </div>
                </div>
                <div className="d-flex gap-3 px-0 pt-3  pt-xl-3">
                  <div className="d-flex w-100 gap-3 gap-xl-3">
                    <Link to={"/"} className="btn primary-btn-big w-100">
                      <h6 className="font-weight-bold ">返回首頁</h6>
                    </Link>
                    <Link to={"/cart"} className="btn primary-btn-big w-100">
                      <h6 className="font-weight-bold ">返回購物車</h6>
                    </Link>
                  </div>
                </div>
                {checkError && (
                  <div
                    className={`pt-2 ${
                      !namePhoneValid ||
                      !paymentOption ||
                      paymentOption === null ||
                      !isInvoiceValid ||
                      !addressValid
                        ? "d-block"
                        : "d-none"
                    }`}
                  >
                    <Nodata
                      icon={"dangernotice"}
                      dangerclass={"ps-1 p1"}
                      dangerdescript={`尚未填寫${
                        selectedAreaType !== null
                          ? ""
                          : !selectedAreaType &&
                            reciptName &&
                            reciptPhone &&
                            addressValid &&
                            paymentOption &&
                            isInvoiceValid
                          ? "運送方式"
                          : "運送方式、"
                      }${
                        reciptName
                          ? ""
                          : !reciptName &&
                            reciptPhone &&
                            addressValid &&
                            paymentOption &&
                            isInvoiceValid
                          ? "姓名"
                          : "姓名、"
                      }
${
  reciptPhone
    ? ""
    : !reciptPhone && addressValid && paymentOption && isInvoiceValid
    ? "手機"
    : "手機、"
}
${
  addressValid
    ? ""
    : !addressValid && paymentOption && isInvoiceValid
    ? "地址"
    : "地址、"
}

${
  paymentOption
    ? ""
    : !paymentOption && isInvoiceValid
    ? "付款方式"
    : "付款方式、"
}
${invoiceType === "" ? "發票格式" : ""}
${invoiceType === "" ? "" :isInvoiceValid ? "" : invoiceType === 3 && invoiceTitle ? "" : invoiceType ===3 && !invoiceTitle && businessNumber ? "發票抬頭" : "發票抬頭、"}
${invoiceType === "" ? "" :isInvoiceValid ? "" : invoiceType === 3 && businessNumber ? "" : !businessNumber ? "統一編號" : ""}
`}
                      bgclass={"bg-white"}
                    />
                  </div>
                )}
              </div>
            )}
            {!auth && (
              <div className="container px-0 ps-xxl-5">
                <div className="login-btn pt-5">
                  <div
                    className={`btn primary-btn-big2 w-100 
                    `}
                    onClick={handleNextButtonClick}
                  >
                    <h6 className="font-weight-bold ">前往結帳</h6>
                  </div>
                </div>
                <div className="d-flex gap-3 px-0 pt-3  pt-xl-3">
                  <div className="d-flex w-100 gap-3 gap-xl-3">
                    <Link to={"/"} className="btn primary-btn-big w-100">
                      <h6 className="font-weight-bold ">返回首頁</h6>
                    </Link>
                    <Link to={"/cart"} className="btn primary-btn-big w-100">
                      <h6 className="font-weight-bold ">返回購物車</h6>
                    </Link>
                  </div>
                </div>
                {checkError && (
                  <div
                    className={`pt-2 ${
                      !namePhoneValid ||
                      !paymentOption ||
                      paymentOption === null ||
                      !isInvoiceValid ||
                      !addressValid ||
                      !isEmailValid
                        ? "d-block"
                        : "d-none"
                    }`}
                  >
                    <Nodata
                      icon={"dangernotice"}
                      dangerclass={"ps-1 p1"}
                      dangerdescript={`尚未填寫${
                        selectedAreaType !== null
                          ? ""
                          : !selectedAreaType &&
                            reciptName &&
                            reciptPhone &&
                            addressValid &&
                            paymentOption &&
                            isInvoiceValid &&
                            isEmailValid
                          ? "運送方式"
                          : "運送方式、"
                      }${
                        reciptName
                          ? ""
                          : !reciptName &&
                            reciptPhone &&
                            addressValid &&
                            paymentOption &&
                            isInvoiceValid &&
                            isEmailValid
                          ? "姓名"
                          : "姓名、"
                      }
   ${
     reciptPhone
       ? ""
       : !reciptPhone &&
         addressValid &&
         paymentOption &&
         isInvoiceValid &&
         isEmailValid
       ? "手機"
       : "手機、"
   }
   ${
     addressValid
       ? ""
       : !addressValid && paymentOption && isInvoiceValid && isEmailValid
       ? "地址"
       : "地址、"
   }
   
   ${
     paymentOption
       ? ""
       : !paymentOption && isInvoiceValid && isEmailValid
       ? "付款方式"
       : "付款方式、"
   }
   ${
     isInvoiceValid || businessNumber
       ? ""
       : !businessNumber && invoiceTitle && isEmailValid
       ? "統一編號"
       : "統一編號、"
   }
   
   ${
     isInvoiceValid || invoiceTitle
       ? ""
       : !isInvoiceValid && !invoiceTitle && isEmailValid
       ? "發票抬頭"
       : "發票抬頭、"
   }
   ${isEmailValid && email ? "" : "信箱"}
   `}
                      bgclass={"bg-white"}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </form>
    </>
  );
}

export default CheckoutStep2;
