import React, { useEffect, useState } from "react";
import axios from "axios";
import Loading from "./Loading";
import CollectionCard from "../sharedComponents/CollectionCard";

function AllCollections() {
  const [apiSection, setApiSection] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCategory = async () => {
    try {
      const response = await axios.get(`api/Home/Section`);
      if (response.status === 200) {
        const data = response.data;
        const filteredData = data.filter((item) => item.code === 'collection');
        console.log("collection","filteredData")
        setApiSection(filteredData);
      }
    } catch (error) {
      console.error("Error fetching section data from API:", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="container productcate px-md-0 pb-5">
      <h3>產品分類</h3>
      {apiSection.map((item, index) => (
        <div className="container category px-0 " key={`collection-${index}`}>
          <div className="row category-row  row-cols-2 row-cols-xxl-3">
            {item.content &&
              item.content.map((value, valueIndex) => (
                <div key={`collection-${index}-${valueIndex}`} className="col">
                  <CollectionCard content={value} />
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default AllCollections;
