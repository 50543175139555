import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";
import Popup from "../../sharedComponents/Popup";

function OnePageNavMenu({ endTime }) {
  const [countdown, setCountdown] = useState({
    daysTens: 0,
    daysOnes: 0,
    hoursTens: 0,
    hoursOnes: 0,
    minutesTens: 0,
    minutesOnes: 0,
    secondsTens: 0,
    secondsOnes: 0,
  });
  const [noPage, setNoPage] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    //...計算 countdown 的邏輯
    const interval = setInterval(() => {
      const now = new Date();
      const distance = new Date(endTime) - now;
      if (distance < 0) {
        clearInterval(interval);
        setNoPage(true);
        setCountdown({
          daysTens: 0,
          daysOnes: 0,
          hoursTens: 0,
          hoursOnes: 0,
          minutesTens: 0,
          minutesOnes: 0,
          secondsTens: 0,
          secondsOnes: 0,
        });
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const daysTens = Math.floor(days / 10);
        const daysOnes = days % 10;

        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const hoursTens = Math.floor(hours / 10);
        const hoursOnes = hours % 10;

        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const minutesTens = Math.floor(minutes / 10);
        const minutesOnes = minutes % 10;
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        const secondsTens = Math.floor(seconds / 10);
        const secondsOnes = seconds % 10;

        setCountdown({
          daysTens,
          daysOnes,
          hoursTens,
          hoursOnes,
          minutesTens,
          minutesOnes,
          secondsTens,
          secondsOnes,
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [endTime]);

  // if(endTime){
  //   console.log("endTime",endTime)
  // }
  const daysTensString = countdown.daysTens.toString();
  const daysTensArray = daysTensString.split("");
  if (!endTime) {
    return <Loading />;
  }
  return (
    <>
      {noPage && (
        <div className="onepage">
          <div className="container-fluid px-0">
            <Popup
              onNevigate={() => {
                setNoPage(false);
                navigate("/");
              }}
              onClose={() => {
                setNoPage(false);
              }}
              icon={"notice"}
              title={"活動已結束"}
              orangebtn={"回到首頁"}
              greenbtn={""}
            />
          </div>
        </div>
      )}
      <div className="timer-container d-flex justify-content-center align-items-center gap-2 py-2 px-2 info-light-bg">
        <h5 className="time-title danger">商品優惠倒數</h5>
        <div className="gap-1 d-flex">
          <div className="d-flex time-block align-items-end">
            {daysTensArray.map((digit) => (
              <div className="time-number" key={digit}>
                <h5>{digit}</h5>
              </div>
            ))}
            <div className="time-number">
              <h5>{countdown.daysOnes}</h5>
            </div>
            <div className="time-unit">
              <h6 className="font-weight-bold">天</h6>
            </div>
          </div>
          <div className="d-flex time-block align-items-end">
            <div className="time-number">
              <h5>{countdown.hoursTens}</h5>
            </div>
            <div className="time-number">
              <h5>{countdown.hoursOnes}</h5>
            </div>
            <div className="time-unit">
              <h6 className="font-weight-bold">時</h6>
            </div>
          </div>
          <div className="d-flex time-block align-items-end">
            <div className="time-number">
              <h5>{countdown.minutesTens}</h5>
            </div>
            <div className="time-number">
              <h5>{countdown.minutesOnes}</h5>
            </div>
            <div className="time-unit">
              <h6 className="font-weight-bold">分</h6>
            </div>
          </div>
          <div className="d-flex time-block align-items-end">
            <div className="time-number">
              <h5>{countdown.secondsTens}</h5>
            </div>
            <div className="time-number">
              <h5>{countdown.secondsOnes}</h5>
            </div>
            <div className="time-unit">
              <h6 className="font-weight-bold">秒</h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OnePageNavMenu;
