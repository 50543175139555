// FilterContent.js
import React from 'react';

const FilterContent = ({content}) => {

  const filter = (content) => {
    if(!content) return '';
    return content.replace(/&nbsp;/g, ' ');
  }

  return filter(content); 
}

export default FilterContent;