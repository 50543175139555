import React from "react";
import { Link } from "react-router-dom";
import FilterContent from "./FilterContent";

function NewsCardNodate(props) {
  // const cleanMetadesc = removeHtmlTags(props.content.metadesc);
  // const textOnlyMetadesc = extractTextFromHtml(cleanMetadesc);

  return (
    <div className="news-card">
      <Link
        to={
          props.content.blogcateSlug === ""
            ? `${props.content.buttonLink}`
            : `/blogs/${props.content.blogcateSlug}/${props.content.blogSlug}`
        }
      >
        <img src={props.content.image} className="w-100" alt="" />
        <div className="content">
          <h6 className="fw-bold">

            <FilterContent content={props.content.title} />
          </h6>
          <div className="descript">
            <p>
            <FilterContent content={props.content.content} />
            </p>
            {/* <p> <FilterContent>
              {"測試的內容&nbsp;&nbsp;測試 的內容 "}
            </FilterContent></p> */}
          </div>
          <button className="btn primary-btn p-1 mt-3">
          <h6 className="mb-0">{props.content.buttonContent === '' ? "查看詳細資訊" :  <FilterContent content={props.content.buttonContent} />}</h6>
          </button>
        </div>
        <div className="news-border"></div>
      </Link>
    </div>
  );
}

export default NewsCardNodate;
