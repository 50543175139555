import React, { useState, useRef, useEffect, useContext } from "react";
import Loading from "../components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import AuthContext from "../Context/AuthContext";
import Cartlist from "../sharedComponents/Cartlist";
import CouponPopup from "../sharedComponents/CouponPopup";
import { deleteProduct } from "../redux/actions/action";
import Nodata from "../sharedComponents/Nodata";

function Cart() {
  const {
    myAuth,
    updateCartData,
    updateCartAmount,
    checkExpiration,
    logout,
  } = useContext(AuthContext);
  const [cartData, setCartData] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [noLoginupdate, setNoLoginUpdate] = useState();
  const [priceChange, setPriceChange] = useState([]);

  const [subProcessingPrice, setSubProcessingPrice] = useState(0);

  const [totalPrice, setTotalPrice] = useState(0);
  const [discounttotalPrice, setDiscountTotalPrice] = useState(0);

  const [totalWeight, setTotalWeight] = useState(0);

  const [popupOpen, setPopupOpen] = useState(false);

  // coupon選擇的index和資訊
  const [inputDiscount, setInputDiscount] = useState({});
  // 是否可以使用折價券
  const [discountAvalible, setDiscountAvalible] = useState(false);
  // 折扣的金額
  const [discountPrice, setDiscountPrice] = useState(0);

  const dispatch = useDispatch();
  const productId = useSelector((state) => state.product.productId);
  const localStorageCartData = localStorage.getItem("cart");
  //會員資訊
  const auth = myAuth.authorized;
  const storedToken = myAuth.token;
  const mid = parseInt(myAuth.sid);
  //判斷網址

  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      await checkExpiration();
      // 等待 checkExpiration 完成後再執行以下代碼

      setCartData(myAuth.cartData || []);
      setIsLoading(false);
    } catch (error) {
      // 處理錯誤
      console.error("Error during data fetch:", "error");
    }
  };
  //未登入
  const getUnauthShoppingCart = () => {
    console.log("未登入的localStorageCartData", "localStorageCartData");
    if (localStorageCartData) {
      try {
        const cartItems = JSON.parse(localStorageCartData);
        const requestData = {
          memberid: 0,
          orderItems: cartItems, // 将解析后的数据放入数组
        };
        axios
          .post(`/api/Order/GetshoppingCart`, requestData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              const localCart = response.data;
              console.log("未登入的loginCart:", "localCart");
              console.log("未登入的cartItems:", "cartItems");
              console.log("未登入的loginCart在這:", "localCart.orderItems");
              setCartData(localCart);
            } else {
              console.log("Request failed:", "response");
              // Token is invalid, log out the user
            }
          })
          .catch((error) => {
            console.error("Error:", "error");
            console.error("requestData:", "requestData");
          })
          .finally(() => {
            setIsLoading(false); // Mark loading as finished regardless of success or error
          });
      } catch (error) {
        console.error("Error parsing localStorageCartData:", "error");
        // 不加401
      }
    } else {
      console.log("購物車沒有資料")
      setCartData([]);
      setIsLoading(false);
    }
  };

  //未登入的初始資料
  useEffect(() => {
    if (myAuth.authorized !== null) {
      if (!myAuth.authorized) {
        //未登入的初始資料
        getUnauthShoppingCart();
      } else {
        //登入的cartdata更動
        fetchData();
      }
    }
  }, [localStorageCartData, noLoginupdate, myAuth.cartData, myAuth.authorized]);

  // //登入的cartdata更動
  // useEffect(() => {
  //   console.log("myAuth.cartData");
  //   setCartData(myAuth.cartData);
  // }, [myAuth.cartData]);

  //刪除的處理
  const handleDeleteItem = async (
    prodid,
    skuid,
    processid,
    quantity,
    orderitemId,
    descript
  ) => {
    setIsLoading(true);
    if (myAuth.authorized) {
      const storedToken = myAuth.token;
      const orderItem = {
        orderitemId: orderitemId,
        prodid: prodid,
        skuid: skuid,
        quantity: quantity,
        itemdesc: descript,
      };

      // 只有在processid不为null时才包含它
      if (processid !== null) {
        orderItem.processid = processid;
      }

      const requestData = {
        memberid: myAuth.sid,
        orderItems: [orderItem],
      };
      axios
        .post("/api/Order/DeleteOrderitem", requestData, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            // 处理成功响应
            // 根据后端响应更新购物车数据
            updateCartData();
            updateCartAmount();
          } else {
            console.log("Request failed:", "response");
            console.log("requestData failed:", "requestData");
            // 处理请求失败的情况
          }
        })
        .catch((error) => {
          console.error("Error:", "error");
          console.error("Error requestData:", "requestData");
          // 处理错误情况
          if (error.response && error.response.status === 401) {
            // 如果是 401，觸發 logout()
            logout();
          }
        });
    } else {
      console.log("processid", "processid");
      const localStorageCartData =
        JSON.parse(localStorage.getItem("cart")) || [];
      const updatedCart = cartData.filter((item) => {
        let match = item.prodid === prodid && item.skuid === skuid;

        // 是否有 processid
        if (processid && item.processid) {
          // 有傳遞 processid,判斷是否匹配
          match =
            item.prodid === prodid &&
            item.skuid === skuid &&
            item.processid === processid;
        } else if (!processid && !item.processid) {
          // 沒傳遞 processid,且 item 也沒有,则匹配
          match = item.prodid === prodid && item.skuid === skuid;
        }

        return !match;
      });

      const updatedLocalCart = localStorageCartData.filter((item) => {
        let match = false;

        if (processid && item.processid) {
          match =
            item.prodid === prodid &&
            item.skuid === skuid &&
            item.processid === processid;
        } else if (!processid && !item.processid) {
          match = item.prodid === prodid && item.skuid === skuid;
        }

        return !match;
      });
      localStorage.setItem("cart", JSON.stringify(updatedLocalCart));
      console.log("localStorage.setItem", "JSON.stringify(updatedLocalCart)");

      setCartData(updatedCart);
      setIsLoading(false);
    }
    // 使用所需的参数调用deleteProduct函数
    dispatch(deleteProduct({ prodid, skuid, processid }));
  };

  const handleDecreaseItem = (
    prodid,
    skuid,
    processid,
    quantity,
    orderitemId,
    descript
  ) => {
    // 更新购物车数据，触发重新渲染
    if (myAuth.authorized) {
      handleAuthQuantityChange(
        prodid,
        skuid,
        processid,
        quantity,
        orderitemId,
        descript,
        -1
      );
    } else {
      handleQuantityChange(prodid, skuid, processid, -1);
    }
  };

  const handleIncreaseItem = (
    prodid,
    skuid,
    processid,
    quantity,
    orderitemId,
    descript
  ) => {
    // 更新购物车数据，触发重新渲染
    if (myAuth.authorized) {
      handleAuthQuantityChange(
        prodid,
        skuid,
        processid,
        quantity,
        orderitemId,
        descript,
        1
      );
    } else {
      handleQuantityChange(prodid, skuid, processid, 1);
    }
  };
  const handleChangeItem = (
    prodid,
    skuid,
    processid,
    quantity,
    orderitemId,
    descript
  ) => {
    // 更新购物车数据，触发重新渲染
    if (myAuth.authorized) {
      const newquantity = quantity;
      const orderItem = {
        orderitemId: orderitemId,
        prodid: prodid,
        skuid: skuid,
        quantity: newquantity,
        itemdesc: descript,
      };

      // 只有在processid不为null时才包含它
      if (processid !== null) {
        orderItem.processid = processid;
      }

      const requestData = {
        memberid: myAuth.sid,
        orderItems: [orderItem],
      };
      postAuthEditData(requestData);
    } else {
      console.log("未登入的handleQuantityChange");
      setIsLoading(true);

      const updatedCart = JSON.parse(localStorage.getItem("cart")) || [];
      updateUnAuthEditData(
        updatedCart,
        prodid,
        skuid,
        processid,
        quantity,
        null
      );

      setIsLoading(false);
    }
  };
  const handleAuthQuantityChange = (
    prodid,
    skuid,
    processid,
    quantity,
    orderitemId,
    descript,
    change
  ) => {
    if (quantity + change > 0) {
      const newquantity = quantity + change;
      const orderItem = {
        orderitemId: orderitemId,
        prodid: prodid,
        skuid: skuid,
        quantity: newquantity,
        itemdesc: descript,
      };

      // 只有在processid不为null时才包含它
      if (processid !== null) {
        orderItem.processid = processid;
      }

      const requestData = {
        memberid: myAuth.sid,
        orderItems: [orderItem],
      };
      postAuthEditData(requestData);
    } else {
      console.log("數量不得為0");
    }
  };
  const postAuthEditData = (requestData) => {
    const storedToken = myAuth.token;
    axios
      .post("/api/Order/EditOrderitem", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          // 处理成功响应

          const updatedCart = response.data; // 根据后端响应更新购物车数据
          console.log("EditOrderitem", "updatedCart");
          updateCartData();
        } else {
          console.log("Request failed:", "response");
          console.log("requestData failed:", "requestData");
          // 处理请求失败的情况
        }
      })
      .catch((error) => {
        console.error("Error:", "error");
        console.error("Error requestData:", "requestData");
        // 处理错误情况
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      });
  };

  const handleQuantityChange = (prodid, skuid, processid, change) => {
    console.log("未登入的handleQuantityChange");
    setIsLoading(true);

    const updatedCart = JSON.parse(localStorage.getItem("cart")) || [];
    updateUnAuthEditData(updatedCart, prodid, skuid, processid, null, change);

    setIsLoading(false);
  };

  const updateUnAuthEditData = (
    updatedCart,
    prodid,
    skuid,
    processid,
    quantity,
    change
  ) => {
    const updatedItemIndex = updatedCart.findIndex(
      (item) =>
        item.prodid === prodid &&
        item.skuid === skuid &&
        (item.processid === processid || (!item.processid && !processid))
    );

    if (updatedItemIndex !== -1) {
      const updatedItem = updatedCart[updatedItemIndex];

      if (change) {
        updatedItem.quantity += change; // 更新数量
      } else {
        updatedItem.quantity = quantity; // 设置数量
      }

      if (updatedItem.quantity <= 0) {
        updatedCart.splice(updatedItemIndex, 1);
      }

      // localStorage.setItem("cart", JSON.stringify(updatedCart));
      console.log("updatedCart", "updatedCart");

      localStorage.setItem("cart", JSON.stringify(updatedCart));
      setNoLoginUpdate(updatedCart);
    }
  };

  const findUnitPriceByQuantity = (quantity, unitPrices) => {
    if (unitPrices && unitPrices.length > 0) {
      for (let i = unitPrices.length - 1; i >= 0; i--) {
        if (quantity >= unitPrices[i].quantity) {
          return unitPrices[i].unitPrice;
        }
      }
    }

    if (unitPrices) {
      return unitPrices[0].unitPrice; // 預設單價
    }

    return null;
  };
  //根據數量查找適當的折價金額
  const findDiscountPrice = (unitPrice, discountValue) => {
    if (isNaN(unitPrice) || isNaN(discountValue)) {
      // 如果unitPrice或discountValue不是有效数字，则无法继续计算
      return "Invalid Input";
    }

    const discount = parseInt(discountValue);
    const discountedPrice = (1 - discount * 0.01) * unitPrice;

    // 通过toFixed将小数位数限制为两位
    return parseFloat(discountedPrice.toFixed(2));
  };
  // 清空折價選項
  const clearLocalStorageAndInputDiscount = () => {
    // Clear localStorage
    if (localStorage.getItem("promoData")) {
      let promoData = JSON.parse(localStorage.getItem("promoData"));
      delete promoData.Discount;
      localStorage.setItem("promoData", JSON.stringify(promoData));
    }
    setDiscountPrice(0);
    // Clear inputDiscount
    setInputDiscount({});
  };

  //是否可以使用折價
  const ProductCheckDiscoun = () => {
    const requestData = { mid: mid };
    // 觸發時清空選擇
    clearLocalStorageAndInputDiscount();

    // console.log("storedToken", storedToken);
    axios
      .post("/api/Order/CkProductDiscoun", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("優惠", "response.data");
        console.log("發送優惠的請求", "requestData");
        setDiscountAvalible(response.data);
      })
      .catch((error) => {
        console.error("優惠回傳有錯誤：", error);
        console.log("發送優惠的請求有錯誤", "requestData");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      });
  };
  useEffect(() => {
    if (Object.keys(inputDiscount).length > 0) {
      let promoData = {};
      promoData.Discount = inputDiscount[0];
      localStorage.setItem("promoData", JSON.stringify(promoData));
    }
  }, [inputDiscount]);
  //一般的總金額+總重量
  useEffect(() => {
    let total = 0;
    let subProcessing = 0;
    let subTotal = 0;
    let subWeight = 0;
    console.log("cartData", "cartData");
    if (cartData) {
      cartData.forEach((item) => {
        const unitPrice = findUnitPriceByQuantity(
          item.quantity,
          item.skuData.unitPrices
        );
        const itemSubTotal = item.quantity * unitPrice;
        subTotal += itemSubTotal;

        if (item.processingData) {
          item.processingData.forEach((processingItem) => {
            subProcessing +=
              processingItem.unitPrice *
              item.quantity *
              item.skuData.unitPieces;
          });
        }
        console.log("item", "item");
        const unitweight = item.prodWeight || 0;
        console.log("unitweight", "unitweight");
        const itemSubWeight = unitweight * item.quantity;
        subWeight += itemSubWeight;
      });
      const caculaterTotalWeight = parseFloat(subWeight.toFixed(2));
      const calculatedTotalPrice = subTotal + subProcessing;
      setTotalPrice(calculatedTotalPrice);
      console.log("caculaterTotalWeight", "caculaterTotalWeight");
      setTotalWeight(caculaterTotalWeight);
    }
  }, [cartData]);

  //折價的總金額
  useEffect(() => {
    let total = 0;
    let subProcessing = 0;
    let discountsubTotal = 0;

    cartData &&
      cartData.forEach((item) => {
        const unitPrice = findUnitPriceByQuantity(
          item.quantity,
          item.skuData.unitPrices
        );

        const discountPrice = item.discountData.discountValue
          ? findDiscountPrice(unitPrice, item.discountData.discountValue)
          : unitPrice;

        const itemdiscountsubTotal = item.quantity * discountPrice;

        discountsubTotal += itemdiscountsubTotal;

        if (item.processingData) {
          item.processingData.forEach((processingItem) => {
            subProcessing +=
              processingItem.unitPrice *
              item.quantity *
              item.skuData.unitPieces;
          });
        }
      });
    console.log("subProcessing", "subProcessing");
    const calculatedTotalPrice = discountsubTotal + subProcessing;
    if (calculatedTotalPrice === totalPrice) {
      setDiscountTotalPrice(null);
    } else {
      setDiscountTotalPrice(calculatedTotalPrice);
    }

    console.log("setDiscountTotalPrice", "calculatedTotalPrice");
  }, [cartData, totalPrice]);

  //在登入且有購物車時才去取得折價券資訊
  useEffect(() => {
    if (myAuth.authorized && cartData) {
      ProductCheckDiscoun();
    }
  }, [cartData, totalPrice, myAuth.authorized]);


  const totalQuantity =
    cartData && cartData.reduce((total, item) => total + item.quantity, 0);
    useEffect(()=>{
console.log("cartData","cartData")
console.log("isLoading",isLoading)
    },[cartData, isLoading])

  if (cartData === null || isLoading) {
    return <Loading />;
  }
  return (
    <>
      {popupOpen && auth && (
        <CouponPopup
          closePopup={() => {
            setPopupOpen(false);
          }}
          initCouponselect={null}
          cartPrice={totalPrice}
          onCouponChange={(index, data) => {
            // console.log(index, data);
            setInputDiscount(data);
            setDiscountPrice(data[3]);
            // console.log(data, data[3]);
          }}
          discountAvalible={discountAvalible}
        />
      )}
      <div className="container pt-0 px-md-0">
        <h4>購物車</h4>
      </div>

      {cartData && cartData.length === 0 && cartData !== null && (
        <div className="container px-md-0 pt-1 pt-md-3">
          <Nodata
            descript={"目前購物車內沒有商品"}
            link={"../../collections"}
            linkDescript={"去逛逛"}
          />
        </div>
      )}
      {cartData !== null && !isLoading && cartData.length > 0 && (
        <div className="pt-1 pt-md-3 page-cart container px-md-0 d-flex flex-column gap-10px">
          <>
            <div className="page-content-title ">
              <h6>商品明細</h6>
              <h6>價格 / 單價</h6>
              <h6>數量</h6>
              <h6>加購</h6>
              <h6>小計</h6>
              <h6>移除</h6>
            </div>
            <div className="page-content">
              <div className="overflow-wrap">
                <div className="d-flex flex-column gap-10px">
                  {/* TODO:後端發送請求列出全部商品資訊，傳遞給元件CARTLIST */}
                  {cartData && isLoading && <Loading />}
                  {cartData &&
                    !isLoading &&
                    cartData.map((item, index) => (
                      <Cartlist
                        key={index}
                        cartitem={item}
                        content={"page"}
                        onDelete={() =>
                          handleDeleteItem(
                            item.prodid,
                            item.skuid,
                            item.processid,
                            item.quantity,
                            item.orderitemId,
                            item.itemdesc
                          )
                        }
                        onDecrease={() =>
                          handleDecreaseItem(
                            item.prodid,
                            item.skuid,
                            item.processid,
                            item.quantity,
                            item.orderitemId,
                            item.itemdesc
                          )
                        }
                        onQuantityChange={(newquantity) => {
                          console.log("newquantity", "newquantity");
                          handleChangeItem(
                            item.prodid,
                            item.skuid,
                            item.processid,
                            newquantity,
                            item.orderitemId,
                            item.itemdesc
                          );
                        }}
                        onIncrease={() =>
                          handleIncreaseItem(
                            item.prodid,
                            item.skuid,
                            item.processid,
                            item.quantity,
                            item.orderitemId,
                            item.itemdesc
                          )
                        }
                      />
                    ))}
                </div>
              </div>
            </div>
            <div className="container d-flex gap-10px flex-column flex-md-row justify-content-end justify-content-md-between bottom-container">
              <div className="d-flex justify-content-end ">
                <h6 className="primary-deep2">*購物車內淨重 ：</h6>
                <h6 className="primary-deep3">{totalWeight}</h6>
                <h6 className="primary-deep2">公斤</h6>
              </div>
              <div className="d-flex justify-content-end gap-10px">
                <div className="count d-flex flex-column gap-10px primary-deep2">
                  <div className="d-flex justify-content-between">
                    <h6>合計</h6>
                    {discounttotalPrice && (
                      <h6>${Math.round(discounttotalPrice).toLocaleString()}</h6>
                    )}
                    {!discounttotalPrice && (
                      <h6>${Math.round(totalPrice).toLocaleString()}</h6>
                    )}
                  </div>
                  <div className="d-flex justify-content-between">
                    <h6>折扣</h6>
                    <h6>${discountPrice.toLocaleString()}</h6>
                  </div>
                  <div className="d-flex justify-content-between">
                    <h6>稅金</h6>
                    {discounttotalPrice && (
                      <h6>
                        $
                        {((Math.ceil(
                          (discounttotalPrice - discountPrice) * 1.05
                        ))- (Math.round(discounttotalPrice) - discountPrice)).toLocaleString()}
                      </h6>
                    )}
                    {!discounttotalPrice && (
                      <h6>
                        $
                        {((Math.ceil(
                          (totalPrice - discountPrice) * 1.05
                        ))- (Math.round(totalPrice)- discountPrice)).toLocaleString()}
                      </h6>
                    )}
                  </div>
                  <div className="total-cartprice">
                    <h6>總金額</h6>
                    {discounttotalPrice && (
                      <h6>
                        $
                        {Math.ceil(
                          (discounttotalPrice - discountPrice) * 1.05
                        ).toLocaleString()}
                      </h6>
                    )}
                    {!discounttotalPrice && (
                      <h6>
                        $
                        {Math.ceil(
                          (totalPrice - discountPrice) * 1.05
                        ).toLocaleString()}
                      </h6>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {auth &&
              discountAvalible &&
              Object.keys(inputDiscount).length === 0 && (
                <div className="d-flex justify-content-end">
                  <div
                    className="coupon-btn d-flex gap-10px cursor-pointer align-items-center rounded"
                    onClick={() => {
                      setPopupOpen(true);
                      setInputDiscount({});
                    }}
                  >
                    <div className="coupon-icon"></div>
                    <h6 className="p">點取優惠</h6>
                  </div>
                </div>
              )}
            {inputDiscount && Object.keys(inputDiscount).length > 0 && (
              <div className="d-flex justify-content-end gap-10px align-items-center flex-wrap">
                <h6 className="p primary-deep3">{inputDiscount[1]} {' '}  {inputDiscount[2]}</h6>
                <div
                  className="coupon-btn d-flex gap-10px cursor-pointer align-items-center rounded"
                  onClick={() => {
                    setPopupOpen(true);
                    clearLocalStorageAndInputDiscount();
                  }}
                >
                  <div className="coupon-icon"></div>
                  <h6 className="p">重選優惠</h6>
                </div>
              </div>
            )}
            
            <div className="d-flex justify-content-end">
              <h6 className="p info">*</h6>
              <h6 className="p primary-deep2">
                購物滿3000元免運費，不含外島
              </h6>
            </div>

            <div className="container pt-3 pb-5 px-0">
              <div className="d-flex justify-content-end btn-section">
                <div
                  className="btn primary-btn-big "
                  onClick={() => {
                    navigate("/collections");
                  }}
                >
                  <h6 className="font-weight-bold ">繼續購物</h6>
                </div>
                <div
                  className="btn primary-btn-big "
                  onClick={() => {
                    if (myAuth.authorized) {
                      navigate("/checkout");
                    } else {
                      navigate("/checkoutstep1");
                    }
                  }}
                >
                  <h6 className="font-weight-bold ">前往結帳</h6>
                </div>
              </div>
            </div>
          </>
        </div>
      )}
    </>
  );
}

export default Cart;
