import { useContext, useState, useEffect } from "react";
import axios from "axios";

import Nodata from "./Nodata";
import AuthContext from "../Context/AuthContext";

function CouponPopup({
  closePopup,
  cartPrice,
  initCouponselect,
  onCouponChange,
  discountAvalible,
}) {
  const { myAuth, logout } = useContext(AuthContext); //登入狀態

  const [ecouponData, setEcouponData] = useState();
  const [pointData, setPointData] = useState("");

  //避免選項共用狀態
  const [selectedPromoCode, setSelectedPromoCode] = useState(""); // For promo code
  const [selectedEcoupon, setSelectedEcoupon] = useState(""); // For ecoupon
  const [selectedPoint, setSelectedPoint] = useState(""); // For member points
  //選擇的pointdata
  const [selectedPointData, setSelectedPointData] = useState({});

  //選擇的ecouponData
  const [Ecouponinfo, setEcouponinfo] = useState("");
  const [selectedEcouponData, setSelectedEcouponData] = useState({});
  //選擇的promocode(如果可以才會有資料)
  const [selectedPromoData, setSelectedPromoData] = useState({});
  //輸入的promocode
  const [inputpromocode, setInputPromoCode] = useState("");

  const [promoinfo, setPromoinfo] = useState("");
  const [promostate, setPromostate] = useState(false);
  const [promoerrorInfo, setPromoerrorInfo] = useState("");
  // 選擇優惠的名稱
  const [discountName, setDiscountName] = useState(null);
  // 選擇優惠的金額
  const [discountPrice, setDiscountPrice] = useState(0);
  const [giftData, setGiftData] = useState({});
  //選擇使用優惠
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);
  const [closeError, setCloseEror] = useState("");
  const couponoptions = [
    { label: "優惠代碼", value: "discountcode" },
    { label: "折價券", value: "ecoupon" },
    { label: "紅利點數", value: "memberpoint" },
  ];
  const mid = parseInt(myAuth.sid);
  const storedToken = myAuth.token;
  //選擇的折價

  const handleOptionChange = (index) => {
    if (index === selectedOptionIndex) {
      return;
    }

    setSelectedOptionIndex(index);
    setInputPromoCode("");
    setPromoinfo("");
    setPromostate(false);
    setPromoerrorInfo("");
    setSelectedEcouponData({});
    setSelectedPointData({});
    setSelectedPromoData({});
    setSelectedPromoCode(""); // Reset the selected promo code
    setSelectedEcoupon(""); // Reset the selected ecoupon
    setSelectedPoint(""); // Reset the selected member points
    console.log("改變了selectedOptionIndex", "index");
  };
  //使用者輸入完，觸發promoBlur再去檢查是否可使用
  const promoBlur = () => {
    if (selectedPromoCode) {
      promoteCodeCheck(selectedPromoCode);
    }
  };
  //判斷優惠代碼是否可以使用
  const promoteCodeCheck = (input) => {
    const requestData = { mid: mid, promocode: input };
    // console.log("storedToken", storedToken);
    axios
      .post("/api/Order/CkPromoCode", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("取得折扣碼資訊", "response.data");
        console.log("發送取得折扣碼資訊的請求", "requestData");
        setPromostate(true);
        setPromoinfo(response.data);
        setDiscountName(response.data.promoTitle);
        setPromoerrorInfo("");
        setSelectedPromoData({ promoCode: response.data.promoCode });
        if (response.data.promoPrice) {
          const discountAmount = response.data.promoPrice;
          setDiscountPrice(discountAmount);
          console.log("setDiscountPrice");
        } else if (response.data.promoDiscount) {
          const promoDiscount = parseFloat(response.data.promoDiscount);
          const totalPrice = parseFloat(response.data.totalItemsPrice);

          // Calculate the discounted price
          const discountedPrice = totalPrice * (1 - promoDiscount / 100);

          // Calculate the discount amount
          const discountAmount = Math.round(totalPrice - discountedPrice);

          setDiscountPrice(discountAmount);
        }
      })
      .catch((error) => {
        console.error("取得折扣碼資訊回傳有錯誤：", "error");
        console.log("發送取得折扣碼資訊的請求有錯誤", "requestData");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }

        setPromostate(false);
        setPromoerrorInfo("此為無效代碼");
      });
  };
  //取得折價券資料
  const getEcouponData = () => {
    const requestData = { mid: mid };
    axios
      .post("/api/Order/GetEcoupon", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("取得折價券資訊", "response.data");
        console.log("發送取得折價券資訊的請求", "requestData");
        setEcouponData(response.data);

        // setDiscountAvalible(response.data);
      })
      .catch((error) => {
        console.error("取得折價券資訊回傳有錯誤：", "error");
        console.log("發送取得折價券資訊的請求有錯誤", "requestData");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      });
  };
  //取得紅利點數
  const getMemberPoint = () => {
    const requestData = { mid: mid };
    axios
      .post("/api/Member/GetMemberPoint", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("取得紅利點數資訊", "response.data");
        console.log("發送取得紅利點數資訊的請求", "requestData");

        // setPointData(response.data.bonusData)
        // setEcouponData(response.data[0]);
        // setDiscountAvalible(response.data);
        const realBonus = response.data.realBonus;
        const monthLimitBonus = response.data.monthLimitBonus;

        // Calculate avaliblePoint based on conditions
        let avaliblePoint;

        if (realBonus > monthLimitBonus) {
          avaliblePoint = monthLimitBonus;
        } else if (realBonus < monthLimitBonus) {
          avaliblePoint = realBonus;
        } else {
          // Handle the case when they are equal, choose one or set a default value
          avaliblePoint = realBonus; // You can set it to monthLimitBonus if you prefer
        }
        const avaliblePointData = Array.from(
          { length: avaliblePoint / 100 },
          (_, index) => (index + 1) * 100
        );
        setPointData(avaliblePointData);
      })
      .catch((error) => {
        console.error("取得紅利點數資訊回傳有錯誤：", "error");
        console.log("發送取得紅利點數資訊的請求有錯誤", "requestData");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      });
  };
  // 確定優惠選擇
  const confirmSelect = () => {
    if (discountName) {
      if (selectedOptionIndex === 0 && selectedPromoData) {
        onCouponChange(selectedOptionIndex, [
          selectedPromoData,
          "優惠代碼",
          discountName,
          discountPrice,
        ]);
        console.log("要傳回父層的優惠代碼promoCode", "selectedPromoData");
      }
      if (selectedOptionIndex === 1 && selectedEcouponData) {
        onCouponChange(selectedOptionIndex, [
          selectedEcouponData,
          "折價券",
          discountName,
          discountPrice,
        ]);
        console.log("要傳回父層的折價券", "selectedEcouponData");
      }
      if (selectedOptionIndex === 2 && selectedPointData) {
        console.log("要傳回父層的紅利點數", "selectedPointData");
        onCouponChange(selectedOptionIndex, [
          selectedPointData,
          "紅利點數",
          discountName,
          discountPrice,
        ]);
      }
      closePopup();
    } else {
      closePopup();
    }
  };

  //如果可以使用折價，再去取得優惠資訊
  useEffect(() => {
    if (discountAvalible) {
      getEcouponData();
      console.log("getEcouponData");
      console.log("cartPrice", "cartPrice");
      if (cartPrice > 2000) {
        getMemberPoint();
      } else {
        console.log("沒有大於2000");
        // setEcouponinfo("")
      }
    }
  }, [myAuth, discountAvalible, cartPrice]);

  // useEffect(() => {
  //   //將選擇的index和資訊回傳給父層
  //   if (initCouponselect === null || selectedOptionIndex !== initCouponselect) {
  //     if (selectedOptionIndex === 0) {
  //       onCouponChange(selectedOptionIndex, selectedPromoData);
  //       console.log("要傳回父層的優惠代碼promoCode", "selectedPromoData");
  //     }
  //     if (selectedOptionIndex === 1) {
  //       onCouponChange(selectedOptionIndex, selectedEcouponData);
  //       console.log("要傳回父層的折價券", "selectedEcouponData");
  //     }
  //     if (selectedOptionIndex === 2) {
  //       console.log("要傳回父層的紅利點數", "selectedPointData");
  //       onCouponChange(selectedOptionIndex, selectedPointData);
  //     }
  //   }
  // }, [
  //   selectedOptionIndex,
  //   selectedEcouponData,
  //   selectedPointData,
  //   selectedPromoData,
  // ]);

  return (
    <div className="coupon-pop-bg" onClick={closePopup}>
      <div
        className="pop-continer d-flex flex-column justify-content-between"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="d-flex flex-column gap-10px">
          {couponoptions &&
            couponoptions.map((item, index) => (
              <div
                className={`${
                  (index === 1 && (!ecouponData || ecouponData.length === 0)) ||
                  (index === 2 && pointData.length === 0)
                    ? "disabled"
                    : "popup-check cursor-pointer"
                } row  row-cols-1 row-cols-sm-2 px-0  align-items-center gap-1`}
                onClick={() =>
                  (index === 1 && (!ecouponData || ecouponData.length === 0)) ||
                  (index === 2 && pointData.length === 0)
                    ? null
                    : handleOptionChange(index)
                }
                key={index}
              >
                <div className="d-flex gap-1 col-auto px-0 select-item">
                  <div className="btn checkbox-button d-flex">
                    <div
                      className={`checkbox-icon default ${
                        selectedOptionIndex === index ? "d-none" : "d-block"
                      }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="18"
                        viewBox="0 0 19 18"
                        fill="none"
                      >
                        <path
                          d="M2.5 18C1.95 18 1.479 17.804 1.087 17.412C0.695002 17.02 0.499335 16.5493 0.500002 16V2C0.500002 1.45 0.696002 0.979002 1.088 0.587002C1.48 0.195002 1.95067 -0.000664969 2.5 1.69779e-06H16.5C17.05 1.69779e-06 17.521 0.196002 17.913 0.588002C18.305 0.980002 18.5007 1.45067 18.5 2V16C18.5 16.55 18.304 17.021 17.912 17.413C17.52 17.805 17.0493 18.0007 16.5 18H2.5ZM2.5 16H16.5V2H2.5V16Z"
                          fill="#FF5C26"
                        />
                      </svg>
                    </div>
                    <div
                      className={`checkbox-icon check ${
                        selectedOptionIndex === index ? "d-block" : "d-none"
                      }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="18"
                        viewBox="0 0 19 18"
                        fill="none"
                      >
                        <path
                          d="M8.1 13.2L15.15 6.15L13.75 4.75L8.1 10.4L5.25 7.55L3.85 8.95L8.1 13.2ZM2.5 18C1.95 18 1.479 17.804 1.087 17.412C0.695002 17.02 0.499335 16.5493 0.500002 16V2C0.500002 1.45 0.696002 0.979002 1.088 0.587002C1.48 0.195002 1.95067 -0.000664969 2.5 1.69779e-06H16.5C17.05 1.69779e-06 17.521 0.196002 17.913 0.588002C18.305 0.980002 18.5007 1.45067 18.5 2V16C18.5 16.55 18.304 17.021 17.912 17.413C17.52 17.805 17.0493 18.0007 16.5 18H2.5ZM2.5 16H16.5V2H2.5V16Z"
                          fill="#FF5C26"
                        />
                      </svg>
                    </div>
                  </div>
                  <h6 className="pt-0 primary-deep2 text-nowrap">
                    {/* {skuProcessing[processingKey].processingName} */}
                    {item.label}
                  </h6>
                </div>
                {/* 優惠代碼的顯示 */}
                {selectedOptionIndex === 0 && selectedOptionIndex === index && (
                  <div className="px-0 ps-sm-1 col">
                    <div className="d-flex gap-1">
                      <input
                        type="text"
                        id="membercode"
                        name="membercode"
                        className="form-input"
                        pattern=".{3,}"
                        placeholder="請輸入優惠代碼"
                        value={selectedPromoCode} // Use the selectedPromoCode state
                        onChange={(e) => {
                          setSelectedPromoCode(e.target.value);
                          setPromoerrorInfo("");
                          setPromoinfo("");
                        }}
                      />
                      <div
                        className="btn secondary-btn px-1 py-2 d-flex align-items-center"
                        onClick={promoBlur}
                      >
                        <h6 className="text-nowrap phone-p2 ">確認輸入</h6>
                      </div>
                    </div>

                    {selectedPromoCode && promostate && promoinfo && (
                      <p className="pt-1 primary-deep3">
                        {promoinfo.promoTitle}
                      </p>
                    )}
                    {promoerrorInfo && (
                      <p className="pt-1  danger">{promoerrorInfo}</p>
                    )}
                  </div>
                )}
                {/* 折價券的顯示 */}
                {selectedOptionIndex === 1 &&
                  selectedOptionIndex === index &&
                  ecouponData &&
                  ecouponData.length !== 0 && (
                    <div className="px-0 ps-sm-1 col">
                      <select
                        name="ecoupon"
                        id="ecoupon"
                        className="form-select "
                        // defaultValue=""
                        value={selectedEcoupon} // Use the selectedEcoupon state
                        onChange={(e) => {
                          const selectedOption = e.target.value;
                          setSelectedEcoupon(selectedOption); // Update selectedEcoupon state
                          const selectedEcoupon = ecouponData.find(
                            (item) => item.ecouponNo === selectedOption
                          );
                          if (selectedEcoupon) {
                            setSelectedEcouponData({
                              ecouponNo: selectedEcoupon.ecouponNo,
                            });
                            const discountName = `${
                              selectedEcoupon.title
                            }(可折抵${
                              selectedEcoupon.ecouponPrice
                            }元，到期時間: ${new Date(
                              selectedEcoupon.newedate
                            ).toLocaleDateString()})`;
                            setDiscountName(discountName);
                            setDiscountPrice(selectedEcoupon.ecouponPrice);
                          }
                        }}
                      >
                        <option
                          className="form-select-option "
                          value=""
                          disabled
                        >
                          --請選擇折價券--
                        </option>
                        {ecouponData &&
                          ecouponData.map((item, index) => (
                            <option
                              key={index}
                              className="form-select-option"
                              value={item.ecouponNo || ""}
                            >
                              {item.title}(可折抵{item.ecouponPrice}
                              元，到期時間:
                              {new Date(item.newedate).toLocaleDateString()})
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                {/* 紅利點數的顯示 */}
                {selectedOptionIndex === 2 &&
                  selectedOptionIndex === index &&
                  pointData.length !== 0 && (
                    <div className="px-0 ps-sm-1 col">
                      <select
                        name="memberpoint"
                        id="memberpoint"
                        className="form-select"
                        value={selectedPoint} // Use the selectedPoint state
                        onChange={(e) => {
                          const selectedOption = e.target.value;
                          console.log("selectedPoints", "selectedOption");
                          setSelectedPoint(selectedOption); // Update selectedPoint state
                          setSelectedPointData({
                            memberUsePoint: selectedOption,
                          });
                          const discountName = `紅利${selectedOption}點 折抵: ${selectedOption}元`;
                          setDiscountName(discountName);
                          setDiscountPrice(selectedOption);
                        }}
                      >
                        <option
                          className="form-select-option"
                          value=""
                          disabled
                        >
                          --請選擇欲折紅利點數--
                        </option>
                        {pointData.map((item, index) => (
                          <option
                            key={index}
                            className="form-select-option"
                            value={item || ""}
                          >
                            紅利{item}點 折抵: ${item}元
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
              </div>
            ))}
        </div>
        <div className="d-flex flex-column align-items-end">
          <div className="btn-section d-flex gap-10px">
            <div
              className="btn primary-btn-big w-100  py-3"
              onClick={closePopup}
            >
              <h6 className="font-weight-bold">取消</h6>
            </div>
            <div
              className="btn primary-btn-big w-100  py-3"
              onClick={() => {
                confirmSelect();
              }}
            >
              <h6 className="font-weight-bold ">確認選擇</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CouponPopup;
