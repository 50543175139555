import React, { useState, useRef, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import OnePageCartList from "./OnePageCartList";
import Loading from "../Loading";
import Nodata from "../../sharedComponents/Nodata";

function OnePageCart({onCartChange = cartData=>{}}) {
  const [cartData, setCartData] = useState([]);
  const [noLoginupdate, setNoLoginUpdate] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [totalPrice, setTotalPrice] = useState(0);
  const [discounttotalPrice, setDiscountTotalPrice] = useState(0);

  const [totalWeight, setTotalWeight] = useState(0);
  const dispatch = useDispatch();
  const localStorageCartData = localStorage.getItem("onepagecart");
  const selectedOption = useSelector((state) => state.product.productId);
  console.log("購物車有新資料", "selectedOption");
  //刪除的處理
  const handleDeleteItem = async (
    prodid,
    skuid,
    processid,
    quantity,
    orderitemId,
    descript
  ) => {
    setIsLoading(true);
    const localStorageCartData =
      JSON.parse(localStorage.getItem("onepagecart")) || [];
      const updatedCart = cartData.filter((item) => {
        let match = item.prodid === prodid && item.skuid === skuid;

        // 是否有 processid
        if (processid && item.processid) {
          // 有傳遞 processid,判斷是否匹配
          match =
            item.prodid === prodid &&
            item.skuid === skuid &&
            item.processid === processid;
        } else if (!processid && !item.processid) {
          // 沒傳遞 processid,且 item 也沒有,则匹配
          match =
            item.prodid === prodid &&
            item.skuid === skuid 

        }

        return !match;
      });

      const updatedLocalCart = localStorageCartData.filter((item) => {
        let match = false;

        if (processid && item.processid) {
          match =
            item.prodid === prodid &&
            item.skuid === skuid &&
            item.processid === processid;
        } else if (!processid && !item.processid) {
          match =
            item.prodid === prodid &&
            item.skuid === skuid 
        }

        return !match;
      });
    localStorage.setItem("onepagecart", JSON.stringify(updatedLocalCart));
    console.log("localStorage.setItem", "JSON.stringify(updatedLocalCart)");

    setCartData(updatedCart);
    setIsLoading(false);

    // 使用所需的参数调用deleteProduct函数
    // dispatch(deleteProduct({ prodid, skuid, processid }));
  };
  const handleDecreaseItem = (
    prodid,
    skuid,
    processid,
    quantity,
    orderitemId,
    descript
  ) => {
    handleQuantityChange(prodid, skuid, processid, -1);
  };

  const handleIncreaseItem = (
    prodid,
    skuid,
    processid,
    quantity,
    orderitemId,
    descript
  ) => {
    // 更新购物车数据，触发重新渲染

    handleQuantityChange(prodid, skuid, processid, 1);
  };
  const handleQuantityChange = (prodid, skuid, processid, change) => {
    console.log("未登入的handleQuantityChange");
    setIsLoading(true);

    const updatedCart = JSON.parse(localStorage.getItem("onepagecart")) || [];
    updateUnAuthEditData(updatedCart, prodid, skuid, processid, null, change);

    setIsLoading(false);
  };
  const updateUnAuthEditData = (
    updatedCart,
    prodid,
    skuid,
    processid,
    quantity,
    change
  ) => {
    const updatedItemIndex = updatedCart.findIndex(
      (item) =>
        item.prodid === prodid &&
        item.skuid === skuid &&
        (item.processid === processid || (!item.processid && !processid))
    );

    if (updatedItemIndex !== -1) {
      const updatedItem = updatedCart[updatedItemIndex];

      if (change) {
        updatedItem.quantity += change; // 更新数量
      } else {
        updatedItem.quantity = quantity; // 设置数量
      }

      if (updatedItem.quantity <= 0) {
        updatedCart.splice(updatedItemIndex, 1);
      }

      // localStorage.setItem("onepagecart", JSON.stringify(updatedCart));
      console.log("updatedCart", "updatedCart");

      localStorage.setItem("onepagecart", JSON.stringify(updatedCart));
      setNoLoginUpdate(updatedCart);
    }
  };
  const handleChangeItem = (
    prodid,
    skuid,
    processid,
    quantity,
    orderitemId,
    descript
  ) => {
    // 更新购物车数据，触发重新渲染
    {
      console.log("未登入的handleQuantityChange");
      setIsLoading(true);

      const updatedCart = JSON.parse(localStorage.getItem("onepagecart")) || [];
      updateUnAuthEditData(
        updatedCart,
        prodid,
        skuid,
        processid,
        quantity,
        null
      );

      setIsLoading(false);
    }
  };
   //未登入的初始資料
   useEffect(() => {

      console.log("未登入的localStorageCartData", "localStorageCartData");
      if (localStorageCartData) {
        try {
          const cartItems = JSON.parse(localStorageCartData);
          const requestData = {
            orderItems: cartItems, // 将解析后的数据放入数组
          };
          axios
            .post(`/api/Onepage/GetOPshoppingCart`, requestData, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              if (response.status === 200) {
                const localCart = response.data;
                console.log("未登入的onepagecartCart:", "localCart");
                console.log("未登入的onepagecartcartItems:", "cartItems");
                console.log("未登入的onepagecartloginCart在這:", "localCart.orderItems");
                setCartData(localCart);
              } else {
                console.log("Request failed:", "response");
                // Token is invalid, log out the user
              }
            })
            .catch((error) => {
              console.error("Error:", "error");
              console.error("requestData:", "requestData");
            })
            .finally(() => {
              setIsLoading(false); // Mark loading as finished regardless of success or error
            });
        } catch (error) {
          console.error("Error parsing localStorageCartData:", "error");
          // 不加401
        }
      
      setIsLoading(false);
    }
  }, [localStorageCartData, selectedOption, noLoginupdate]);

  //改變的資訊傳回父層
  useEffect(()=>{
onCartChange(cartData);
  },[cartData])

  return (
    <div className="checkout-content">
      {cartData.length === 0 && (
        <Nodata blockdescript={"目前尚無選購商品，請至上方選購商品。"} />
      )}
      {cartData &&
        !isLoading &&
        cartData.map((item, index) => (
          <OnePageCartList
            key={index}
            cartitem={item}
            onDelete={() =>
              handleDeleteItem(
                item.prodid,
                item.skuid,
                item.processid,
                item.quantity,
                item.orderitemId,
                item.itemdesc
              )
            }
            onDecrease={() =>
              handleDecreaseItem(
                item.prodid,
                item.skuid,
                item.processid,
                item.quantity,
                item.orderitemId,
                item.itemdesc
              )
            }
            onQuantityChange={(newquantity) => {
              console.log("newquantity", "newquantity");
              handleChangeItem(
                item.prodid,
                item.skuid,
                item.processid,
                newquantity,
                item.orderitemId,
                item.itemdesc
              );
            }}
            onIncrease={() =>
              handleIncreaseItem(
                item.prodid,
                item.skuid,
                item.processid,
                item.quantity,
                item.orderitemId,
                item.itemdesc
              )
            }
          />
        ))}
    </div>
  );
}

export default OnePageCart;
