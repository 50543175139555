import React from "react";
import axios from "axios";

function CheckoutShop({ shop, type, cvsData, stepThreeMethod }) {
  let shopName = "";
  let logisticsSubType = "";


  if (shop === 3) {
    shopName = "7-ELEVEN取貨";
    logisticsSubType = "UNIMARTC2C";
  } else if (shop === 4) {
    shopName = "全家取貨";
    logisticsSubType = "FAMIC2C";
  } else if (shop === 5) {
    shopName = "萊爾富取貨";
    logisticsSubType = "HILIFEC2C";
  } else if (shop === 6) {
    shopName = "OK超商取貨";
    logisticsSubType = "OKMARTC2C";
  }

  const resetCvsData = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/Order/ResetCVSInfo`)
        .then((response) => {
          if (response.status === 200) {
            console.log("CVSInfo:", "response.data");
            resolve(response.data); // Resolve the promise with the response data
          } else {
            console.log("CVSInfo failed:", "response");
            reject(response); // Reject the promise with the response object
          }
        })
        .catch((error) => {
          console.error("Error:", "error");
          reject(error); // Reject the promise with the error object
        });
    });
  };

  const createHiddenInput = (form, name, value) => {
    const hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = name;
    hiddenField.value = value;
    form.appendChild(hiddenField);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      await resetCvsData(); // Wait for resetCvsData to complete before proceeding
      sessionStorage.setItem("shopid", shop);
      sessionStorage.setItem("areatype", type);
  
      const form = document.createElement("form");
      form.action = "https://logistics.ecpay.com.tw/Express/map";
      form.method = "POST";
      form.style.display = "none";
      createHiddenInput(form, "MerchantID", "3395689");
      createHiddenInput(form, "LogisticsType", "CVS");
      createHiddenInput(form, "LogisticsSubType", logisticsSubType);
      createHiddenInput(form, "IsCollection", "N");
      createHiddenInput(
        form,
        "ServerReplyURL",
        `${window.location.protocol}//${window.location.host}/api/Order/Logistics`
      );
  
      document.body.appendChild(form);
      form.submit();
    } catch (error) {
      // Handle errors if needed
      console.error("Error in handleSubmit:", "error");
    }
  };

  //   const getData = (e) => {
  //     axios
  //       .get("https://localhost:44420/api/Order/CVSInfo")
  //       .then((response) => {
  //         console.log(response.data);
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //       });
  //   };
  console.log("cvsData","cvsData")
  if (cvsData && !cvsData.cvsInfo) {
    if(cvsData.cvsInfo === null){
      sessionStorage.removeItem("shopid");
      sessionStorage.removeItem("areatype");

      // clearCvsData()
      // navigate("/checkout");
      
    }else{
      const infoParts = cvsData.split("///");
      if (infoParts.length === 4) {
        const [shopinfo, shopcode, shopname, shopaddress] = infoParts;
        const csvInfo = {
          shopinfo,
          shopcode,
          shopname,
          shopaddress,
        };
        console.log("Formatted cvsInfo:", "csvInfo");
  
        return (
          <>
  
            <div >
              {/* {shopName} */}
  
              <h5>{stepThreeMethod}</h5>
            </div>
  
            <div>
              {cvsData && (
                <div className="primary-deep2">
                  <p>門市名稱：{csvInfo.shopname}</p>
                  <p>門市地址：{csvInfo.shopaddress}</p>
                </div>
              )}
            </div>
          </>
        );
      }
    }
    
  }
  if (cvsData && cvsData.cvsInfo) {
    const infoParts = cvsData.cvsInfo.split("///");
    if (infoParts.length === 4) {
      const [shopinfo, shopcode, shopname, shopaddress] = infoParts;
      const csvInfo = {
        shopinfo,
        shopcode,
        shopname,
        shopaddress,
      };
      console.log("Formatted cvsInfo:", "csvInfo");

      return (
        <div className="py-4 d-flex flex-column gap-1">
          <div className="form-label d-flex align-items-center">
          <h6 className="text-bold primary-deep2">{shopName}</h6>
          </div>
          <div>
            {cvsData && (
              <div className="primary-deep2">
                <p>已選擇門市店號：{csvInfo.shopcode}</p>
                <p>已選擇門市名稱：{csvInfo.shopname}</p>
                <p>門市地址：{csvInfo.shopaddress}</p>
              </div>
            )}
          </div>
          <div className="btn primary-btn w-100 " onClick={handleSubmit}>
            變更門市
          </div>
        </div>
      );
    }
  }

  return (
    <>
      <div className="form-label d-flex align-items-center">
        <h6 className="text-bold primary-deep2">{shopName}</h6>
        
        <h6 className="primary-deep2">選擇門市：</h6>
        {cvsData ? <h6 className="primary-deep2">選擇門市</h6> : <></>}
      </div>
      <div className="btn primary-btn w-100 " onClick={handleSubmit}>
        搜尋門市
      </div>
    </>
  );
}

export default CheckoutShop;
