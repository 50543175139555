import React from "react";

function Pagination({ currentPage, totalPages, onPageChange }) {
  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    if (totalPages <= 7) {
      return pageNumbers.map((pageNum) => (
        <div
          key={pageNum}
          onClick={() => onPageChange(pageNum)}
          className={currentPage === pageNum ? "selected" : "cursor-pointer"}
        >
          <h6>{pageNum}</h6>
        </div>
      ));
    } else {
      const pageElements = [];
      const visiblePages = [1, totalPages - 1, totalPages];

      for (let i = 1; i <= totalPages; i++) {
        if (i === 1 || i === 2 || i === totalPages || i === totalPages - 1) {
          pageElements.push(
            <div
              key={i}
              onClick={() => onPageChange(i)}
              className={currentPage === i ? "selected" : "cursor-pointer"}
            >
              <h6>{i}</h6>
            </div>
          );
        } else if (
          (i >= currentPage - 2 && i <= currentPage + 2) ||
          visiblePages.includes(i)
        ) {
          pageElements.push(
            <div
              key={i}
              onClick={() => onPageChange(i)}
              className={currentPage === i ? "selected" : "cursor-pointer"}
            >
              <h6>{i}</h6>
            </div>
          );
        } else if (
          (i === currentPage - 3 && !visiblePages.includes(currentPage - 2)) ||
          (i === currentPage + 3 && !visiblePages.includes(currentPage + 2))
        ) {
          pageElements.push(
            <div key={i} className="dots">
              <h6>...</h6>
            </div>
          );
        }
      }
      return pageElements;
    }
  };

  const isPreviousDisabled = currentPage === 1;
  const isNextDisabled = currentPage === totalPages;

  return (
    <div className="container d-flex pagination justify-content-center primary-deep align-items-center">
      <div
        onClick={() => {
          if (!isPreviousDisabled) {
            onPageChange(currentPage - 1);
          }
        }}
        className={`cursor-pointer  ${isPreviousDisabled ? "disabled" : ""} `}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="14"
          viewBox="0 0 17 14"
          fill="none"
        >
          <path
            d="M8.07737 6.875C8.07737 7.03943 8.1082 7.19892 8.16986 7.35348C8.23152 7.50803 8.31373 7.63629 8.4165 7.73823L14.0891 13.4109C14.3152 13.637 14.603 13.75 14.9524 13.75C15.3018 13.75 15.5895 13.637 15.8156 13.4109C16.0417 13.1848 16.1547 12.897 16.1547 12.5476C16.1547 12.1982 16.0417 11.9105 15.8156 11.6844L11.0062 6.875L15.8156 2.06558C16.0417 1.8395 16.1547 1.55176 16.1547 1.20236C16.1547 0.852953 16.0417 0.565212 15.8156 0.339128C15.5895 0.113044 15.3018 5.38583e-07 14.9524 5.2331e-07C14.603 5.08037e-07 14.3152 0.113044 14.0891 0.339128L8.4165 6.01177C8.29318 6.13509 8.20562 6.26869 8.15383 6.41256C8.10203 6.55643 8.07655 6.71058 8.07737 6.875Z"
            fill="#364F38"
          ></path>
          <path
            d="M7.92069e-06 6.875C7.9135e-06 7.03942 0.030838 7.19892 0.0924972 7.35347C0.154156 7.50803 0.236369 7.63629 0.339134 7.73823L6.01178 13.4109C6.23786 13.637 6.52561 13.75 6.87501 13.75C7.22441 13.75 7.51215 13.637 7.73824 13.4109C7.96432 13.1848 8.07736 12.897 8.07736 12.5476C8.07736 12.1982 7.96432 11.9105 7.73824 11.6844L2.92882 6.875L7.73824 2.06558C7.96432 1.8395 8.07736 1.55176 8.07736 1.20235C8.07736 0.852952 7.96432 0.56521 7.73824 0.339126C7.51215 0.113042 7.22441 -7.22737e-07 6.87501 -7.3801e-07C6.52561 -7.53283e-07 6.23786 0.113042 6.01178 0.339126L0.339134 6.01177C0.215816 6.13509 0.128259 6.26868 0.076465 6.41256C0.0246712 6.55643 -0.000814195 6.71058 7.92069e-06 6.875Z"
            fill="#364F38"
          ></path>
        </svg>
      </div>
      <div className="d-flex flex-wrap justify-content-center">
        {renderPageNumbers()}
      </div>

      <div
        onClick={() => {
          if (!isNextDisabled) {
            onPageChange(currentPage + 1);
          }
        }}
        className={`cursor-pointer ${isNextDisabled ? "disabled" : ""}`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="14"
          viewBox="0 0 17 14"
          fill="none"
        >
          <path
            d="M8.07735 6.875C8.07735 7.03943 8.04652 7.19892 7.98486 7.35348C7.9232 7.50803 7.84099 7.63629 7.73823 7.73823L2.06558 13.4109C1.8395 13.637 1.55176 13.75 1.20235 13.75C0.852951 13.75 0.565209 13.637 0.339125 13.4109C0.113041 13.1848 -3.72837e-08 12.897 -5.25566e-08 12.5476C-6.78294e-08 12.1982 0.113041 11.9105 0.339125 11.6844L5.14854 6.875L0.339125 2.06558C0.113041 1.8395 -5.33202e-07 1.55176 -5.48475e-07 1.20236C-5.63748e-07 0.852953 0.113041 0.565212 0.339124 0.339128C0.565208 0.113044 0.852951 5.38583e-07 1.20235 5.2331e-07C1.55175 5.08037e-07 1.8395 0.113044 2.06558 0.339128L7.73823 6.01177C7.86155 6.13509 7.9491 6.26869 8.0009 6.41256C8.05269 6.55643 8.07818 6.71058 8.07735 6.875Z"
            fill="#364F38"
          ></path>
          <path
            d="M16.1547 6.875C16.1547 7.03942 16.1239 7.19892 16.0622 7.35347C16.0006 7.50803 15.9184 7.63629 15.8156 7.73823L10.1429 13.4109C9.91686 13.637 9.62912 13.75 9.27972 13.75C8.93031 13.75 8.64257 13.637 8.41649 13.4109C8.1904 13.1848 8.07736 12.897 8.07736 12.5476C8.07736 12.1982 8.1904 11.9105 8.41649 11.6844L13.2259 6.875L8.41649 2.06558C8.1904 1.8395 8.07736 1.55176 8.07736 1.20235C8.07736 0.852952 8.1904 0.56521 8.41649 0.339126C8.64257 0.113042 8.93031 -7.22737e-07 9.27972 -7.3801e-07C9.62912 -7.53283e-07 9.91686 0.113042 10.1429 0.339126L15.8156 6.01177C15.9389 6.13509 16.0265 6.26868 16.0783 6.41256C16.1301 6.55643 16.1555 6.71058 16.1547 6.875Z"
            fill="#364F38"
          ></path>
        </svg>
      </div>
    </div>
  );
}

export default Pagination;
