import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import AuthContext from "../../Context/AuthContext";
import Loading from "../Loading";
import ForgotPwd from "../ForgotPwd";
import ResetPwdSection from "../../sharedComponents/ResetPwdSection";

function MemberPw() {
  const [memberType, setMemberType] =useState("")
  const [phone, setPhone] =useState("");
  const [email, setEmail] =useState("");
  //取得會員資料
  const { myAuth } = useContext(AuthContext);
// console.log("myAuth",myAuth)
  //密碼變更
useEffect(()=>{
  setMemberType(myAuth.memberType)
  if(myAuth.mobile){
    setPhone(myAuth.mobile)
  }
  if(myAuth.email){
    setEmail(myAuth.email)
  }
  
},[])
 

  return (
    <div className="container member-content ps-xxl-5 table-container">
      <div className="login-title pt-4 pb-2 pt-xxl-3 pb-xxl-2">
        <h5>重設密碼</h5>
      </div>
      <ResetPwdSection state={memberType} MemberPhone={phone} MemberEmail={email}/>

     
    </div>
  );
}

export default MemberPw;
