//TWZipCode.js
import { useEffect, useState } from "react";
import { countries, townships, postcodes } from "./data-townships";

export default function TWZipCode({
  initPostcode = "",
  onPostcodeChange = (country, township, postcode) => {},
  onAddressChange = (address) => {},
  itemAddress = "",
  selectedShipArea = "",
  isTWZipCodeValid = (isValid) => {},
}) {
  //console.log(countries, townships, postcodes)

  // 記錄陣列的索引值，預設值是-1，相當於"請選擇xxx"
  const [countryIndex, setCountryIndex] = useState(-1);
  const [townshipIndex, setTownshipIndex] = useState(-1);

  // 郵遞區號使用字串(數字字串)
  const [postcode, setPostcode] = useState("");
  const [address, setAddress] = useState("");
  const [hasAddressChanged, setHasAddressChanged] = useState(false);

  //詳細地址
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);

  const handleAddressChange = (e) => {
    const newValue = e.target.value;
    setAddress(newValue);
    console.log("changename");

    if (newValue !== itemAddress) {
      console.log("已經修改姓名請記得儲存");
      setHasAddressChanged(true); // 名稱已經修改
    } else {
      setHasAddressChanged(false); // 名稱還原成原始值
    }
  };
  const isValidAddress = (address) => {
    const addressRegex = /^[A-Za-z0-9\u4e00-\u9fa5]+$/;
    return address.trim() !== "" && addressRegex.test(address);
  };
  const handleQuickFillAddress = () => {
    // 只有在名稱還沒修改的情況下才填充名稱
    if (!hasAddressChanged) {
      setAddress(itemAddress || "");
    }
  };
  // 地址的部分利用傳入時的initPostcode初始化用
  useEffect(() => {
    if (initPostcode) {
      setPostcode(initPostcode);
      // 使用initPostcode尋找對應的countryIndex, townshipIndex
      for (let i = 0; i < postcodes.length; i++) {
        for (let j = 0; j < postcodes[i].length; j++) {
          if (postcodes[i][j] === initPostcode) {
            setCountryIndex(i);
            setTownshipIndex(j);
            return; // 跳出巢狀for迴圈
          }
        }
      }
    }
  }, [initPostcode]);

  // 當countryIndex, townshipIndex均有值時，設定postcode值
  useEffect(() => {
    if (countryIndex > -1 && townshipIndex > -1) {
      setPostcode(postcodes[countryIndex][townshipIndex]);
    }
  }, [countryIndex, townshipIndex]);

  // 當使用者改變的countryIndex, townshipIndex，使用onPostcodeChange回傳至父母元件
  useEffect(() => {
    if (postcode && postcode !== initPostcode) {
      onPostcodeChange(
        countries[countryIndex],
        townships[countryIndex][townshipIndex],
        postcode
      );
    }
  }, [postcode]);
  //
  useEffect(() => {
    if (address && address !== itemAddress) {
      onAddressChange(address);
    }
  }, [address]);
  //子元件格式是否合法
  useEffect(() => {
    if (
      countryIndex !== -1 &&
      townshipIndex !== -1 &&
      postcode !== "" &&
      address !== "" &&
      hasAddressChanged
    ) {
      isTWZipCodeValid(true);
      //不能為空才回傳
    } else {
      isTWZipCodeValid(false);
    }
  }, [countryIndex, townshipIndex, postcode, address]);
  const errorInput = (hasChanged, isValid, id, errormessage) => (
    <div
      className={`error-message ${
        hasChanged && !isValid ? "visible" : "invisible"
      } d-flex pb-0`}
      id={id}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="19"
        viewBox="0 0 21 19"
        fill="none"
        className="pt-1"
      >
        <path
          d="M0.98903 18.5C0.805697 18.5 0.63903 18.454 0.48903 18.362C0.33903 18.27 0.222364 18.1493 0.139031 18C0.0556972 17.85 0.00969715 17.6873 0.00103049 17.512C-0.00763618 17.3367 0.0383638 17.166 0.139031 17L9.38903 1C9.48903 0.833333 9.61836 0.708333 9.77703 0.625C9.9357 0.541667 10.098 0.5 10.264 0.5C10.4307 0.5 10.5934 0.541667 10.752 0.625C10.9107 0.708333 11.0397 0.833333 11.139 1L20.389 17C20.489 17.1667 20.535 17.3377 20.527 17.513C20.519 17.6883 20.473 17.8507 20.389 18C20.3057 18.15 20.189 18.271 20.039 18.363C19.889 18.455 19.7224 18.5007 19.539 18.5H0.98903ZM2.71403 16.5H17.814L10.264 3.5L2.71403 16.5ZM10.264 15.5C10.5474 15.5 10.785 15.404 10.977 15.212C11.169 15.02 11.2647 14.7827 11.264 14.5C11.264 14.2167 11.168 13.979 10.976 13.787C10.784 13.595 10.5467 13.4993 10.264 13.5C9.9807 13.5 9.74303 13.596 9.55103 13.788C9.35903 13.98 9.26336 14.2173 9.26403 14.5C9.26403 14.7833 9.36003 15.021 9.55203 15.213C9.74403 15.405 9.98136 15.5007 10.264 15.5ZM10.264 12.5C10.5474 12.5 10.785 12.404 10.977 12.212C11.169 12.02 11.2647 11.7827 11.264 11.5V8.5C11.264 8.21667 11.168 7.979 10.976 7.787C10.784 7.595 10.5467 7.49933 10.264 7.5C9.9807 7.5 9.74303 7.596 9.55103 7.788C9.35903 7.98 9.26336 8.21733 9.26403 8.5V11.5C9.26403 11.7833 9.36003 12.021 9.55203 12.213C9.74403 12.405 9.98136 12.5007 10.264 12.5Z"
          fill="#DC3545"
        />
      </svg>
      <p className="ps-1 danger">{errormessage}</p>
    </div>
  );
  return (
    <>
      <div className="input-group mb-2 input-gap">
        <select
          value={countryIndex}
          onChange={(e) => {
            // 將字串轉成數字
            setCountryIndex(+e.target.value);
            // 重置townshipIndex的值
            setTownshipIndex(-1);
            // 重置postcode的值
            setPostcode("");
          }}
          className="form-input"
        >
          <option className="form-select-option" value="-1">
            選擇縣市
          </option>
          {countries.map((value, index) => (
            <option key={index} value={index} className="form-select-option">
              {value}
            </option>
          ))}
        </select>
        <select
          value={townshipIndex}
          onChange={(e) => {
            // 將字串轉成數字
            setTownshipIndex(+e.target.value);
          }}
          className="form-input"
        >
          <option className="form-select-option" value="-1">
            選擇區域
          </option>
          {countryIndex > -1 &&
            townships[countryIndex].map((value, index) => (
              <option key={index} value={index}>
                {value}
              </option>
            ))}
        </select>
        <h6 className="primary-deep2 pt-1">{postcode}</h6>
      </div>
      <input
        type="text"
        id="address"
        name="address"
        className={`form-input ${itemAddress ? "prev-input-data" : ""}`}
        pattern=".{2,}"
        placeholder={itemAddress || "請輸入地址"}
        onChange={handleAddressChange}
        value={address}
        onClick={handleQuickFillAddress} // 點擊時填充姓名
      />
      {errorInput(
        hasAddressChanged,
        isValidAddress(address),
        "error-address",
        "收件人地址不得為空"
      )}
    </>
  );
}
