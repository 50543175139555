import React from 'react'
import { Link } from 'react-router-dom'

function PwdLink({state}) {
  return (
    <Link to="/forgotpwd" className="text-decoration-underline" state={state}>
            <p>忘記密碼</p>
          </Link>
  )
}

export default PwdLink