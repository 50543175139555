//CouponCollection.js
import React, { useEffect, useState, useContext, useMemo } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../Context/AuthContext";
import axios from "axios";
import Loading from "./Loading";
import ProductCard from "../sharedComponents/ProductCard";
import Pagination from "../sharedComponents/Pagination";
import Popup from "../sharedComponents/Popup";
import Nodata from "../sharedComponents/Nodata";
function CouponCollection() {
  //頁碼
  const [currentPage, setCurrentPage] = useState(1); //頁碼初始值
  const itemsPerPage = 16; //一頁有幾個

  //畫面載入
  const [isLoading, setIsLoading] = useState(true);

  //被選中的愛心
  const [selectedhearts, setSelectedhearts] = useState({});
  //篩選出來的封面圖
  const [pcCoverImg, setPcCoverImg] = useState("");
  const [mbCoverImg, setMbCoverImg] = useState("");
  //篩選出來的全部商品
  const [products, setProducts] = useState([]);

  const [noPage, setNoPage] = useState(false);

  const navigate = useNavigate();
  const { myAuth } = useContext(AuthContext);
  //來自URL的slug
  const { couponType, couponSlug, searchWord } = useParams();

  console.log("type是初始的路由", "couponType");
  console.log("couponSlug是初始的路由", "couponSlug");

  //取得過濾後的資料
  const fetchData = () => {
    if (couponType && couponSlug) {
      axios
        .post("api/Product/ActivityProd", {
          ActivityType: couponType,
          ActivitySlug: couponSlug,
        })
        .then((response) => {
          console.log("Fetched data after filtering:", response.data);
          setProducts(response.data.prodData);
          setPcCoverImg(response.data.activityImg);
          // setPcCoverImg(null);
          setMbCoverImg(response.data.activityImg_m);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", "error");
          setNoPage(true);
          // Log a message for when products are not found
          // if (error.response && error.response.status === 404) {
          //   console.log("error.response");
          // }
        });
    } else if (searchWord) {
      axios
        .post("api/Product/KeywordSearch", {
          prodkeyword: searchWord,
        })
        .then((response) => {
         console.log("Fetched data after filtering:", response.data);
          setProducts(response.data); // 直接將回應賦值給 products
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", "error");
          setNoPage(true);
          // Log a message for when products are not found
          // if (error.response && error.response.status === 404) {
          //   console.log("error.response");
          // }
        });
    }
  };

  // 分頁後的產品資料
  const paginatedProducts = useMemo(() => {
    if(products){
      // console.log("products", products)
      const firstIndex = (currentPage - 1) * itemsPerPage;
      const lastIndex = currentPage * itemsPerPage;
  
      return products?.slice(firstIndex, lastIndex) || [];
    }

  }, [currentPage, products]);
  useEffect(() => {
    if (couponType && couponSlug) {
      fetchData();
    } else if (searchWord) {
      fetchData();
    } else {
      console.log("例外");
    }
  }, [couponType, couponSlug, searchWord]);
  // 登入按下愛心之後要重新取得收藏資訊
  useEffect(() => {
    if (myAuth.authorized) {
      const newSelectedhearts = {};
      console.log("heartData", "myAuth.heartData");
      if (myAuth.heartData) {
        myAuth.heartData.forEach((item) => {
          newSelectedhearts[item.pid] = true;
        });
        setSelectedhearts(newSelectedhearts);
      }
    }
  }, [myAuth.heartData]);

  //頁碼改變的時候要回到最上面
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("currentPage", "currentPage");
  }, [currentPage]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {noPage && (
        <Popup
          onNevigate={() => {
            setNoPage(false);
            navigate("/");
          }}
          onClose={() => {
            setNoPage(false);
          }}
          icon={"notice"}
          title={"活動不存在或已結束"}
          orangebtn={"回到首頁"}
          greenbtn={""}
        />
      )}
            {searchWord && products && products.length<1 && (
          <div className="container px-md-0">
            <Nodata
            descript={"找不到商品"}
            link={"../../collections"}
            linkDescript={"查看所有商品"}
                    />
          </div>
      )}

      {!noPage && paginatedProducts && (
        <div>
          {couponType && couponSlug && (
            <>
              {pcCoverImg === "" ? (
                <Loading />
              ) : pcCoverImg === null ? (
                <></>
              ) : (
                <div className="container-md news-hero px-0 px-md-auto mb-2 mb-xxl-5 d-none d-xxl-block">
                  <img src={pcCoverImg} alt={couponSlug} title={couponSlug} />
                </div>
              )}

              {mbCoverImg === "" ? (
                <Loading />
              ) : mbCoverImg === null ? (
                <></>
              ) : (
                <div className="container-md news-hero px-0 px-md-auto mb-2 mb-xxl-5 d-block d-xxl-none">
                  <img src={mbCoverImg} alt={couponSlug} title={couponSlug} />
                </div>
              )}
              <div className="container productcate px-md-0">
                <h3>{couponSlug}</h3>
              </div>
            </>
          )}

          <div>
            <div className="container productcate-content px-0 px-md-auto">
              <div className="row row-cols-2 row-cols-md-4">
                {paginatedProducts && paginatedProducts.length > 0 && paginatedProducts.map((product) => (
                  <ProductCard
                    key={product.prodId}
                    prodId={product.prodId}
                    prodImage={product.prodImage}
                    prodName={product.prodName}
                    prodSlug={product.prodSlug}
                    prodIcon={product.prodIcon}
                    selectedheart={selectedhearts[product.prodId] || false}
                  />
                ))}
              </div>
            </div>
            <div className="container px-md-0">
              {products && products.length > 0 &&  (
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(products.length / itemsPerPage)}
                  onPageChange={setCurrentPage}
                />
              ) }
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CouponCollection;
