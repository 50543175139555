import React from 'react'
function ProductTitle({ title }) {
    return (
      <div className="product-title pt-4 pb-0 pt-xxl-0 pb-xxl-4">
        <h4 className='phone-h6'>{title}</h4>
      </div>
    );
  }
  

export default ProductTitle