import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createStore } from 'redux';
import { initialState, exampleReducer, fetchProductData } from '../redux/reducers/productReducer';

function TestProdRedux() {

  const { productSlug } = useParams();
  const store = createStore(exampleReducer, initialState);
  const [productData, setProductData] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
const [quantity, setQuantity] = useState(1);

  // Subscribe to store updates and update local state when the store changes
  const subscribeToStore = () => {
    const unsubscribe = store.subscribe(() => {
      const currentState = store.getState();
      setProductData(currentState.productData);
      setSelectedSize(Object.keys(currentState.productData.sizePrices)[0]); // Set selectedSize
      setSelectedColor(Object.keys(currentState.productData.colorPrices)[0]);
    });

    return unsubscribe;
  };

  useEffect(() => {
    subscribeToStore();
    // Dispatch an action to fetch product data based on the productSlug
    store.dispatch({ type: 'FETCH_PRODUCT_DATA', productSlug });
  }, [productSlug]);

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };
  
  const handleColorChange = (event) => {
    setSelectedColor(event.target.value);
  };
  const incrementQuantity = () => {
    setQuantity(quantity + 1);
  };
  
  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  const addToCart = () => {
    const cartItem = {
      productName: productData.productName,
      selectedSize,
      selectedColor,
      quantity,
    };
  
    // Get the existing cart from session or initialize an empty cart
    const existingCart = JSON.parse(sessionStorage.getItem('cart')) || [];
  
    // Check if the same item is already in the cart
    const existingCartItemIndex = existingCart.findIndex(
      (item) =>
        item.productName === cartItem.productName &&
        item.selectedSize === cartItem.selectedSize &&
        item.selectedColor === cartItem.selectedColor
    );
  
    if (existingCartItemIndex !== -1) {
      // If the same item exists in the cart, update its quantity
      existingCart[existingCartItemIndex].quantity += cartItem.quantity;
    } else {
      // If the item doesn't exist in the cart, add it
      existingCart.push(cartItem);
    }
  
    // Update the cart in session storage
    sessionStorage.setItem('cart', JSON.stringify(existingCart));
  
    // Reset the quantity to 1
    setQuantity(1);
  
    // Optional: Provide user feedback that the item was added to the cart
  };
  
  

  if (!productData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Product Details</h1>
      <p>Product Name: {productData.productName}</p>
      <p>
  單價: {productData.originPrice +
    (selectedSize ? productData.sizePrices[selectedSize] : 0) +
    (selectedColor ? productData.colorPrices[selectedColor] : 0)}
</p>

      {Object.keys(productData.sizePrices).length > 0 && (
        <div>
          {Object.keys(productData.sizePrices).map((size) => (
            <label key={size}>
              <input
                type="radio"
                value={size}
                checked={size === selectedSize}
                onChange={handleSizeChange}
              />
              {size}
            </label>
          ))}
        </div>
      )}
      {Object.keys(productData.colorPrices).length > 0 && (
        <div>
          {Object.keys(productData.colorPrices).map((color) => (
            <label key={color}>
              <input
                type="radio"
                value={color}
                checked={color === selectedColor}
                onChange={handleColorChange}
              />
              {color}
            </label>
          ))}
        </div>
      )}
      <div>
  {/* ... Other product details */}
  <p>Quantity: {quantity}</p>
  <button onClick={decrementQuantity}>-</button>
  <button onClick={incrementQuantity}>+</button>
  <button onClick={addToCart}>Add to Cart</button>
</div>
<div>
  購物車
</div>
      {/* Display other product details */}
    </div>
  );
}

export default TestProdRedux;
