import React, { useState, useRef, useEffect, useContext } from "react";

function OnePageOrderProd({cartitem}) {

  const [isLoading, setIsLoading] = useState(false);

  const [subTotal, setSubTotal] = useState(null);
  const [subProcessingPrice, setSubProcessingPrice] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);


  //數量的更新
  const [newquantity, setNewQuantity] = useState(cartitem.quantity);
  console.log("item","cartitem")
  const prodImage = cartitem?.prodImg;
  const descript = cartitem?.itemdesc || cartitem?.itemDesc;
  const processingData = cartitem?.processingData || cartitem.processing;
  const unit = cartitem.skuData?.unit || cartitem.unit;
  const unitPieces = cartitem.skuData?.unitPieces || cartitem.unitPieces;
  const unitPrice = cartitem.skuData?.unitPrice || cartitem.unitPrice;
  const quantity = cartitem.quantity;
  useEffect(() => {
    if (subTotal) {
      const total = subProcessingPrice + subTotal;
      setTotalPrice(total);
    }
  }, [subTotal, subProcessingPrice]);
  useEffect(() => {
    if (cartitem) {

      const quantity = cartitem.quantity;

      setNewQuantity(quantity);
      //針對訂單的popup特別處理
      const calculatedUnitPrice = cartitem.unitPrice

      // 檢查 cartitem.quantity 和 unitPrice 是否為 null、undefined 或 0
      let subtotal = null;
      let subProcessingPrice = null;
      if (
        cartitem.quantity == null ||
        calculatedUnitPrice == null ||
        cartitem.quantity === 0 ||
        calculatedUnitPrice === 0
      ) {
        subtotal = "請洽客服人員";
        subProcessingPrice = "請洽客服人員";
      } else {
        subtotal = cartitem.quantity * calculatedUnitPrice;
        if (processingData) {
          // 计算 subProcessingPrice
          subProcessingPrice =
            processingData.reduce((acc, item) => {
              return acc + item.unitPrice;
            }, 0) *
            unitPieces *
            cartitem.quantity;
        }
      }

      setSubTotal(subtotal);
      setSubProcessingPrice(subProcessingPrice);
      setIsLoading(false);
    }
  }, [cartitem]);
  return (
    <div className="checkout-list">
    <div className="checkout-img">
    <img src={prodImage} alt="" />
    </div>
    <div className="checkout-descript primary-deep2">
    
      <div className="checkout-title">
        <h6>{descript}</h6>
        
      </div>
      <div className="checkout-number">
        <h6 className="p-xxl">數量：{cartitem.quantity}{unit}({unitPieces}個/{unit})</h6>
      </div>
      <div className="checkout-price promo-text">
        <h5>總價</h5>
        <h4>${totalPrice && totalPrice.toLocaleString()}</h4>
      </div>
    </div>
   
  </div>
  )
}

export default OnePageOrderProd