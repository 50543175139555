import React, { useEffect, useState } from "react";
import Loading from "../Loading";
import DOMPurify from "dompurify";
import FormattedDate from "../../sharedComponents/FormattedDate";

function InvoiceIndex({ invoiceSlug, invoiceData, storeData, loadTime }) {
  const [haspaid, setHaspaid] = useState(null);

  const sanitizedHTML = DOMPurify.sanitize(
    storeData ? storeData.footerInfo : ""
  );
  const sanitizedMBHTML = DOMPurify.sanitize(
    storeData ? storeData.footerMInfo : ""
  );
  //替換 <h6> 標籤
  const modifiedHTML = sanitizedHTML.replace(/<h6>/g, '<h6 class="p1">');
  const modifiedMBHTML = sanitizedMBHTML.replace(/<h6>/g, '<h6 class="p1">');

  const paymentClick = ()=>{
    if (invoiceData.paymentStatus && invoiceData.paymentStatus == "1") {
      window.location.href = invoiceData.b2CLink;
    }
  }
  const iconElement = (<svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 80 80"
    fill="none"
  >
    <path
      d="M40 0C17.9087 0 0 17.9087 0 40C0 62.0913 17.9087 80 40 80C62.0913 80 80 62.0913 80 40C80 17.9087 62.0913 0 40 0ZM40 8.6914C57.2913 8.6914 71.3037 22.7131 71.3037 40C71.3037 57.287 57.2913 71.3037 40 71.3037C22.7087 71.3037 8.69627 57.287 8.69627 40C8.69633 22.7131 22.7087 8.6914 40 8.6914ZM55.3271 21.0059L32.1435 44.1943L24.624 36.6748L17.2461 44.0479L24.7656 51.5674L32.1923 58.9941L39.5655 51.6162L62.7539 28.4326L55.3271 21.0059Z"
      fill="#FF5C26"
    />
  </svg>)
    

  
  useEffect(() => {
    if (invoiceData.paymentStatus && invoiceData.paymentStatus == "1") {
      setHaspaid(false);
      //未付款
    } else if (invoiceData.paymentStatus && invoiceData.paymentStatus == "2") {
      setHaspaid(true);
      //已付款
    }
  }, [invoiceData.paymentStatus]);


  if (!storeData || !invoiceData) {
    return <Loading />;
  }
  return (
    <div className="h-100vh secondary-light2-bg invoive-index pb-5">
      <div className="container pt-4 pt-xxl-5 pb-3 d-flex flex-wrap">
        <h4 className="pe-2">{storeData.storename} </h4>
        <h4>電子帳單</h4>
      </div>
      <div className="container d-flex flex-column flex-lg-row gap-3 ">
        <div className="invoice-order d-flex flex-column ">
          <div className="section-card container-fluid px-4 py-4 d-flex flex-column  gap-4 pb-5">
            <div className="container-fluid d-flex flex-column flex-sm-row pt-2 gap-3 px-0 justify-content-between">
              <div className="d-flex flex-column gap-3">
                <div className="navbar-logo me-0 d-xxl-none ">
                  {storeData && storeData.headerMLogo && (
                    <img src={storeData.headerMLogo} alt="" />
                  )}
                </div>
                <div className="navbar-logo me-0 d-none d-xxl-block">
                  {storeData && storeData.headerLogo && (
                    <img src={storeData.headerLogo} alt="" />
                  )}
                </div>
                <div
                  className="d-none d-xxl-block store-info d-flex gap-2 flex-column  justify-content-between p-0"
                  dangerouslySetInnerHTML={{ __html: modifiedHTML }}
                />
                <div
                  className="d-block d-xxl-none store-info d-flex gap-2 flex-column  justify-content-between p-0"
                  dangerouslySetInnerHTML={{ __html: modifiedMBHTML }}
                />
              </div>
              <div className=" d-flex gap-2 invoice-info flex-column align-items-end justify-content-between p-0">
                <div className=" d-flex gap-1 flex-column align-items-end ">
                  {invoiceData.invoiceno && (
                    <h6 className="p1">
                      電子帳單號碼 : {invoiceData.invoiceno}
                    </h6>
                  )}
                  {invoiceData.invoiceDate && (
                    <div className="d-flex">
                      <h6 className="p1">開票 : </h6>
                      <FormattedDate
                        date={invoiceData.invoiceDate}
                        fontclass={"p1"}
                      />
                    </div>
                  )}

                  {invoiceData.invoiceDate && (
                    <div className="d-flex">
                      <h6 className="p1">付款期限 : </h6>
                      <FormattedDate
                        date={invoiceData.deadline}
                        fontclass={"p1"}
                      />
                    </div>
                  )}
                </div>
                {invoiceData.prodPrice !== null && invoiceData.invoiceTax !== null&& (
                <div className="d-flex gap-0 flex-column align-items-end ">
                    <h4 className="phone-h6 ">應付款項</h4>
                    <h3 className="phone-h6 ">NT${(
                            invoiceData.invoiceTax + invoiceData.prodPrice
                          ).toLocaleString()}</h3>

                  </div>
                    )}

              </div>
            </div>
            <div className="container-fluid d-flex flex-column gap-3 px-0">
              <h6>商品</h6>
              <div className="invoice-border p-3 d-flex flex-column gap-2">
                <div className="d-flex justify-content-between">
                  {invoiceData.prodname && (
                    <h6 className="p">{invoiceData.prodname}</h6>
                  )}
                  {invoiceData.prodPrice !== null && (
                    <h6 className="p">
                      NT${invoiceData.prodPrice.toLocaleString()}
                    </h6>
                  )}
                </div>
                <div className="d-flex flex-column gap-1">
                  {invoiceData.proddesc && (
                    <h6 className="p1">{invoiceData.proddesc}</h6>
                  )}
                </div>
              </div>
              <div className="border-bottom-light pb-1 gap-2 d-flex justify-content-end">
                <div className="price-section d-flex flex-column gap-1">
                {invoiceData.prodPrice !== null && (
                  <div className={`${invoiceData.invoiceTax ===0  ? "border-bottom-light pb-1":""}  d-flex justify-content-between`}>
                    <h6 className="p1">小計</h6>
                   
                      <h6 className="p1">
                        NT${invoiceData.prodPrice.toLocaleString()}
                      </h6>
                    
                  </div>)}
                  {invoiceData.invoiceTax !==0    && (
                  <div className="border-bottom-light pb-1 d-flex justify-content-between">
                    <h6 className="p1">稅金</h6>
                    
                      <h6 className="p1">
                        NT${invoiceData.invoiceTax.toLocaleString()}
                      </h6>
                    
                  </div>)}
                  <div className="d-flex justify-content-between pt-1">
                    <h6 className="p1">總計</h6>
                    {invoiceData.invoiceTax !== null &&
                      invoiceData.prodPrice !== null && (
                        <h6 className="p1">
                          NT$
                          {(
                            invoiceData.invoiceTax + invoiceData.prodPrice
                          ).toLocaleString()}
                        </h6>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid px-0 border-bottom-light d-flex flex-column gap-1 pb-2">
              <h6>給客戶的賣家附註</h6>
              {invoiceData.remark !== null && (
                <h6 className="p1">{invoiceData.remark}</h6>
              )}
            </div>
          </div>
        </div>

        <div className="invoice-payment d-flex flex-column gap-3">
          {/* {loadTime && (
            <div className="section-card  p-4  container-fluid ">
              <h6 className="p1">最近更新日期：{loadTime}</h6>
            </div>
          )} */}

          <div className="section-card container-fluid px-0  d-flex flex-column gap-0 ">
            <div className="border-bottom-light p-4 d-flex justify-content-between align-items-center">
              <h6>應付金額：</h6>
              {invoiceData.invoiceTax !== null &&
                      invoiceData.prodPrice !== null && (
                        <h5 >
                          NT$
                          {(
                            invoiceData.invoiceTax + invoiceData.prodPrice
                          ).toLocaleString()}
                        </h5>
                      )}
            </div>
            {haspaid === null ? (
              <Loading height={"h-auto pt-4"} />
            ) : haspaid === true ? (
              <div className="p-4 d-flex gap-1 justify-content-start align-items-center">
               <div className="text-center primary-deep-svg ">{iconElement}</div>
                <h6 className="p">已付款</h6>
              </div>
            ) : haspaid === false  && invoiceData.b2CLink!==null ? (
              <div className="p-4 d-flex flex-column gap-3 justify-content-between">
                <h6 className="p">選擇付款方式：</h6>
                <div className="btn primary-btn2 text-center" onClick={paymentClick}>
                  <h6 className="p1">信用卡</h6>
                </div>
              </div>
            ) : (
              <Loading height={"h-auto pt-4"} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceIndex;
