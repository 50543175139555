import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import AuthContext from "../Context/AuthContext";
import Loading from "../components/Loading";
function ThirdPartyFinish() {
 //導入authcontext
 const {cartDataTologin} =useContext(AuthContext)
 const navigate = useNavigate();
     const connectData = () => {
        axios
          .post("/api/User/SSOLoginFinish", {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            const responseData = response.data;
            console.log("回傳的SSOLoginFinish", "responseData");
            cartDataTologin(responseData)
          })
          .catch((error) => {
            console.error("SSOLoginFinish有錯誤：", "error");

            console.error(
              "寄送SSOLoginFinish出現的錯誤訊息",
              "error.response.data.message"
            );
            navigate("/")
          });
      };
      useEffect(()=>{
        connectData()
      },[])
    return (
        <>
<Loading/>
        </>
    
  )
}

export default ThirdPartyFinish