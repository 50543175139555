//MemberCollection
import React, { useState, useContext } from "react";
import Pagination from "../../sharedComponents/Pagination";
import AuthContext from "../../Context/AuthContext";
import { Link } from "react-router-dom";
import Nodata from "../../sharedComponents/Nodata";
import axios from "axios";

function MemberCollection() {
  const [currentPage, setCurrentPage] = useState(1); //頁碼初始值
  const itemsPerPage = 16; //一頁有幾個

  const { myAuth, getHeartData, logout } = useContext(AuthContext);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const deletefavorite = async (prodId) => {
    const mid = parseInt(myAuth.sid);
    const pid = parseInt(prodId);
    const requestData = {
      mid: mid,
      pid: pid,
    };


    const storedToken = myAuth.token;
    
    axios
      .post(`/api/Member/DeleteFavorite`, requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("response Data:", "response.data");
          getHeartData(storedToken, mid);
        } else {
          console.log("Request failed:", "response");
          // Token is invalid, log out the user
        }
      }).catch((error) => {
        console.error("Error:", "error");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      })
    
    }
  console.log("heartData", "myAuth.heartData");
  const displayedData =
    myAuth.heartData && myAuth.heartData.slice(startIndex, endIndex);
  return (
    <div className="container member-content ps-xxl-5">
      <div className="login-title pt-4 pb-2 pt-xxl-3 pb-xxl-2 member-like-title">
        <h5>我的收藏</h5>
        <div className="d-flex primary-deep3 pt-3 pt-xxl-0">
          <h5>收藏數量：</h5>
          <h5 className="pe-2">{myAuth.heartamount}</h5>
          <h5>/ 50（最多50筆）</h5>
        </div>
      </div>

    <div className="video-responsive">


    </div>



      {myAuth.heartamount > 0 ? (
        <>
  <div className="row g-xxl-0 row-cols-2 row-cols-md-4 member-like">
    {displayedData && displayedData.length > 0 && displayedData.map((value, index) => (
      <div className="col product-card" key={index}>
        <Link to={`/product/${value.prodSlug}`}>
          <img src={value.prodImage} className="w-100" alt="" />
        </Link>
        <div className="product-title">
          <h6>{value.prodName}</h6>
          <div className="delete" onClick={() => deletefavorite(value.pid)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="21"
              viewBox="0 0 18 21"
              fill="none"
            >
                    <path
                      d="M17.25 4.0696H13.5V3.30037C13.5 2.68833 13.2629 2.10136 12.841 1.66858C12.419 1.23581 11.8467 0.992676 11.25 0.992676H6.75C6.15326 0.992676 5.58097 1.23581 5.15901 1.66858C4.73705 2.10136 4.5 2.68833 4.5 3.30037V4.0696H0.75C0.551088 4.0696 0.360322 4.15064 0.21967 4.2949C0.0790178 4.43916 0 4.63482 0 4.83883C0 5.04284 0.0790178 5.2385 0.21967 5.38276C0.360322 5.52702 0.551088 5.60806 0.75 5.60806H1.5V19.4542C1.5 19.8622 1.65804 20.2536 1.93934 20.5421C2.22064 20.8306 2.60218 20.9927 3 20.9927H15C15.3978 20.9927 15.7794 20.8306 16.0607 20.5421C16.342 20.2536 16.5 19.8622 16.5 19.4542V5.60806H17.25C17.4489 5.60806 17.6397 5.52702 17.7803 5.38276C17.921 5.2385 18 5.04284 18 4.83883C18 4.63482 17.921 4.43916 17.7803 4.2949C17.6397 4.15064 17.4489 4.0696 17.25 4.0696ZM6 3.30037C6 3.09636 6.07902 2.9007 6.21967 2.75644C6.36032 2.61218 6.55109 2.53114 6.75 2.53114H11.25C11.4489 2.53114 11.6397 2.61218 11.7803 2.75644C11.921 2.9007 12 3.09636 12 3.30037V4.0696H6V3.30037ZM15 19.4542H3V5.60806H15V19.4542ZM7.5 9.45421V15.6081C7.5 15.8121 7.42098 16.0077 7.28033 16.152C7.13968 16.2962 6.94891 16.3773 6.75 16.3773C6.55109 16.3773 6.36032 16.2962 6.21967 16.152C6.07902 16.0077 6 15.8121 6 15.6081V9.45421C6 9.2502 6.07902 9.05455 6.21967 8.91029C6.36032 8.76603 6.55109 8.68498 6.75 8.68498C6.94891 8.68498 7.13968 8.76603 7.28033 8.91029C7.42098 9.05455 7.5 9.2502 7.5 9.45421ZM12 9.45421V15.6081C12 15.8121 11.921 16.0077 11.7803 16.152C11.6397 16.2962 11.4489 16.3773 11.25 16.3773C11.0511 16.3773 10.8603 16.2962 10.7197 16.152C10.579 16.0077 10.5 15.8121 10.5 15.6081V9.45421C10.5 9.2502 10.579 9.05455 10.7197 8.91029C10.8603 8.76603 11.0511 8.68498 11.25 8.68498C11.4489 8.68498 11.6397 8.76603 11.7803 8.91029C11.921 9.05455 12 9.2502 12 9.45421Z"
                      fill="#364F38"
                    />
                   </svg>
          </div>
        </div>
      </div>
    ))}
  </div>
  {displayedData && displayedData.length > 0 && 
   <div className="container px-md-0">
   <div className="pagination justify-content-center primary-deep">
     <Pagination
       currentPage={currentPage} // 傳遞 currentPage
       totalPages={Math.ceil(myAuth.heartamount / itemsPerPage)} // 計算 totalPages
       onPageChange={setCurrentPage} // 傳遞頁數變化處理函式
     />
   </div>
 </div>
  }
       </>
) : (
  <Nodata
    descript={"目前沒有收藏資料"}
    link={"../../collections"}
    linkDescript={"去逛逛"}
  />
)}
      

    </div>
  );
}

export default MemberCollection;
