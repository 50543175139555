import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import HeroSlider from "./HeroSlider";
import RenderHtml from "../sharedComponents/RenderHtml";
import CollectionCard from "../sharedComponents/CollectionCard";
import NewsCardNodate from "../sharedComponents/NewsCardNodate";
import axios from "axios";

function Home() {
  const [apiBanner, setApiBanner] = useState(null);
  const [apiSection, setApiSection] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    axios
      .get(`api/Home/Banner`)
      .then((response) => {
        if (response.status === 200) {
          console.log("Banner"," response.data");
          return response.data;
        }
        return null;
      })
      .then((data) => {
        if (data) {
          setApiBanner(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching banner data from API:", "error");
      });
  }, []);

  useEffect(() => {
    axios
      .get(`api/Home/Section`)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return null;
      })
      .then((data) => {
        if (data) {
          setApiSection(data);
          console.log("setApiSection", "data");
        }
      })
      .catch((error) => {
        console.error("Error fetching section data from API:", "error");
      });
  }, []);

  useEffect(() => {
    if (apiBanner) {
      const timer = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % apiBanner.length);
      }, 6000);

      return () => clearInterval(timer);
    }
  }, []);

  const goToPrevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + apiBanner.length) % apiBanner.length
    );
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % apiBanner.length);
  };

  const goToSpecificSlide = (value) => {
    setCurrentIndex(value);
  };
  if (!apiBanner || !apiSection) {
    return (
      <div className="first-section">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <HeroSlider images={apiBanner} currentIndex={currentIndex} />
      {/* 嵌入的影片加上iframe，注意要是embed才不會有安全策略問題 */}
      {/* <div className="container justify-content-center">
        <div>
          <div className="logo-story text-center">
            <div className="row row-cols-1 row-cols-md-2">
              <div className="col pe-md-5">
                <div className="logo-svg"></div>
                <h3>您的專屬包裝顧問</h3>
                <div className="logo-story-content">
                  <div className="content-wrapper">
                    <p>
                      錫安包裝自2003年草創時期即秉持「品質第一、技術領先」及「回饋社會」的日式經營模式，與日本、歐美…….等國內外知名大廠技術交流合作，不斷更新設備，研發新式包裝材料，產品皆具有SGS認證及專利。
                    </p>
                  </div>
                  <div className="btn primary-btn mt-5">
                    <h6 className="mb-0">品牌故事</h6>
                  </div>
                </div>
              </div>
              <div className="col">
                <iframe
                  src={`https://www.youtube.com/embed/gSamwuyKVVM`}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    


      {apiSection.map((item, index) => {
        if (item.sectionType === "a") {
          return (
            <div className="container justify-content-center" key={index}>
              <RenderHtml content={item.content} />
            </div>
          );
        } else if (item.code === "collection") {
          return (
            <div className="container category" key={`collection-${index}`}>
              <div className="row category-row px-1 row-cols-2 row-cols-xxl-3">
                {item.content &&
                  item.content.map((value, valueIndex) => (
                    <div
                      key={`collection-${index}-${valueIndex}`}
                      className="col"
                    >
                      <CollectionCard content={value} />
                    </div>
                  ))}
              </div>
            </div>
          );
        } else if (item.code === "blog") {
          return (
            <div className="container index-news " key={`blog-${index}`}>
              <div className="news">
                {item.content &&
                  item.content.map((value, valueIndex) => (
                    <NewsCardNodate
                      key={`blog-${index}-${valueIndex}`}
                      content={value}
                    />
                  ))}
              </div>
            </div>
          );
        } else {
          return "都不是";
        }
      
      })}
    </>
  );
}

export default Home;
