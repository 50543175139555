import React, { useState, useEffect } from "react";
import MemberDataInfo from "./MemberDataInfo";
import MemberDataInvoice from "./MemberDataInvoice";
import MemberDataShipping from "./MemberDataShipping";

function MemberData() {
  
  return (
    <div className="container member-content table-container ps-xxl-5">
             <MemberDataInfo/>
             <MemberDataInvoice/>
             <MemberDataShipping/>
    </div>
  )
}

export default MemberData