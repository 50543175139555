//CartPopup.js
import React, { useState, useRef, useEffect, useContext } from "react";
import Loading from "../components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import AuthContext from "../Context/AuthContext";
import Cartlist from "./Cartlist";
import { deleteProduct } from "../redux/actions/action";
import Nodata from "./Nodata";
function CartPopup({ onClose, onNevigate }) {
  const {
    myAuth,
    updateCartData,
    updateCartAmount,
    checkExpiration,
    logout,
  } = useContext(AuthContext);
  const [cartData, setCartData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [noLoginupdate, setNoLoginUpdate] = useState();
  const [priceChange, setPriceChange] = useState([]);

  const [subProcessingPrice, setSubProcessingPrice] = useState(0);

  const [totalPrice, setTotalPrice] = useState(0);
  const [discounttotalPrice, setDiscountTotalPrice] = useState(0);

  const [totalWeight, setTotalWeight] = useState(0);
  const [isFetched, setIsFetched] = useState(false);
  const dispatch = useDispatch();
  const productId = useSelector((state) => state.product.productId);
  const localStorageCartData = localStorage.getItem("cart");
  const storedToken = localStorage.getItem("userToken");
  const storeduserMid = localStorage.getItem("userMid");

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      await checkExpiration();
      // 等待 checkExpiration 完成後再執行以下代碼

      setCartData(myAuth.cartData);
      setIsLoading(false);
    } catch (error) {
      // 處理錯誤
      console.error("Error during data fetch:", "error");
      setIsLoading(false);
    }
  };

  //登入的初始資料
  useEffect(() => {
    if (!isFetched) {
      if (myAuth.authorized) {
        fetchData();
        setIsFetched(true);
      } else {
        setIsFetched(false);
      }
    }
  }, [myAuth.authorized]);

  //未登入的初始資料
  useEffect(() => {
    if (!myAuth.authorized) {
      console.log("未登入的localStorageCartData", "localStorageCartData");
      if (localStorageCartData) {
        try {
          const cartItems = JSON.parse(localStorageCartData);
          const requestData = {
            memberid: 0,
            orderItems: cartItems, // 将解析后的数据放入数组
          };
          axios
            .post(`/api/Order/GetshoppingCart`, requestData, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              if (response.status === 200) {
                const localCart = response.data;
                console.log("未登入的loginCart:", "localCart");
                console.log("未登入的cartItems:", "cartItems");
                console.log("未登入的loginCart在這:", "localCart.orderItems");
                setCartData(localCart);
              } else {
                console.log("Request failed:", "response");
                // Token is invalid, log out the user
              }
            })
            .catch((error) => {
              console.error("Error:", "error");
              console.error("requestData:", "requestData");
            })
            .finally(() => {
              setIsLoading(false); // Mark loading as finished regardless of success or error
            });
        } catch (error) {
          console.error("Error parsing localStorageCartData:", "error");
          // 不加401
        }
      }
      setIsLoading(false);
    }
  }, [localStorageCartData, noLoginupdate]);

  //登入的cartdata更動
  useEffect(() => {
    console.log("myAuth.cartData");
    setCartData(myAuth.cartData);
    setIsLoading(false);
  }, [myAuth.cartData]);

  //刪除的處理
  const handleDeleteItem = async (
    prodid,
    skuid,
    processid,
    quantity,
    orderitemId,
    descript
  ) => {
    setIsLoading(true);
    if (myAuth.authorized) {
      const storedToken = myAuth.token;
      const orderItem = {
        orderitemId: orderitemId,
        prodid: prodid,
        skuid: skuid,
        quantity: quantity,
        itemdesc: descript,
      };

      // 只有在processid不为null时才包含它
      if (processid !== null) {
        orderItem.processid = processid;
      }

      const requestData = {
        memberid: myAuth.sid,
        orderItems: [orderItem],
      };
      axios
        .post("/api/Order/DeleteOrderitem", requestData, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            // 处理成功响应
            // 根据后端响应更新购物车数据
            updateCartData();
            updateCartAmount();
          } else {
            console.log("Request failed:", "response");
            console.log("requestData failed:", "requestData");
            // 处理请求失败的情况
          }
        })
        .catch((error) => {
          console.error("Error:", "error");
          console.error("Error requestData:", "requestData");
          // 处理错误情况
          if (error.response && error.response.status === 401) {
            // 如果是 401，觸發 logout()
            logout();
          }
        });
    } else {
      console.log("processid", "processid");
      const localStorageCartData =
        JSON.parse(localStorage.getItem("cart")) || [];
      const updatedCart = cartData.filter((item) => {
        let match = item.prodid === prodid && item.skuid === skuid;

        // 是否有 processid
        if (processid && item.processid) {
          // 有傳遞 processid,判斷是否匹配
          match =
            item.prodid === prodid &&
            item.skuid === skuid &&
            item.processid === processid;
        } else if (!processid && !item.processid) {
          // 沒傳遞 processid,且 item 也沒有,则匹配
          match =
            item.prodid === prodid &&
            item.skuid === skuid 

        }

        return !match;
      });

      const updatedLocalCart = localStorageCartData.filter((item) => {
        let match = false;

        if (processid && item.processid) {
          match =
            item.prodid === prodid &&
            item.skuid === skuid &&
            item.processid === processid;
        } else if (!processid && !item.processid) {
          match =
            item.prodid === prodid &&
            item.skuid === skuid 
        }

        return !match;
      });
      localStorage.setItem("cart", JSON.stringify(updatedLocalCart));
      console.log("localStorage.setItem", "JSON.stringify(updatedLocalCart)");

      setCartData(updatedCart);
      setIsLoading(false);
    }
    // 使用所需的参数调用deleteProduct函数
    dispatch(deleteProduct({ prodid, skuid, processid }));
  };

  const handleDecreaseItem = (
    prodid,
    skuid,
    processid,
    quantity,
    orderitemId,
    descript
  ) => {
    // 更新购物车数据，触发重新渲染
    if (myAuth.authorized) {
      handleAuthQuantityChange(
        prodid,
        skuid,
        processid,
        quantity,
        orderitemId,
        descript,
        -1
      );
    } else {
      handleQuantityChange(prodid, skuid, processid, -1);
    }
  };

  const handleIncreaseItem = (
    prodid,
    skuid,
    processid,
    quantity,
    orderitemId,
    descript
  ) => {
    // 更新购物车数据，触发重新渲染
    if (myAuth.authorized) {
      handleAuthQuantityChange(
        prodid,
        skuid,
        processid,
        quantity,
        orderitemId,
        descript,
        1
      );
    } else {
      handleQuantityChange(prodid, skuid, processid, 1);
    }
  };
  const handleChangeItem = (
    prodid,
    skuid,
    processid,
    quantity,
    orderitemId,
    descript
  ) => {
    // 更新购物车数据，触发重新渲染
    if (myAuth.authorized) {
      const newquantity = quantity;
      const orderItem = {
        orderitemId: orderitemId,
        prodid: prodid,
        skuid: skuid,
        quantity: newquantity,
        itemdesc: descript,
      };

      // 只有在processid不为null时才包含它
      if (processid !== null) {
        orderItem.processid = processid;
      }

      const requestData = {
        memberid: myAuth.sid,
        orderItems: [orderItem],
      };
      postAuthEditData(requestData);
    } else {
      console.log("未登入的handleQuantityChange");
      setIsLoading(true);

      const updatedCart = JSON.parse(localStorage.getItem("cart")) || [];
      updateUnAuthEditData(
        updatedCart,
        prodid,
        skuid,
        processid,
        quantity,
        null
      );

      setIsLoading(false);
    }
  };
  const handleAuthQuantityChange = (
    prodid,
    skuid,
    processid,
    quantity,
    orderitemId,
    descript,
    change
  ) => {
    if (quantity + change > 0) {
      const newquantity = quantity + change;
      const orderItem = {
        orderitemId: orderitemId,
        prodid: prodid,
        skuid: skuid,
        quantity: newquantity,
        itemdesc: descript,
      };

      // 只有在processid不为null时才包含它
      if (processid !== null) {
        orderItem.processid = processid;
      }

      const requestData = {
        memberid: myAuth.sid,
        orderItems: [orderItem],
      };
      postAuthEditData(requestData);
    } else {
      console.log("數量不得為0");
    }
  };
  const postAuthEditData = (requestData) => {
    const storedToken = myAuth.token;
    axios
      .post("/api/Order/EditOrderitem", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          // 处理成功响应

          const updatedCart = response.data; // 根据后端响应更新购物车数据
          console.log("EditOrderitem", "updatedCart");
          updateCartData();
        } else {
          console.log("Request failed:", "response");
          console.log("requestData failed:", "requestData");
          // 处理请求失败的情况
        }
      })
      .catch((error) => {
        console.error("Error:", "error");
        console.error("Error requestData:", "requestData");
        // 处理错误情况
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      });
  };

  const handleQuantityChange = (prodid, skuid, processid, change) => {
    console.log("未登入的handleQuantityChange");
    setIsLoading(true);

    const updatedCart = JSON.parse(localStorage.getItem("cart")) || [];
    updateUnAuthEditData(updatedCart, prodid, skuid, processid, null, change);

    setIsLoading(false);
  };

  const updateUnAuthEditData = (
    updatedCart,
    prodid,
    skuid,
    processid,
    quantity,
    change
  ) => {
    const updatedItemIndex = updatedCart.findIndex(
      (item) =>
        item.prodid === prodid &&
        item.skuid === skuid &&
        (item.processid === processid || (!item.processid && !processid))
    );

    if (updatedItemIndex !== -1) {
      const updatedItem = updatedCart[updatedItemIndex];

      if (change) {
        updatedItem.quantity += change; // 更新数量
      } else {
        updatedItem.quantity = quantity; // 设置数量
      }

      if (updatedItem.quantity <= 0) {
        updatedCart.splice(updatedItemIndex, 1);
      }

      // localStorage.setItem("cart", JSON.stringify(updatedCart));
      console.log("updatedCart", "updatedCart");

      localStorage.setItem("cart", JSON.stringify(updatedCart));
      setNoLoginUpdate(updatedCart);
    }
  };

  const findUnitPriceByQuantity = (quantity, unitPrices) => {
    if (unitPrices && unitPrices.length > 0) {
      for (let i = unitPrices.length - 1; i >= 0; i--) {
        if (quantity >= unitPrices[i].quantity) {
          return unitPrices[i].unitPrice;
        }
      }
    }

    if (unitPrices) {
      return unitPrices[0].unitPrice; // 預設單價
    }

    return null;
  };
  //根據數量查找適當的折價金額
  const findDiscountPrice = (unitPrice, discountValue) => {
    if (isNaN(unitPrice) || isNaN(discountValue)) {
      // 如果unitPrice或discountValue不是有效数字，则无法继续计算
      return "Invalid Input";
    }

    const discount = parseInt(discountValue);
    const discountedPrice = (1 - discount * 0.01) * unitPrice;

    // 通过toFixed将小数位数限制为两位
    return parseFloat(discountedPrice.toFixed(2));
  };

  //一般的總金額+總重量
  useEffect(() => {
    let total = 0;
    let subProcessing = 0;
    let subTotal = 0;
    let subWeight = 0;
    console.log("cartData", "cartData");
    if (cartData) {
      cartData.forEach((item) => {
        const unitPrice = findUnitPriceByQuantity(
          item.quantity,
          item.skuData.unitPrices
        );
        const itemSubTotal = item.quantity * unitPrice;
        subTotal += itemSubTotal;

        if (item.processingData) {
          item.processingData.forEach((processingItem) => {
            subProcessing +=
              processingItem.unitPrice *
              item.quantity *
              item.skuData.unitPieces;
          });
        }
        console.log("item", "item");
        const unitweight = item.prodWeight || 0;
        console.log("unitweight", "unitweight");
        const itemSubWeight = unitweight * item.quantity;
        subWeight += itemSubWeight;
      });
      const caculaterTotalWeight = parseFloat(subWeight.toFixed(2));
      const calculatedTotalPrice = subTotal + subProcessing;
      setTotalPrice(calculatedTotalPrice);
      console.log("caculaterTotalWeight", "caculaterTotalWeight");
      setTotalWeight(caculaterTotalWeight);
    }
  }, [cartData]);

  //折價的總金額
  useEffect(() => {
    let total = 0;
    let subProcessing = 0;
    let discountsubTotal = 0;

    cartData &&
      cartData.forEach((item) => {
        const unitPrice = findUnitPriceByQuantity(
          item.quantity,
          item.skuData.unitPrices
        );

        const discountPrice = item.discountData.discountValue
          ? findDiscountPrice(unitPrice, item.discountData.discountValue)
          : unitPrice;

        const itemdiscountsubTotal = item.quantity * discountPrice;

        discountsubTotal += itemdiscountsubTotal;

        if (item.processingData) {
          item.processingData.forEach((processingItem) => {
            subProcessing +=
              processingItem.unitPrice *
              item.quantity *
              item.skuData.unitPieces;
          });
        }
      });
    console.log("subProcessing", "subProcessing");
    const calculatedTotalPrice = discountsubTotal + subProcessing;
    if (calculatedTotalPrice === totalPrice) {
      setDiscountTotalPrice(null);
    } else {
      setDiscountTotalPrice(calculatedTotalPrice);
    }

    console.log("setDiscountTotalPrice", "calculatedTotalPrice");
  }, [cartData, totalPrice]);

  const totalQuantity =
    cartData && cartData.reduce((total, item) => total + item.quantity, 0);

  return (
    <div className="popup-cart popup-cart2">
      <div className="popup-select">
        <div className="page-title">
          <div className="d-flex">
            <h6>購物車</h6>
          </div>
          <button className="btn" onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              className="d-block d-xxl-none"
            >
              <path
                d="M13.5704 0.939986C13.474 0.843302 13.3594 0.766598 13.2332 0.714262C13.107 0.661926 12.9718 0.634987 12.8352 0.634987C12.6986 0.634987 12.5633 0.661926 12.4372 0.714262C12.311 0.766598 12.1964 0.843302 12.0999 0.939986L7 6.02947L1.90008 0.929556C1.80353 0.833 1.6889 0.756407 1.56274 0.704151C1.43659 0.651895 1.30137 0.625 1.16482 0.625C1.02827 0.625 0.893055 0.651895 0.766898 0.704151C0.640741 0.756407 0.526112 0.833 0.429556 0.929556C0.333 1.02611 0.256407 1.14074 0.204151 1.2669C0.151895 1.39305 0.125 1.52827 0.125 1.66482C0.125 1.80137 0.151895 1.93659 0.204151 2.06274C0.256407 2.1889 0.333 2.30353 0.429556 2.40008L5.52947 7.5L0.429556 12.5999C0.333 12.6965 0.256407 12.8111 0.204151 12.9373C0.151895 13.0634 0.125 13.1986 0.125 13.3352C0.125 13.4717 0.151895 13.6069 0.204151 13.7331C0.256407 13.8593 0.333 13.9739 0.429556 14.0704C0.526112 14.167 0.640741 14.2436 0.766898 14.2958C0.893055 14.3481 1.02827 14.375 1.16482 14.375C1.30137 14.375 1.43659 14.3481 1.56274 14.2958C1.6889 14.2436 1.80353 14.167 1.90008 14.0704L7 8.97053L12.0999 14.0704C12.1965 14.167 12.3111 14.2436 12.4373 14.2958C12.5634 14.3481 12.6986 14.375 12.8352 14.375C12.9717 14.375 13.1069 14.3481 13.2331 14.2958C13.3593 14.2436 13.4739 14.167 13.5704 14.0704C13.667 13.9739 13.7436 13.8593 13.7958 13.7331C13.8481 13.6069 13.875 13.4717 13.875 13.3352C13.875 13.1986 13.8481 13.0634 13.7958 12.9373C13.7436 12.8111 13.667 12.6965 13.5704 12.5999L8.47053 7.5L13.5704 2.40008C13.9668 2.00377 13.9668 1.3363 13.5704 0.939986Z"
                fill="white"
                fillOpacity="0.7"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              className="d-none d-xxl-block"
            >
              <path
                d="M24.4463 0.572701C24.2708 0.396914 24.0625 0.25745 23.8331 0.162295C23.6037 0.067139 23.3578 0.0181589 23.1094 0.0181589C22.8611 0.0181589 22.6152 0.067139 22.3858 0.162295C22.1564 0.25745 21.948 0.396914 21.7726 0.572701L12.5 9.82631L3.22743 0.553738C3.05187 0.378182 2.84345 0.238922 2.61408 0.143912C2.3847 0.0489008 2.13886 1.84979e-09 1.89058 0C1.64231 -1.84979e-09 1.39646 0.0489009 1.16709 0.143912C0.937711 0.238922 0.729295 0.378182 0.553738 0.553738C0.378182 0.729295 0.238922 0.937711 0.143912 1.16709C0.0489009 1.39646 -1.84979e-09 1.64231 0 1.89058C1.84979e-09 2.13886 0.0489008 2.3847 0.143912 2.61408C0.238922 2.84345 0.378182 3.05187 0.553738 3.22743L9.82631 12.5L0.553738 21.7726C0.378182 21.9481 0.238922 22.1565 0.143912 22.3859C0.0489009 22.6153 0 22.8611 0 23.1094C0 23.3577 0.0489009 23.6035 0.143912 23.8329C0.238922 24.0623 0.378182 24.2707 0.553738 24.4463C0.729295 24.6218 0.937711 24.7611 1.16709 24.8561C1.39646 24.9511 1.64231 25 1.89058 25C2.13886 25 2.3847 24.9511 2.61408 24.8561C2.84345 24.7611 3.05187 24.6218 3.22743 24.4463L12.5 15.1737L21.7726 24.4463C21.9481 24.6218 22.1565 24.7611 22.3859 24.8561C22.6153 24.9511 22.8611 25 23.1094 25C23.3577 25 23.6035 24.9511 23.8329 24.8561C24.0623 24.7611 24.2707 24.6218 24.4463 24.4463C24.6218 24.2707 24.7611 24.0623 24.8561 23.8329C24.9511 23.6035 25 23.3577 25 23.1094C25 22.8611 24.9511 22.6153 24.8561 22.3859C24.7611 22.1565 24.6218 21.9481 24.4463 21.7726L15.1737 12.5L24.4463 3.22743C25.1668 2.50686 25.1668 1.29327 24.4463 0.572701Z"
                fill="#364F38"
              />
            </svg>
          </button>
        </div>
        {cartData && cartData.length > 0 ? (
          <>
            <div className="page-content-title">
              <h6>商品明細</h6>
              <h6>價格 / 單價</h6>
              <h6>數量</h6>
              <h6>小計</h6>
              <h6>加購</h6>
              <h6>移除</h6>
            </div>
            <div className="page-content">
              <div className="overflow-wrap">
                <div className="overflow">
                  {/* TODO:後端發送請求列出全部商品資訊，傳遞給元件CARTLIST */}
                  {cartData && isLoading && <Loading />}
                  {cartData &&
                    !isLoading &&
                    cartData.map((item, index) => (
                      <Cartlist
                        key={index}
                        cartitem={item}
                        onDelete={() =>
                          handleDeleteItem(
                            item.prodid,
                            item.skuid,
                            item.processid,
                            item.quantity,
                            item.orderitemId,
                            item.itemdesc
                          )
                        }
                        onDecrease={() =>
                          handleDecreaseItem(
                            item.prodid,
                            item.skuid,
                            item.processid,
                            item.quantity,
                            item.orderitemId,
                            item.itemdesc
                          )
                        }
                        onQuantityChange={(newquantity) => {
                          console.log("newquantity", "newquantity");
                          handleChangeItem(
                            item.prodid,
                            item.skuid,
                            item.processid,
                            newquantity,
                            item.orderitemId,
                            item.itemdesc
                          );
                        }}
                        onIncrease={() =>
                          handleIncreaseItem(
                            item.prodid,
                            item.skuid,
                            item.processid,
                            item.quantity,
                            item.orderitemId,
                            item.itemdesc
                          )
                        }
                      />
                    ))}
                </div>
              </div>
            </div>
            <div className="popup-footer pb-xxl-0 h-auto">
              <div className="footer-bottom gap-0 d-flex flex-wrap align-items-center justify-content-between">
                <div className="product-sum">
                  <h6>購物車內共 ：</h6>
                  <h6 className="primary-deep3">{cartData.length}</h6>
                  <h6>項商品</h6>
                </div>
                <div className="product-sum pt-1 pt-lg-0">
                  <h6>購物車未稅總金額 ：</h6>
                  {discounttotalPrice ? (
                    <div className="d-flex align-items-center ">
                      <h6 className="disable text-decoration-line-through p">
                        ${totalPrice.toLocaleString()}
                      </h6>

                      <h6 className="ps-1 danger">${discounttotalPrice}</h6>
                    </div>
                  ) : (
                    <h6 className="danger">${totalPrice.toLocaleString()}</h6>
                  )}
                </div>
                <div className="product-sum pt-1 pt-lg-0 d-flex flex-column">
                  <div className="d-flex">
                    <h6>購物車內淨重 ：</h6>
                    <h6 className="primary-deep3">{totalWeight}</h6>
                    <h6>公斤</h6>
                  </div>
                  {/* <div>
                    <h6 className="p1 secondary-deep">
                      限制5公斤以下才可超商取貨
                    </h6>
                  </div> */}
                  <div className="danger d-flex align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="19"
                      viewBox="0 0 21 19"
                      fill="none"
                    >
                      <path
                        d="M0.98903 18.5C0.805697 18.5 0.63903 18.454 0.48903 18.362C0.33903 18.27 0.222364 18.1493 0.139031 18C0.0556972 17.85 0.00969715 17.6873 0.00103049 17.512C-0.00763618 17.3367 0.0383638 17.166 0.139031 17L9.38903 1C9.48903 0.833333 9.61836 0.708333 9.77703 0.625C9.9357 0.541667 10.098 0.5 10.264 0.5C10.4307 0.5 10.5934 0.541667 10.752 0.625C10.9107 0.708333 11.0397 0.833333 11.139 1L20.389 17C20.489 17.1667 20.535 17.3377 20.527 17.513C20.519 17.6883 20.473 17.8507 20.389 18C20.3057 18.15 20.189 18.271 20.039 18.363C19.889 18.455 19.7224 18.5007 19.539 18.5H0.98903ZM2.71403 16.5H17.814L10.264 3.5L2.71403 16.5ZM10.264 15.5C10.5474 15.5 10.785 15.404 10.977 15.212C11.169 15.02 11.2647 14.7827 11.264 14.5C11.264 14.2167 11.168 13.979 10.976 13.787C10.784 13.595 10.5467 13.4993 10.264 13.5C9.9807 13.5 9.74303 13.596 9.55103 13.788C9.35903 13.98 9.26336 14.2173 9.26403 14.5C9.26403 14.7833 9.36003 15.021 9.55203 15.213C9.74403 15.405 9.98136 15.5007 10.264 15.5ZM10.264 12.5C10.5474 12.5 10.785 12.404 10.977 12.212C11.169 12.02 11.2647 11.7827 11.264 11.5V8.5C11.264 8.21667 11.168 7.979 10.976 7.787C10.784 7.595 10.5467 7.49933 10.264 7.5C9.9807 7.5 9.74303 7.596 9.55103 7.788C9.35903 7.98 9.26336 8.21733 9.26403 8.5V11.5C9.26403 11.7833 9.36003 12.021 9.55203 12.213C9.74403 12.405 9.98136 12.5007 10.264 12.5Z"
                        fill="#6A7C6C"
                      />
                    </svg>
                    <h6 className="ps-1  p">
                      含氣閥商品或是總重量超過5公斤不可選擇超商取貨
                    </h6>
                  </div>
                </div>

                <div className="coupon-button-group pt-lg-0">
                  <button className="btn primary-btn3" onClick={onClose}>
                    <h6>繼續購物</h6>
                  </button>
                  <button className="btn primary-btn3" onClick={onNevigate}>
                    <h6>前往結帳</h6>
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="page-content justify-content-center align-items-center">
            <div>
              <svg
                width="192"
                height="178"
                viewBox="0 0 192 178"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M188.675 39.5603C186.966 36.5998 184.52 34.1327 181.573 32.3997C178.627 30.6666 175.282 29.7267 171.864 29.6717H45.2899L39.5545 7.32338C38.975 5.16606 37.6822 3.26849 35.8866 1.93974C34.091 0.610981 31.8984 -0.0706716 29.6659 0.00579949H9.88863C7.266 0.00579949 4.75079 1.04763 2.89631 2.90211C1.04183 4.75659 0 7.2718 0 9.89443C0 12.5171 1.04183 15.0323 2.89631 16.8867C4.75079 18.7412 7.266 19.7831 9.88863 19.7831H22.1505L49.4431 121.24C50.0226 123.398 51.3154 125.295 53.1111 126.624C54.9067 127.953 57.0993 128.634 59.3318 128.558H148.329C150.156 128.552 151.944 128.041 153.498 127.081C155.051 126.121 156.309 124.75 157.13 123.119L189.565 58.2498C190.971 55.3032 191.625 52.0543 191.47 48.7932C191.315 45.532 190.354 42.36 188.675 39.5603Z"
                  fill="#F0F5EF"
                />
                <path
                  d="M54.3837 178C62.5757 178 69.2167 171.359 69.2167 163.167C69.2167 154.975 62.5757 148.334 54.3837 148.334C46.1917 148.334 39.5508 154.975 39.5508 163.167C39.5508 171.359 46.1917 178 54.3837 178Z"
                  fill="#F0F5EF"
                />
                <path
                  d="M153.274 178C161.466 178 168.107 171.359 168.107 163.167C168.107 154.975 161.466 148.334 153.274 148.334C145.082 148.334 138.441 154.975 138.441 163.167C138.441 171.359 145.082 178 153.274 178Z"
                  fill="#F0F5EF"
                />
              </svg>
            </div>
            <p className="primary-deep2  ps-3 pt-4">你的購物車是空的</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default CartPopup;
