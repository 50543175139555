//member.js
import React, { useState, useContext, useEffect } from "react";
import { Link, Routes, Route, useLocation, Navigate } from "react-router-dom";
import AuthContext from "../Context/AuthContext";
import MemberOrder from "./member/MemberOrder"; //訂單資料
import MemberCollection from "./member/MemberCollection"; //我的收藏
import MemberCoupon from "./member/MemberCoupon"; //折價券
import MemberPoint from "./member/MemberPoint"; //紅利點數
import MemberData from "./member/MemberData"; //會員資料
import MemberPw from "./member/MemberPw"; //修改密碼
import MemberOld from "./member/MemberOld"; //登入舊會員

import Loading from "./Loading";
function Member({ state }) {
  // const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const activeTab = state.split("/").pop();
  const { myAuth, logout } = useContext(AuthContext);

  useEffect(() => {
    if (myAuth.authorized && myAuth.connectOld !== null) {
      setIsLoading(false);
    }
  }, [myAuth.authorized]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="container px-0">
      <div className="member-detail">
        <div className="container member-filter-container">
          <div className="filter-group">
            <div className="d-none d-xxl-block fiter-col">
              <h5 className="pt-4 mb-3">購物相關</h5>
            </div>
            <Link
              to="/member/order"
              className={`btn primary-btn2 ${
                activeTab === "order" ? "select" : ""
              }`}
            >
              <p>訂單資料</p>
            </Link>
            <Link
              to="/member/collection"
              className={`btn primary-btn2  ${
                activeTab === "collection" ? "select" : ""
              }`}
            >
              <p>我的收藏</p>
            </Link>
            <Link
              to="/member/coupon"
              className={`btn primary-btn2  ${
                activeTab === "coupon" ? "select" : ""
              }`}
            >
              <p>折價券</p>
            </Link>
            <Link
              to="/member/point"
              className={`btn primary-btn2  ${
                activeTab === "point" ? "select" : ""
              }`}
            >
              <p>紅利點數</p>
            </Link>
            <div className="d-none d-xxl-block fiter-col">
              <h5 className="membrt-filter mb-3">會員帳戶</h5>
            </div>
            <Link
              to="/member/data"
              className={`btn primary-btn2  ${
                activeTab === "data" ? "select" : ""
              }`}
            >
              <p>會員資料</p>
            </Link>
            <Link
              to="/member/password"
              className={`btn primary-btn2  ${
                activeTab === "password" ? "select" : ""
              }`}
            >
              <p>修改密碼</p>
            </Link>
            {myAuth.connectOld === false && (
              <Link
                to="/member/oldmember"
                className={`btn primary-btn2 oldmember-btn px-0  ${
                  activeTab === "oldmember" ? "select" : ""
                }`}
              >
                <p>登入舊會員</p>
              </Link>
            )}

            <div
              className="d-none d-xxl-flex pt-4 mb-3 btn primary-btn2 w-100"
              onClick={logout}
            >
              <h5>登出</h5>
            </div>
          </div>
        </div>

        <div className="w-100">
          <Routes>
            <Route path="/order" element={<MemberOrder />} />
            <Route path="/collection" element={<MemberCollection />} />
            <Route path="/coupon" element={<MemberCoupon />} />
            <Route path="/point" element={<MemberPoint />} />
            <Route path="/data" element={<MemberData />} />
            <Route path="/password" element={<MemberPw />} />
            {myAuth.connectOld === false && (
              <Route path="/oldmember" element={<MemberOld />} />
            )}

            {/* Redirect root path to /order */}

            <Route path="/" element={<Navigate to="/member/order" />} />
            <Route path="/*" element={<Navigate to="/member/order" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Member;
