//Collection.js
import React, { useEffect, useState, useContext, useMemo } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setFilteredTag } from "../redux/actions/action";
import AuthContext from "../Context/AuthContext";
import axios from "axios";
import Loading from "./Loading";
import ProductCard from "../sharedComponents/ProductCard";
import Pagination from "../sharedComponents/Pagination";
import Nodata from "../sharedComponents/Nodata";
function Collection() {
  //頁碼
  const [currentPage, setCurrentPage] = useState(1); //頁碼初始值
  const itemsPerPage = 16; //一頁有幾個

  //畫面載入
  const [isLoading, setIsLoading] = useState(true);

  //被選中的愛心
  const [selectedhearts, setSelectedhearts] = useState({});
  //用途/袋形/材質
  const [navCate, setNavCate] = useState([]);

  //篩選出來的全部商品
  const [products, setProducts] = useState([]);
  //下拉選單被按到的filter
  const [filterClick, setFilterClick] = useState(null);
  //來自URL的slug
  const { collectionsSlug } = useParams();

  console.log("collectionsSlug是初始的路由", collectionsSlug);


      // 下一步的disable
      const [isNextDisabled, setNextDisabled] = useState(false);

    //上面的標籤按鈕
    // const [filteredTag, setFilteredTag] = useState(null);
//redux儲存的filteredTag
const dispatch = useDispatch();
const filteredTag = useSelector(state => state.collection.filteredTag);

  const navigate = useNavigate();
  // const location = useLocation();
  const { myAuth } = useContext(AuthContext);

  //取得全部資料
  const getAllData = () => {
    axios
      .post("api/Product/CateByProd", [])
      .then((response) => {
        console.log("Fetched data after filtering:", "response.data");
        setProducts(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", "error");
        // Log a message for when products are not found
        if (error.response && error.response.status === 404) {
          console.log("error.response");
        }
      });
  };

  //取得過濾後的資料
  const fetchData = () => {
    if(isNextDisabled){
      return;
    }
    setNextDisabled(true);
    if (filteredTag.length === 0 || filteredTag=== null) {
      return; // 如果filteredTag为空，不执行操作
    }
console.log("fetchDatafilteredTag",filteredTag)
    axios
      .post("api/Product/CateByProd", filteredTag)
      .then((response) => {
        console.log("Fetched data after filtering:", "response.data");
        setProducts(response.data);
       
      })
      .catch((error) => {
        console.log("搜尋出現錯誤的fetchDatafilteredTag:", filteredTag);
        console.error("Error fetching data:", "error");
        // Log a message for when products are not found
        if (error.response && error.response.status === 404) {
          console.log("error.response");
        }
      }).finally(()=>{
        setNextDisabled(false);
        setIsLoading(false);
      }
       
      );
  };

  //下拉選單的資訊取得
  const fetchNavCate = () => {
    axios
      .get("api/Product/ProdCate")
      .then((response) => {
        setNavCate(response.data);
        console.log("setNavCate", "response.data");
      })
      .catch((error) => {
        console.error("Error fetching category data:", "error");
      });
  };

  // 分頁後的產品資料
  const paginatedProducts = useMemo(() => {
    const firstIndex = (currentPage - 1) * itemsPerPage;
    const lastIndex = currentPage * itemsPerPage;
    return products.slice(firstIndex, lastIndex);
  }, [currentPage, products]);
  //商品標籤由slug轉為名稱
  const filteredTagName = (item) =>
    navCate
      .find((category) =>
        category.children.some((child) => child.slug === item)
      )
      ?.children.find((child) => child.slug === item)?.catename;

  //下拉選單判斷是用途、袋型還是材質被點擊
  const handleFilterClick = (index) => {
    setFilterClick((prevIndex) => (prevIndex === index ? null : index));
  };

  //下拉選單中被按下的篩選
  const handleCategoryClick = (slug, catename) => {
    console.log("按下catename", catename);
    console.log("按下slug", slug);
    const updatedTags = [...filteredTag];
    if (!updatedTags.includes(slug)) {
      updatedTags.push(slug);
      setFilteredTag(updatedTags);
    } else {
      const index = updatedTags.indexOf(slug);
      if (index !== -1) {
        updatedTags.splice(index, 1);
        setFilteredTag(updatedTags);
      }
    }

    // 更新後的標籤要存回Session Storage
    dispatch(setFilteredTag(updatedTags));

    sessionStorage.setItem("collectiontag", updatedTags.join(","));
  };

  //將標籤移除
  const handleTagRemove = (tag) => {
    const updatedTags = filteredTag.filter((item) => item !== tag);
    dispatch(setFilteredTag(updatedTags));
    if (updatedTags.length === 0) {
      setFilteredTag([]);
      navigate("/collections/全部");
      sessionStorage.removeItem("collectiontag")
    } else {
      console.log("filteredTag", "filteredTag");
      //  更新後的標籤要存回Session Storage
      sessionStorage.setItem("collectiontag", updatedTags.join(","));
    }
  };
  //初次載入時要取得下拉式選單資訊，同時設定來自url的slug
  useEffect(() => {
    console.log("初次載入")
    dispatch(setFilteredTag(null));
    if (collectionsSlug === "全部") {
      dispatch(setFilteredTag([]));
      getAllData();
      // sessionStorage.removeItem("collectiontag");
    } else if (collectionsSlug !== "全部" && collectionsSlug) {
      dispatch(setFilteredTag([collectionsSlug]));
      console.log(" dispatch(setFilteredTag([collectionsSlug]));")
      // sessionStorage.setItem("collectiontag", collectionsSlug);
    } else {
      setIsLoading(true);
    }
    fetchNavCate();
  }, []);



  

  //嘗試
  //   useEffect(() => {
  //     const storedCollectionTag = sessionStorage.getItem("collectiontag");
  //     if (storedCollectionTag) {
  //       const collectionTags = storedCollectionTag.split(",");
  //       if (collectionTags.includes(collectionsSlug)) {
  //         setFilteredTag(collectionTags);
  //       } else {
  //         // 如果sessionStorage中的数据不包含URL的值，将其删除
  //         sessionStorage.removeItem("collectiontag");
  //         if (collectionsSlug === "全部") {
  //           setFilteredTag([]);
  //           getAllData();
  //           console.log("初始載入判斷url為全部", collectionsSlug);
  //           sessionStorage.removeItem("collectiontag");
  //         } else if (collectionsSlug !== "全部" && collectionsSlug) {
  //           setFilteredTag([collectionsSlug]);
  //           console.log("初始載入判斷url不是全部", collectionsSlug);
  //           sessionStorage.setItem("collectiontag", collectionsSlug);
  //         } else {
  //           setIsLoading(true);
  //         }
  //       }
  //     } 
   
  //   fetchNavCate();
  // }, []);

  //url更新的時候刷新FilteredTag
  useEffect(() => {
    if (collectionsSlug === "全部") {
      dispatch(setFilteredTag([]));
      setIsLoading(true);
    } else if (collectionsSlug !== "全部" && collectionsSlug) {
      console.log("網址的collectionsSlug",collectionsSlug)
      dispatch(setFilteredTag([collectionsSlug]));
      setIsLoading(true);
    } else {
      setIsLoading(true);
    }
  }, [collectionsSlug]);

  //被刷新的FilteredTag(由url或是使用者操作而來)
  useEffect(() => {
    console.log("現有的filteredTag", "filteredTag");
    if (filteredTag !== null) {
      if (filteredTag.length === 0) {
        getAllData();
        console.log("判斷要執行getAllData");
      } else {
        fetchData();
        console.log("判斷要執行fetchData");
      }
      setCurrentPage(1);
      console.log("filteredTag目前儲存的資訊", "filteredTag");
    }
  }, [filteredTag]);

  // 登入按下愛心之後要重新取得收藏資訊
  useEffect(() => {
    if (myAuth.authorized) {
      const newSelectedhearts = {};
      console.log("heartData", "myAuth.heartData");
      if (myAuth.heartData) {
        myAuth.heartData.forEach((item) => {
          newSelectedhearts[item.pid] = true;
        });
        setSelectedhearts(newSelectedhearts);
      }
    }
  }, [myAuth.heartData]);

  //頁碼改變的時候要回到最上面
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("currentPage", "currentPage");
  }, [currentPage]);

  if (!navCate || isLoading || !paginatedProducts) {
    return    <div className="container">
    <Loading height="h-100vh w-100vw position-absolute top-0"/>
       </div>

    
  }
  return (
    <React.Fragment>
      <div className="container productcate px-md-0">
        <h4 className="pb-3">商品</h4>
        <div className="productfilter">
          <div className="filter-select">
            {navCate && navCate.map((category, index) => (
              <div
                key={index}
                className={`${
                  filterClick === index ? "phone-filter-select" : "phone-filter"
                } filter-btn btn d-flex gap-0 gap-sm-1 gap-xxl-2`}
                onClick={() => handleFilterClick(index)}
              >
                <div className="my-0">
                  <h6 >{category.catename}</h6>
                </div>

                <svg
                  width="14"
                  height="9"
                  viewBox="0 0 14 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="show-more"
                >
                  <path
                    id="Vector"
                    d="M7 8.53866C6.83557 8.53866 6.67608 8.50783 6.52152 8.44617C6.36697 8.38451 6.23871 8.3023 6.13677 8.19953L0.464125 2.52689C0.238041 2.3008 0.125 2.01306 0.125 1.66366C0.125 1.31426 0.238041 1.02651 0.464125 0.800429C0.690209 0.574345 0.977952 0.461304 1.32735 0.461304C1.67676 0.461304 1.9645 0.574345 2.19058 0.800429L7 5.60985L11.8094 0.800429C12.0355 0.574345 12.3232 0.461304 12.6726 0.461304C13.022 0.461304 13.3098 0.574345 13.5359 0.800429C13.762 1.02651 13.875 1.31426 13.875 1.66366C13.875 2.01306 13.762 2.3008 13.5359 2.52689L7.86323 8.19953C7.73991 8.32285 7.60631 8.41041 7.46244 8.4622C7.31857 8.51399 7.16442 8.53948 7 8.53866Z"
                    fill="#364F38"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="show-less"
                  width="14"
                  height="9"
                  viewBox="0 0 14 9"
                  fill="none"
                >
                  <path
                    d="M7 0.461343C7.16442 0.461343 7.32392 0.492174 7.47847 0.553833C7.63303 0.615492 7.76129 0.697704 7.86323 0.800469L13.5359 6.47311C13.762 6.6992 13.875 6.98694 13.875 7.33634C13.875 7.68574 13.762 7.97349 13.5359 8.19957C13.3098 8.42565 13.022 8.5387 12.6726 8.5387C12.3232 8.5387 12.0355 8.42566 11.8094 8.19957L7 3.39015L2.19059 8.19957C1.9645 8.42566 1.67676 8.5387 1.32736 8.5387C0.977953 8.5387 0.690211 8.42566 0.464128 8.19957C0.238045 7.97349 0.125001 7.68575 0.125001 7.33634C0.125001 6.98694 0.238045 6.6992 0.464127 6.47311L6.13677 0.80047C6.26009 0.677151 6.39368 0.589595 6.53756 0.5378C6.68143 0.486007 6.83558 0.460521 7 0.461343Z"
                    fill="#364F38"
                  />
                </svg>
                {category.children && category.children.length > 0 && filteredTag && (
                  <div className="filter-content">
                    {category.children.map((child, childIndex) => (
                      <div
                        key={childIndex}
                        className={`cate ${
                          filteredTag.includes(child.slug) ? "selected" : ""
                        }`}
                        onClick={() =>
                          handleCategoryClick(child.slug, child.catename)
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                        >
                          <path
                            d="M5 10C4.30833 10 3.65833 9.86867 3.05 9.606C2.44167 9.34333 1.9125 8.98717 1.4625 8.5375C1.0125 8.0875 0.656333 7.55833 0.394 6.95C0.131667 6.34167 0.000333333 5.69167 0 5C0 4.30833 0.131333 3.65833 0.394 3.05C0.656667 2.44167 1.01283 1.9125 1.4625 1.4625C1.9125 1.0125 2.44167 0.656333 3.05 0.394C3.65833 0.131667 4.30833 0.000333333 5 0C5.69167 0 6.34167 0.131333 6.95 0.394C7.55833 0.656667 8.0875 1.01283 8.5375 1.4625C8.9875 1.9125 9.34383 2.44167 9.6065 3.05C9.86917 3.65833 10.0003 4.30833 10 5C10 5.69167 9.86867 6.34167 9.606 6.95C9.34333 7.55833 8.98717 8.0875 8.5375 8.5375C8.0875 8.9875 7.55833 9.34383 6.95 9.6065C6.34167 9.86917 5.69167 10.0003 5 10Z"
                            fill="#364F38"
                          />
                        </svg>
                        <p className="mb-0">{child.catename}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="filter-result">
            <h6>搜尋結果：{products && products.length}項</h6>
          </div>
        </div>

        <div className="filter-tag">
          {filteredTag && filteredTag.length !== 0  &&
            filteredTag.map((item, index) => (
              <div
                key={index}
                className="tag p-1 px-md-1 cursor-pointer"
                onClick={() => handleTagRemove(item)}
              >
                <h6>{filteredTagName(item)}</h6>
                <div className="btn px-0 ps-md-1">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      id="Vector"
                      d="M13.5704 0.439986C13.474 0.343302 13.3594 0.266598 13.2332 0.214262C13.107 0.161926 12.9718 0.134987 12.8352 0.134987C12.6986 0.134987 12.5633 0.161926 12.4372 0.214262C12.311 0.266598 12.1964 0.343302 12.0999 0.439986L7 5.52947L1.90008 0.429556C1.80353 0.333 1.6889 0.256407 1.56274 0.204151C1.43659 0.151895 1.30137 0.125 1.16482 0.125C1.02827 0.125 0.893055 0.151895 0.766898 0.204151C0.640741 0.256407 0.526112 0.333 0.429556 0.429556C0.333 0.526112 0.256407 0.640741 0.204151 0.766898C0.151895 0.893055 0.125 1.02827 0.125 1.16482C0.125 1.30137 0.151895 1.43659 0.204151 1.56274C0.256407 1.6889 0.333 1.80353 0.429556 1.90008L5.52947 7L0.429556 12.0999C0.333 12.1965 0.256407 12.3111 0.204151 12.4373C0.151895 12.5634 0.125 12.6986 0.125 12.8352C0.125 12.9717 0.151895 13.1069 0.204151 13.2331C0.256407 13.3593 0.333 13.4739 0.429556 13.5704C0.526112 13.667 0.640741 13.7436 0.766898 13.7958C0.893055 13.8481 1.02827 13.875 1.16482 13.875C1.30137 13.875 1.43659 13.8481 1.56274 13.7958C1.6889 13.7436 1.80353 13.667 1.90008 13.5704L7 8.47053L12.0999 13.5704C12.1965 13.667 12.3111 13.7436 12.4373 13.7958C12.5634 13.8481 12.6986 13.875 12.8352 13.875C12.9717 13.875 13.1069 13.8481 13.2331 13.7958C13.3593 13.7436 13.4739 13.667 13.5704 13.5704C13.667 13.4739 13.7436 13.3593 13.7958 13.2331C13.8481 13.1069 13.875 12.9717 13.875 12.8352C13.875 12.6986 13.8481 12.5634 13.7958 12.4373C13.7436 12.3111 13.667 12.1965 13.5704 12.0999L8.47053 7L13.5704 1.90008C13.9668 1.50377 13.9668 0.836298 13.5704 0.439986Z"
                      fill="#80786A"
                    />
                  </svg>
                </div>
              </div>
            ))}
        </div>
      </div>
      
        {paginatedProducts && paginatedProducts.length > 0 ? (
          <div>
          <div className="container productcate-content px-0 px-md-auto">
            <div className="row row-cols-2 row-cols-md-4">
              {paginatedProducts.map((product) => (
                <ProductCard
                  key={product.prodId}
                  prodId={product.prodId}
                  prodImage={product.prodImage}
                  prodName={product.prodName}
                  prodSlug={product.prodSlug}
                  prodIcon={product.prodIcon}
                  selectedheart={selectedhearts[product.prodId] || false}
                />
              ))}
            </div>
          </div>
          <div className="container px-md-0">
            <Pagination
              currentPage={currentPage} // 傳遞 currentPage
              totalPages={Math.ceil(products.length / itemsPerPage)} // 計算 totalPages
              onPageChange={setCurrentPage} // 傳遞頁數變化處理函式
            />
          </div>
        </div>
        ):(
          <>
          <div className="container productcate-content px-0 px-md-auto">
            <div className="container px-0">
            <Nodata
    descript={"篩選的範圍沒有商品"}
    link={"../../collections"}
    linkDescript={"查看全部商品"}
  />
            </div>
          
          </div>
         
          </>
        )}
       
        
     
    </React.Fragment>
  );
}

export default Collection;
