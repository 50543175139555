import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../Context/AuthContext";
import LoginLocalSection from "../../sharedComponents/LoginLocalSection";
import LoginForeignSection from "../../sharedComponents/LoginForeignSection";
import RegisterLocalSection from "../../sharedComponents/RegisterLocalSection";
import RegisterForeignSection from "../../sharedComponents/RegisterForeignSection";
import Setpath from "../../sharedComponents/Setpath";
import Loading from "../Loading";
import Popup from "../../sharedComponents/Popup";
function CheckoutStep1() {
  const [isLocalMember, setIsLocalMember] = useState(true);
  const [isLocalRegister, setIsLocalRegister] = useState(true);

  const [cartlength, setCartLength] = useState(null); // 设置初始值为 null
  const { myAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [noPage, setNoPage] = useState(false);
// 使用 handleNextStep 函数

  // const handleNextButtonClick = () => {
  //   handleNextStep(); // 調用 handleNextStep 函数來更新 step
  // };

  const handleLoginChange = () => {
    setIsLocalMember(!isLocalMember);
  };
  const handleRegisterChange = () => {
    setIsLocalRegister(!isLocalRegister);
  };
  useEffect(()=>{
    if (myAuth.authorized !== null) {
    if(myAuth.authorized  === true){
      console.log("myAuth.cartamount", "myAuth.cartamount");
        setCartLength(myAuth.cartamount);

    }
if(myAuth.authorized  === false){
  const cartData = localStorage.getItem("cart");
  const cartArray = cartData ? JSON.parse(cartData) : [];
  setCartLength(cartArray.length);
  console.log("cartlength", cartlength);
}
  }},[myAuth.authorized])
if(cartlength === null){
  return <Loading/>
}
if(cartlength === 0){
  return  <div className="container-fluid px-0">
  <Popup
    onNevigate={() => {
      setNoPage(false);
      navigate("/collections");
    }}
    onClose={() => {
      setNoPage(false);
    }}
    icon={"notice"}
    title={"購物車中沒有商品"}
    orangebtn={"回到商品頁"}
    greenbtn={""}
  />
</div>
}

  return (
    <>
    <Setpath/>
    <div className="container">
    <div className="checkout-step">
    <div className="step step1 selected">
        <div className="circle">
          <p>1</p>
        </div>
        <div className="step-descript">
          <p>填寫資料</p>
        </div>
      </div>
      <div className="step step2">
        <div className="circle">
          <p>2</p>
        </div>
        <div className="step-descript">
          <p>付款和運送方式</p>
        </div>
      </div>
      <div className="step step2">
        <div className="circle">
          <p>3</p>
        </div>
        <div className="step-descript">
          <p>最後確認</p>
        </div>
      </div>
      <div className="step step2">
        <div className="circle">
          <p>4</p>
        </div>
        <div className="step-descript">
          <p>購物完成</p>
        </div>
      </div>
      </div>
    <div className="container checkout-container px-0">
      <div className="container px-0">
        <div className="login-detail">
          <div className="container login-content px-0">
            <div className="container px-0">
              <div className="news-cate pt-0">
                <div
                  className={`btn news-cate-btn ${
                    isLocalMember ? "selected" : ""
                  }`}
                  onClick={handleLoginChange}
                >
                  國內會員登入
                </div>
                <div
                  className={`btn news-cate-btn ${
                    !isLocalMember ? "selected" : ""
                  }`}
                  onClick={handleLoginChange}
                >
                  國外會員登入
                </div>
              </div>
            </div>
            {isLocalMember ? (
              <LoginLocalSection title={"如果您已是會員請先登入"} />
            ) : (
              <LoginForeignSection  title={"如果您已是會員請先登入"}/>
            )}
          </div>
          <div className="container login-content px-0 ps-xl-5">
            <div className="container px-0">
              <div className="news-cate pt-0">
                <div
                  className={`btn news-cate-btn ${
                    isLocalRegister ? "selected" : ""
                  }`}
                  onClick={handleRegisterChange}
                >
                  國內會員註冊
                </div>
                <div
                  className={`btn news-cate-btn ${
                    !isLocalRegister ? "selected" : ""
                  }`}
                  onClick={handleRegisterChange}
                >
                  國外會員註冊
                </div>
              </div>
            </div>
            {isLocalRegister ? (
              <RegisterLocalSection title={"首次購物請填寫下方資料："} buttondescript={"前往結帳"}/>
            ) : (
              <RegisterForeignSection  title={"首次購物請填寫下方資料："} buttondescript={"前往結帳"}/>
            )}
          </div>
        </div>
      </div>
      </div>
      {/* <div onClick={handleNextButtonClick}>下一步</div>{" "} */}
      {/* 點擊時調用 handleNextButtonClick */}
      </div>
    </>
  );
}

export default CheckoutStep1;
