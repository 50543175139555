import React from 'react';
import { Link } from 'react-router-dom';
import FilterContent from './FilterContent';

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}.${month}.${day}`;
};


const NewsCard = ({ blogTitle, blogDescription, blogDate, imageUrl, blogSlug, blogCateSlug }) => {
  const formattedDate = blogDate ? formatDate(blogDate) : 'Loading...';
  return (
    <div className="news-card">
<Link to={`/blogs/${blogCateSlug}/${blogSlug}`}>
        <img src={`${imageUrl}`} className="w-100" alt="" />
        <div className="content">
          <h6 className="fw-bold"><FilterContent content={blogTitle} /></h6>
          <div className="descript">
            <p><FilterContent content={blogDescription} /></p>
          </div>
          <button className="btn primary-btn p-1 mt-3">
            <h6 className="mb-0">查看詳細資訊</h6>
          </button>
          <p className="p-1 pt-3 primary-deep2 ps-0 mb-0 mb-md-auto">{formattedDate}</p>
        </div>
        <div className="news-border"></div>
      </Link>
    </div>
  );
};

export default NewsCard;
