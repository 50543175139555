import React from 'react'

function FormattedDate({ date , fontclass}) {
    const formatted = new Date(date).toLocaleDateString('zh-CN', {
        year: 'numeric', 
        month: '2-digit',
        day: '2-digit'
      });
    
      return(
        <>
        <h6 className={`${fontclass}`}>{formatted}</h6>
        </> 
      )

}

export default FormattedDate