import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

function CheckOutNa({title}) {
  return (
    <div className="w-100 ">
                  <div className="pt-3 w-100 border-bottom-light"></div>
          <div className="login-title pt-3 pb-2 pt-xxl-3 pb-xxl-2 ">
            {title && <h5>無法完成註冊(收不到簡訊驗證碼)</h5>}
            {!title && <h4>無法完成註冊(收不到簡訊驗證碼)</h4>}

            <div className="pt-2 pb-3">
              <div className="d-flex flex-wrap">
                <h6 className="d-inline ">若您收不到簡訊驗證碼請查看</h6>
                <Link to="/faqs">
                  <h6 className="ps-1 primary-deep text-nowrap text-decoration-underline">
                    帳號相關問題
                  </h6>
                </Link>
                <h6>。</h6>
              </div>

              <div className="pt-3 d-flex flex-column gap-3">
                <h6 className="d-inline ">
                  您也可以使用非會員方式進行購物。
                </h6>
                
                <Link
                  to="/checkoutnotauth"
                  className={`btn ${title ? "primary-btn-big":"primary-btn6"} w-100 d-flex justify-content-center align-items-center gap-10px`}
                >
                                <h6 className={` ${title ? "font-weight-bold black":"p1 py-1"}`}>未收到驗證碼 繼續購物</h6>


                </Link>
              </div>
            </div>
          </div>
    </div>
  )
}

export default CheckOutNa