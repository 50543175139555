// ProductProcessing.js
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector} from "react-redux";
import {  selectOptionName, selectOptionSecondName, selectProcessingName } from "../../redux/selectors/selectors";

import {
  setSelectedProcessingForPrice,
  setAdditionalOptionSelected,
  setSelectedOptionName 
} from "../../redux/actions/action";
import Nodata from "../../sharedComponents/Nodata";
function ProductProcessing() {
  const [selectedProcessing, setSelectedProcessing] = useState([]);
  const [loading, setLoading] = useState(true); // 新增 loading 状态
  const dispatch = useDispatch();
  const selectedOptionForPrice = useSelector(
    (state) => state.product.selectedOptionForPrice
  );
  const selectedProcessingForPrice  = useSelector(
    (state) => state.product.selectedProcessingForPrice
  );
  const optionName = useSelector(selectOptionName);
  const optionSecondName = useSelector(selectOptionSecondName);
  const processingName = useSelector(selectProcessingName);

  const skuProcessing = selectedOptionForPrice ? selectedOptionForPrice.skuProcessing : {};
console.log("skuProcessing","skuProcessing")
  //   const skuProcessing = {
//     0
//     :   {"processingName": "單向排氣閥",
//     "unit": "片",
//     "unitPrice": 1.5},
//     1
//     :   {"processingName": "選項2",
//     "unit": "片",
//     "unitPrice": 2},
// } //多個勾選的資料情況
const handleToggleCheckbox = (processingKey, processingData) => {
  if (isSelected(processingKey)) {
    setSelectedProcessing((prevSelected) =>
      prevSelected.filter((item) => item.key !== processingKey)
    );
    dispatch(setSelectedProcessingForPrice(selectedProcessingForPrice.filter((item) => item.key !== processingKey)));
  } else {
    setSelectedProcessing((prevSelected) => [
      ...prevSelected,
      { key: processingKey, data: processingData },
    ]);
    dispatch(setSelectedProcessingForPrice([...selectedProcessingForPrice, { key: processingKey, data: processingData }]));
  }
};

const isSelected = (processingKey) => {
  return selectedProcessingForPrice.some((item) => item.key === processingKey);
};

useEffect(() => {
  setSelectedProcessing([]);
}, [selectedOptionForPrice]);

useEffect(() => {
  dispatch(setSelectedProcessingForPrice(selectedProcessing));
  const processingNames = selectedProcessing.map((item) => item.data.processingName);
  dispatch(setSelectedOptionName(optionName, optionSecondName, processingNames));
}, [dispatch, selectedProcessing]);

useEffect(() => {
  if (skuProcessing) {
    setLoading(false); // 数据加载完成后设置 loading 为 false
  }
}, [skuProcessing]);

  return (
    <>
{!loading && skuProcessing && Object.keys(skuProcessing).length > 0 && (
  <>
    <div className="select-result pt-3 pt-xxl-5">
      <div className="d-flex">
        <h6 >加購：</h6>
      </div>
    </div>
    <div className="d-flex gap-10px pt-xxl-2 pb-0 pb-xxl-0">
      {Object.keys(skuProcessing).map((processingKey, index) => (
        <div
        className="d-flex primary-deep2 align-items-end align-items-xxl-center cursor-pointer"

          // className="d-flex primary-deep2 align-items-end align-items-xxl-center mb-1 mb-xxl-5"
          key={processingKey}
          onClick={() =>
            handleToggleCheckbox(
              processingKey,
              skuProcessing[processingKey]
            )
          }
        >
          <div
            className="btn checkbox-button "

          >
            <div
              className={`checkbox-icon default ${
                isSelected(processingKey) ? "d-none" : "d-block"
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M2 18.1819C1.45 18.1819 0.979002 17.9859 0.587002 17.5939C0.195002 17.2019 -0.000664969 16.7312 1.69779e-06 16.1819V2.18189C1.69779e-06 1.63189 0.196002 1.16089 0.588002 0.768886C0.980002 0.376886 1.45067 0.18122 2 0.181886H16C16.55 0.181886 17.021 0.377887 17.413 0.769886C17.805 1.16189 18.0007 1.63255 18 2.18189V16.1819C18 16.7319 17.804 17.2029 17.412 17.5949C17.02 17.9869 16.5493 18.1826 16 18.1819H2ZM2 16.1819H16V2.18189H2V16.1819Z"
                  fill="#364F38"
                />
              </svg>
            </div>
            <div
              className={`checkbox-icon check ${
                isSelected(processingKey) ? "d-block" : "d-none"
              }`}
            > 
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M7.6 13.3819L14.65 6.33189L13.25 4.93189L7.6 10.5819L4.75 7.73189L3.35 9.13189L7.6 13.3819ZM2 18.1819C1.45 18.1819 0.979002 17.9859 0.587002 17.5939C0.195002 17.2019 -0.000664969 16.7312 1.69779e-06 16.1819V2.18189C1.69779e-06 1.63189 0.196002 1.16089 0.588002 0.768886C0.980002 0.376886 1.45067 0.18122 2 0.181886H16C16.55 0.181886 17.021 0.377887 17.413 0.769886C17.805 1.16189 18.0007 1.63255 18 2.18189V16.1819C18 16.7319 17.804 17.2029 17.412 17.5949C17.02 17.9869 16.5493 18.1826 16 18.1819H2ZM2 16.1819H16V2.18189H2V16.1819Z"
                  fill="#364F38"
                />
              </svg>
            </div>
          </div>
          <p className="pt-0 ps-1">
            {skuProcessing[processingKey]?.processingName || ''}
          </p>
         
        </div>
      ))}
      
    </div>
    <div className="processing-notice">
    <Nodata dangerdescript={"加購商品無法貨到付款、超商取貨"} icon={"dangernotice"} bgclass={"nobg gap-0 pt-0 pb-1 ps-0"}/>

    </div>
  </>
)}

    </>
  );
}

export default ProductProcessing;