

// reducers.js
const initialState = {
  selectedOption: '', // 初始化选项名称为空字符串
  selectedOptionSecond: '', // 初始化选项名称为空字符串
  selectedOptionForPrice:[],
  selectedProcessingForPrice: [],
  optionName: '', // 新增选项名称
  optionSecondName: '', // 新增第二个选项名称
  processingName: '', // 新增处理名称
  cart: [],// 新增購物車狀態
  filteredTag: null,//分類的tag
};

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SELECTED_OPTION':
      return {
        ...state,
        selectedOption: action.payload,
      };
    case 'SET_SELECTED_OPTION_SECOND':
      return {
        ...state,
        selectedOptionSecond: action.payload,
      };
      case 'SET_SELECTED_OPTION_NAME':
      return {
        ...state,
        optionName: action.payload.optionName,
        optionSecondName: action.payload.optionSecondName,
        processingName: action.payload.processingName,
      };
      case 'SET_SELECTED_OPTION_FOR_PRICE':
        return {
          ...state,
          selectedOptionForPrice: action.payload,
        };
        case 'SET_SELECTED_PROCESSING_FOR_PRICE':
          return {
            ...state,
            selectedProcessingForPrice: action.payload,
          };
      
    case 'ADD_TO_CART':
      // 將商品添加到購物車
      return {...state,
        productId: action.payload.productId,

        // 其他商品相關的數據也可以在這裡添加
      };
      case 'DELETE_PRODUCT':
      // 处理产品删除操作
      const productIdToDelete = action.payload;
      return {...state,
        productId: productIdToDelete,
      };
      // 这里可以根据你的需要处理删除操作，例如从购物车中移除该产品等
      // 更新productId状态
      case 'ADD_TO_OP_CART':
        // 將商品添加到購物車
        return {...state,
          productId: action.payload.productId,
  
          // 其他商品相關的數據也可以在這裡添加
        };
        case 'DELETE_OP_PRODUCT':
        // 处理产品删除操作
        const productOPIdToDelete = action.payload;
      return {
        ...state,
        productId: productOPIdToDelete,
      };

    default:
      return state;
  }
};

export const collectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FILTERED_TAG':
      return {
        ...state,
        filteredTag: action.payload,
      };
    // 处理其他可能的 action 类型...
    default:
      return state;
  }
};
