import React, { useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import OldMemberLogin from "../../sharedComponents/OldMemberLogin";


function MemberOld() {
  const { myAuth} = useContext(AuthContext);
  const mid = parseInt(myAuth.sid);
  
  const storedToken = myAuth.token;
  return (
    <div className="container member-content table-container ps-xxl-5">

      <OldMemberLogin islogin={true} mid={mid} storedToken={storedToken}/>
    </div>
  );
}

export default MemberOld;
