import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Popup from "../../sharedComponents/Popup";
import AuthContext from "../../Context/AuthContext";
import Loading from "../Loading";
function MemberDataInfo() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [hasNameChanged, setHasNameChanged] = useState(false); // 追蹤名稱是否已經修改
  const [hasEmailChanged, setHasEmailChanged] = useState(false);
  const { myAuth, updateMemberData, logout } = useContext(AuthContext);


  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    console.log("changeEmail");

    // 檢查名稱是否與myAuth.memberEmail不同
    if (newEmail !== myAuth.email) {
      console.log("已經修改姓名請記得儲存");
      setHasEmailChanged(true); // 名稱已經修改
    } else {
      setHasEmailChanged(false); // 名稱還原成原始值
    }
  };
  const handleNameChange = (e) => {
    const newName = e.target.value;
    setName(newName);
    console.log("changename");

    // 檢查名稱是否與myAuth.memberName不同
    if (newName !== myAuth.memberName) {
      console.log("已經修改姓名請記得儲存");
      setHasNameChanged(true); // 名稱已經修改
    } else {
      setHasNameChanged(false); // 名稱還原成原始值
    }
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    console.log("check");
    let firstInvalidInputRef = null;

    if (!isValidEmail(email)) {
      firstInvalidInputRef = document.getElementById("email");
    } else if (!isValidName(name)) {
      firstInvalidInputRef = document.getElementById("username");
    }

    if (firstInvalidInputRef) {
      firstInvalidInputRef.focus();
      return;
    }
    const mid = parseInt(myAuth.sid);
    const storedToken = myAuth.token;
    const requestData = {
      mid: mid,
      name: name,
      email: email
    };
    axios
    .post(`/api/Member/EditMemberData`, requestData, {
      headers: {
        Authorization: `Bearer ${storedToken}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        console.log("response Data:", "response.data");
        setIsPopupOpen(true);
        updateMemberData();
        // setPointData(response.data);
      } else {
        console.log("Request failed:", "response");
        // Token is invalid, log out the user
      }
    })
    .catch((error) => {
      console.error("Error:", "error");
      if (error.response && error.response.status === 401) {
        // 如果是 401，觸發 logout()
        logout();
      }
    })


    // setIsPopupOpen(true);



  };
  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };
  const isValidName = (name) => {
    const nameRegex = /^[A-Za-z0-9\u4e00-\u9fa5]+$/;
    return name.trim() !== "" && nameRegex.test(name);
  };
  const handleQuickFill = () => {
    // 只有在名稱已經修改的情況下才填充名稱
    if (!hasNameChanged) {
      setName(myAuth.memberName || "");
    }
    if (!hasEmailChanged) {
      setEmail(myAuth.email || "");
    }
  };
  // const handleQuickFillEmail = () => {
  //   if (!hasEmailChanged) {
  //     setEmail(myAuth.email || "請輸入常用信箱");
  //   }
  // };


  return (
    <>
      {isPopupOpen && (
        <Popup
          onNevigate={() => {
            setIsPopupOpen(false);
            setHasEmailChanged(false);
            setHasNameChanged(false);
          }}
          onClose={() => {
            setIsPopupOpen(false);
          }}
          icon={"check"}
          title={"已修改成功"}
          orangebtn={"確認"}
          greenbtn={""}
        />
      )}
      <div className="login-title pt-4 pb-2 pt-xxl-3 pb-xxl-2">
        <h5>會員資料</h5>
      </div>
      <form className="member-form" onSubmit={handleFormSubmit}>
        <label htmlFor="username" className="form-label d-block pt-0 ">
          <h6 className="primary-deep2">姓名*</h6>
        </label>
        <input
          type="text"
          id="username"
          name="username"
          className={`form-input ${myAuth.memberName ? "prev-input-data" : ""}`}
          onChange={handleNameChange}
          placeholder={myAuth.memberName || "請輸入姓名"}
          value={name}
          onClick={handleQuickFill} // 點擊時填充姓名
      maxLength={50}
        />
        <div
          className={`d-flex pb-0 ${hasNameChanged ? "visible" : "invisible"}`}
          id="error-username"
        >

          <p className="ps-1 secondary-deep d-none d-xxl-block">請記得點擊右側的按鈕儲存變更</p>
          <p className="ps-1 secondary-deep d-block d-xxl-none">請記得點擊下方的按鈕儲存變更</p>
        </div>
        <div
          className={`d-flex pb-0 ${
            hasNameChanged && !isValidName(name) ? "d-block " : " d-none"
          }`}
          id="error-username"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="19"
            viewBox="0 0 21 19"
            fill="none"
            className="pt-1"
          >
            <path
              d="M0.98903 18.5C0.805697 18.5 0.63903 18.454 0.48903 18.362C0.33903 18.27 0.222364 18.1493 0.139031 18C0.0556972 17.85 0.00969715 17.6873 0.00103049 17.512C-0.00763618 17.3367 0.0383638 17.166 0.139031 17L9.38903 1C9.48903 0.833333 9.61836 0.708333 9.77703 0.625C9.9357 0.541667 10.098 0.5 10.264 0.5C10.4307 0.5 10.5934 0.541667 10.752 0.625C10.9107 0.708333 11.0397 0.833333 11.139 1L20.389 17C20.489 17.1667 20.535 17.3377 20.527 17.513C20.519 17.6883 20.473 17.8507 20.389 18C20.3057 18.15 20.189 18.271 20.039 18.363C19.889 18.455 19.7224 18.5007 19.539 18.5H0.98903ZM2.71403 16.5H17.814L10.264 3.5L2.71403 16.5ZM10.264 15.5C10.5474 15.5 10.785 15.404 10.977 15.212C11.169 15.02 11.2647 14.7827 11.264 14.5C11.264 14.2167 11.168 13.979 10.976 13.787C10.784 13.595 10.5467 13.4993 10.264 13.5C9.9807 13.5 9.74303 13.596 9.55103 13.788C9.35903 13.98 9.26336 14.2173 9.26403 14.5C9.26403 14.7833 9.36003 15.021 9.55203 15.213C9.74403 15.405 9.98136 15.5007 10.264 15.5ZM10.264 12.5C10.5474 12.5 10.785 12.404 10.977 12.212C11.169 12.02 11.2647 11.7827 11.264 11.5V8.5C11.264 8.21667 11.168 7.979 10.976 7.787C10.784 7.595 10.5467 7.49933 10.264 7.5C9.9807 7.5 9.74303 7.596 9.55103 7.788C9.35903 7.98 9.26336 8.21733 9.26403 8.5V11.5C9.26403 11.7833 9.36003 12.021 9.55203 12.213C9.74403 12.405 9.98136 12.5007 10.264 12.5Z"
              fill="#DC3545"
            />
          </svg>
          <p className="ps-1 danger">使用者名稱不得為空</p>
        </div>
        <label htmlFor="email" className="form-label d-block">
          <h6 className="primary-deep2 pt-1">信箱*</h6>
        </label>
        <input
          type="email"
          id="email"
          name="email"
          className={`form-input ${myAuth.email ? "prev-input-data" : ""}`}
          onChange={handleEmailChange}
          placeholder={myAuth.email || "請輸入常用信箱"}
          value={email}
          onClick={handleQuickFill}
          minLength={5}
          maxLength={100}
        />
        <div
          className={`d-flex pb-0 ${hasEmailChanged ? "visible" : "invisible"}`}
          id="error-useremail"
        >
          
          <p className="ps-1 secondary-deep d-none d-xxl-block">請記得點擊右側的按鈕儲存變更</p>
          <p className="ps-1 secondary-deep d-block d-xxl-none">請記得點擊下方的按鈕儲存變更</p>
        </div>
        <div
          className={`d-flex pb-0 ${
            hasEmailChanged && !isValidEmail(email) ? "visible" : "invisible"
          }`}
          id="error-email"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="19"
            viewBox="0 0 21 19"
            fill="none"
            className="pt-1"
          >
            <path
              d="M0.98903 18.5C0.805697 18.5 0.63903 18.454 0.48903 18.362C0.33903 18.27 0.222364 18.1493 0.139031 18C0.0556972 17.85 0.00969715 17.6873 0.00103049 17.512C-0.00763618 17.3367 0.0383638 17.166 0.139031 17L9.38903 1C9.48903 0.833333 9.61836 0.708333 9.77703 0.625C9.9357 0.541667 10.098 0.5 10.264 0.5C10.4307 0.5 10.5934 0.541667 10.752 0.625C10.9107 0.708333 11.0397 0.833333 11.139 1L20.389 17C20.489 17.1667 20.535 17.3377 20.527 17.513C20.519 17.6883 20.473 17.8507 20.389 18C20.3057 18.15 20.189 18.271 20.039 18.363C19.889 18.455 19.7224 18.5007 19.539 18.5H0.98903ZM2.71403 16.5H17.814L10.264 3.5L2.71403 16.5ZM10.264 15.5C10.5474 15.5 10.785 15.404 10.977 15.212C11.169 15.02 11.2647 14.7827 11.264 14.5C11.264 14.2167 11.168 13.979 10.976 13.787C10.784 13.595 10.5467 13.4993 10.264 13.5C9.9807 13.5 9.74303 13.596 9.55103 13.788C9.35903 13.98 9.26336 14.2173 9.26403 14.5C9.26403 14.7833 9.36003 15.021 9.55203 15.213C9.74403 15.405 9.98136 15.5007 10.264 15.5ZM10.264 12.5C10.5474 12.5 10.785 12.404 10.977 12.212C11.169 12.02 11.2647 11.7827 11.264 11.5V8.5C11.264 8.21667 11.168 7.979 10.976 7.787C10.784 7.595 10.5467 7.49933 10.264 7.5C9.9807 7.5 9.74303 7.596 9.55103 7.788C9.35903 7.98 9.26336 8.21733 9.26403 8.5V11.5C9.26403 11.7833 9.36003 12.021 9.55203 12.213C9.74403 12.405 9.98136 12.5007 10.264 12.5Z"
              fill="#DC3545"
            />
          </svg>
          <p className="ps-1 danger">請輸入正確的信箱</p>
        </div>

        <div className="member-form-btn pt-2 pt-xxl-0 pb-3 border-bottom-light">
        <button className="btn primary-btn2" disabled={!isValidEmail(email) || !isValidName(name) || (!hasNameChanged && !hasEmailChanged)}>
  <h6 className="py-1">儲存會員資料</h6>
</button>

        </div>
      </form>
    </>
  );
}

export default MemberDataInfo;
