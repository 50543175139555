// OnePageCard.js
import React from "react";

const OnePageCard = ({ info, onCardClick }) => {
  return (
    <div className="onepage-card">
      <div className="onepage-card-head">
        <img src={info.prodImage} className="w-100" alt="" />
        <h6 className="black">{info.prodName}</h6>
      </div>
      <div className="onepage-card-content">
        {/* <div className="price text-decoration-line-through black">
          <h6 className="p1">原售價</h6>
          <h6 className="p1">$1,400</h6>
        </div> */}
        <div className="price search">
          <h6 className="p ">優惠價</h6>
          <h6 className="p ">${info.lowestprice.toLocaleString()}</h6>
        </div>
        <div
        className="btn mt-1 mt-xxl-2 primary-btn2 px-0 w-100"
        onClick={() => onCardClick(info.prodId)}
      >
        <h6 className="phone-p1">選擇尺寸/顏色</h6>
      </div>
      </div>
      
    </div>
  );
};

export default OnePageCard;
