import React, { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
function Promolist({ promolist, content }) {
  const [isLoading, setIsLoading] = useState(false);
  const [unitPrice, setUnitPrice] = useState(null); // 單價
  const [discountPrice, setDiscountPrice] = useState();

  const [subTotal, setSubTotal] = useState(null);
  const [subProcessingPrice, setSubProcessingPrice] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);

  const [discountsubTotal, setDiscountSubTotal] = useState(null);
  const [discounttotalPrice, setDiscountTotalPrice] = useState(0);
  //數量的更新
  const [newquantity, setNewQuantity] = useState(promolist.quantity);

  // 引用promolist的地方
  const [contentType, setContentType] = useState("");

  console.log("promolist", "promolist");
  const unit = promolist.skuData?.unit || promolist.unit;
  const unitPieces = promolist.skuData?.unitPieces || promolist.unitPieces;
  const processingData = promolist?.processingData || promolist.processing;
  const descript = promolist?.itemdesc || promolist?.itemDesc;
  const prodImage = promolist?.prodImg;
  const discountData = promolist?.discountData || promolist.discountData || 0;
  const prodSlug = promolist?.prodSlug || promolist.prodSlug;
  //根據數量查找適當的金額
  const findUnitPriceByQuantity = (quantity, unitPrices) => {
    if (unitPrices && unitPrices.length > 0) {
      for (let i = unitPrices.length - 1; i >= 0; i--) {
        if (quantity >= unitPrices[i].quantity) {
          return unitPrices[i].unitPrice;
        }
      }
    }

    if (unitPrices) {
      return unitPrices[0].unitPrice; // 預設單價
    }

    return null;
  };
  //根據數量查找適當的折價金額
  const findDiscountPrice = (unitPrice, discountValue) => {
    if (isNaN(unitPrice) || isNaN(discountValue)) {
      // 如果unitPrice或discountValue不是有效数字，则无法继续计算
      return "Invalid Input";
    }

    const discount = parseInt(discountValue);
    const discountedPrice = (1 - discount * 0.01) * unitPrice;

    // 通过toFixed将小数位数限制为两位
    return parseFloat(discountedPrice.toFixed(2));
  };

  useEffect(() => {
    if (promolist) {
      const unitPrices = promolist.skuData?.unitPrices || [];
      console.log("promolist.skuData", "promolist.skuData");
      const quantity = promolist.quantity;
      setNewQuantity(quantity);

      console.log("promolist.unitPrice", "promolist.unitPrice");
      //針對訂單的popup特別處理
      const calculatedUnitPrice =
        promolist.unitPrice === 0
          ? promolist.unitPrice
          : findUnitPriceByQuantity(quantity, unitPrices);

      setUnitPrice(calculatedUnitPrice);

      // 檢查 promolist.quantity 和 unitPrice 是否為 null、undefined 或 0
      let subtotal = null;
      let subProcessingPrice = null;
      if (promolist.quantity === null || calculatedUnitPrice === null) {
        subtotal = "請洽客服人員";
        subProcessingPrice = "請洽客服人員";
      } else {
        subtotal = promolist.quantity * calculatedUnitPrice;
        if (processingData) {
          // 计算 subProcessingPrice
          subProcessingPrice =
            processingData.reduce((acc, item) => {
              return acc + item.unitPrice;
            }, 0) *
            unitPieces *
            promolist.quantity;
        }
      }

      setSubTotal(subtotal);
      setSubProcessingPrice(subProcessingPrice);
      setIsLoading(false);
    }
  }, [promolist]);
  useEffect(() => {
    if (subTotal) {
      const total = subProcessingPrice + subTotal;
      setTotalPrice(total);
    }
  }, [subTotal, subProcessingPrice]);

  // 父層傳入的呈現promolisttype
  useEffect(() => {
    if (content) {
      setContentType(content);
    }
  }, [content]);

  if (!promolist) {
    return <Loading />;
  }
  return (
    <>
      {contentType === "page" && (
        <div className="d-flex gap-10px flex-column">
          <div className="d-flex gap-10px">
            <div className="d-flex flex-column d-xl-none justify-content-between product-img">
              <div className="">
                <img src={prodImage} className="w-100" alt="" />
              </div>
            </div>

            <div className="product cart-list">
              <div className="list-detail d-flex main-descript d-none d-xl-flex">
                <div  className="product-img">
                  <img src={prodImage} alt="" />
                </div>
                <div >
                  <h6 className="p text-wrap ">{descript}</h6>
                </div>
              </div>
              {/*  */}
              <div className="list-detail d-flex flex-column flex-xl-row gap-10px justify-content-center">
                <div
                  className="d-flex d-xl-none justify-content-between"
                  
                >
                  <h6 className="p pe-2 ">{descript}</h6>
                  {/* <div
                    className={`product-delete  py-1 px-2  cursor-pointer ${
                      DeleteShow ? DeleteShow : ""
                    }`}
                    onClick={onDelete}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="20"
                      viewBox="0 0 18 20"
                      fill="none"
                    >
                      <path
                        d="M17.25 3.07692H13.5V2.30769C13.5 1.69565 13.2629 1.10868 12.841 0.675907C12.419 0.243131 11.8467 0 11.25 0H6.75C6.15326 0 5.58097 0.243131 5.15901 0.675907C4.73705 1.10868 4.5 1.69565 4.5 2.30769V3.07692H0.75C0.551088 3.07692 0.360322 3.15797 0.21967 3.30223C0.0790178 3.44648 0 3.64214 0 3.84615C0 4.05017 0.0790178 4.24582 0.21967 4.39008C0.360322 4.53434 0.551088 4.61538 0.75 4.61538H1.5V18.4615C1.5 18.8696 1.65804 19.2609 1.93934 19.5494C2.22064 19.8379 2.60218 20 3 20H15C15.3978 20 15.7794 19.8379 16.0607 19.5494C16.342 19.2609 16.5 18.8696 16.5 18.4615V4.61538H17.25C17.4489 4.61538 17.6397 4.53434 17.7803 4.39008C17.921 4.24582 18 4.05017 18 3.84615C18 3.64214 17.921 3.44648 17.7803 3.30223C17.6397 3.15797 17.4489 3.07692 17.25 3.07692ZM6 2.30769C6 2.10368 6.07902 1.90802 6.21967 1.76376C6.36032 1.61951 6.55109 1.53846 6.75 1.53846H11.25C11.4489 1.53846 11.6397 1.61951 11.7803 1.76376C11.921 1.90802 12 2.10368 12 2.30769V3.07692H6V2.30769ZM15 18.4615H3V4.61538H15V18.4615ZM7.5 8.46154V14.6154C7.5 14.8194 7.42098 15.0151 7.28033 15.1593C7.13968 15.3036 6.94891 15.3846 6.75 15.3846C6.55109 15.3846 6.36032 15.3036 6.21967 15.1593C6.07902 15.0151 6 14.8194 6 14.6154V8.46154C6 8.25753 6.07902 8.06187 6.21967 7.91761C6.36032 7.77335 6.55109 7.69231 6.75 7.69231C6.94891 7.69231 7.13968 7.77335 7.28033 7.91761C7.42098 8.06187 7.5 8.25753 7.5 8.46154ZM12 8.46154V14.6154C12 14.8194 11.921 15.0151 11.7803 15.1593C11.6397 15.3036 11.4489 15.3846 11.25 15.3846C11.0511 15.3846 10.8603 15.3036 10.7197 15.1593C10.579 15.0151 10.5 14.8194 10.5 14.6154V8.46154C10.5 8.25753 10.579 8.06187 10.7197 7.91761C10.8603 7.77335 11.0511 7.69231 11.25 7.69231C11.4489 7.69231 11.6397 7.77335 11.7803 7.91761C11.921 8.06187 12 8.25753 12 8.46154Z"
                        fill="#DC3545"
                      />
                    </svg>
                  </div> */}
                </div>
                {discountPrice && (
                  <div className=" d-flex flex-xxl-column ">
                    <p className="disable text-decoration-line-through">
                      ${unitPrice}
                    </p>
                    <h6 className="info ps-1 ps-xxl-0">${discountPrice}</h6>
                  </div>
                )}
                {!discountPrice && (
                  <div className="d-flex flex-xxl-column ">
                    <h6 className="">${unitPrice}</h6>
                  </div>
                )}
              </div>
              <div className="list-detail number-edit d-flex">
                
              </div>
              <div className="list-detail d-none  d-xl-block">
                {processingData
                  ? processingData.map((item, index) => (
                      <div
                        className="d-flex flex-wrap flex-column-reverse flex-xxl-column"
                        key={index}
                      >
                        <h6 className="d-block p">
                          價格： $
                          {item.unitPrice * unitPieces * promolist.quantity}
                        </h6>

                        <h6 className="d-block  p">
                          {index + 1}.{item.processingName}
                        </h6>
                      </div>
                    ))
                  : ""}
              </div>
              <div className="list-detail d-none d-xl-block">
                {discountsubTotal && (
                  <h6 className="product-price ">
                    $ {discountsubTotal && discountsubTotal.toLocaleString()}
                  </h6>
                )}
                {!discountsubTotal && (
                  <h6 className="product-price ">
                    $ {totalPrice && totalPrice.toLocaleString()}
                  </h6>
                )}
              </div>

              <div
                className={` list-detail d-none d-xl-flex justify-content-center cursor-pointer product-delete `}
               
              >
               
              </div>
            </div>
          </div>
          {processingData && (
            <div className="d-flex d-xl-none gap-10px align-items-center">
              <h6 className="primary-deep2 w-30">加購</h6>
              {processingData.map((item, index) => (
                <div className="d-flex flex-wrap gap-10px" key={index}>
                  <h6 className=" p">
                    {index + 1}.{item.processingName}
                  </h6>
                  <h6 className="p">
                    價格： ${item.unitPrice * unitPieces * promolist.quantity}
                  </h6>
                </div>
              ))}
            </div>
          )}
          <div className="product-subtotal d-flex  d-xl-none justify-content-end">
            <h6 className="primary-deep2 ">小計</h6>
            <h6 className="product-price ">
              $ {totalPrice && totalPrice.toLocaleString()}
            </h6>
          </div>
        </div>
      )}
      {contentType === null ||
        (contentType === "" && (
          <div className="product cart-list">
            <div className="d-block d-xxl-flex">
              <div className="product-content">
                <div className="product-img">
                  <img src={prodImage} alt="" />
                </div>
                <div className="product-descript">
                  {/* <h6 className="product-name">{promolist.itemdesc}</h6> */}
                  <div className="product-name">
                    <div>
                      <h6>{descript}</h6>
                    </div>
                  </div>
                  {discountPrice && (
                    <div className="product-price">
                      <div className=" d-flex flex-xxl-column ">
                        <p className="disable text-decoration-line-through">
                          ${unitPrice}
                        </p>
                        <h6 className="info ps-1 ps-xxl-0">${discountPrice}</h6>
                      </div>
                    </div>
                  )}
                  {!discountPrice && (
                    <div className="product-price">
                      <div className="d-flex flex-xxl-column ">
                        <h6 className="">${unitPrice}</h6>
                      </div>
                    </div>
                  )}

                  {discountsubTotal ? (
                    <h6 className="product-price d-none d-xxl-block info">
                      $ {discountsubTotal}
                    </h6>
                  ) : (
                    <h6 className="product-price d-none d-xxl-block">
                      $ {subTotal}
                    </h6>
                  )}
                </div>
              </div>
              {processingData && (
                <div className="d-xxl-flex">
                  <div className="product-add d-flex flex-wrap">
                    <h6 className="primary-deep2 d-block d-xxl-none">加購</h6>
                    {processingData
                      ? processingData.map((item, index) => (
                          <div
                            className="d-flex flex-wrap  flex-xxl-column"
                            key={index}
                          >
                            <h6 className="d-block ">
                              {index + 1}.{item.processingName}
                            </h6>

                            <h6 className="d-block">
                              價格： $
                              {item.unitPrice * unitPieces * promolist.quantity}
                            </h6>
                          </div>
                        ))
                      : ""}
                  </div>
                </div>
              )}
            </div>
            <div className="product-subtotal">
              <h6 className="pe-2">合計</h6>
              {discountsubTotal && (
                <div className="d-flex align-items-center">
                  {/* <h6 className="disable text-decoration-line-through p">
          ${totalPrice && totalPrice.toLocaleString()}
        </h6> */}
                  <h6 className="ps-2 info">
                    ${discounttotalPrice && discounttotalPrice.toLocaleString()}
                  </h6>
                </div>
              )}
              {!discountsubTotal && (
                <h6>${totalPrice && totalPrice.toLocaleString()}</h6>
              )}
            </div>
          </div>
        ))}
    </>
  );
}

export default Promolist;
