import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";


function OnePageFooter() {
    const [apiData, setApiData] = useState(null);
    const [companyInfo, setCompanyInfo] = useState(null);
    useEffect(() => {
      // fetch(`api/Blog/slug/新商品上市`)
      axios.get(`api/Home/FooterNav`)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return null;
      })
      .then((data) => {
        if (data) {
          setApiData(data);
          // console.log("Data from FooterNav:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data from API:", "error");
      });
  }, []);
  
    useEffect(() => {
      axios.get(`api/Home/StoreData`)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return null;
      })
      .then((data) => {
        if (data) {
          setCompanyInfo(data);
          console.log("撈取商家資料", "data");
        }
      })
      .catch(() => {
        console.error("Error fetching data from API");
      });
  }, []);
    const sanitizedHTML = DOMPurify.sanitize(
      companyInfo ? companyInfo.footerInfo : ""
    );
    const sanitizedMobile = DOMPurify.sanitize(
      companyInfo ? companyInfo.footerMInfo : ""
    );
    return (
      <>
        <footer className="footer mt-4 mt-xxl-5 onepage-footer">
          <div className="d-none d-sm-block">
            <div className="primary-light-bg ">
              <div className="container d-flex justify-content-center ">
                <div className="logo pe-5 d-flex ">
                  
  {companyInfo && companyInfo.footerLogo && (
    <img src={companyInfo.footerLogo} alt="" />
  )}
  
                </div>
                <div className="content d-flex primary-deep2 ps-5">
                  
  
                  {companyInfo && (
                    <div className="footer-info primary-deep2">
                      <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {companyInfo && <div className="primary-deep-bg white text-center">
  © {new Date().getFullYear()} {companyInfo.storename}版權所有，並保留所有權利
</div>} 
          </div>
          <div className="d-block d-sm-none">
            <div className="primary-light-bg pb-2">
              <div className="footer-center">
                <div className="footer-bg">
                {companyInfo && companyInfo.footerMLogo && (
    <img src={companyInfo.footerMLogo} alt="" />
  )}
                </div>
                {companyInfo && (<div className="footer-info ">
                  <div dangerouslySetInnerHTML={{ __html: sanitizedMobile }} />
                </div>)}
              </div>
            </div>
          </div>
        </footer>
      </>
    );
}

export default OnePageFooter