//Popup.js
import React, { useEffect } from "react";

function Popup({ onNevigate, onClose, option, icon, title, message, orangebtn, greenbtn }) {
  let iconElement = null;
  // useEffect(()=>{
  //   console.log("option",option)
  // },[])
  // useEffect(()=>{
  //   console.log("icon",icon)
  // },[icon])

  if (icon === "notice") {
    iconElement = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="61"
        height="62"
        viewBox="0 0 61 62"
        fill="none"
      >
        <g clipPath="url(#clip0_333_10370)">
          <path
            d="M30.5 0.451172C24.5666 0.451172 18.7664 2.23813 13.8329 5.58608C8.89943 8.93403 5.05426 13.6926 2.78363 19.26C0.512999 24.8275 -0.0811016 30.9537 1.07646 36.8641C2.23401 42.7744 5.09124 48.2035 9.28681 52.4646C13.4824 56.7257 18.8279 59.6276 24.6473 60.8032C30.4667 61.9789 36.4987 61.3755 41.9805 59.0694C47.4623 56.7633 52.1477 52.858 55.4441 47.8475C58.7405 42.8369 60.5 36.9461 60.5 30.9199C60.5 22.8391 57.3393 15.0893 51.7132 9.37526C46.0871 3.66126 38.4565 0.451172 30.5 0.451172ZM33.5 49.2012H27.5V43.1074H33.5V49.2012ZM33.5 37.0137H27.5V12.6387H33.5V37.0137Z"
            fill="#FF5C26"
          />
        </g>
        <defs>
          <clipPath id="clip0_333_10370">
            <rect
              width="60"
              height="60.9375"
              fill="white"
              transform="translate(0.5 0.451172)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (icon === "check") {
    iconElement = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
      >
        <path
          d="M40 0C17.9087 0 0 17.9087 0 40C0 62.0913 17.9087 80 40 80C62.0913 80 80 62.0913 80 40C80 17.9087 62.0913 0 40 0ZM40 8.6914C57.2913 8.6914 71.3037 22.7131 71.3037 40C71.3037 57.287 57.2913 71.3037 40 71.3037C22.7087 71.3037 8.69627 57.287 8.69627 40C8.69633 22.7131 22.7087 8.6914 40 8.6914ZM55.3271 21.0059L32.1435 44.1943L24.624 36.6748L17.2461 44.0479L24.7656 51.5674L32.1923 58.9941L39.5655 51.6162L62.7539 28.4326L55.3271 21.0059Z"
          fill="#FF5C26"
        />
      </svg>
    );
  }
  return (
    <div className="popup-full-bg">
      <div className="popup-checkbox">
        <div className="popup-content">
          <div className="popup-icon text-center">{iconElement}</div>
          <div className="popup-title text-center mx-3 mx-xxl-0">
            <h5>{title}</h5>
            
          </div>
          {message}
        </div>
        <div className={`container d-flex justify-content-center justify-content-center ${orangebtn !== "" && greenbtn !== "" ? 'btn-group' : ''}`}>
          {orangebtn !== "" && (
            <button className="btn search-btn2  " onClick={onNevigate}>
              <h6>{orangebtn}</h6>
            </button>
          )}
          {greenbtn !== "" && (
            <button className="btn primary-btn  " onClick={onClose}>
              <h6>{greenbtn}</h6>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Popup;
