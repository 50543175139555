import { createSelector } from 'reselect';

const selectProduct = (state) => state.product;

export const selectOptionName = createSelector(
  [selectProduct],
  (product) => product.optionName || ''
);

export const selectOptionSecondName = createSelector(
  [selectProduct],
  (product) => product.optionSecondName || ''
);

export const selectProcessingName = createSelector(
  [selectProduct],
  (product) => product.processingName || []
);
