//RegisterForeignSection.js
import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../Context/AuthContext";
import axios from "axios";
import Popup from "../sharedComponents/Popup";
import PwdLink from "../sharedComponents/PwdLink";
import Loading from "../components/Loading";
import Nodata from "./Nodata";

function RegisterForeignSection({ title, buttondescript }) {
  const { login } = useContext(AuthContext);
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [emailOnblur, setEmailonBlur] = useState(false);
  const [name, setName] = useState("");
  const [nameOnBlur, setNameBlur] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordOnblur, setPasswordBlur] = useState(false);

  const [captcha, setCaptcha] = useState("");
  const [captchaData, setCaptchaData] = useState({});
  const [captchaOnblur, setCaptchaBlur] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordOnBlur, setConfirmPasswordBlur] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(true);
  const [emailuniq, setEmailuniq] = useState(false);
  const [formCheck, setFormCheck] = useState(false);

  // 檢查錯誤
  const [checkError, setCheckError] = useState(false);

  // 下一步的disable
  const [isNextDisabled, setNextDisabled] = useState(false);
  // popup
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [checkoutPopup, setCheckoutPopup] = useState(false);

  const navigate = useNavigate();
  const getCaptcha = () => {
    axios
      .get(`/api/User/Captcha`, {
        headers: {},
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("Captcha Data:", "response.data");
          setCaptchaData(response.data);
        } else {
          console.log("Captcha failed:", "response");
          // Token is invalid, log out the user
        }
      })
      .catch((error) => {
        console.error("Error:", "error");
      })
      .finally(() => {
        // setIsLoading(false); // Mark loading as finished regardless of success or error
      });
  };

  //檢查密碼和確認密碼是否相同
  useEffect(() => {
    if (
      password === confirmPassword &&
      password !== "" &&
      confirmPassword !== ""
    ) {
      setConfirmPasswordError(false);
      console.log("密碼沒錯");
    } else {
      setConfirmPasswordError(true);
      console.log("密碼不相同");
    }
  }, [password, confirmPassword]);
  //初始載入畫面取得驗證碼
  useEffect(() => {
    getCaptcha();
  }, []);

  // useEffect(() => {
  //   if (!emailuniq) {
  //     window.scrollTo(0, 0);
  //   }

  // }, [emailuniq]);
  //其他格式檢查
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setFormCheck(false);
    setIsSubmitted(false);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const togglePasswordVisibility = (e) => {
    e.preventDefault(); // 阻止默認行為
    setShowPassword(!showPassword);
  };
  const handleCaptchaChange = (e) => {
    setCaptcha(e.target.value);
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    try {
      const registerResponse = await axios.post("api/User/Register", {
        Email: escapeHTML(email),
        Password: escapeHTML(password),
        MemberName: escapeHTML(name),
        MemberType: "F",
      });

      console.log("Form submitted:", "registerResponse.data");
      setIsPopupOpen(true);
      // if (location.pathname !== "/checkoutstep1") {
      //   setIsPopupOpen(true);
      // }
      // if (location.pathname === "/checkoutstep1") {
      //   setCheckoutPopup(true);
      // set(true);
      // try {
      //   const loginResult = await login(email, password, "F"); // 等待登录完成

      //   if (!loginResult) {
      //     // 登录失败，打开弹出窗口

      //     console.log("loginResult", loginResult);
      //   }
      // } catch (error) {
      //   console.error("Error during login:", error);
      //   // 处理登录时的错误情况
      // }
      //   console.log("登入完成");
      // }
      // Handle registration response (registerResponse.data)
      console.log("Form submitted:", "registerResponse.data");
    } catch (registerError) {
      console.error("Error submitting registration:", "registerError");
    }
  };
  // const checkoutLogin =async ()=>{

  //   try {
  //       const loginResult = await login(email, password, "F"); // 等待登录完成

  //       if (!loginResult) {
  //         // 登录失败，打开弹出窗口

  //         console.log("loginResult", loginResult);
  //       }
  //     } catch (error) {
  //       console.error("Error during login:", error);
  //       // 处理登录时的错误情况
  //     }
  //     console.log("登入完成");
  // }
  // const checkoutLogin = ()=>{

  // }
  const handleCheckForm = async (e) => {
    e.preventDefault();
    // setFormCheck(true);
    setEmailonBlur(true);
    setEmailonBlur(true);
    setNameBlur(true);
    setPasswordBlur(true);
    setConfirmPasswordBlur(true);
    setCaptchaBlur(true);

    if (
      !isValidEmail(email) ||
      !isValidName(name) ||
      !isValidPassword(password) ||
      confirmPasswordError ||
      !isValidCaptcha(captcha)
    ) {
      let firstInvalidInputRef = null;
      if (!isValidEmail(email) || !email) {
        firstInvalidInputRef = document.getElementById("email");
      } else if (!isValidName(name) || !name) {
        firstInvalidInputRef = document.getElementById("username");
      } else if (!isValidPassword(password) || !password) {
        firstInvalidInputRef = document.getElementById("member-pwd");
      } else if (confirmPasswordError || !confirmPassword) {
        firstInvalidInputRef = document.getElementById("confirm-pwd");
      } else if (!isValidCaptcha(captcha) || !captcha) {
        firstInvalidInputRef = document.getElementById("captcha");
      }

      if (firstInvalidInputRef) {
        console.log("firstInvalidInputRef", firstInvalidInputRef);
        firstInvalidInputRef.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      setCheckError(true);
      return;
    } else {
      setCheckError(false);
    }

    if (isNextDisabled) {
      console.log("連點禁止");
      return;
    }

    try {
      const checkResponse = await axios.post("api/User/Register/Check", {
        Email: escapeHTML(email),
        MemberType: "F",
      });

      if (checkResponse.status === 200) {
        setEmailuniq(true);
        setIsSubmitted(true);
        console.log("Form Register Check submitted:", "checkResponse.data");
        handleSubmitForm(e); // 传递事件对象 e 给 handleSubmitForm
        // Set isSubmitted to true only after successful validation
      } else {
        console.log("checkResponse", "escapeHTML(email)");
        console.log("Email is already registered.");
        setEmailuniq(false);

        setCaptcha("");
        getCaptcha();
        window.scrollTo(0, 0);
      }
    } catch (checkError) {
      console.error("Error submitting registration:", "checkError");
      console.log("Error checking availability");
      setEmailuniq(false);
      setEmailonBlur(true);
      setCaptcha("");
      getCaptcha();
      setCaptchaBlur(false);
      if (checkError.response) {
        console.log("Response data:", "checkError.response.data");
        console.log("Email is already registered.");
      }
    } finally {
      setFormCheck(true);
      setNextDisabled(false);
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };
  const isValidName = (name) => {
    const nameRegex = /^[A-Za-z0-9\u4e00-\u9fa5]+$/;
    return name.trim() !== "" && nameRegex.test(name);
  };
  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,20}$/;
    return passwordRegex.test(password);
  };
  const isValidCaptcha = (captcha) => {
    return captcha === captchaData.code;
  };

  const escapeHTML = (unsafeText) => {
    return unsafeText.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  };
  const handleQuickFill = () => {
    // const quickFillData = {
    //   email: "aa1@gmail.com",
    //   password: "Password123",
    //   name: "Nick",
    //   confirmPassword: "Password123",
    //   captcha: "123456789",
    // };

    // setEmail(quickFillData.email);
    // setPassword(quickFillData.password);
    // setName(quickFillData.name);
    // setConfirmPassword(quickFillData.confirmPassword);
    // setConfirmPasswordError(false); // Reset the confirmPassword error
    // setCaptcha(quickFillData.captcha);

    console.log("click", "handleQuickFill");
  };

  //重複的表單

  const renderInputField = (
    id,
    type,
    name,
    classname,
    placeholder,
    value,
    onChange,
    onBlur = null,
    onClick = null,
    minLength,
    maxLength
  ) => (
    <input
      type={type}
      id={id}
      name={name}
      className={classname}
      // placeholder={placeholder}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      onClick={onClick}
      minLength={minLength}
      maxLength={maxLength}
    />
  );
  const errorInput = (hasData, isValid, id, errormessage, show, notshow) => (
    <div
      className={`error-message ${
        hasData && !isValid ? show || "visible" : notshow || "invisible"
      } d-flex pb-0`}
      id={id}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="19"
        viewBox="0 0 21 19"
        fill="none"
        // className="pt-1"
      >
        <path
          d="M0.98903 18.5C0.805697 18.5 0.63903 18.454 0.48903 18.362C0.33903 18.27 0.222364 18.1493 0.139031 18C0.0556972 17.85 0.00969715 17.6873 0.00103049 17.512C-0.00763618 17.3367 0.0383638 17.166 0.139031 17L9.38903 1C9.48903 0.833333 9.61836 0.708333 9.77703 0.625C9.9357 0.541667 10.098 0.5 10.264 0.5C10.4307 0.5 10.5934 0.541667 10.752 0.625C10.9107 0.708333 11.0397 0.833333 11.139 1L20.389 17C20.489 17.1667 20.535 17.3377 20.527 17.513C20.519 17.6883 20.473 17.8507 20.389 18C20.3057 18.15 20.189 18.271 20.039 18.363C19.889 18.455 19.7224 18.5007 19.539 18.5H0.98903ZM2.71403 16.5H17.814L10.264 3.5L2.71403 16.5ZM10.264 15.5C10.5474 15.5 10.785 15.404 10.977 15.212C11.169 15.02 11.2647 14.7827 11.264 14.5C11.264 14.2167 11.168 13.979 10.976 13.787C10.784 13.595 10.5467 13.4993 10.264 13.5C9.9807 13.5 9.74303 13.596 9.55103 13.788C9.35903 13.98 9.26336 14.2173 9.26403 14.5C9.26403 14.7833 9.36003 15.021 9.55203 15.213C9.74403 15.405 9.98136 15.5007 10.264 15.5ZM10.264 12.5C10.5474 12.5 10.785 12.404 10.977 12.212C11.169 12.02 11.2647 11.7827 11.264 11.5V8.5C11.264 8.21667 11.168 7.979 10.976 7.787C10.784 7.595 10.5467 7.49933 10.264 7.5C9.9807 7.5 9.74303 7.596 9.55103 7.788C9.35903 7.98 9.26336 8.21733 9.26403 8.5V11.5C9.26403 11.7833 9.36003 12.021 9.55203 12.213C9.74403 12.405 9.98136 12.5007 10.264 12.5Z"
          fill="#DC3545"
        />
      </svg>
      <p className={`ps-1 danger`}>{errormessage}</p>
    </div>
  );

  if (!captchaData || !captchaData.imageData) {
    <Loading />;
  }
  return (
    <>
      {isPopupOpen && (
        <Popup
          onNevigate={() => {
            setIsPopupOpen(false);
            navigate("/foreignlogin");
          }}
          onClose={() => {
            setIsPopupOpen(false);
          }}
          icon={"check"}
          title={"已註冊成功，請先前往信箱接收驗證信"}
          orangebtn={"前往登入"}
          greenbtn={""}
        />
      )}
      {checkoutPopup && (
        <Popup
          onNevigate={() => {
            setIsPopupOpen(false);
            //  checkoutLogin();
          }}
          icon={"check"}
          title={"已註冊成功，請先前往信箱接收驗證信"}
          orangebtn={"驗證完畢，繼續結帳"}
          greenbtn={""}
        />
      )}
      <div className="login-title pt-3 pb-2 pt-xxl-3 pb-xxl-2">
        {title && (
          <h5 onClick={handleQuickFill} className="pb-2">
            {title}
          </h5>
        )}
        {!title && (
          <>
            <h4 onClick={handleQuickFill}>會員帳號註冊</h4>
            <div className="d-flex pt-2 pb-3">
              <h6>若您已是會員，請直接</h6>
              <Link to="/foreignlogin">
                <h6 className="ps-1 primary-deep text-decoration-underline">
                  登入
                </h6>
              </Link>
              <h6>。</h6>
            </div>
          </>
        )}
      </div>
      <form
        className="login-form pb-3 d-flex flex-column gap-3"
        onSubmit={handleCheckForm}
      >
        <div>
          <label htmlFor="email" className="form-label d-block">
            <h6 className="primary-deep2">信箱(必填)</h6>
          </label>
          {renderInputField(
            "email",
            "email",
            "email",
            "form-input",
            "請輸入常用信箱",
            email,
            handleEmailChange,
            () => {
              setEmailonBlur(true);
            },
            () => {
              setEmailonBlur(false);
            },
            5,
            254
          )}
          {errorInput(
            email || emailOnblur,
            isValidEmail(email),
            "same-email",
            "請輸入正確的信箱",
            "d-block",
            "d-none"
          )}
          {errorInput(
            formCheck && email,
            emailuniq,
            "same-email",
            "該信箱已被驗證過",
            "d-block",
            "d-none"
          )}
        </div>
        <div>
          <label htmlFor="username" className="form-label d-block">
            <h6 className="primary-deep2">姓名(必填)</h6>
          </label>
          {renderInputField(
            "username",
            "text",
            "username",
            "form-input",
            "輸入姓名",
            name,
            handleNameChange,
            () => {
              setNameBlur(true);
            },
            () => {
              setNameBlur(false);
            },
            1,
            60
          )}
          {errorInput(
            name || nameOnBlur,
            isValidName(name),
            "error-username",
            "請輸入正確的名稱，不得包含特殊符號",
            "d-block",
            "d-none"
          )}
        </div>
        <div>
          <label htmlFor="member-pwd" id="user-pwd" className="form-label">
            <h6 className="primary-deep2">密碼(必填)</h6>
          </label>
          <div className="d-flex">
            {renderInputField(
              "member-pwd",
              showPassword ? "text" : "password",
              "phone-number",
              "form-pwd",
              "請輸入密碼(格式為英文字母大小寫加上數字,共8~20位英數字)",
              password,
              handlePasswordChange,
              () => {
                setPasswordBlur(true);
              },
              () => {
                setPasswordBlur(false);
              },
              8,
              20
            )}
            <div className="btn see-pwd ">
              <button
                className="btn checkbox-icon check showpwd"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.3267 0L0 1.32686L3.32283 4.64929C1.86598 5.81447 0.715658 7.30499 0 8.99996C1.73 13.0973 6 16 11 16C12.1278 16 13.2185 15.8523 14.2513 15.5765L16.9518 18.2766L18.2785 16.9497L1.3267 0ZM12.6749 14.0002L10.4219 11.7475C9.84146 11.6411 9.30305 11.3759 8.87868 10.9799C8.40503 10.5378 8.10612 9.96312 8.02343 9.34933L4.68517 6.01147C3.63979 6.83061 2.78481 7.84738 2.18 8.99996C2.98825 10.5402 4.24331 11.838 5.80248 12.7457C7.36165 13.6533 9.1624 14.1345 11 14.1345C11.5637 14.1345 12.124 14.0892 12.6749 14.0002ZM10.2905 6.27939L13.8592 9.84771C13.9517 9.57602 14 9.29031 14 8.99996C14 8.25735 13.6839 7.54516 13.1213 7.02006C12.5587 6.49496 11.7956 6.19996 11 6.19996C10.7589 6.19996 10.5209 6.22704 10.2905 6.27939ZM19.82 8.99996C19.0728 10.424 17.9436 11.6407 16.5453 12.5335L17.92 13.9081C19.7365 12.6789 21.1645 10.9788 22 8.99996C20.27 4.90262 16 1.99996 11 1.99996C9.50062 1.99996 8.06689 2.26098 6.74761 2.73697L8.24441 4.2336C9.13525 3.99055 10.0628 3.86539 11 3.86539C12.8376 3.86539 14.6383 4.34658 16.1975 5.25425C17.7567 6.16193 19.0117 7.45967 19.82 8.99996Z"
                      fill="#D9D9D9"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="19"
                    viewBox="0 0 22 19"
                    fill="none"
                  >
                    <path
                      d="M11 6.2C11.7956 6.2 12.5587 6.495 13.1213 7.0201C13.6839 7.5452 14 8.25739 14 9C14 9.74261 13.6839 10.4548 13.1213 10.9799C12.5587 11.505 11.7956 11.8 11 11.8C10.2044 11.8 9.44129 11.505 8.87868 10.9799C8.31607 10.4548 8 9.74261 8 9C8 8.25739 8.31607 7.5452 8.87868 7.0201C9.44129 6.495 10.2044 6.2 11 6.2ZM11 2C16 2 20.27 4.90267 22 9C20.27 13.0973 16 16 11 16C6 16 1.73 13.0973 0 9C1.73 4.90267 6 2 11 2ZM2.18 9C2.98825 10.5403 4.24331 11.838 5.80248 12.7457C7.36165 13.6534 9.1624 14.1346 11 14.1346C12.8376 14.1346 14.6383 13.6534 16.1975 12.7457C17.7567 11.838 19.0117 10.5403 19.82 9C19.0117 7.45971 17.7567 6.16197 16.1975 5.2543C14.6383 4.34662 12.8376 3.86544 11 3.86544C9.1624 3.86544 7.36165 4.34662 5.80248 5.2543C4.24331 6.16197 2.98825 7.45971 2.18 9Z"
                      fill="#D9D9D9"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <div className="pt-1 secondary-deep">
            <p>英文字母大小寫加上數字,共8~20位英數字</p>
          </div>
          {errorInput(
            password || passwordOnblur,
            isValidPassword(password),
            "error-pwd",
            "請輸入正確的密碼(英文字母大小寫加上數字,共8~20位英數字 )",
            "d-block",
            "d-none"
          )}
        </div>
        <div>
          <label htmlFor="confirm-pwd" className="form-label d-block">
            <h6 className="primary-deep2">再次輸入密碼(必填)</h6>
          </label>
          {renderInputField(
            "confirm-pwd",
            "password",
            "confirm-pwd",
            "form-input",
            "請再次輸入密碼",
            confirmPassword,
            handleConfirmPasswordChange,
            () => {
              setConfirmPasswordBlur(true);
            },
            () => {
              setConfirmPasswordBlur(true);
            }
          )}
          {/* <input
            type="password"
            id="confirm-pwd"
            onChange={handleConfirmPasswordChange}
            className="form-input"
            placeholder="請再次輸入密碼"
            value={confirmPassword}
          /> */}
          {errorInput(
            confirmPassword || confirmPasswordOnBlur,
            !confirmPasswordError,
            "error-confirm-pwd",
            "再次輸入密碼和密碼不相同",
            "d-block",
            "d-none"
          )}
        </div>
        {/* <div
          className={`d-flex pb-0 
      ${confirmPasswordError && formCheck ? "visible" : "invisible"}
     `}
          id="error-confirm-pwd"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="19"
            viewBox="0 0 21 19"
            fill="none"
            className="pt-1"
          >
            <path
              d="M0.98903 18.5C0.805697 18.5 0.63903 18.454 0.48903 18.362C0.33903 18.27 0.222364 18.1493 0.139031 18C0.0556972 17.85 0.00969715 17.6873 0.00103049 17.512C-0.00763618 17.3367 0.0383638 17.166 0.139031 17L9.38903 1C9.48903 0.833333 9.61836 0.708333 9.77703 0.625C9.9357 0.541667 10.098 0.5 10.264 0.5C10.4307 0.5 10.5934 0.541667 10.752 0.625C10.9107 0.708333 11.0397 0.833333 11.139 1L20.389 17C20.489 17.1667 20.535 17.3377 20.527 17.513C20.519 17.6883 20.473 17.8507 20.389 18C20.3057 18.15 20.189 18.271 20.039 18.363C19.889 18.455 19.7224 18.5007 19.539 18.5H0.98903ZM2.71403 16.5H17.814L10.264 3.5L2.71403 16.5ZM10.264 15.5C10.5474 15.5 10.785 15.404 10.977 15.212C11.169 15.02 11.2647 14.7827 11.264 14.5C11.264 14.2167 11.168 13.979 10.976 13.787C10.784 13.595 10.5467 13.4993 10.264 13.5C9.9807 13.5 9.74303 13.596 9.55103 13.788C9.35903 13.98 9.26336 14.2173 9.26403 14.5C9.26403 14.7833 9.36003 15.021 9.55203 15.213C9.74403 15.405 9.98136 15.5007 10.264 15.5ZM10.264 12.5C10.5474 12.5 10.785 12.404 10.977 12.212C11.169 12.02 11.2647 11.7827 11.264 11.5V8.5C11.264 8.21667 11.168 7.979 10.976 7.787C10.784 7.595 10.5467 7.49933 10.264 7.5C9.9807 7.5 9.74303 7.596 9.55103 7.788C9.35903 7.98 9.26336 8.21733 9.26403 8.5V11.5C9.26403 11.7833 9.36003 12.021 9.55203 12.213C9.74403 12.405 9.98136 12.5007 10.264 12.5Z"
              fill="#DC3545"
            />
          </svg>
          <p className="ps-1 danger">確認密碼與先前輸入密碼不符合</p>
        </div> */}
        <div>
          <div className="capcha-box">
            <div className="capcha-foriput">
              <label htmlFor="captcha" className="form-label d-block">
                <h6 className="primary-deep2">圖形驗證碼(必填)</h6>
              </label>
              {renderInputField(
                "captcha",
                "text",
                "captcha",
                "form-input",
                "輸入圖形驗證碼",
                captcha,
                handleCaptchaChange,
                () => {
                  setCaptchaBlur(false);
                },
                () => {
                  setCaptchaBlur(false);
                  setFormCheck(false);
                },
                5,
                5
              )}
            </div>
            {captchaData.imageData && (
              <div
                className="captcha-photo pt-2 cursor-pointer"
                onClick={() => getCaptcha()}
              >
                <img src={captchaData.imageData} alt="圖形驗證碼" />
              </div>
            )}
          </div>
          <div className="pt-1 secondary-deep2">
            <p>點擊圖片可刷新驗證碼</p>
          </div>
          {errorInput(
            captchaOnblur || formCheck,
            isValidCaptcha(captcha),
            "error-capcha",
            "請輸入正確的圖形驗證碼",
            "d-block",
            "d-none"
          )}
        </div>

        <div className="login-btn pt-2">
          <button
            className={`btn ${
              buttondescript ? "primary-btn-big2" : "primary-btn2"
            } w-100`}
            type="submit"
            onClick={handleCheckForm}
            // disabled={
            //   !isValidEmail(email) ||
            //   !isValidName(name) ||
            //   !isValidPassword(password) ||
            //   !isValidCaptcha(captcha) ||
            //   confirmPasswordError
            // }
          >
            {buttondescript && (
              <h6 className="font-weight-bold ">{buttondescript}</h6>
            )}
            {!buttondescript && "註冊"}
          </button>

          <div
            className={`pt-0 ${
              formCheck && !emailuniq ? "d-block w-100" : "d-none"
            }`}
          >
            <Nodata
              icon={"dangernotice"}
              dangerclass={"ps-1 p1"}
              dangerdescript={"該信箱已被驗證過"}
              bgclass={"bg-white"}
            />
          </div>

          <div
            className={`pt-0 ${
              !isValidEmail(email) ||
              !isValidName(name) ||
              !isValidPassword(password) ||
              confirmPasswordError ||
              !isValidCaptcha(captcha)
                ? "d-block w-100"
                : "d-none"
            }`}
          >
            <Nodata
              icon={"dangernotice"}
              dangerclass={"ps-1 p1"}
              dangerdescript={`請填寫正確的${
                isValidEmail(email)
                  ? ""
                  : !isValidEmail(email) &&
                    isValidName(name) &&
                    isValidPassword(password) &&
                    !confirmPasswordError &&
                    isValidCaptcha(captcha)
                  ? "信箱"
                  : "信箱、"
              }
                ${
                  isValidName(name)
                    ? ""
                    : !isValidName(name) &&
                      isValidPassword(password) &&
                      !confirmPasswordError &&
                      isValidCaptcha(captcha)
                    ? "姓名"
                    : "姓名、"
                }
                ${
                  isValidPassword(password)
                    ? ""
                    : !isValidPassword(password) &&
                      !confirmPasswordError &&
                      isValidCaptcha(captcha)
                    ? "密碼"
                    : "密碼、"
                }
                ${
                  !confirmPasswordError
                    ? ""
                    : !confirmPasswordError && isValidCaptcha(captcha)
                    ? "再次輸入密碼"
                    : "再次輸入密碼、"
                }
                ${
                  isValidCaptcha(captcha)
                    ? ""
                    : !isValidCaptcha(captcha)
                    ? "圖形驗證碼"
                    : ""
                }`}
              bgclass={"bg-white"}
            />
          </div>

          {/* <PwdLink state={"F"} /> */}
        </div>
      </form>
    </>
  );
}

export default RegisterForeignSection;
