import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import AuthContext from "../Context/AuthContext";
import PopupGreen from "./PopupGreen";
import Loading from "../components/Loading";

function TextPopup({temp, text, textstyle}) {
    const [textPopup, setTextPopup] = useState(false);
    const [isLoading, setIsLoading] =useState(false);
    const [textData, setTextData] =useState(null);
    const [companyInfo, setCompanyInfo] = useState(null);
    const handleTextPopup = () => {
      setTextPopup(true);
      // console.log("按下");
    };
const getStoreData = ()=>{
    axios.get(`api/Home/StoreData`)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return null;
    })
    .then((data) => {
      if (data) {
        setCompanyInfo(data);

        if (data.selfdomain.includes('winb')) {
          setTextData('https://line.me/R/ti/p/@wb01');
        } else if (data.selfdomain.includes('shian')) {
          setTextData('https://line.me/R/ti/p/@sa01');
        }else {
          setTextData(data.socialLine);  
        }
      }
    })
    .catch(() => {
      console.error("Error fetching data from API");
    });
}
    useEffect(()=>{
        if(temp==="sms"){
            console.log("setTextData")
            getStoreData();
        }else{
            if(text){
                setTextData(text)
            }
        }

    },[text, temp])

    if(!textData){
        return <Loading height={"d-inline-block h-auto"} width={"w-auto"}/>
        }
  return (
    <>  {textPopup && (
        <PopupGreen
          onNevigate={() => {
            setTextPopup(false);
          }}
          onClose={() => {
            setTextPopup(false);
          }}
          icon={""}
          title={""}
          orangebtn={""}
          greenbtn={"關閉"}
          bigmessage={`<div><h6>如果您沒收到簡訊，表示電信業者已將您的門號設定成拒收廣告簡訊，建議您向電信業者反應或使用另一門號做設定。</h6><div style="display: flex; text-wrap: nowrap;"><h6 style="">您可以於營業時間</h6><a style="text-decoration-line: underline;" href="${textData}" target="_blank"><h6>加Line諮詢</h6></a><h6>。</h6></div><h6>您也可以使用非會員方式進行購物。</h6></div>`}
          bigmsgcl={"primary-deep2 text-wrap"}
        />
      )}

      <div className="w-100 pt-1 d-flex justify-content-end">
          <div
            className={` ${textstyle ? textstyle :"primary-deep2"} text-decoration-underline cursor-pointer px-1 py-1`}
            onClick={() => handleTextPopup()}
          >
            <h6 className="p1 ">收不到簡訊驗證碼?</h6>

          </div>
      </div>
    </>
  )
}

export default TextPopup