import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../Context/AuthContext";
import Loading from "../components/Loading";
import Popup from "./Popup";
import Nodata from "./Nodata";
import TextPopup from "./TextPopup";

function ResetPwdSection({ state, MemberPhone, MemberEmail, pass }) {
  const [phone, setPhone] = useState("");
  const [phoneOnBlur, setPhoneBlur] = useState(false);
  const [phoneNotFound, setPhoneNotFound] = useState(false);
  //若為黑名單等情況SSOMobileCK會有錯誤訊息
  const [phoneError, setPhoneError] = useState(null);

  // 第二步(最後要改成false)
  const [showNext, setShowNext] = useState(false);
  //密碼 passwardValid控制是否可以發送請求修改
  const [newPassword, setNewPassword] = useState("");
  const [newPwdBlur, setNewPwdBlur] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(true);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [passwardValid, setPasswardValid] = useState(false);
  // 確認密碼
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordOnBlur, setConfirmPasswordBlur] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(true);

  const [email, setEmail] = useState("");
  const [emailOnblur, setEmailonBlur] = useState(false);
  const [emailNotFound, setEmailNotFound] = useState(false);
  const [verifyObj, setVerifyObj] = useState({});
  const [emailError, setEmailError] = useState(null);
  //captcha
  const [captcha, setCaptcha] = useState("");
  const [captchaOnblur, setCaptchaBlur] = useState(false);
  const [captchaData, setCaptchaData] = useState({});
  const [isSameCaptcha, setIsSameCaptcha] = useState(true);
  const [verifycaptcha, setVerifyCaptcha] = useState(false);
  // emailcaptcha
  const [emailcaptcha, setEmailCaptcha] = useState("");
  const [emailCaptchaOnblur, setEmailCaptchaBlur] = useState(false);
  const [emailCaptchaData, setEmailCaptchaData] = useState({});
  const [isSameEmailCaptcha, setIsSameEmailCaptcha] = useState(true);
  const [verifyEmailCaptcha, setVerifyEmailCaptcha] = useState(false);
  // 未登入的mid
  const [returnMid, setReturnMid] = useState(null);
  const [submitMid, setSubmitMid] = useState(null);
  //寄送SendVerificationCode出現的錯誤訊息
  const [isConnect, setIsConnect] = useState(false);
  //驗證
  const [verifysent, setVerifysent] = useState(false);
  const [countdown, setCountdown] = useState(60);

  //彈出視窗
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isResetPopup, setIsResetPopup] = useState(false);
  //路徑
  const location = useLocation();
  const pathname = location.pathname;

  const navigate = useNavigate();
  let navigatePath;

  if (state === "L") {
    navigatePath = "/login";
  } else if (state === "F") {
    navigatePath = "/foreignlogin";
  }
  //取得會員資料
  const { myAuth, logout, setMyAuth } = useContext(AuthContext);

  //發送驗證碼
  const sendPhoneCaptcha = () => {
    const requestData = { mobile: phone.toString() };

    axios
      .post("/api/User/SendVerificationCode", requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const responseData = response.data;
        console.log("回傳的SendVerificationCode", "responseData");
        console.log("傳過去的資料SendVerificationCode", "requestData");
        console.log("verificationCode", "responseData.verificationCode");
        setCaptchaData(responseData.verificationCode);
        setVerifysent(true);
      })
      .catch((error) => {
        console.error("SendVerificationCode有錯誤：", "error");
        console.log("傳過去的SendVerificationCode有錯誤", "requestData");
        console.error(
          "寄送SendVerificationCode出現的錯誤訊息",
          "error.response.data.message"
        );
        setPhoneError("簡訊驗證碼已達每日發送上限。");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      });
  };
  const sendEamilCaptcha = () => {
    const requestData = { email: email.toString() };

    axios
      .post("/api/User/emailSendCAPTCHA", requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const responseData = response.data;
        console.log("回傳的emailSendCAPTCHA", "responseData");
        console.log("傳過去的資料emailSendCAPTCHA", "requestData");
        console.log("verificationCode", "responseData.verificationCode");
        setEmailCaptchaData(responseData.verificationCode);
        setVerifysent(true);
      })
      .catch((error) => {
        console.error("emailSendCAPTCHA有錯誤：", "error");
        console.log("傳過去的emailSendCAPTCHA有錯誤", "requestData");
        console.error(
          "寄送emailSendCAPTCHA出現的錯誤訊息",
          " error.response.data.message"
        );
        setEmailError("Email驗證碼已達每日發送上限。");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      });
  };
  const checkAccount = () => {
    let requestData = {}; // 初始化 requestData

    if (phone && !email) {
      requestData = { mobile: phone.toString(), memberType: "L" };
    }

    if (email && !phone) {
      requestData = { email: email.toString(), memberType: "F" };
    }

    // 如果同時有 phone 和 email，則不執行 axios 請求
    if (phone && email) {
      console.log("同時擁有 phone 和 email，不執行 axios 請求");
      return;
    }

    axios
      .post("/api/User/ForgetPwdCKMobile", requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("回傳的ForgetPwdCKMobile", "response.data");
        // 回傳的ForgetPwdCKMobile會取得mid
        console.log("傳過去的資料ForgetPwdCKMobile", "requestData");
        const responseData = response.data;
        setVerifyCaptcha(true);
        setReturnMid(responseData.mid);
      })
      .catch((error) => {
        console.error("SendVerificationCode有錯誤：", "error");
        console.log("傳過去的SendVerificationCode有錯誤", "requestData");
        setPhoneNotFound(true);
        setEmailNotFound(true);
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      });
  };

  const handlePhoneCheck = async (e) => {
    e.preventDefault();
    if (!phone || !isValidPhoneNumber(phone) || verifysent) {
      console.log("handlePhoneCheck return");
      return;
    } else {
      sendPhoneCaptcha();
    }
    // // sendPhoneNumber();
    // setVerifysent(true);
    return;
  };
  const handleEmailCheck = async (e) => {
    e.preventDefault();
    if (!email || !isValidEmail(email) || verifysent) {
      console.log("handlePhoneCheck return");
      return;
    } else {
      sendEamilCaptcha();
    }
    // // sendPhoneNumber();
    // setVerifysent(true);
    return;
  };
  //發送步驟一
  const handlePhoneNext = (e) => {
    e.preventDefault();
    console.log("handlePhoneNext");
    console.log("captcha", "captcha");
    console.log("captchaData", "captchaData");
    if (!isValidCaptcha(captcha)) {
      return;
    }
    //驗證碼是否一樣?
    if (captcha !== captchaData.toString()) {
      console.log("captcha", "captcha");
      console.log("captchaData", "captchaData");
      setIsSameCaptcha(false);
      setVerifyCaptcha(true);
      return;
    }

    if (!phone || !isValidPhoneNumber(phone) || phoneNotFound || !captcha) {
      setPhoneBlur(true);
      setCaptchaBlur(true)
      console.log("資料未填")
      return;
    }
    setIsConnect(true);
    setShowNext(true);
    setPasswardValid(true);
    setSubmitMid(returnMid);
    // onNextStep();
  };
  const handleEmailNext = (e) => {
    e.preventDefault();
    console.log("handleEmailNext");
    if(!email ||
      !isValidEmail(email) ||
      emailNotFound ||
      !emailcaptcha){
        setEmailonBlur(true)
        setEmailCaptchaBlur(true)
      console.log("資料未填")
      return;
    }
    if (!isValidEmailCaptcha(emailcaptcha)) {
      return;
    }
    //驗證碼是否一樣?
    if (emailcaptcha !== emailCaptchaData.toString()) {
      console.log("emailcaptcha", "emailcaptcha");
      console.log("emailCaptchaData", "emailCaptchaData");
      setIsSameEmailCaptcha(false);
      setVerifyEmailCaptcha(true);
      return;
    }

    if (!email || !isValidEmail(email)) {
      return;
    }
    setIsConnect(true);
    setShowNext(true);
    console.log("shownext");
    setPasswardValid(true);
    setSubmitMid(returnMid);
    // onNextStep();
  };
  //發送步驟二
  const handleFinishPwdEdit = (e) => {
    e.preventDefault();
    console.log("handleFinishPwdEdit");
  };
  //發送已登入的改變密碼
  const handleChangepwd = (e) => {
    e.preventDefault();
    const storedToken = myAuth.token;
    const mid = parseInt(returnMid);
    console.log("returnMid", "returnMid");

    if(!passwardValid || !isConnect || confirmPasswordError){
      setNewPwdBlur(true)
      setConfirmPasswordBlur(true)
      console.log("格式不對")
      return
    }
    const requestData = {
      mid: mid,
      newpwd: newPassword,
    };
    axios
      .post(`/api/User/ForgetPwdEdit`, requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("response Data:", "response.data");

          if (!myAuth.authorized) {
            setIsPopupOpen(true);
          }
          if (myAuth.authorized) {
            setIsResetPopup(true);
          }
        } else {
          console.log("Request failed:", "response");
          // Token is invalid, log out the user
        }
      })
      .catch((error) => {
        console.error("Error:", "error");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      })
      .finally(() => {
        // setIsLoading(false); // Mark loading as finished regardless of success or error
      });
  };

  //手機
  const handlePhoneNumberChange = (e) => {
    setPhone(e.target.value);
    setPhoneNotFound(false);
  };

  //Email
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailNotFound(false);
  };
  //Pwd
  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };
  const toggleNewPasswordVisibility = (e) => {
    e.preventDefault(); // 阻止默認行為
    setShowNewPassword(!showNewPassword);
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  //Captcha
  const handleCaptchaChange = (e) => {
    setCaptcha(e.target.value);
    setVerifyCaptcha(false);
    setIsSameCaptcha(true);
    
  };
  const handleEmailCaptchaChange = (e) => {
    setEmailCaptcha(e.target.value);
    setVerifyEmailCaptcha(false);
    setIsSameEmailCaptcha(true);
  };
  //格式檢查
  const isValidPhoneNumber = (phone) => {
    const phoneRegex = /^09\d{8}$/;
    return phoneRegex.test(phone);
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };
  const isValidCaptcha = (captcha) => {
    return captcha && captcha.trim() !== "";
  };

  const isValidEmailCaptcha = (emailcaptcha) => {
    return emailcaptcha && emailcaptcha.trim() !== "";
  };

  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,20}$/;
    return passwordRegex.test(password);
  };

  useEffect(() => {
    if (MemberPhone) {
      setPhone(MemberPhone);
      setIsConnect(true);
      setReturnMid(myAuth.sid);
    }
    if (MemberEmail) {
      setEmail(MemberEmail);
      setIsConnect(true);
      setReturnMid(myAuth.sid);
    }
    if (pass) {
      setShowNext(true);
      setPasswardValid(true);
    }
  }, [MemberPhone, MemberEmail, pass]);

  //阻擋重複發送請求
  useEffect(() => {
    if (verifysent) {
      const countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown > 1) {
            return prevCountdown - 1;
          } else {
            clearInterval(countdownInterval);
            setVerifysent(false); // 倒计时结束时重置 phonesent
            return 60; // 重置倒计时为 60
          }
        });
      }, 1000);

      return () => {
        clearInterval(countdownInterval);
      };
    } else {
      setCountdown(60);
    }
  }, [verifysent]);
  //檢查密碼和確認密碼是否相同
  useEffect(() => {
    if (showNext) {
      if (
        newPassword === confirmPassword &&
        newPassword !== "" &&
        confirmPassword !== ""
      ) {
        setConfirmPasswordError(false);
        console.log("密碼沒錯");
      } else {
        setConfirmPasswordError(true);
        console.log("密碼不相同");
      }
    }
  }, [newPassword, confirmPassword]);

  //重複的表單

  const renderInputField = (
    id,
    type,
    name,
    classname,
    placeholder,
    value,
    onChange,
    onBlur = null,
    onClick = null,
    minLength,
    maxLength,
    readonly
  ) => (
    <input
      type={type}
      id={id}
      name={name}
      className={classname}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      onClick={onClick}
      minLength={minLength}
      maxLength={maxLength}
      readOnly={readonly}
    />
  );
  const errorInput = (hasData, isValid, id, errormessage, show, notshow) => (
    <div
      className={`error-message ${
        hasData && !isValid ? show || "visible" : notshow || "invisible"
      } d-flex pb-0`}
      id={id}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="19"
        viewBox="0 0 21 19"
        fill="none"
        className="pt-1"
      >
        <path
          d="M0.98903 18.5C0.805697 18.5 0.63903 18.454 0.48903 18.362C0.33903 18.27 0.222364 18.1493 0.139031 18C0.0556972 17.85 0.00969715 17.6873 0.00103049 17.512C-0.00763618 17.3367 0.0383638 17.166 0.139031 17L9.38903 1C9.48903 0.833333 9.61836 0.708333 9.77703 0.625C9.9357 0.541667 10.098 0.5 10.264 0.5C10.4307 0.5 10.5934 0.541667 10.752 0.625C10.9107 0.708333 11.0397 0.833333 11.139 1L20.389 17C20.489 17.1667 20.535 17.3377 20.527 17.513C20.519 17.6883 20.473 17.8507 20.389 18C20.3057 18.15 20.189 18.271 20.039 18.363C19.889 18.455 19.7224 18.5007 19.539 18.5H0.98903ZM2.71403 16.5H17.814L10.264 3.5L2.71403 16.5ZM10.264 15.5C10.5474 15.5 10.785 15.404 10.977 15.212C11.169 15.02 11.2647 14.7827 11.264 14.5C11.264 14.2167 11.168 13.979 10.976 13.787C10.784 13.595 10.5467 13.4993 10.264 13.5C9.9807 13.5 9.74303 13.596 9.55103 13.788C9.35903 13.98 9.26336 14.2173 9.26403 14.5C9.26403 14.7833 9.36003 15.021 9.55203 15.213C9.74403 15.405 9.98136 15.5007 10.264 15.5ZM10.264 12.5C10.5474 12.5 10.785 12.404 10.977 12.212C11.169 12.02 11.2647 11.7827 11.264 11.5V8.5C11.264 8.21667 11.168 7.979 10.976 7.787C10.784 7.595 10.5467 7.49933 10.264 7.5C9.9807 7.5 9.74303 7.596 9.55103 7.788C9.35903 7.98 9.26336 8.21733 9.26403 8.5V11.5C9.26403 11.7833 9.36003 12.021 9.55203 12.213C9.74403 12.405 9.98136 12.5007 10.264 12.5Z"
          fill="#DC3545"
        />
      </svg>
      <p className="ps-1 danger">{errormessage}</p>
    </div>
  );

  const viewpwd = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.3267 0L0 1.32686L3.32283 4.64929C1.86598 5.81447 0.715658 7.30499 0 8.99996C1.73 13.0973 6 16 11 16C12.1278 16 13.2185 15.8523 14.2513 15.5765L16.9518 18.2766L18.2785 16.9497L1.3267 0ZM12.6749 14.0002L10.4219 11.7475C9.84146 11.6411 9.30305 11.3759 8.87868 10.9799C8.40503 10.5378 8.10612 9.96312 8.02343 9.34933L4.68517 6.01147C3.63979 6.83061 2.78481 7.84738 2.18 8.99996C2.98825 10.5402 4.24331 11.838 5.80248 12.7457C7.36165 13.6533 9.1624 14.1345 11 14.1345C11.5637 14.1345 12.124 14.0892 12.6749 14.0002ZM10.2905 6.27939L13.8592 9.84771C13.9517 9.57602 14 9.29031 14 8.99996C14 8.25735 13.6839 7.54516 13.1213 7.02006C12.5587 6.49496 11.7956 6.19996 11 6.19996C10.7589 6.19996 10.5209 6.22704 10.2905 6.27939ZM19.82 8.99996C19.0728 10.424 17.9436 11.6407 16.5453 12.5335L17.92 13.9081C19.7365 12.6789 21.1645 10.9788 22 8.99996C20.27 4.90262 16 1.99996 11 1.99996C9.50062 1.99996 8.06689 2.26098 6.74761 2.73697L8.24441 4.2336C9.13525 3.99055 10.0628 3.86539 11 3.86539C12.8376 3.86539 14.6383 4.34658 16.1975 5.25425C17.7567 6.16193 19.0117 7.45967 19.82 8.99996Z"
        fill="#D9D9D9"
      />
    </svg>
  );
  const hidepwd = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
    >
      <path
        d="M11 6.2C11.7956 6.2 12.5587 6.495 13.1213 7.0201C13.6839 7.5452 14 8.25739 14 9C14 9.74261 13.6839 10.4548 13.1213 10.9799C12.5587 11.505 11.7956 11.8 11 11.8C10.2044 11.8 9.44129 11.505 8.87868 10.9799C8.31607 10.4548 8 9.74261 8 9C8 8.25739 8.31607 7.5452 8.87868 7.0201C9.44129 6.495 10.2044 6.2 11 6.2ZM11 2C16 2 20.27 4.90267 22 9C20.27 13.0973 16 16 11 16C6 16 1.73 13.0973 0 9C1.73 4.90267 6 2 11 2ZM2.18 9C2.98825 10.5403 4.24331 11.838 5.80248 12.7457C7.36165 13.6534 9.1624 14.1346 11 14.1346C12.8376 14.1346 14.6383 13.6534 16.1975 12.7457C17.7567 11.838 19.0117 10.5403 19.82 9C19.0117 7.45971 17.7567 6.16197 16.1975 5.2543C14.6383 4.34662 12.8376 3.86544 11 3.86544C9.1624 3.86544 7.36165 4.34662 5.80248 5.2543C4.24331 6.16197 2.98825 7.45971 2.18 9Z"
        fill="#D9D9D9"
      />
    </svg>
  );
  if (!captchaData || !captchaData.imageData) {
    <Loading />;
  }
  return (
    <div className="reset-pwd">
      {isResetPopup && (
        <Popup
          onNevigate={() => {
            setIsResetPopup(false);
            setMyAuth((prevAuth) => ({
              ...prevAuth,
              editpwd: false,
            }));
            window.location.reload();
          }}
          onClose={() => {
            setIsResetPopup(false);
          }}
          icon={"check"}
          title={"修改密碼成功"}
          orangebtn={"好的"}
          greenbtn={""}
        />
      )}
      {isPopupOpen && (
        <Popup
          onNevigate={() => {
            setIsPopupOpen(false);
            navigate(navigatePath);
          }}
          onClose={() => {
            setIsPopupOpen(false);
          }}
          icon={"check"}
          title={"已修改成功"}
          orangebtn={"前往登入"}
          greenbtn={""}
        />
      )}

      {state === "L" && (
        <div className={`${"d-block"}`}>
          <form
            className={`d-flex flex-column gap-4`}
            onSubmit={handlePhoneNext}
          >
            <div>
              <label htmlFor="phone-number" className="form-label d-block">
                <h6 className="primary-deep2">手機號碼</h6>
              </label>

              {renderInputField(
                "phone-number",
                "text",
                "tel",
                "form-input",
                "輸入手機號碼(0912345678)",
                phone,
                handlePhoneNumberChange,
                () => {
                  setPhoneBlur(true);
                  checkAccount();
                },
                () => {
                  setPhoneBlur(false);
                },
                10,
                10,
                isConnect
              )}
              {/* 手機號碼blur的時候檢查是否可以發送驗證碼 */}
              {errorInput(
                phone || phoneOnBlur,
                isValidPhoneNumber(phone),
                "error-phonenumber",
                "請輸入正確的格式(例如：0912345678)，請注意輸入號碼需為半形",
                " d-block",
                "d-none"
              )}
              {errorInput(
                isValidPhoneNumber(phone) && phoneError,
                !phoneOnBlur,
                "error-notfound-phonenumber",
                phoneError,
                "d-block",
                "d-none"
              )}
              {errorInput(
                isValidPhoneNumber(phone),
                !phoneNotFound,
                "error-notfound-phonenumber",
                "查無此手機號碼",
                "d-block",
                "d-none"
              )}
            </div>
            {showNext ? (
              <></>
            ) : (
              <div>
                <div className="capcha-box">
                  <div className="capcha-foriput">
                    <label
                      htmlFor="phonecaptcha"
                      className="form-label d-block"
                    >
                      <h6 className="primary-deep2">驗證碼(必填)</h6>
                    </label>
                    {renderInputField(
                      "phonecaptcha",
                      "phonecaptcha",
                      "text",
                      "form-input w-100",
                      "請輸入手機驗證碼",
                      captcha,
                      handleCaptchaChange,
                      () => {
                        setCaptchaBlur(true);
                        
                      },
                      () => {
                        setCaptchaBlur(false);
                      }
                    )}
                  </div>

                  <div className="capcha-photo pt-2">
                    <button
                      onClick={handlePhoneCheck}
                      className="btn primary-btn"
                      disabled={
                        !phone ||
                        !isValidPhoneNumber(phone) ||
                        verifysent ||
                        phoneNotFound
                      }
                    >
                      <p className="p2">
                        {verifysent ? `發送驗證碼(${countdown})` : "發送驗證碼"}
                      </p>
                    </button>
                  </div>
                  
                </div>
                {/* <TextPopup temp={"sms"}/> */}
                <div
          className={`pt-0 ${
            !isValidPhoneNumber(phone) 
              ? "d-block w-100"
              : "d-none"
          }`}
        >
          <Nodata
            icon={"dangernotice"}
            dangerclass={"ps-1"}
            dangerdescript={`尚未填寫正確的${
              isValidPhoneNumber(phone)
                ? ""
                : "手機"
            }
                欄位，無法發送驗證碼`}
            bgclass={"bg-white"}
          />
        </div>
                {errorInput(
                  verifycaptcha,
                  isSameCaptcha,
                  "error-pwd",
                  "請輸入正確的驗證碼",
                  "d-block",
                  "d-none"
                )}
              </div>
            )}
            {showNext ? (
              <></>
            ) : (
              <div>
                <button
                  className={`btn primary-btn2 form-input ${
                    showNext ? "d-none" : "d-block"
                  }`}
                  type="submit"
                  // disabled={
                  //   !phone ||
                  //   !isValidPhoneNumber(phone) ||
                  //   phoneNotFound ||
                  //   !captcha
                  // }
                >
                  <p className="p1">下一步</p>
                </button>
                {errorInput(
                  isValidPhoneNumber(phone) && phoneError,
                  !phoneOnBlur,
                  "error-notfound-phonenumber",
                  phoneError,
                  "d-block",
                  "d-none"
                )}
                {errorInput(
                  isValidPhoneNumber(phone),
                  !phoneNotFound,
                  "error-notfound-phonenumber",
                  "查無此手機號碼",
                  "d-block",
                  "d-none"
                )}
                <div
                  className={`pt-0 ${
                    !isValidPhoneNumber(phone) || !isValidCaptcha(captcha)
                      ? "d-block w-100"
                      : "d-none"
                  }`}
                >
                  <Nodata
                    icon={"dangernotice"}
                    dangerclass={"ps-1"}
                    dangerdescript={`尚未填寫正確的
                  ${
                    isValidPhoneNumber(phone)
                      ? ""
                      : !isValidPhoneNumber(phone) && isValidCaptcha(captcha)
                      ? "帳號(手機號碼)"
                      : "帳號(手機號碼)、"
                  }
                 
                  ${
                    isValidCaptcha(captcha)
                      ? ""
                      : !isValidCaptcha(captcha)
                      ? "驗證碼"
                      : ""
                  }`}
                    bgclass={"bg-white"}
                  />
                </div>
              </div>
            )}
          </form>
          {/* <button onClick={handlePhoneNext}>下一步</button> */}
        </div>
      )}
      {state === "F" && (
        <div className={`${"d-block"}`}>
          <form
            className={` d-flex flex-column gap-4`}
            onSubmit={handleEmailNext}
          >
            <div>
              <label htmlFor="email" className="form-label d-block">
                <h6 className="primary-deep2">電子信箱</h6>
              </label>
              {renderInputField(
                "email",
                "email",
                "email",
                "form-input",
                "輸入註冊信箱",
                email,
                handleEmailChange,
                () => {
                  setEmailonBlur(true);
                  checkAccount();
                },
                () => {
                  setEmailonBlur(false);
                  
                },
                5,
                100,
                isConnect
              )}

              {errorInput(
                email || emailOnblur,
                isValidEmail(email),
                "same-email",
                "請輸入正確的信箱格式",
                "d-block",
                "d-none"
              )}
              {errorInput(
                isValidEmail(email),
                !emailError,
                "error-email",
                emailError,
                "d-block",
                "d-none"
              )}
              {errorInput(
                isValidEmail(email) && emailNotFound,
                !emailNotFound,
                "error-notfound-email",
                "查無此信箱",
                "d-block",
                "d-none"
              )}
            </div>

            {showNext ? (
              <></>
            ) : (
              <div>
                <div className="capcha-box">
                  <div className="capcha-foriput">
                    <label
                      htmlFor="emailcaptcha"
                      className="form-label d-block"
                    >
                      <h6 className="primary-deep2">驗證碼(必填)</h6>
                    </label>
                    {renderInputField(
                      "emailcaptcha",
                      "emailcaptcha",
                      "text",
                      "form-input w-100",
                      "請輸入信箱驗證碼",
                      emailcaptcha,
                      handleEmailCaptchaChange,
                      () => {
                        setEmailCaptchaBlur(true);
                        setVerifyEmailCaptcha(false);
                      },
                      () => {
                        setEmailCaptchaBlur(true);
                      }
                    )}
                  </div>

                  <div className="capcha-photo pt-2">
                    <button
                      onClick={handleEmailCheck}
                      className="btn primary-btn"
                      disabled={
                        !email ||
                        !isValidEmail(email) ||
                        verifysent ||
                        emailNotFound
                      }
                    >
                      <p className="p2">
                        {verifysent ? `發送驗證碼(${countdown})` : "發送驗證碼"}
                      </p>
                    </button>
                  </div>
                 
                </div>
                <div
          className={`pt-0 ${
            !isValidEmail(email) 
              ? "d-block w-100"
              : "d-none"
          }`}
        >
          <Nodata
            icon={"dangernotice"}
            dangerclass={"ps-1"}
            dangerdescript={`尚未填寫正確的${
              isValidEmail(email)
                ? ""
                : "信箱"
            }
                欄位，無法發送驗證碼`}
            bgclass={"bg-white"}
          />
        </div>
                {errorInput(
                  emailCaptchaOnblur && verifyEmailCaptcha,
                  isSameEmailCaptcha,
                  "error-pwd",
                  "請輸入正確的驗證碼，請注意輸入驗證碼需為半形",
                  "d-block",
                  "d-none"
                )}
              </div>
            )}

            {showNext ? (
              <></>
            ) : (
              <div>
                <button
                  className={`btn primary-btn2 form-input ${
                    showNext ? "d-none" : "d-block"
                  }`}
                  type="submit"
                >
                  下一步
                </button>
                <div
                  className={`pt-0 ${
                    !isValidEmail(email) || !emailcaptcha
                      ? "d-block w-100"
                      : "d-none"
                  }`}
                >
                  <Nodata
                    icon={"dangernotice"}
                    dangerclass={"ps-1"}
                    dangerdescript={`尚未填寫正確的
                  ${
                    isValidEmail(email)
                      ? ""
                      : !isValidEmail(email) && emailcaptcha
                      ? "信箱"
                      : "信箱、"
                  }
                 
                  ${emailcaptcha ? "" : !emailcaptcha ? "驗證碼" : ""}`}
                    bgclass={"bg-white"}
                  />
                </div>
                <div
                  className={`pt-0 ${
                    isValidEmail(email) && emailError && emailNotFound
                      ? "d-block w-100"
                      : "d-none"
                  }`}
                >
                  <Nodata
                    icon={"dangernotice"}
                    dangerclass={"ps-1"}
                    dangerdescript={emailError}
                    bgclass={"bg-white"}
                  />
                </div>
                <div
                  className={`pt-0 ${
                    isValidEmail(email) && emailError
                      ? "d-block w-100"
                      : "d-none"
                  }`}
                >
                  <Nodata
                    icon={"dangernotice"}
                    dangerclass={"ps-1"}
                    dangerdescript={emailError}
                    bgclass={"bg-white"}
                  />
                </div>

                <div
                  className={`pt-0 ${
                    isValidEmail(email) && emailNotFound
                      ? "d-block w-100"
                      : "d-none"
                  }`}
                >
                  <Nodata
                    icon={"dangernotice"}
                    dangerclass={"ps-1"}
                    dangerdescript={"查無此信箱"}
                    bgclass={"bg-white"}
                  />
                </div>
              </div>
            )}
          </form>
        </div>
      )}

      {showNext && (
        <div className={`${"d-block"}`}>
          <form
            className={`d-flex flex-column gap-4  `}
            onSubmit={handleChangepwd}
          >
            <div className="pt-4">
              <label htmlFor="new-pwd" className="form-label d-block">
                <h6 className="primary-deep2">新密碼</h6>
              </label>
              <div className="d-flex pwd-input">
                {renderInputField(
                  "new-pwd",
                  showNewPassword ? "text" : "password",
                  "new-pwd",
                  "form-input form-pwd",
                  "請輸入新密碼",
                  newPassword,
                  handleNewPasswordChange,
                  () => {
                    setNewPwdBlur(true);
                  },
                  () => {
                    setNewPwdBlur(false);
                  },
                  8,
                  20
                )}
                <div className="btn see-pwd ">
                  <button
                    className="btn checkbox-icon check showpwd"
                    onClick={toggleNewPasswordVisibility}
                  >
                    {showNewPassword ? viewpwd : hidepwd}
                  </button>
                </div>
              </div>
              <div className="pt-1 secondary-deep">
                <p>英文字母大小寫加上數字,共8~20位英數字</p>
              </div>
              {errorInput(
               newPassword || newPwdBlur,
                isValidPassword(newPassword),
                "error-confirm-pwd",
                "請輸入正確的密碼格式",
                "d-block",
                "d-none"
              )}
            </div>
            <div>
              <label htmlFor="confirm-pwd" className="form-label d-block">
                <h6 className="primary-deep2">再次輸入密碼(必填)</h6>
              </label>
              {renderInputField(
                "confirm-pwd",
                "password",
                "confirm-pwd",
                "form-input ",
                "請再次輸入密碼",
                confirmPassword,
                handleConfirmPasswordChange,
                () => {
                  setConfirmPasswordBlur(true);
                },
                () => {
                  setConfirmPasswordBlur(true);
                },
                8,
                20
              )}
              {errorInput(
                confirmPassword || confirmPasswordOnBlur,
                !confirmPasswordError,
                "error-confirm-pwd",
                "再次輸入密碼和新密碼不相同",
                "d-block",
                "d-none"
              )}
            </div>
            <div>
              
              <button
                className="btn primary-btn2 form-input"
                type="submit"
                // disabled={!passwardValid || !isConnect || confirmPasswordError}
              >
                <p className="p1">儲存變更</p>
              </button>
              <div
                  className={`pt-0 ${
                    !isValidPassword(newPassword) || confirmPasswordError
                      ? "d-block w-100"
                      : "d-none"
                  }`}
                >
                  <Nodata
                    icon={"dangernotice"}
                    dangerclass={"ps-1"}
                    dangerdescript={`尚未填寫正確的
                  ${
                    isValidPassword(newPassword)
                      ? ""
                      : !isValidPassword(newPassword) && !confirmPasswordError
                      ? "新密碼"
                      : "新密碼、"
                  }
                 
                  ${!confirmPasswordError ? "" : confirmPasswordError ? "再次輸入密碼" : ""}`}
                    bgclass={"bg-white"}
                  />
                </div>
            </div>

          </form>
        </div>
      )}
    </div>
  );
}

export default ResetPwdSection;
