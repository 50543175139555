import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import Loading from './Loading';
function Faq() {
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    // Replace the fetch call with axios
    axios.get('/api/Home/Faq')
      .then(response => {
        if (response.status !== 200) {

          // window.location.href = '/notfound';
          return [];
        }
        return response.data;
      })
      .then(data => {
        if (data) {
          setApiData(data);
          console.log('Faq Data from API:', "data");
        }
      })
      .catch(error => {
        console.error('Error fetching data from API:', "error");
      });
  }, []);
if(!apiData || apiData.length === 0){
 return <Loading/>
}
  return (
    <div className="container faq px-md-0">
      <h4>常見問題</h4>
      <div className="pt-3">
        
          {apiData.map((faqItem, index) => (
           
           <div key={index}>
              <h5 className='pb-2'>{faqItem.title}</h5>
              {faqItem.children.map((childItem, childIndex) => (
                 <Accordion flush key={childIndex}>
                <Accordion.Item  eventKey={index.toString()}>
                
                  <Accordion.Header>{childItem.title}</Accordion.Header>
                  <Accordion.Body>
                    <div dangerouslySetInnerHTML={{ __html: childItem.content }} />
                  </Accordion.Body>
                
                </Accordion.Item>
                 </Accordion>
              ))}
              </div>
             
          ))}
       
      </div>
    </div>
  );
}

export default Faq;








