import { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { CheckoutProvider, useCheckout } from "../../Context/CheckoutContext";
import Promolist from "../../sharedComponents/Promolist";
import AuthContext from "../../Context/AuthContext";
import Cartlist from "../../sharedComponents/Cartlist";
import Loading from "../Loading";
import CheckoutShop from "./CheckoutShop";
function CheckoutStep3({ storePickUp, handlePrevStep, handleOrderData }) {
  //會員資訊
  const { myAuth, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  //會員資訊
  const auth = myAuth.authorized;
  const mid = parseInt(myAuth.sid);
  const storedToken = myAuth.token;
  // 使用 handleNextStep 函数
  // const {  setOrderData, handleNextStep,  handlePrevStep} = useCheckout();
  const [isLoading, setIsLoading] = useState(true);
  //取得最新資料
  const [checkData, setCheckData] = useState({});
  // 登入的購物車資料
  const [cartData, setCartData] = useState([]);
  // 未登入的購物車資料
  const localStorageCartData = localStorage.getItem("cart");
  //資料庫撈出的超商資訊
  const [cvsData, setCvsData] = useState(null);

  // 下一步的disable
  const [isNextDisabled, setNextDisabled] = useState(false);

  // console.log("checkoutData", checkoutData);
  const stepTwoStoreData = JSON.parse(localStorage.getItem("stepTwoStoreData"));
  const storePromo = JSON.parse(localStorage.getItem("promoData"));
  const escapeHTML = (unsafeText) => {
    return unsafeText.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  };
  const handleNextButtonClick = () => {
    if (isNextDisabled) {
      console.log("連點禁止");
      return;
    }
    setNextDisabled(true);
    let requestData = {
      mid: mid,
      shippingId: stepTwoStoreData.shippingId.toString(),
      paymentId: stepTwoStoreData.paymentId,
      invoiceType: stepTwoStoreData.invoiceType,
      recipientName: stepTwoStoreData.recipientName,
      recipientMobile: stepTwoStoreData.recipientMobile,
      recipientTel: stepTwoStoreData.recipientTel,
      orderRemark: escapeHTML(stepTwoStoreData.orderRemark),
    };
    let addr = stepTwoStoreData.recipientAddr.replace(/\/+/g, "");
    if (addr.trim()) {
      // 如果有,恢復格式
      requestData.recipientAddr = stepTwoStoreData.recipientAddr;
    }

    if (
      stepTwoStoreData.recipientShop &&
      stepTwoStoreData.recipientShop.cvsInfo
    ) {
      requestData.recipientShop = stepTwoStoreData.recipientShop.cvsInfo;
    }
    if (storePromo && storePromo.Discount) {
      requestData = {
        ...requestData,
        ...storePromo.Discount,
        ...stepTwoStoreData.invoiceData,
      };

      console.log("Discount", "storePromo.Discount");
    } else {
      requestData = {
        ...requestData,
        ...stepTwoStoreData.invoiceData,
      };
    }
    // 若未登入會有email欄位
    const cartItems = JSON.parse(localStorageCartData);
    if (!auth) {
      requestData.recipinetEmailForNonM =
        stepTwoStoreData.recipinetEmailForNonM;
      requestData.mid = 0;
      requestData.orderItems = cartItems;
    }
    if (auth) {
      axios
        .post("/api/Order/ToOrder", requestData, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("發送Order/ToOrder的回應", "response.data");
          console.log("發送Order/ToOrder資訊:", "requestData");
          setIsLoading(false);
          //TODO:
          handleOrderData(response.data);

          if (
            response.data.paymentid === "1" &&
            response.data.b2CLink !== null
          ) {
            console.log("b2CLink", "response.data.b2CLink");
            localStorage.removeItem("stepTwoStoreData");
            localStorage.removeItem("promoData");
            window.location.href = response.data.b2CLink;
          } else if (response.data.paymentId !== "1") {
            console.log("response.data.paymentid", "response.data.orderno");
            navigate("/finishorder", { state: response.data.orderno });
          } else {
            console.log("例外情況");
          }
        })
        .catch((error) => {
          console.error("發送Order/ToOrder的回應有錯誤：", error);
          console.log("發送Order/ToOrder資訊有錯誤", "requestData");
          if (error.response && error.response.status === 401) {
            // 如果是 401，觸發 logout()
            logout();
          }
        })
        .finally(() => {
          setNextDisabled(false);
        });
    }
    if (!auth) {
      axios
        .post("/api/Order/ToOrderNonM", requestData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("發送Order/ToOrderNonM的回應", "response.data");
          console.log("發送Order/ToOrderNonM資訊:", "requestData");
          setIsLoading(false);
          //TODO:
          handleOrderData(response.data);

          if (
            response.data.paymentid === "1" &&
            response.data.b2CLink !== null
          ) {
            console.log("b2CLink", "response.data.b2CLink");
            console.log("response.data", "response.data");
            localStorage.removeItem("stepTwoStoreData");
            localStorage.removeItem("cart");
            window.location.href = response.data.b2CLink;
          } else if (response.data.paymentId !== "1") {
            console.log("response.data.paymentid", "response.data.orderno");
            console.log("response.data", "response.data");
            console.log("例外情況");
            // navigate("/finishorder", { state: response.data.orderno });
          } else {
            console.log("例外情況");
          }
        })
        .catch((error) => {
          console.error("發送Order/ToOrderNonM的回應有錯誤：", "error");
          console.log("發送Order/ToOrderNonM資訊有錯誤", "requestData");
        })
        .finally(() => {
          setNextDisabled(false);
        });
    }

    //handleNextStep(); // 調用 handleNextStep 函数來更新 step
  };
  const handlePrevButtonClick = () => {
    handlePrevStep(); // 調用函数來更新 step
  };

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    let requestData = {
      mid: stepTwoStoreData.mid,
      shippingId: stepTwoStoreData.shippingId.toString(),
      paymentId: stepTwoStoreData.paymentId,
      invoiceType: stepTwoStoreData.invoiceType,
      recipientName: stepTwoStoreData.recipientName,
      recipientMobile: stepTwoStoreData.recipientMobile,
      recipientTel: stepTwoStoreData.recipientTel,
      recipientAddr: stepTwoStoreData.recipientAddr,
      orderRemark: escapeHTML(stepTwoStoreData.orderRemark),
    };

    if (
      stepTwoStoreData.recipientShop &&
      stepTwoStoreData.recipientShop.cvsInfo
    ) {
      requestData.recipientShop = stepTwoStoreData.recipientShop.cvsInfo;
    }

    if (storePromo && storePromo.Discount) {
      requestData = {
        ...requestData,
        ...storePromo.Discount,
        ...stepTwoStoreData.invoiceData,
      };

      console.log("Discount", "storePromo.Discount");
    } else {
      requestData = {
        ...requestData,
        ...stepTwoStoreData.invoiceData,
      };
    }
    if (auth) {
      axios
        .post("/api/Order/OrderCheckOut", requestData, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("發送步驟二資訊的回應", "response.data");
          console.log("發送步驟二資訊:", "requestData");
          setCheckData(response.data);

          if (response.data.recipientShop) {
            setCvsData(response.data.recipientShop);
          }

          const newCart = response.data.orderItems;
          setCartData(newCart);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("步驟二資訊回傳有錯誤：", "error");
          console.log("發送步驟二資訊有錯誤", "requestData");
          if (error.response && error.response.status === 401) {
            // 如果是 401，觸發 logout()
            logout();
          }
        });
    } else if (!auth) {
      const cartItems = JSON.parse(localStorageCartData);
      const unauthrequestData = {
        ...requestData,
        orderItems: cartItems,
        recipinetEmailForNonM: stepTwoStoreData.recipinetEmailForNonM,
      };
      axios
        .post("/api/Order/OrderCheckOutNonM", unauthrequestData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("發送步驟二資訊的回應", "response.data");
          console.log("發送步驟二資訊:", "unauthrequestData");
          setCheckData(response.data);

          if (response.data.recipientShop) {
            setCvsData(response.data.recipientShop);
          }

          const newCart = response.data.orderItems;
          setCartData(newCart);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("步驟二資訊回傳有錯誤：", "error");
          console.log("發送步驟二資訊有錯誤", "unauthrequestData");
        });
    }
  }, [auth]);

  //TODO:折抵

  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      <h4 className="d-xxl-none checkout-title pb-2">訂單明細</h4>
      {cartData.length > 0 && !isLoading ? (
        <div className="checkout-section">
          <div className="pt-1 pt-md-3 page-cart  container px-0 d-flex flex-column gap-10px">
            <div className="page-content-title">
              <h6>商品明細</h6>
              <h6>價格 / 單價</h6>
              <h6>數量</h6>
              <h6>加購</h6>
              <h6>小計</h6>
            </div>
            <div className="page-content ">
              <div className="overflow-wrap">
                <div className="d-flex flex-column gap-10px">
                  {cartData.map((item, index) => (
                    <Cartlist
                      key={index}
                      cartitem={item}
                      content={"page"}
                      DeleteShow={"d-none d-xl-none"}
                    />
                  ))}
                  {checkData.promoGiftData && (
                    <Promolist
                      promolist={checkData.promoGiftData}
                      content={"page"}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="container d-flex gap-10px flex-column justify-content-end  bottom-container">
              <div className="d-flex justify-content-end gap-10px">
                <div className="count d-flex flex-column gap-10px primary-deep2">
                  <div className="d-flex justify-content-end flex-column gap-10px">
                    <div className="d-flex justify-content-between">
                      <h6>合計</h6>
                      <h6>${checkData.orderItemTotal.toLocaleString()}</h6>
                    </div>
                    {checkData && checkData.otherFee && (
                      <div className="d-flex justify-content-between">
                        <h6>手續費</h6>
                        <h6>${checkData.otherFee.toLocaleString()}</h6>
                      </div>
                    )}
                    {checkData && checkData.orderDiscount !== null && (
                      <div className="d-flex justify-content-between">
                        <h6>折扣</h6>
                        <h6>${checkData.orderDiscount.toLocaleString()}</h6>
                      </div>
                    )}
                    <div className="d-flex justify-content-between">
                      <h6>運費</h6>
                      <h6>${checkData.shippingFee.toLocaleString()}</h6>
                    </div>
                    <div className="d-flex justify-content-between">
                      <h6>稅金</h6>
                      <h6>${checkData.orderFax.toLocaleString()}</h6>
                    </div>
                    <div className="total-cartprice">
                      <h6>總金額</h6>
                      <h6>${checkData.orderTotal.toLocaleString()}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom w-100 justify-content-start pt-5 px-2">
              <h4 className="phone-h6">付款方式：{checkData.paymentMethod}</h4>
              <div className="pt-2 primary-deep">
                <div className="d-flex pt-2">
                  <h5>收件人：</h5>
                  <h5>{checkData.recipientName}</h5>
                </div>
                <div className="d-flex pt-2">
                  <h5>聯絡手機：</h5>
                  <h5>{checkData.recipientMobile}</h5>
                </div>
                <div className="d-flex pt-2">
                  <h5>聯絡市話：</h5>
                  <h5>{checkData.recipientTel || "（無）"}</h5>
                </div>
                {!cvsData && (
                  <div className="d-flex pt-2">
                    {checkData.recipientAddr &&
                    checkData.recipientAddr === "/////////" &&
                    checkData.recipientShop === null ? (
                      <>
                        <h5>自取地址：</h5>
                        <h5 className="text-wrap">{storePickUp}</h5>
                      </>
                    ) : (
                      <>
                        <h5>收件地址：</h5>
                        <h5 className="text-wrap">
                          {checkData.recipientAddr.split("///").join("")}
                        </h5>
                      </>
                    )}
                  </div>
                )}
                {cvsData && (
                  <>
                    <div className="d-flex pt-2">
                      <h5>{checkData.shippingMethod}</h5>
                    </div>
                    <CheckoutShop
                      shop={stepTwoStoreData.shippingId}
                      type={stepTwoStoreData.selectedAreaType}
                      cvsData={cvsData}
                    />
                  </>
                )}
                <div className="d-flex pt-2">
                  <h5>發票資訊：</h5>
                  <h5>{checkData.invoiceType === "2" ? "二聯式" : "三聯式"}</h5>
                </div>
                {checkData.invoiceNo && (
                  <div className="d-flex pt-2">
                    <h5>統一編號：</h5>
                    <h5>{checkData.invoiceNo}</h5>
                  </div>
                )}
                {checkData.invoiceTitle && (
                  <div className="d-flex pt-2">
                    <h5>發票抬頭：</h5>
                    <h5>{checkData.invoiceTitle}</h5>
                  </div>
                )}
                <div className="d-flex pt-2">
                  <h5>備註：</h5>
                  <h5>{checkData.orderRemark || "（無）"}</h5>
                </div>
                {checkData.recipinetEmailForNonM && (
                  <div className="d-flex pt-2">
                    <h5>信箱：</h5>
                    <h5 className="text-wrap">
                      {checkData.recipinetEmailForNonM}
                    </h5>
                  </div>
                )}
                <div className="d-flex pt-2">
                  <h5 className="danger phone-p">＊訂單成立後無法取消訂單</h5>
                </div>
              </div>
            </div>
            <div className="container pt-4 pb-5 px-0">
              <div className="d-flex flex-column  ">

                <div className="d-flex flex-column flex-xl-row gap-2 gap-xl-5 px-0">
                  <div
                    onClick={handlePrevButtonClick}
                    className="btn primary-btn-big2 w-100"
                  >
                    <h6 className="font-weight-bold ">返回上頁</h6>
                  </div>
                  <div
                    onClick={handleNextButtonClick}
                    className="btn primary-btn-big2 w-100"
                  >
                    <h6 className="font-weight-bold ">確認結帳</h6>
                  </div>
                </div>
                <div className="d-flex gap-5 px-0 pt-2 pt-xl-3">
                  <div className="d-flex w-100 gap-2 gap-xl-3">
                    <Link
                     to={"/"}
                      className="btn primary-btn-big w-100"
                    >
                      <h6 className="font-weight-bold ">返回首頁</h6>
                    </Link>
                    <Link to={"/cart"}
                      className="btn primary-btn-big w-100"
                    >
                      <h6 className="font-weight-bold ">返回購物車</h6>
                    </Link >
                  </div>
                  <div className="w-100 d-none d-xl-block"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* <div>CheckoutStep3</div>
      <div onClick={handleNextButtonClick}>下一步</div>{" "} */}
      {/* 點擊時調用 handleNextButtonClick */}
    </>
  );
}

export default CheckoutStep3;
