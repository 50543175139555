import { useMemo } from 'react';

function useFormattedDate(date) {
  console.log("useFormattedDate","date")
  return useMemo(() => {
    const orderDate = new Date(date);
    
    const year = orderDate.getFullYear();
    const month = String(orderDate.getMonth() + 1).padStart(2, '0'); 
    const day = String(orderDate.getDate()).padStart(2, '0');

    let hours = String(orderDate.getHours()).padStart(2, '0');
    const minutes = String(orderDate.getMinutes()).padStart(2, '0');
    const seconds = String(orderDate.getSeconds()).padStart(2, '0');

    // 判斷是上午還是下午
    let amOrPm = '上午';
    if (hours >= 12) {
      amOrPm = '下午';
      hours = String(hours - 12).padStart(2, '0');
    }
    
    const formattedDate = `${year}/${month}/${day}`;
    const formattedTime = `${amOrPm} ${hours}:${minutes}:${seconds}`;
    
    return { formattedDate, formattedTime };
  }, [date]); 
}

export default useFormattedDate;
