//InvoiceSelect
import React, { useState, useEffect, useContext } from "react";

import AuthContext from "../Context/AuthContext";
function InvoiceSelect({
  onInvoiceChange,
  isInvoiceValid,
  noChangeOk,
  errornone,
  initInvoiceType,
}) {
  // const [invoiceOption, setInvoiceOption] = useState(null);

  const [selectedInvoiceOption, setSelectedInvoiceOption] = useState("");

  //三聯式的資料
  const [businessNumber, setBusinessNumber] = useState("");
  const [invoiceTitle, setInvoiceTitle] = useState("");
  const [bussinessBlur, setBussinessBlur] = useState(false);
  const [invoiceTitleBlur, setinvoiceTitleBlur] = useState(false);
  //是否有改變發票資料
  const [hasTypeChange, setHasTypeChange] = useState(false);
  const [hasTitleChange, setHasTitleChange] = useState(false);
  const [hasNumberChange, setHasNumberChange] = useState(false);

  const { myAuth } = useContext(AuthContext);
  const mid = parseInt(myAuth.sid);
  const storedToken = myAuth.token;

  const invoiceoptions = [
    { label: "二聯式發票", value: "2" },
    { label: "三聯式發票", value: "3" },
  ];

  //初始值
  useEffect(()=>{
isInvoiceValid(false)
if(initInvoiceType){
  setSelectedInvoiceOption(initInvoiceType)
}
  },[])
  // useEffect(() => {
  //   console.log("myAuth", "myAuth");
  //   setSelectedInvoiceOption(myAuth.invoiceType);
  //   if (myAuth.invoiceType === "3") {
  //     setInvoiceTitle(myAuth.invoiceTitle);
  //     setBusinessNumber(myAuth.businessNo);
  //   }
  // }, []);

  // 若選擇了3聯式
  useEffect(() => {
    // console.log("myAuth.invoiceType", myAuth.invoiceType);
    if (myAuth && selectedInvoiceOption) {
      const selectedInvoiceType = selectedInvoiceOption.toString();
      const authInvoiceType = myAuth.invoiceType ? myAuth.invoiceType.toString() : "";
  
      if (selectedInvoiceType === authInvoiceType && authInvoiceType === "3") {
        setInvoiceTitle(myAuth.invoiceTitle);
        setBusinessNumber(myAuth.businessNo);
      }
    }
   
  }, [selectedInvoiceOption]);
  //是否修改type
  const handleTypeChange = (e) => {
    const inputValue = e.target.value;
    setSelectedInvoiceOption(inputValue);
    if (inputValue !== myAuth.invoiceType) {
      setHasTypeChange(true);
      console.log("已修改", " e.target.value");
    } else {
      setHasTypeChange(false);
    }
  };

  //是否修改發票抬頭
  const handleTitleChange = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(
      /[\s~`!@#$%^&*()_+=[{\]};:'"<>,./?\\|]/g,
      ""
    );
    setInvoiceTitle(sanitizedValue);
    if (sanitizedValue !== myAuth.invoiceTitle) {
      setHasTitleChange(true);
      console.log("已修改");
    } else {
      setHasTitleChange(false);
    }
  };

  //是否修改統一編號
  const handleBusinessNumberChange = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/\D/g, ""); // \D 表示非数字字符
    setBusinessNumber(sanitizedValue);
    console.log("hasNumberChange");
    //TODO:檢查統一編號是否與會員資料不同
    if (sanitizedValue !== myAuth.businessNo) {
      setHasNumberChange(true);
      console.log("已修改");
    } else {
      setHasNumberChange(false);
    }
  };
  //編輯狀態
  const handleQuickFill = () => {
    if (!hasTitleChange) {
      setInvoiceTitle(myAuth.invoiceTitle || "");
    }
    if (!hasNumberChange) {
      setBusinessNumber(myAuth.businessNo || "");
    }
  };

  //格式檢查
  const isValidNumber = (businessNumber) => {
    const isValid = /^\d{8}$/.test(businessNumber);
    return isValid;
  };
  const isValidInvoiceTitle = (invoiceTitle) => {
    return invoiceTitle !== null && invoiceTitle !== "";
  };
  //回傳給父層格式檢查
  useEffect(() => {
    console.log("selectedInvoiceOption", "selectedInvoiceOption");
    console.log("hasTypeChange", "hasTypeChange");
    console.log("businessNumber", "businessNumber");
    console.log("invoiceTitle", "invoiceTitle");
    console.log("noChangeOk", "noChangeOk");
    if (selectedInvoiceOption === "2" && (hasTypeChange || noChangeOk)) {
      isInvoiceValid(true);
      console.log("hasTypeChange", "hasTypeChange");
    }
    if (
      selectedInvoiceOption === "3" &&
      (hasTitleChange || hasNumberChange || noChangeOk)
    ) {
      if (isValidNumber(businessNumber) && isValidInvoiceTitle(invoiceTitle)) {
        isInvoiceValid(true);
      } else {
        isInvoiceValid(false);
      }
    }
  }, [
    selectedInvoiceOption,
    businessNumber,
    invoiceTitle,
    hasTypeChange,
    hasTitleChange,
    hasNumberChange,
    noChangeOk,
  ]);

  //當選擇的不是原本的才回傳統一編號、invoicetitle等等
  useEffect(() => {
    onInvoiceChange(selectedInvoiceOption, businessNumber, invoiceTitle);
     console.log("onInvoiceChange")
    //  console.log("selectedInvoiceOption", selectedInvoiceOption)
    //  console.log("businessNumber", businessNumber)
    //  console.log("invoiceTitle", invoiceTitle)
  }, [selectedInvoiceOption, businessNumber, invoiceTitle]);

  // useEffect(() => {
  //   console.log("selected invoice", "selectedInvoiceOption");
  // }, [selectedInvoiceOption]);

  //重複的表單

  const renderInputField = (
    id,
    name,
    placeholder,
    prevalue,
    value,
    onChange,
    onClick = null,
    onBlur = null,
    minLength,
    maxLength
  ) => (
    <input
      type="text"
      id={id}
      name={name}
      className={`form-input ${prevalue ? "prev-input-data" : ""} `}
      placeholder={placeholder || ""}
      onChange={onChange}
      value={value}
      onClick={onClick}
      onBlur={onBlur}
      minLength={minLength}
      maxLength={maxLength}
    />
  );
  const errorInput = (hasChanged, isValid, id, errormessage) =>
    errornone === true ? (
      <div
        className={`error-message ${
          hasChanged && !isValid ? "d-block" : "d-none"
        } d-flex pb-0`}
        id={id}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="19"
          viewBox="0 0 21 19"
          fill="none"
          className="pt-1"
        >
          <path
            d="M0.98903 18.5C0.805697 18.5 0.63903 18.454 0.48903 18.362C0.33903 18.27 0.222364 18.1493 0.139031 18C0.0556972 17.85 0.00969715 17.6873 0.00103049 17.512C-0.00763618 17.3367 0.0383638 17.166 0.139031 17L9.38903 1C9.48903 0.833333 9.61836 0.708333 9.77703 0.625C9.9357 0.541667 10.098 0.5 10.264 0.5C10.4307 0.5 10.5934 0.541667 10.752 0.625C10.9107 0.708333 11.0397 0.833333 11.139 1L20.389 17C20.489 17.1667 20.535 17.3377 20.527 17.513C20.519 17.6883 20.473 17.8507 20.389 18C20.3057 18.15 20.189 18.271 20.039 18.363C19.889 18.455 19.7224 18.5007 19.539 18.5H0.98903ZM2.71403 16.5H17.814L10.264 3.5L2.71403 16.5ZM10.264 15.5C10.5474 15.5 10.785 15.404 10.977 15.212C11.169 15.02 11.2647 14.7827 11.264 14.5C11.264 14.2167 11.168 13.979 10.976 13.787C10.784 13.595 10.5467 13.4993 10.264 13.5C9.9807 13.5 9.74303 13.596 9.55103 13.788C9.35903 13.98 9.26336 14.2173 9.26403 14.5C9.26403 14.7833 9.36003 15.021 9.55203 15.213C9.74403 15.405 9.98136 15.5007 10.264 15.5ZM10.264 12.5C10.5474 12.5 10.785 12.404 10.977 12.212C11.169 12.02 11.2647 11.7827 11.264 11.5V8.5C11.264 8.21667 11.168 7.979 10.976 7.787C10.784 7.595 10.5467 7.49933 10.264 7.5C9.9807 7.5 9.74303 7.596 9.55103 7.788C9.35903 7.98 9.26336 8.21733 9.26403 8.5V11.5C9.26403 11.7833 9.36003 12.021 9.55203 12.213C9.74403 12.405 9.98136 12.5007 10.264 12.5Z"
            fill="#DC3545"
          />
        </svg>
        <p className="ps-1 danger">{errormessage}</p>
      </div>
    ) : (
      <div
        className={`error-message ${
          hasChanged && !isValid ? "visible" : "invisible"
        } d-flex pb-0`}
        id={id}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="19"
          viewBox="0 0 21 19"
          fill="none"
          className="pt-1"
        >
          <path
            d="M0.98903 18.5C0.805697 18.5 0.63903 18.454 0.48903 18.362C0.33903 18.27 0.222364 18.1493 0.139031 18C0.0556972 17.85 0.00969715 17.6873 0.00103049 17.512C-0.00763618 17.3367 0.0383638 17.166 0.139031 17L9.38903 1C9.48903 0.833333 9.61836 0.708333 9.77703 0.625C9.9357 0.541667 10.098 0.5 10.264 0.5C10.4307 0.5 10.5934 0.541667 10.752 0.625C10.9107 0.708333 11.0397 0.833333 11.139 1L20.389 17C20.489 17.1667 20.535 17.3377 20.527 17.513C20.519 17.6883 20.473 17.8507 20.389 18C20.3057 18.15 20.189 18.271 20.039 18.363C19.889 18.455 19.7224 18.5007 19.539 18.5H0.98903ZM2.71403 16.5H17.814L10.264 3.5L2.71403 16.5ZM10.264 15.5C10.5474 15.5 10.785 15.404 10.977 15.212C11.169 15.02 11.2647 14.7827 11.264 14.5C11.264 14.2167 11.168 13.979 10.976 13.787C10.784 13.595 10.5467 13.4993 10.264 13.5C9.9807 13.5 9.74303 13.596 9.55103 13.788C9.35903 13.98 9.26336 14.2173 9.26403 14.5C9.26403 14.7833 9.36003 15.021 9.55203 15.213C9.74403 15.405 9.98136 15.5007 10.264 15.5ZM10.264 12.5C10.5474 12.5 10.785 12.404 10.977 12.212C11.169 12.02 11.2647 11.7827 11.264 11.5V8.5C11.264 8.21667 11.168 7.979 10.976 7.787C10.784 7.595 10.5467 7.49933 10.264 7.5C9.9807 7.5 9.74303 7.596 9.55103 7.788C9.35903 7.98 9.26336 8.21733 9.26403 8.5V11.5C9.26403 11.7833 9.36003 12.021 9.55203 12.213C9.74403 12.405 9.98136 12.5007 10.264 12.5Z"
            fill="#DC3545"
          />
        </svg>
        <p className="ps-1 danger">{errormessage}</p>
      </div>
    );

  return (
    <>
      <div className="content-section">
        <label htmlFor="invoiveType" className="form-label d-block">
          <h6 className="primary-deep2">發票格式</h6>
        </label>
        <select
          id="invoiveType"
          className="form-select"
          value={selectedInvoiceOption}
          onChange={handleTypeChange}
        >
          <option className="form-select-option" value="" disabled>
            --選擇發票格式--
          </option>
          {invoiceoptions.map((item, index) => (
            <option
              key={index}
              className="form-select-option"
              value={item.value}
            >
              {item.label}
            </option>
          ))}
        </select>
      </div>
      {selectedInvoiceOption === "3" ? (
        <>
          <label
            htmlFor="businessnumber"
            className={`form-label d-block login-title pt-3 pb-2 pt-xxl-3 pb-xxl-2 ${errornone ? "pt-3" : ""}`}
          >
            <h6 className="primary-deep2">統一編號(必填)</h6>
          </label>
          {renderInputField(
            "businessnumber",
            "businessnumber",
            myAuth.businessNo || "請輸入統一編號",
            myAuth.businessNo,
            businessNumber,
            handleBusinessNumberChange,

            () => {
              handleQuickFill();
              setBussinessBlur(false);
            },
            () => {
              setBussinessBlur(true);
            },
            8,
            8
          )}

          {errorInput(
            (hasNumberChange || !businessNumber) && bussinessBlur,
            isValidNumber(businessNumber),
            "error-businessnumber",
            "請輸入正確的格式(例如：01234567)"
          )}

          <label
            htmlFor="invoicetitle"
            className={`form-label d-block ${errornone ? "pt-3" : ""}`}
          >
            <h6 className="primary-deep2">發票抬頭(必填)</h6>
          </label>
          {renderInputField(
            "invoicetitle",
            "invoicetitle",
            myAuth.invoiceTitle || "請輸入統一編號",
            myAuth.invoiceTitle,
            invoiceTitle,
            handleTitleChange,

            () => {
              handleQuickFill();
              setinvoiceTitleBlur(false);
            },
            () => {
              setinvoiceTitleBlur(true);
            },
            1,
            100
          )}

          {errorInput(
            (hasTitleChange || !invoiceTitle) && invoiceTitleBlur,
            isValidInvoiceTitle(invoiceTitle),
            "error-invoicetitle",
            "發票抬頭不得為空"
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default InvoiceSelect;
