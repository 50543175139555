import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import axios from "axios";
import Loading from "../Loading";
import Nodata from "../../sharedComponents/Nodata";
import { Link } from "react-router-dom";

function MemberCoupon() {
  const { myAuth, logout } = useContext(AuthContext);
  const [coupondata, setCouponData] = useState(null);
  const [rendercoupondata, setRenderCouponData] =useState("available")
  const [isloading, setIsLoading] = useState(true);
// const CouponTag = [
//   {name: "未使用", value:"available"},{name: "已使用", value:"used"}
// ]  
const CouponTag = [
  {name: "未使用", value:"available"}
]  


  const getCouponData = ()=>{
    const mid = parseInt(myAuth.sid);
    const storedToken = myAuth.token;
    const requestData = {
      mid: mid,
    };
    axios
    .post(`/api/Member/GetMemberECoupon`, requestData, {
      headers: {
        Authorization: `Bearer ${storedToken}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.status === 200) {
        console.log("response Data:", "response.data");
        setCouponData(response.data);

      } else {
        console.log("Request failed:", "response");
        // Token is invalid, log out the user
      }
    })
    .catch((error) => {
      console.error("Error:", "error");
      if (error.response && error.response.status === 401) {
        // 如果是 401，觸發 logout()
        logout();
      }
    })
    .finally(() => {
      setIsLoading(false); // Mark loading as finished regardless of success or error
    });
  }
  useEffect(()=>{
    console.log("初次進來可以獲得GetMemberECoupon資料")
    getCouponData()
  },[])
if(isloading){
  <Loading/>
}
return (
  <div className="container member-content table-container ps-xxl-5">
    <div className="login-title pt-4 pb-2 pt-xxl-3 pb-xxl-2">
      <h5>折價券</h5>
    </div>
    <div className="title-switch mb-3 mb-xxl-0 ">
      {CouponTag.map((tag) => (
        <div
          key={tag.value}
          className={`switch-title ${tag.value === rendercoupondata ? "selected" : ""}`}
          onClick={() => setRenderCouponData(tag.value)}
        >
          {tag.name}
        </div>
      ))}
    </div>
    {coupondata &&
        (rendercoupondata === "available" && coupondata.available.length === null ? (
          <Nodata
            descript={"目前無可使用折價券"}

          />
        ) : rendercoupondata === "used" && coupondata.used.length === 0 ? (
          <Nodata
            descript={"目前沒有已使用的折價券資訊"}

          />
        ) : (
          coupondata[rendercoupondata].map((coupon) => (
            <div key={coupon.id} className="coupon">
              <div className="coupon-left">
                <div className="svg-wrapper">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 240 174"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 0H240V174H0V153.816C3.85592 153.816 6.98175 150.7 6.98175 146.856C6.98175 143.013 3.85592 139.896 0 139.896V129.804C3.85592 129.804 6.98175 126.688 6.98175 122.844C6.98175 119 3.85592 115.884 0 115.884V105.792C3.85592 105.792 6.98175 102.675 6.98175 98.832C6.98175 94.9879 3.85592 91.872 0 91.872V81.78C3.85592 81.7794 6.98175 78.6635 6.98175 74.82C6.98175 70.9759 3.85592 67.86 0 67.86V57.7684C3.85592 57.7684 6.98175 54.6523 6.98175 50.8085C6.98175 46.9645 3.85592 43.8485 0 43.8484V33.7563C3.85592 33.7562 6.98175 30.6401 6.98175 26.7963C6.98175 22.9524 3.85592 19.8363 0 19.8363V0Z"
                      fill="#FEF2F2"
                    />
                  </svg>
                </div>
                <div className="coupon-title">
                  <h6 className="danger">${parseInt(coupon.ecouponPrice).toLocaleString()}</h6>
                  <p className="secondary">${parseInt(coupon.priceLimit).toLocaleString()}以上適用</p>
                </div>
              </div>
              <div className="coupon-right">
                <h4>{coupon.ecouponTitle}</h4>
                <div className="expiry-day">
                  <div className="expire-title">
                    <p>有效日期：</p>
                  </div>
                  <div className="expire-cate">
                  <p>{new Date(coupon.sDate).toLocaleDateString()}</p>
          <p>~</p>
          <p>{new Date(coupon.edate).toLocaleDateString()}</p>
                  </div>
                </div>
                {coupon.setitem ==="0" ? (<div className="coupon-restrict d-flex">
                  <div className="coupon-product pe-1 pe-xxl-3">
                    <p>*</p>
                    <p>全商品適用</p>
                  </div>
                </div>):(<div className="coupon-restrict d-flex">
                  <div className="coupon-product pe-1 pe-xxl-3">
                    <p>*</p>
                    <p>部分商品適用</p>
                  </div>
                  <Link to={`/activity/ecoupon/${coupon.ecouponSlug}`}  className="btn secondary-btn-4 px-0 px-1 py-0">
                    <p className="m-0">查看折價商品</p>
                  </Link>
                </div>) }
                
              </div>
            </div>
          ))
        ))}
    </div>
  );
}

export default MemberCoupon;
