import React, { useState } from "react";

function HistoryPage() {
  const initialHistoryData = [
    { id: 1, content: "History 1" },
    { id: 2, content: "History 2" },
    { id: 3, content: "History 3" },
    { id: 4, content: "History 4" },
    { id: 5, content: "History 5" },
  ];

  const [historyData, setHistoryData] = useState(initialHistoryData);
  const [editedData, setEditedData] = useState({});
  const [editedPreData, setEditedPreData] = useState(false);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [newData, setNewData] = useState({});

  const handleEdit = (data) => {
    setEditedData(data);
    setShowConfirmation(false);
    setNewData({});
  };

  const handleEditChange = (event) => {
    const { name, value } = event.target;
    setEditedData((prevData) => ({ ...prevData, [name]: value }));
    setShowConfirmation(true);

  };

  const handleUpdate = () => {
    // Simulate the update process using setTimeout
    setTimeout(() => {
      console.log("Update completed");
      setEditedData({});
      setShowConfirmation(false);
    }, 2000);
  };

  const handleAddNew = () => {
    setNewData({ id: Date.now(), content: "" });
    setShowConfirmation(true);
    setEditedData({});
  };

  const handleNewDataChange = (event) => {
    const { name, value } = event.target;
    setNewData((prevData) => ({ ...prevData, [name]: value }));
    setShowConfirmation(true);
  };

  const handleSendNewData = () => {
    setHistoryData([...historyData, newData]);
    setNewData({});
    setShowConfirmation(false);
  };

  const handleDelete = (data) => {
    const updatedData = historyData.filter((item) => item.id !== data.id);
    setHistoryData(updatedData);
  };

  return (
    <div>
      {historyData.map((data) => (
        <div key={data.id}>
          <p>{data.content}</p>
          <button onClick={() => handleEdit(data)}>
            {editedData.id === data.id ? "Editing" : "Edit"}
          </button>
          <button onClick={() => handleDelete(data)}>Delete</button>
          {editedData.id === data.id && (
            <div>
              <input
                type="text"
                name="content"
                value={editedData.content}
                onChange={handleEditChange}
              />
              {showConfirmation && (
                <div>
                  <p>Do you want to send the changes?</p>
                  <button onClick={handleUpdate}>Send</button>
                  <button onClick={() => setShowConfirmation(false)}>Cancel</button>
                </div>
              )}
            </div>
          )}
        </div>
      ))}
      {newData.id && (
        <div>
          <input
            type="text"
            name="content"
            value={newData.content}
            onChange={handleNewDataChange}
          />
          <div>
            <p>Do you want to send the new data?</p>
            <button onClick={handleSendNewData}>Send</button>
            <button onClick={() => setNewData({})}>Cancel</button>
          </div>
        </div>
      )}
      <button
        onClick={handleAddNew}
        disabled={historyData.length >= 10 || showConfirmation}
      >
        Add New
      </button>
    </div>
  );
}

export default HistoryPage;
