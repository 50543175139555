import React from 'react'
import { Link } from 'react-router-dom'

function Nodata({icon, descript, dangerdescript, descriptsecond, dangerclass, link, linkDescript, memo, blockdescript, blockmemo, bgclass}) {
  return (
    <div className={`d-flex align-items-center nodata-section  ${bgclass ? bgclass : "nodata"}`}>
      {!icon && (      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" viewBox="0 0 21 19" fill="none">
<path d="M0.98903 18.5C0.805697 18.5 0.63903 18.454 0.48903 18.362C0.33903 18.27 0.222364 18.1493 0.139031 18C0.0556972 17.85 0.00969715 17.6873 0.00103049 17.512C-0.00763618 17.3367 0.0383638 17.166 0.139031 17L9.38903 1C9.48903 0.833333 9.61836 0.708333 9.77703 0.625C9.9357 0.541667 10.098 0.5 10.264 0.5C10.4307 0.5 10.5934 0.541667 10.752 0.625C10.9107 0.708333 11.0397 0.833333 11.139 1L20.389 17C20.489 17.1667 20.535 17.3377 20.527 17.513C20.519 17.6883 20.473 17.8507 20.389 18C20.3057 18.15 20.189 18.271 20.039 18.363C19.889 18.455 19.7224 18.5007 19.539 18.5H0.98903ZM2.71403 16.5H17.814L10.264 3.5L2.71403 16.5ZM10.264 15.5C10.5474 15.5 10.785 15.404 10.977 15.212C11.169 15.02 11.2647 14.7827 11.264 14.5C11.264 14.2167 11.168 13.979 10.976 13.787C10.784 13.595 10.5467 13.4993 10.264 13.5C9.9807 13.5 9.74303 13.596 9.55103 13.788C9.35903 13.98 9.26336 14.2173 9.26403 14.5C9.26403 14.7833 9.36003 15.021 9.55203 15.213C9.74403 15.405 9.98136 15.5007 10.264 15.5ZM10.264 12.5C10.5474 12.5 10.785 12.404 10.977 12.212C11.169 12.02 11.2647 11.7827 11.264 11.5V8.5C11.264 8.21667 11.168 7.979 10.976 7.787C10.784 7.595 10.5467 7.49933 10.264 7.5C9.9807 7.5 9.74303 7.596 9.55103 7.788C9.35903 7.98 9.26336 8.21733 9.26403 8.5V11.5C9.26403 11.7833 9.36003 12.021 9.55203 12.213C9.74403 12.405 9.98136 12.5007 10.264 12.5Z" fill="#6A7C6C"/>
</svg>)}
{icon === "check" &&(<svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
  <path d="M11 0.5C4.92488 0.5 0 5.42488 0 11.5C0 17.5751 4.92488 22.5 11 22.5C17.0751 22.5 22 17.5751 22 11.5C22 5.42488 17.0751 0.5 11 0.5ZM11 2.89013C15.7551 2.89013 19.6085 6.74609 19.6085 11.5C19.6085 16.2539 15.7551 20.1085 11 20.1085C6.24488 20.1085 2.39147 16.2539 2.39147 11.5C2.39149 6.74609 6.24488 2.89013 11 2.89013ZM15.215 6.27661L8.83947 12.6534L6.7716 10.5856L4.74267 12.6132L6.81054 14.681L8.85289 16.7234L10.8805 14.6945L17.2573 8.31896L15.215 6.27661Z" fill="#6A7C6C"/>
</svg>)}
{icon === "dangernotice" &&( <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" viewBox="0 0 21 19" fill="none">
<path d="M0.98903 18.5C0.805697 18.5 0.63903 18.454 0.48903 18.362C0.33903 18.27 0.222364 18.1493 0.139031 18C0.0556972 17.85 0.00969715 17.6873 0.00103049 17.512C-0.00763618 17.3367 0.0383638 17.166 0.139031 17L9.38903 1C9.48903 0.833333 9.61836 0.708333 9.77703 0.625C9.9357 0.541667 10.098 0.5 10.264 0.5C10.4307 0.5 10.5934 0.541667 10.752 0.625C10.9107 0.708333 11.0397 0.833333 11.139 1L20.389 17C20.489 17.1667 20.535 17.3377 20.527 17.513C20.519 17.6883 20.473 17.8507 20.389 18C20.3057 18.15 20.189 18.271 20.039 18.363C19.889 18.455 19.7224 18.5007 19.539 18.5H0.98903ZM2.71403 16.5H17.814L10.264 3.5L2.71403 16.5ZM10.264 15.5C10.5474 15.5 10.785 15.404 10.977 15.212C11.169 15.02 11.2647 14.7827 11.264 14.5C11.264 14.2167 11.168 13.979 10.976 13.787C10.784 13.595 10.5467 13.4993 10.264 13.5C9.9807 13.5 9.74303 13.596 9.55103 13.788C9.35903 13.98 9.26336 14.2173 9.26403 14.5C9.26403 14.7833 9.36003 15.021 9.55203 15.213C9.74403 15.405 9.98136 15.5007 10.264 15.5ZM10.264 12.5C10.5474 12.5 10.785 12.404 10.977 12.212C11.169 12.02 11.2647 11.7827 11.264 11.5V8.5C11.264 8.21667 11.168 7.979 10.976 7.787C10.784 7.595 10.5467 7.49933 10.264 7.5C9.9807 7.5 9.74303 7.596 9.55103 7.788C9.35903 7.98 9.26336 8.21733 9.26403 8.5V11.5C9.26403 11.7833 9.36003 12.021 9.55203 12.213C9.74403 12.405 9.98136 12.5007 10.264 12.5Z" fill="#DC3545"/>
</svg>)}
{(icon === "noicon")&& (<></>)}
{blockdescript || blockmemo &&( <div className='d-block d-xxl-flex'>
  {blockdescript &&(<p className="primary-deep2 ">{blockdescript}</p>)}
  {blockmemo &&(<p className="primary-deep2 text-decoration-underline">{blockmemo}</p>)}
     
      </div>)}
     
{descript && (<p className="primary-deep2 ">{descript}</p>)}
     
     {dangerdescript && (     <p className={`black-light ${dangerclass ? dangerclass:"ps-2"}`}>{dangerdescript}</p>
)}
{descriptsecond && ( <p className="primary-deep2 ">{descriptsecond}</p>)}
    
     
     {link && <Link to={link} className="primary-deep2 link">{linkDescript}</Link>}
     {memo && <p className="primary-deep2 text-decoration-underline">{memo}</p>}

   </div>
  )
}

export default Nodata