//ProductFirstSelect
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  setSelectedOption,
  setSelectedOptionSecond,
  setSelectedOptionForPrice,
  setSelectedOptionName,
} from "../../redux/actions/action";
import {
  selectOptionName,
  selectOptionSecondName,
  selectProcessingName,
} from "../../redux/selectors/selectors";
import ProductProcessing from "./ProductProcessing";
import axios from "axios";
import Loading from "../Loading";

function ProductFirstSelect({ specname, option, tosetprice, onePage }) {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const [firstStage, setFirstStage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openMore, setOpenmore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [renderOption, setRenderOption] = useState(null);
  //快速點擊禁止
  const [clickDisabled, setClickDisabled] = useState(false);

  const pageSize = 10;
  const dispatch = useDispatch();
  const selectedOption = useSelector((state) => state.product.selectedOption);
  const selectedOptionSecond = useSelector(
    (state) => state.product.selectedOptionSecond
  );
  // const selectedOptionData = useSelector((state) => ({
  //   optionName: state.product.optionName,
  //   optionSecondName: state.product.optionSecondName,
  //   processingName: state.product.processingName,
  // }), shallowEqual);
  const optionName = useSelector(selectOptionName);
  const optionSecondName = useSelector(selectOptionSecondName);
  const processingName = useSelector(selectProcessingName);
  console.log("onePage", "onePage");
  const firstStageData = async () => {

    setIsLoading(true);
    if(onePage){
      try {
        const response = await axios.post(
          "api/Onepage/GetOnepageSkuPrice",
          {
            OSkuid: parseInt(firstStage),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setRenderOption(response.data);
        console.log("取得的資料", "response.data");
        console.log("單層");
      } catch (error) {
        console.error("POST 請求錯誤:", "error");
      } finally {
      }
    }else{
      try {
        const response = await axios.post(
          "api/Product/ProductDetail/SkuPrice",
          {
            skuid: parseInt(firstStage),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setRenderOption(response.data);
        console.log("取得的資料", "response.data");
        console.log("單層");
      } catch (error) {
        console.error("POST 請求錯誤:", "error");
      } finally {
      }
    }
    
  };

  //單層初次載入，會取得redux儲存的selectedOption和父層傳入的tosetprice
  useEffect(() => {
    if (selectedOption && tosetprice) {
      setFirstStage(selectedOption);
      console.log("selectedOption", "selectedOption");
      //只有單層才會觸發
      dispatch(setSelectedOptionSecond(selectedOption));
    }
  }, [selectedOption, tosetprice]);

  //接收到單層資料，由此元件撈取資料庫的sku資料，然後設置在renderoption之中
  useEffect(() => {
    if (firstStage) {
      firstStageData();
    }
  }, [firstStage]);
  //只有單層會接收到renderOption
  useEffect(() => {
    if (renderOption) {
      const { firstSkuData, skuProcessing, discountData } = renderOption;
      dispatch(
        setSelectedOptionForPrice({
          firstSkuData,
          skuProcessing,
          discountData,
        })
      );
    }
    setIsLoading(false);
    // console.log("單層", renderOption.discountData);
  }, [renderOption]);

  const handleOptionClick = (index, firstoptionName, viewall) => {
    console.log("按下", "firstoptionName");

    setIsLoading(true);

    let absoluteIndex;
    if (!viewall) {
        absoluteIndex = (currentPage - 1) * pageSize + index;
    } else {
        absoluteIndex = index;
    }
    setSelectedOptionIndex(absoluteIndex);
    const selectedOptionId = option[index].specOptionId;

    dispatch(setSelectedOption(selectedOptionId));
    dispatch(
      setSelectedOptionName(firstoptionName, optionSecondName, processingName)
    );

    // if (renderOption) {
    //   const { firstSkuData, skuProcessing } = renderOption;
    //   dispatch(setSelectedOptionForPrice({ firstSkuData, skuProcessing }));
    // }
    setOpenmore(false);
    if (selectedOptionId) {
      setIsLoading(false);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    const maxPage = Math.ceil(option.length / pageSize);
    if (currentPage < maxPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const toggleMoreOption = (e) => {
    e.preventDefault();
    setOpenmore((prevOpenMore) => !prevOpenMore);
  };

  const displayedOptions = option.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const showPagination = option.length > pageSize;
  const showPhonePage = option.length > 6;
  const isPrevDisabled = currentPage === 1;
  const isNextDisabled = currentPage === Math.ceil(option.length / pageSize);
  console.log("showPagination", "showPagination")
  //useEffect表示預設值
  useEffect(() => {
    if (option.length > 0 && !isLoading) {
      const initialSelectedOptionIndex =
        option[selectedOptionIndex].specOptionId;
      const initialSelectedOptionName =
        option[selectedOptionIndex].specOptionName;

      dispatch(
        setSelectedOptionName(
          initialSelectedOptionName,
          optionSecondName,
          processingName
        )
      );
      dispatch(setSelectedOption(initialSelectedOptionIndex));
      console.log("預設值");
    }
  }, [dispatch, selectedOptionIndex, selectedOption, option, isLoading]);
  if (isLoading) {
    return <Loading height={"h-auto"}/>;
  }
  return (
    <>
      {openMore && (
        <div className="popup-size">
          <div className="popup-page">
            
            { Array.from({ length: Math.ceil(option.length / pageSize) }).map(
              (_, index) => (
                <div
                  key={index}
                  className={`point ${
                    currentPage === index + 1 ? "select" : ""
                  }`}
                  onClick={() => setCurrentPage(index + 1)}
                />
              )
            )}
            
          </div>
          <div
            className={`popup-select ${
              showPagination ? "" : "justify-content-center"
            }`}
          >
            {showPagination && (
              <div
                className={`page-left ${
                  isPrevDisabled ? "page-disabled" : "page-control"
                }`}
                onClick={handlePrevPage}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="25"
                  viewBox="0 0 14 25"
                  fill="none"
                >
                  <path
                    d="M10.332 23.5416L0.748697 13.9583C0.540364 13.75 0.393142 13.5243 0.307031 13.2812C0.22092 13.0382 0.17717 12.7777 0.175781 12.5C0.175781 12.2222 0.219531 11.9618 0.307031 11.7187C0.394531 11.4757 0.541753 11.25 0.748697 11.0416L10.332 1.45829C10.714 1.07635 11.2001 0.885376 11.7904 0.885376C12.3806 0.885376 12.8668 1.07635 13.2487 1.45829C13.6306 1.84024 13.8216 2.32635 13.8216 2.91663C13.8216 3.5069 13.6306 3.99302 13.2487 4.37496L5.1237 12.5L13.2487 20.625C13.6306 21.0069 13.8216 21.493 13.8216 22.0833C13.8216 22.6736 13.6306 23.1597 13.2487 23.5416C12.8668 23.9236 12.3806 24.1145 11.7904 24.1145C11.2001 24.1145 10.714 23.9236 10.332 23.5416Z"
                    fill="#D9D9D9"
                  />
                </svg>
              </div>
            )}

            <div
              className={`page-content  ${
                !onePage ? "d-none d-xxl-block" : "d-none"
              }`}
            >
              <div className="overflow-wrap">
                <div className="overflow">
                  {option.map((item, index) => (
                    <div
                      key={item.specOptionId}
                      className={`option ${
                        selectedOptionIndex === index
                          ? "select"
                          : ""
                      }`}
                      onClick={() =>
                        clickDisabled
                          ? null
                          : handleOptionClick(index, item.specOptionName, true)
                      }
                    >
                      <h6>{item.specOptionName}</h6>
                    </div>
                  ))}
                </div>
                <div className="page-title d-none d-xxl-flex">
                  <h6>選擇尺寸：</h6>
                  <p className="primary-deep2">
                    {option[selectedOptionIndex].specOptionName}
                  </p>
                </div>
              </div>
            </div>
            <div className="page-content d-flex d-xxl-none align-items-start">
              <div className="overflow-wrap">
                <div className="overflow">
                  {displayedOptions.map((item, index) => (
                    <div
                      key={item.specOptionId}
                      className={`option ${
                        selectedOptionIndex ===
                        (currentPage - 1) * pageSize + index
                          ? "select"
                          : ""
                      }`}
                      onClick={() =>
                        clickDisabled
                          ? null
                          : handleOptionClick(index, item.specOptionName, false)
                      }
                    >
                      <p>{item.specOptionName}</p>
                    </div>
                  ))}
                </div>
                <div className="page-title d-none d-xxl-flex">
                  <h6>選擇尺寸：</h6>
                  <p className="primary-deep2">
                    {option[selectedOptionIndex].specOptionName}
                  </p>
                </div>
              </div>
            </div>
            {showPagination && (
              <div
                className={`page-right ${
                  isNextDisabled ? "page-disabled" : "page-control"
                }`}
                onClick={handleNextPage}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="25"
                  viewBox="0 0 14 25"
                  fill="none"
                >
                  <path
                    d="M0.749002 20.5724L8.83233 12.4891L0.749002 4.40574C0.556123 4.21286 0.403124 3.98388 0.298738 3.73187C0.194353 3.47986 0.140625 3.20976 0.140625 2.93699C0.140625 2.66422 0.194353 2.39412 0.298738 2.14211C0.403124 1.8901 0.556123 1.66112 0.749002 1.46824C0.941881 1.27536 1.17086 1.12236 1.42287 1.01798C1.67488 0.91359 1.94498 0.859863 2.21775 0.859863C2.49052 0.859863 2.76062 0.91359 3.01263 1.01798C3.26464 1.12236 3.49362 1.27536 3.6865 1.46824L13.249 11.0307C14.0615 11.8432 14.0615 13.1557 13.249 13.9682L3.6865 23.5307C3.49376 23.7239 3.26483 23.8771 3.0128 23.9816C2.76077 24.0862 2.4906 24.14 2.21775 24.14C1.9449 24.14 1.67473 24.0862 1.4227 23.9816C1.17067 23.8771 0.941738 23.7239 0.749002 23.5307C-0.042665 22.7182 -0.0634983 21.3849 0.749002 20.5724Z"
                    fill="#D9D9D9"
                  />
                </svg>
              </div>
            )}
          </div>
          {/* <button className="btn primary-btn2" onClick={toggleMoreOption}>
            <h6 className="d-xxl-none">選擇</h6>
            <h6>確認</h6>
          </button> */}
        </div>
      )}
      <div className={`${onePage ? "d-flex flex-column gap-10px": ""}`}>
        <div className={`select-result ${onePage ? "pt-0": "pt-3"}`}>
          <div className="d-flex text-nowrap">
            {/* <h6 className="pe-0 d-none d-xxl-block ">選擇</h6> */}
            <h6 className="pe-0 ">選擇{specname}：</h6>
            {/* <h6 ></h6> */}
          </div>
         {option && option[selectedOptionIndex].specOptionName &&  <p className="search">{option[selectedOptionIndex].specOptionName}</p>}
          <div></div>
        </div>
        {showPagination && onePage && (
          <div className="popup-page align-items-center w-auto">
            {Array.from({ length: Math.ceil(option.length / pageSize) }).map(
              (_, index) => (
                <div
                  key={index}
                  className={`point ${
                    currentPage === index + 1 ? "select" : ""
                  }`}
                  onClick={() => setCurrentPage(index + 1)}
                />
              )
            )}
          </div>
        )}
        <div className="product-page">
          {showPagination && (
            <div
              className={`page-left   ${
                !onePage ? "d-none d-xxl-flex" : "d-flex"
              } ${isPrevDisabled ? "page-disabled" : "page-control"}`}
              onClick={handlePrevPage}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M10.334 23.0416L0.75065 13.4583C0.542317 13.25 0.395095 13.0243 0.308984 12.7812C0.222873 12.5382 0.179123 12.2777 0.177734 12C0.177734 11.7222 0.221484 11.4618 0.308984 11.2187C0.396484 10.9757 0.543706 10.75 0.75065 10.5416L10.334 0.958292C10.7159 0.576347 11.202 0.385376 11.7923 0.385376C12.3826 0.385376 12.8687 0.576347 13.2506 0.958292C13.6326 1.34024 13.8236 1.82635 13.8236 2.41663C13.8236 3.0069 13.6326 3.49302 13.2506 3.87496L5.12565 12L13.2506 20.125C13.6326 20.5069 13.8236 20.993 13.8236 21.5833C13.8236 22.1736 13.6326 22.6597 13.2506 23.0416C12.8687 23.4236 12.3826 23.6145 11.7923 23.6145C11.202 23.6145 10.7159 23.4236 10.334 23.0416Z"
                  fill="#D9D9D9"
                />
              </svg>
            </div>
          )}
          <div
            className={`product-size-select   ${
              !onePage ? "d-none d-xxl-flex" : "d-flex"
            }`}
          >
            {displayedOptions.map((item, index) => (
              <div
                key={item.specOptionId}
                className={`option ${
                  selectedOptionIndex === (currentPage - 1) * pageSize + index
                    ? "select"
                    : ""
                }`}
                onClick={() =>
                  clickDisabled
                    ? null
                    : handleOptionClick(index, item.specOptionName, false)
                }
              >
                <p>{item.specOptionName}</p>
              </div>
            ))}
          </div>
          <div
            className={`product-size-select   ${
              !onePage ? "d-flex d-xxl-none" : "d-none"
            }`}
          >
            {option.map((item, index) => (
              <div
                key={item.specOptionId}
                className={`option ${
                  selectedOptionIndex === index ? "select" : ""
                }`}
                onClick={() =>
                  clickDisabled
                    ? null
                    : handleOptionClick(index, item.specOptionName, true)
                }
              >
                <p>{item.specOptionName}</p>
              </div>
            ))}
          </div>
          {showPagination && (
            <div
              className={`page-right  ${
                !onePage ? "d-none d-xxl-flex" : "d-flex"
              } ${isNextDisabled ? "page-disabled" : "page-control"}`}
              onClick={handleNextPage}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
              >
                <path
                  d="M0.749002 20.0725L8.83233 11.9892L0.749002 3.90586C0.556123 3.71298 0.403124 3.484 0.298738 3.23199C0.194353 2.97999 0.140625 2.70988 0.140625 2.43711C0.140625 2.16434 0.194353 1.89424 0.298738 1.64223C0.403124 1.39022 0.556123 1.16124 0.749002 0.968362C0.941881 0.775483 1.17086 0.622483 1.42287 0.518098C1.67488 0.413712 1.94498 0.359985 2.21775 0.359985C2.49052 0.359985 2.76062 0.413712 3.01263 0.518098C3.26464 0.622483 3.49362 0.775483 3.6865 0.968362L13.249 10.5309C14.0615 11.3434 14.0615 12.6559 13.249 13.4684L3.6865 23.0309C3.49376 23.224 3.26483 23.3772 3.0128 23.4818C2.76077 23.5863 2.4906 23.6401 2.21775 23.6401C1.9449 23.6401 1.67473 23.5863 1.4227 23.4818C1.17067 23.3772 0.941738 23.224 0.749002 23.0309C-0.042665 22.2184 -0.0634983 20.885 0.749002 20.0725Z"
                  fill="#D9D9D9"
                />
              </svg>
            </div>
          )}
        </div>
      </div>

      {showPhonePage && !onePage && (
        <div className="more pt-2 d-block d-xxl-none">
          <button className="btn primary-btn2" onClick={toggleMoreOption}>
            點開選擇更多尺寸
          </button>
        </div>
      )}

      {showPagination && !onePage && (
        <div className="more pt-2 d-none d-xxl-flex">
          <button className="btn primary-btn2" onClick={toggleMoreOption}>
            點開選擇更多尺寸
          </button>
        </div>
      )}
      {tosetprice &&
        renderOption &&
        renderOption.skuProcessing !== null &&
        renderOption.skuProcessing !== undefined && <ProductProcessing />}
    </>
  );
}

export default ProductFirstSelect;