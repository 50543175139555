import React, { useEffect, useState } from "react";
import InvoiceIndex from "./InvoiceIndex";
import InvoiceFooter from "./InvoiceFooter";
import axios from "axios";
import Popup from "../../sharedComponents/Popup";


import { Link, useParams, useNavigate } from "react-router-dom";

function Invoice() {

    const [apiData, setApiData] = useState(null);
    const [storeData, setStoreData] =useState(null);
    const [noPage, setNoPage] = useState(false);
    const [loadTime, setLoadTime] = useState(null);
 //來自URL的slug
 const { invoiceSlug } = useParams();
   //更改metadata
   const [favicon, setFavicon] = useState(null);
 const navigate = useNavigate();
//取得過濾後的資料
  const fetchData = (slug) => {
  
    axios
      .get(`api/EBilling/${slug}`)
      .then((response) => {
        const responseData = response.data;
        setApiData(responseData.eBillingQuery)
        setStoreData(responseData.storeDataQuery)
        setFavicon(responseData.storeDataQuery.favicon)
      })
      .catch((error) => {
        console.error("Error fetching data:", "error");
        // Log a message for when products are not found
        setNoPage(true);
        if (error.response && error.response.status === 404) {
        }
      });
  };
  useEffect(()=>{
    fetchData(invoiceSlug);
  },[invoiceSlug])
  useEffect(() => {
    const faviconLink = document.querySelector('link[rel="shortcut icon"]');
    const appleicon = document.querySelector('link[rel="apple-touch-icon"]');
    if (favicon) {
      if (faviconLink) {
        faviconLink.setAttribute("href", favicon);
      }
      if (appleicon) {
        appleicon.setAttribute("href", favicon);
      }
      }
  }, [favicon]);
  useEffect(() => {
    if(apiData && storeData){
      const now = new Date();
      const formatted = now.toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit', 
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
        timeZone: 'Asia/Shanghai'
      });
      
      setLoadTime(formatted);
    }
   

  }, [apiData, storeData]);
  return (
    <>
     {noPage && !apiData && (
<div className="onepage">
<div className="container-fluid px-0">
  <Popup
    onNevigate={() => {
      setNoPage(false);
      navigate("/");
    }}
    onClose={() => {
      setNoPage(false);
    }}
    icon={"notice"}
    title={"查無此電子帳單資訊"}
    orangebtn={"回到首頁"}
    greenbtn={""}
  />
</div>
</div>
)}
{!noPage &&  apiData && storeData && (
    <>
    <InvoiceIndex invoiceSlug={invoiceSlug} invoiceData={apiData} storeData={storeData} loadTime={loadTime}/>
    <InvoiceFooter/>
    </>
)}
    
    </>
  );
}

export default Invoice;
