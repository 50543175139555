//MemberOrder
import { useContext, useState, useEffect } from "react";
import axios from "axios";
import AuthContext from "../../Context/AuthContext";
import Nodata from "../../sharedComponents/Nodata";
import Loading from "../Loading";
import OrderDetailPopup from "../../sharedComponents/OrderDetailPopup";
import PopupCheckbox from "../../sharedComponents/PopupCheckbox";
import Popup from "../../sharedComponents/Popup";
import ReturnRemittance from "../../sharedComponents/ReturnRemittance";
import Pagination from "../../sharedComponents/Pagination";
import BankaccountBtn from "../../sharedComponents/BankaccountBtn";

function MemberOrder() {
  const { myAuth, updateCartData, updateCartAmount, logout } = useContext(
    AuthContext
  ); //登入狀態
  const [orderdata, setOrderdata] = useState([]);
  const [orderpopupinfo, setOrderPopupInfo] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isAgainPopupOpen, setIsAgainPopupOpen] = useState(false);
  //取消的popup
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [cancelId, setCancelId] = useState("");
  const mid = myAuth.sid;
  //回傳匯款的popup
  const [isRemitOpen, setIsRemitOpen] = useState(false);
  const [remitId, setRemitlId] = useState("");
  //頁碼
  const [currentPage, setCurrentPage] = useState(1); //頁碼初始值
  const itemsPerPage = 10; //一頁有幾個
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const getOrderData = () => {
    setIsLoading(true);
    console.log("登入狀態", "myAuth.sid");
    const storedToken = myAuth.token;
    const requestData = { mid: myAuth.sid };
    console.log("storedToken", "storedToken");
    axios
      .post("/api/Member/GetMemberOrder", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("回傳的member order", "response.data");
        console.log("回傳的member order", "requestData");
        setOrderdata(response.data);

        // setOrderdata([]);//沒有資料
      })
      .catch((error) => {
        console.error("member order有錯誤：", "error");
        console.log("member order有錯誤", "requestData");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const orderAgain = (orderNo) => {
    setIsLoading(true);
    console.log("orderAgain", "orderNo");

    const storedToken = myAuth.token;
    const requestData = { mid: myAuth.sid, orderno: orderNo };
    console.log("storedToken", "storedToken");
    axios
      .post("/api/Order/OrderAgain", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("回傳的member order AGAIN", "response.data");
        console.log("回傳的member order AGAIN", "requestData");
        updateCartAmount();
        updateCartData();
        setIsAgainPopupOpen(true);
        // setOrderdata(response.data);

        // setOrderdata([]);//沒有資料
      })
      .catch((error) => {
        console.error("member order AGAIN有錯誤：", "error");
        console.log("member order AGAIN有錯誤", "requestData");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getOrderData();
  }, []);
  const handleOpenOrderPopup = (orderpopupinfo) => {
    setOrderPopupInfo(orderpopupinfo);
    setIsPopupOpen(true);
  };
  const handleCancelPopup = (orderNo) => {
    console.log("cancel order", "orderNo");
    setIsCancelOpen(true);
    setCancelId(orderNo);
  };
  const handleRemitOpen = (orderNo) => {
    setIsRemitOpen(true);
    setRemitlId(orderNo);
  };
  const handleCancelOrder = (Name) => {
    console.log("isselectname", "Name");
    console.log("cancelId", "cancelId");
    console.log("mid", "mid");
    const storedToken = myAuth.token;
    const requestData = { mid: myAuth.sid, orderNo: cancelId, CancelMsg: Name };
    setIsLoading(true);
    axios
      .post("/api/Member/ReturnCancelOrder", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("回傳的ReturnCancelOrder", "response.data");
        console.log("回傳的ReturnCancelOrder", "requestData");
        // 取消订单后，重新获取订单数据
        getOrderData();

        // 关闭取消订单弹出框
        setIsCancelOpen(false);
      })
      .catch((error) => {
        console.error("ReturnCancelOrder有錯誤：", "error");
        console.log("ReturnCancelOrder有錯誤", "requestData");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      })
      .finally(() => {
        setIsLoading(true);
      });
  };
  const cancelReasonSelect = [
    { option: "尺寸或顏色訂購錯誤" },
    { option: "重複訂購" },
    { option: "價格太貴" },
    { option: "重新下單" },
    { option: "其他" },
  ];
  const displayedData = orderdata && orderdata.slice(startIndex, endIndex);
  if (isLoading) {
    <Loading />;
  }
  return (
    <>
      {isPopupOpen && (
        <OrderDetailPopup
          onClose={() => {
            setIsPopupOpen(false);
          }}
          onNevigate={() => {
            setIsPopupOpen(false);
          }}
          orderpopupinfo={orderpopupinfo}
          orderAgain={() => {
            orderAgain(orderpopupinfo.orderNo);
          }}
        />
      )}
      {isAgainPopupOpen && (
        <Popup
          onNevigate={() => {
            setIsAgainPopupOpen(false);
          }}
          onClose={() => {
            setIsAgainPopupOpen(false);
          }}
          icon={"notice"}
          title={"商品已加入購物車"}
          orangebtn={"好的"}
          greenbtn={""}
        />
      )}

      {isCancelOpen && (
        <PopupCheckbox
          cancelId={cancelId}
          onClose={() => {
            setIsCancelOpen(false);
          }}
          checkboxData={cancelReasonSelect}
          onCancelOrder={handleCancelOrder}
        />
      )}
      {isRemitOpen && (
        <ReturnRemittance
          orderno={remitId}
          onClose={() => {
            setIsRemitOpen(false);
          }}
          icon={"notice"}
          title={"請填寫匯款資料"}
          orangebtn={"送出"}
          greenbtn={"取消"}
          onNevigate={() => {
            setIsCancelOpen(false);
            window.location.reload();
          }}
        />
      )}

      <div className="container member-content table-container ps-xxl-5">
        <div className="login-title pt-4 pb-2 pt-xxl-3 pb-xxl-2 d-flex gap-2 align-items-center">
          <h5>訂單資料</h5>
          <BankaccountBtn />
        </div>
        {displayedData.length > 0 ? (
          <>
            <div className="color-col title-col">
              <div className="col-content col-title">
                <h6>訂單日期</h6>
              </div>
              <div className="col-content col-title">
                <h6>訂單編號</h6>
              </div>
              <div className="col-content col-title">
                <h6>金額</h6>
              </div>
              <div className="col-content col-title">
                <h6>付款</h6>
              </div>
              <div className="col-content col-title">
                <h6>狀態</h6>
              </div>
              <div className="col-content col-title justify-content-xxl-center">
                <h6>管理</h6>
              </div>
            </div>
            <div className="member-table">
              {displayedData.map((order, index) => {
                // 解析日期和时间
                const orderDate = new Date(order.orderDate);
                const year = orderDate.getFullYear();
                const month = String(orderDate.getMonth() + 1).padStart(2, "0");
                const day = String(orderDate.getDate()).padStart(2, "0");
                const hours = String(orderDate.getHours()).padStart(2, "0");
                const minutes = String(orderDate.getMinutes()).padStart(2, "0");
                const seconds = String(orderDate.getSeconds()).padStart(2, "0");
                const amOrPm = hours >= 12 ? "下午" : "上午";
                const formattedTime = `${amOrPm} ${hours}:${minutes}:${seconds}`;
                const orderPrice = parseFloat(order.orderPrice);
                return (
                  <div className="color-col" key={index}>
                    <div className="col-content ">
                      <div className="col-title">
                        <p className="text-nowrap">訂單日期：</p>
                      </div>
                      <div className="col-descript d-flex flex-wrap">
                        <p>{`${year}/${month}/${day}`}</p>
                        <p>{formattedTime}</p>
                      </div>
                    </div>
                    <div className="col-content">
                      <div className="col-title">
                        <p className="text-nowrap">訂單編號：</p>
                      </div>
                      <div className="col-descript">
                        <p
                          className="text-bold order-number text-decoration-underline"
                          onClick={() => handleOpenOrderPopup(order)}
                        >
                          {order.orderNo}
                        </p>
                      </div>
                    </div>
                    <div className="col-content">
                      <div className="col-title">
                        <p className="text-nowrap">金額：</p>
                      </div>
                      <div className="col-descript">
                        <p>${orderPrice.toLocaleString()}</p>
                      </div>
                    </div>
                    <div className="col-content">
                      <div className="col-title">
                        <p className="text-nowrap">付款：</p>
                      </div>
                      <div className="col-descript d-flex flex-wrap gap-1">
                        <div className="d-flex align-items-center  d-xxl-block">
                          <p>{order.paymentMethod}</p>

                          <p className="ps-1 ps-xxl-0">{order.paymentStatus}</p>
                        </div>

                        {order.paymentId === "2" &&
                        order.paymentStatusId === "1" ? (
                          <div
                            className="btn primary-btn4 px-1 py-1"
                            onClick={() => {
                              handleRemitOpen(order.orderNo);
                            }}
                          >
                            回傳匯款資料
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-content">
                      <div className="col-title text-nowrap ">
                        <p className="text-nowrap">狀態：</p>
                      </div>
                      <div className="col-descript d-flex d-xxl-block align-items-center gap-2 flex-wrap">
                        <p
                          className={`${
                            order.orderStatus === "5" ? "danger" : ""
                          }`}
                        >
                          {order.orderStatus}
                        </p>
                        {order.managerNote !== "" && <h6 className="p text-muted">({order.managerNote})</h6>}
                      </div>
                    </div>
                    <div className="col-content align-items-center justify-content-xxl-center">
                      <div className="col-title">
                        <p className="text-nowrap">管理：</p>
                      </div>
                      <div className="col-descript d-flex gap-1 flex-xxl-column">
                        {order.paymentStatusId === "1" &&
                          (order.paymentId === "2" ||
                            order.paymentId === "3" ||
                            order.paymentId === "4") &&
                          order.orderStatusId === "1" && (
                            <div
                              className="btn secondary-btn-5"
                              onClick={() => handleCancelPopup(order.orderNo)}
                            >
                              取消訂單
                            </div>
                          )}
                        <div
                          onClick={() => orderAgain(order.orderNo)}
                          className="btn primary-btn4"
                        >
                          再次訂購
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="container px-md-0"></div>
          </>
        ) : (
          <Nodata
            descript={"目前沒有訂單資料"}
            link={"../../collections"}
            linkDescript={"去逛逛"}
          />
        )}
        {displayedData && displayedData.length > 0 && (
          <div className="pagination justify-content-center primary-deep">
            <Pagination
              currentPage={currentPage} // 傳遞 currentPage
              totalPages={Math.ceil(orderdata.length / itemsPerPage)} // 計算 totalPages
              onPageChange={setCurrentPage} // 傳遞頁數變化處理函式
            />
          </div>
        )}
      </div>
    </>
  );
}

export default MemberOrder;
