import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const HeroSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
// console.log("images",images)
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 6000);

    return () => clearInterval(timer);
  }, [images]);

  const goToPrevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToSpecificSlide = (value) => {
    setCurrentIndex(value);
  };

  const handleLinkClick = (tag) => {
    // 清空 sessionStorage 中的值
    if(tag){
      sessionStorage.removeItem('collectiontag');
    
      // 設置新的值
      const updatedTags = tag || ""; // 如果 collections 不存在，設置為空字符串
      sessionStorage.setItem('collectiontag', updatedTags);
    }
  };



  return (
    <>
    <div className="first-section hero-section d-none d-md-block">
    <div className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators custom-carousel">
        {images.map((item, index) => (
          <div
            key={index}
            type="button"
            data-bs-slide-to={index}
            className={index === currentIndex ? "active" : ""}
            onClick={() => goToSpecificSlide(index)}
          >
            {index === currentIndex ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="87"
                height="8"
                viewBox="0 0 87 8"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.14619 0H81.4013H81.4016C84.1682 0 86.411 1.79086 86.411 4C86.411 6.20914 84.1682 8 81.4016 8H81.4013H5.14619C5.14613 8 5.14606 8 5.146 8C2.37943 7.99992 0.136719 6.20909 0.136719 4C0.136719 1.79091 2.37943 8.49813e-05 5.146 0C5.14606 0 5.14613 0 5.14619 0Z"
                  fill="#364F38"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="87"
                height="8"
                viewBox="0 0 87 8"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.42061 0H81.6758H81.676C84.4426 0 86.6855 1.79086 86.6855 4C86.6855 6.20914 84.4426 8 81.676 8H81.6758H5.42061C5.42054 8 5.42048 8 5.42041 8C2.65384 7.99992 0.411133 6.20909 0.411133 4C0.411133 1.79091 2.65384 8.49813e-05 5.42041 0C5.42048 0 5.42054 0 5.42061 0Z"
                  fill="#D9D9D9"
                />
              </svg>
            )}
          </div>
        ))}
      </div>
      
      <div className="carousel-inner">
        {images.map((item, index) => (
          <div
            key={index}
            className={`carousel-item ${
              index === currentIndex ? "active" : ""
            }`}
            data-bs-interval="3000"
            style={{
              transform: `translateX(${(index - currentIndex) * 100}%)`,
            }}
          >
            <Link to={item.linkUrl} onClick={() => handleLinkClick(item.collections)}>
              <img
                src={item.mainImage}
                className="d-block w-100"
                alt={`Slide ${index + 1}`}
              />
            </Link>
          </div>
        ))}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
        onClick={goToPrevSlide}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="80"
          height="80"
          viewBox="0 0 80 80"
          fill="none"
        >
          <path
            d="M44.333 57.6666L28.9997 42.3333C28.6663 42 28.4308 41.6388 28.293 41.25C28.1552 40.8611 28.0852 40.4444 28.083 40C28.083 39.5555 28.153 39.1388 28.293 38.75C28.433 38.3611 28.6686 38 28.9997 37.6666L44.333 22.3333C44.9441 21.7222 45.7219 21.4166 46.6663 21.4166C47.6108 21.4166 48.3886 21.7222 48.9997 22.3333C49.6108 22.9444 49.9163 23.7222 49.9163 24.6666C49.9163 25.6111 49.6108 26.3888 48.9997 27L35.9997 40L48.9997 53C49.6108 53.6111 49.9163 54.3888 49.9163 55.3333C49.9163 56.2777 49.6108 57.0555 48.9997 57.6666C48.3886 58.2777 47.6108 58.5833 46.6663 58.5833C45.7219 58.5833 44.9441 58.2777 44.333 57.6666Z"
            fill="#E3DFD7"
          />
        </svg>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
        onClick={goToNextSlide}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="80"
          height="80"
          viewBox="0 0 80 80"
          fill="none"
        >
          <path
            d="M30.9666 52.9334L43.8999 40L30.9666 27.0667C30.658 26.7581 30.4132 26.3917 30.2461 25.9885C30.0791 25.5853 29.9932 25.1531 29.9932 24.7167C29.9932 24.2803 30.0791 23.8481 30.2461 23.4449C30.4132 23.0417 30.658 22.6753 30.9666 22.3667C31.2752 22.0581 31.6415 21.8133 32.0448 21.6463C32.448 21.4793 32.8801 21.3933 33.3166 21.3933C33.753 21.3933 34.1852 21.4793 34.5884 21.6463C34.9916 21.8133 35.358 22.0581 35.6666 22.3667L50.9666 37.6667C52.2666 38.9667 52.2666 41.0667 50.9666 42.3667L35.6666 57.6667C35.3582 57.9757 34.9919 58.2209 34.5886 58.3882C34.1854 58.5554 33.7531 58.6415 33.3166 58.6415C32.88 58.6415 32.4477 58.5554 32.0445 58.3882C31.6412 58.2209 31.2749 57.9757 30.9666 57.6667C29.6999 56.3667 29.6666 54.2334 30.9666 52.9334Z"
            fill="#E3DFD7"
          />
        </svg>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  </div>
   <div className="first-section hero-section d-block d-md-none">
   <div className="carousel slide" data-bs-ride="carousel">
     <div className="carousel-inner">
       {images.map((item, index) => (
         <div
           key={index}
           className={`carousel-item ${
             index === currentIndex ? "active" : ""
           }`}
         >
           <img
             src={item.mainMImage}
             className="d-block w-100"
             alt={`Slide ${index + 1}`}
           />
         </div>
       ))}
     </div>
   </div>
 </div>
 </>
  );
};

export default HeroSlider;
