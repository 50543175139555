import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";

function Footer() {
  const [apiData, setApiData] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  useEffect(() => {
    // fetch(`api/Blog/slug/新商品上市`)
    axios.get(`api/Home/FooterNav`)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return null;
    })
    .then((data) => {
      if (data) {
        setApiData(data);
        // console.log("Data from FooterNav:", data);
      }
    })
    .catch((error) => {
      console.error("Error fetching data from API:", "error");
    });
}, []);

  useEffect(() => {
    axios.get(`api/Home/StoreData`)
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return null;
    })
    .then((data) => {
      if (data) {
        setCompanyInfo(data);
        console.log("StoreData footer", "data");
      }
    })
    .catch(() => {
      console.error("Error fetching data from API");
    });
}, []);
  const sanitizedHTML = DOMPurify.sanitize(
    companyInfo ? companyInfo.footerInfo : ""
  );
  const sanitizedMobile = DOMPurify.sanitize(
    companyInfo ? companyInfo.footerMInfo : ""
  );
  return (
    <>
      <footer className="footer mt-4 mt-xxl-5">
        <div className="d-none d-lg-block">
          <div className="primary-light-bg">
            <div className="container d-flex justify-content-between">
              <div className="logo">
                
{companyInfo && companyInfo.footerLogo && (
  <img src={companyInfo.footerLogo} alt="" />
)}

              </div>
              <div className="content d-flex primary-deep2 ">
                <div className="about">
                  {apiData &&
                    apiData.map((item) => (
                      <Link
                        to={`${item.navurl}`}
                        key={item.id}
                        className="d-flex align-items-center"
                      >
                        <svg
                          width="14"
                          height="17"
                          viewBox="0 0 14 17"
                          className="me-2"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            id="Vector"
                            d="M13.18 8.5L0.179993 16.5V0.5L13.18 8.5Z"
                            fill="#364F38"
                          />
                        </svg>
                        <h6>{item.navname}</h6>
                      </Link>
                    ))}
                </div>

                {companyInfo && (
                  <div className="footer-info primary-deep2">
                    <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
                  </div>
                )}
              </div>
            </div>
          </div>
         {companyInfo && <div className="primary-deep-bg white text-center">
  © {new Date().getFullYear()} {companyInfo.storename}版權所有，並保留所有權利
</div>} 
        </div>
        <div className="d-block d-lg-none">
          <div className="primary-light-bg">
            <div className="footer-center">
              <div className="footer-bg">
              {companyInfo && companyInfo.footerMLogo && (
  <img src={companyInfo.footerMLogo} alt="" />
)}
              </div>
              {companyInfo && (<div className="footer-info d-flex flex-column gap-0">
                <div dangerouslySetInnerHTML={{ __html: sanitizedMobile }} />
                <h6 className="pt-0">
                © {new Date().getFullYear()} {companyInfo.storename}版權所有，並保留所有權利

                </h6>
              </div>)}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
