// store.js
import { createStore, combineReducers } from 'redux';
import { productReducer, collectionReducer  } from '../reducers/reducers';

const rootReducer = combineReducers({
  product: productReducer,
  collection: collectionReducer,
});

const store = createStore(rootReducer);

export default store;
