import React from 'react';

function RenderHtml({ content }) {

  let formattedContent = content;
  
  if (content) {
    formattedContent = content.replaceAll('&nbsp;', ' '); 
  }

  return (
    <div dangerouslySetInnerHTML={{ __html: formattedContent }} />
  ); 
}

export default RenderHtml;