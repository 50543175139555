//action.js
export const loginUser = (phoneNumber, password) => ({
    type: 'LOGIN_USER',
    payload: { phoneNumber, password },
  });
  
  export const setSelectedOption = (optionId) => ({
    type: 'SET_SELECTED_OPTION',
    payload: optionId,
  });

export const setSelectedOptionSecond = (optionId) => ({
  type: 'SET_SELECTED_OPTION_SECOND',
  payload: optionId,
});

export const setSelectedOptionName = (optionName, optionSecondName, processingName) => ({
  type: 'SET_SELECTED_OPTION_NAME',
  payload: { optionName, optionSecondName, processingName },
});
export const setSelectedOptionForPrice = (optionData) => ({
  type: 'SET_SELECTED_OPTION_FOR_PRICE',
  payload: optionData,
});

export const setSelectedProcessingForPrice = (selectedProcessing) => ({
  type: "SET_SELECTED_PROCESSING_FOR_PRICE",
  payload: selectedProcessing,
});

export const addToCart = (productId, quantity) => ({
  type: 'ADD_TO_CART',
  payload: { productId, quantity },
});

export const deleteProduct = (productId) => ({
  type: 'DELETE_PRODUCT',
  payload: productId,
});

export const setFilteredTag = (tags) => ({
  type: 'SET_FILTERED_TAG',
  payload: tags,
});

export const addToOPCart = (productId, quantity) => {
  return {
    type: 'ADD_TO_OP_CART',
    payload: { productId, quantity },
  };
};
export const deleteOPProduct = (productId) => ({
  type: 'DELETE_OP_PRODUCT',
  payload: productId,
});
