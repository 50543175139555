import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Loading from "../components/Loading";
import AuthContext from "../Context/AuthContext";
import Nodata from "./Nodata";
import Popup from "./Popup";

function OldMemberLogin({ islogin, mid, storedToken }) {
  const location = useLocation();
  const navigate = useNavigate();
  //舊會員
  const [account, setAccount] = useState("");
  const [accountOnBlur, setAccountBlur] = useState(false);
  const [accountNotFound, setAccountNotFound] = useState(false);
  const [accountError, setAccountError] = useState(null);

  //firstCaptcha

  const [firstCaptcha, setFirstCaptcha] = useState("");
  const [firstCaptchaOnblur, setFirstCaptchaBlur] = useState(false);
  const [firstCaptchaData, setFirstCaptchaData] = useState(null);
  const [isSameFirstCaptcha, setIsSameFirstCaptcha] = useState(true);
  const [verifyfirstCaptcha, setVerifyFirstCaptcha] = useState(false);
  //captcha
  const [captcha, setCaptcha] = useState("");
  const [captchaOnblur, setCaptchaBlur] = useState(false);
  const [captchaData, setCaptchaData] = useState(null);
  // const [captchaData, setCaptchaData] = useState("123456");
  const [isSameCaptcha, setIsSameCaptcha] = useState(true);
  const [verifycaptcha, setVerifyCaptcha] = useState(false);
  //寄送SendVerificationCode出現的錯誤訊息
  const [captchaError, setCaptchaError] = useState(null);

  const [isConnect, setIsConnect] = useState(false);
  // const [isConnect, setIsConnect] = useState(true); // State to track if the form is submitted

  //驗證
  const [verifysent, setVerifysent] = useState(false);
  const [countdown, setCountdown] = useState(60);
  //舊資料連接
  const [OldData, setOldData] = useState(null);

  //第二步的格式驗證如下:
  //手機
  const [phone, setPhone] = useState("");
  const [phoneOnBlur, setphoneBlur] = useState(false);
  const [phoneNotFound, setPhoneNotFound] = useState(false);
  //手機是否為唯一值
  const [phoneuniq, setPhoneuniq] = useState(false);
  //是否發送過檢查手機號碼
  const [phonesent, setPhonesent] = useState(false);
  //姓名
  const [name, setName] = useState("");
  const [nameOnBlur, setNameBlur] = useState(false);
  //信箱
  const [email, setEmail] = useState("");
  const [emailOnblur, setEmailonBlur] = useState(false);
  //舊密碼
  const [firstPassword, setFirstPassword] = useState("");
  const [firstPasswordOnblur, setFirstPasswordBlur] = useState(false);
  //查看舊密碼的firstPassword
  const [showFirstPassword, setShowFirstPassword] = useState(false);
  //密碼
  const [password, setPassword] = useState("");
  const [passwordOnblur, setPasswordBlur] = useState(false);
  //查看密碼的password
  const [showPassword, setShowPassword] = useState(false);
  //再次確認密碼
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordOnBlur, setConfirmPasswordBlur] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(true);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isRegisterPopupOpen, setIsRegisterPopupOpen] = useState(false);

  const [loginConnect, setLoginConnect] = useState(false);
  //註冊的錯誤訊息
  const [errorMsg, setErrorMsg] = useState(null);
  //導入authcontext
  const { login } = useContext(AuthContext);
  //檢查格式是否合法
  const isValidAccount = (account) => {
    // 驗證手機號碼或電子郵件地址的正規表達式
    const phoneRegex = /^09\d{8}$/; // 手機號碼格式
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/; // 電子郵件地址格式

    // 檢查是否符合手機號碼或電子郵件地址的格式
    return phoneRegex.test(account) || emailRegex.test(account);
  };
  const isValidFirstPassword = (firstPassword) => {
    const passwordRegex = /^(?=.*[a-zA-Z0-9]).+$/;
    return passwordRegex.test(firstPassword);
  };
  const isValidPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^09\d{8}$/;
    return phoneRegex.test(phoneNumber);
  };
  const isValidFirstCaptcha = (firstCaptcha) => {
    return firstCaptcha === firstCaptchaData.code;
  };
  const isValidName = (name) => {
    if (typeof name === "string" || name instanceof String) {
      const nameRegex = /^[A-Za-z0-9\u4e00-\u9fa5]+$/;
      // 确保字符串不为空
      return name.trim() !== "" && nameRegex.test(name);
    }
    return false; // 如果 name 不是字符串，返回 false
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };

  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,20}$/;
    return passwordRegex.test(password);
  };
  //監聽input value change

  const handleAccountChange = (e) => {
    setAccount(e.target.value);
    setAccountNotFound(false);
    // setPhoneError(null);
  };
  const handlePhoneNumberChange = (e) => {
    setPhone(e.target.value);
    setPhonesent(false);
    setPhoneuniq(false);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleFirstPasswordChange = (e) => {
    setFirstPassword(e.target.value);
    setAccountNotFound(false);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  //查看password
  const toggleFirstPasswordVisibility = (e) => {
    e.preventDefault(); // 阻止默認行為
    setShowFirstPassword(!showFirstPassword);
  };
  const togglePasswordVisibility = (e) => {
    e.preventDefault(); // 阻止默認行為
    setShowPassword(!showPassword);
  };

  //再次輸入密碼
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  //避免惡意輸入
  const escapeHTML = (unsafeText) => {
    return unsafeText.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  };
  //確認取得的captcha
  const getCaptcha = () => {
    axios
      .get(`/api/User/Captcha`, {
        headers: {},
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("Captcha Data:", "response.data");
          setFirstCaptchaData(response.data);
        } else {
          console.log("Captcha failed:", "response");
          // Token is invalid, log out the user
        }
      })
      .catch((error) => {
        console.error("Error:", "error");
      })
      .finally(() => {
        // setIsLoading(false); // Mark loading as finished regardless of success or error
      });
  };

  const handleFirstCaptchaChange = (e) => {
    setFirstCaptcha(e.target.value);
    setVerifyFirstCaptcha(false);
    setIsSameFirstCaptcha(true);
    setIsSameCaptcha(false);
    console.log("firstcaptchaonblur", "firstCaptchaOnblur");
    console.log("issamecaptcha", "isSameFirstCaptcha");
  };
  const handleCaptchaChange = (e) => {
    setCaptcha(e.target.value);
    setVerifyCaptcha(false);
    setIsSameCaptcha(true);
  };

  //發送步驟一
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // if (accountError) {
    //   console.log("accountError", accountError);
    //   return;
    // }
    setAccountBlur(true);
    setFirstPasswordBlur(true);
    setFirstCaptchaBlur(true);
    if (
      !isValidAccount(account) ||
      accountNotFound ||
      !isValidFirstPassword(firstPassword)
    ) {
      // getCaptcha();
      return;
    }
    //驗證碼是否一樣?
    if (firstCaptcha !== firstCaptchaData.code.toString()) {
      console.log("captcha", "firstCaptcha");
      console.log("captchaData", "firstCaptchaData");
      setIsSameFirstCaptcha(false);

      return;
    }
    const requestData = {
      Account: account.toString(),
      Password: firstPassword,
    };
    axios
      .post("/api/User/oldmemberLogin", requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const responseData = response.data;
        console.log("回傳的oldmemberLogin", "responseData");
        console.log("傳過去的資料oldmemberLogin", "requestData");
        setOldData(responseData);
        setIsConnect(true);
        console.log("check");
      })
      .catch((error) => {
        setVerifyFirstCaptcha(true);
        setAccountNotFound(true);
        setFirstCaptcha("");
        getCaptcha();

        setErrorMsg(error.response.data.message);
        console.error("oldmemberLogin有錯誤：", "error");
        console.log("傳過去的oldmemberLogin有錯誤", "requestData");
        console.error(
          "寄送oldmemberLogin出現的錯誤訊息",
          "error.response.data.message"
        );
      });
  };
  //確認步驟二格式是否正常，若正常就發送

  const handlePhoneCheck = async (e) => {
    e.preventDefault();
    if (!account || !isValidAccount(account) || verifysent) {
      console.log("handlePhoneCheck return");
      return;
    }
    // sendPhoneNumber();
  };
  //檢查手機是否為 唯一值 檢驗成功之後發送驗證碼
  const sendPhoneNumber = () => {
    const requestData = { Mobile: phone, MemberType: "L" };
    axios
      .post("api/User/Register/Check", requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setPhoneuniq(true);
        setPhonesent(true);
        // handleCaptchaSend();
        console.log("Form Register Check submitted:", "response.data");
        PostSubmit();
      })
      .catch((error) => {
        console.log("Error checking availability");
        if (error) {
          console.log("Mobile number or Email is already registered.");
          setPhoneuniq(false);
          setPhonesent(true);
          console.log(phonesent, !phoneuniq, isSubmitted);
        }
      });
  };

  //發送驗證碼
  const handleCaptchaSend = () => {
    const requestData = { mobile: phone.toString() };

    axios
      .post("/api/User/SendVerificationCode", requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const responseData = response.data;
        console.log("回傳的SendVerificationCode", "responseData");
        console.log("傳過去的資料SendVerificationCode", "requestData");
        console.log("verificationCode", "responseData.verificationCode");
        setCaptchaData(responseData.verificationCode);
        setVerifysent(true);
      })
      .catch((error) => {
        console.error("SendVerificationCode有錯誤：", "error");
        console.log("傳過去的SendVerificationCode有錯誤", "requestData");
        console.error(
          "寄送SendVerificationCode出現的錯誤訊息",
          "error.response.data.message"
        );
        // setPhoneError("簡訊驗證碼已達每日發送上限。");
      });
  };
  //將連接的新舊資料發送
  const PostSubmit = async () => {
    try {
      const registerResponse = await axios.post("api/User/Register", {
        mobile: escapeHTML(phone),
        email: escapeHTML(email),
        MemberName: escapeHTML(name),
        password: escapeHTML(password),
        MemberType: "L",
        oldmemberid: escapeHTML(OldData.oldmemberid.toString()),
        memberBonus: escapeHTML(OldData.memberBonus.toString()),
      });
      if (location.pathname !== "/checkoutstep1") {
        setIsRegisterPopupOpen(true);
      }
      if (location.pathname === "/checkoutstep1") {
        try {
          const loginResult = await login(phone, password, "L"); // 等待登录完成

          if (!loginResult) {
            // 登录失败，打开弹出窗口
            console.log("出現意外錯誤");
          }
        } catch (error) {
          console.error("Error during login:", "error");
          // 处理登录时的错误情况
        }
        console.log("登入完成");
      }
      // Handle registration response (registerResponse.data)
      console.log("Form submitted:", "registerResponse.data");
    } catch (registerError) {
      // Handle registration error
      console.error("Error submitting registration:", "registerError");
    }
    console.log("phoneuniq", "phoneuniq");
  };

  const oldmemberConnect = () => {
    const requestData = {
      mid: mid,
      oldmid: Number(OldData.oldmemberid),

      oldbonus: Number(OldData.memberBonus),
    };

    axios
      .post("/api/User/newmemberTooldmember", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const responseData = response.data;
        console.log("回傳的newmemberTooldmember", "responseData");
        console.log("傳過去的資料newmemberTooldmember", "requestData");
        setLoginConnect(true);
      })
      .catch((error) => {
        console.error("newmemberTooldmember有錯誤：", "error");
        console.log("傳過去的newmemberTooldmember有錯誤", "requestData");
        console.error(
          "寄送newmemberTooldmember出現的錯誤訊息",
          "error.response.data.message"
        );
      });
  };

  //完成註冊
  const handleFinishRegister = (e) => {
    e.preventDefault();

    setNameBlur(false);
    setEmailonBlur(true);
    setPasswordBlur(true);
    setConfirmPasswordBlur(true);
    // setCaptchaBlur(true);
    console.log("handleFinishRegister");
    // console.log("captcha", captchaData);
    //驗證碼是否一樣?
    // if ( !captcha || !captchaData) {
    //   return;
    // }
    if (!phone || !email || !name || !password) {
      return;
    }
    // if (captcha !== captchaData.toString()) {
    //   console.log("captchaData", "captchaData");
    //   setIsSameCaptcha(false);
    //   return;
    // } else {
    //   setIsSameCaptcha(true);
    //   //驗證碼一樣
    // }
    if (
      !isValidPhoneNumber(phone) ||
      !isValidEmail(email) ||
      !isValidName(name) ||
      !isValidPassword(password) ||
      confirmPasswordError
    ) {
      //  confirmPasswordError為true，代表的是密碼不相同，而confirmPasswordError為false代表密碼相同;
      return;
    }

    sendPhoneNumber();
  };
  const postTosubmit = () => {
    if (!phoneuniq) {
      console.log("phoneuniq", phoneuniq);
      //  confirmPasswordError為true，代表的是密碼不相同，而confirmPasswordError為false代表密碼相同;
      return;
    }
    PostSubmit();
  };
  //初始載入
  useEffect(() => {
    getCaptcha();
  }, []);

  //阻擋重複發送請求
  useEffect(() => {
    if (verifysent) {
      const countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown > 1) {
            return prevCountdown - 1;
          } else {
            clearInterval(countdownInterval);
            setVerifysent(false); // 倒數計時結束
            return 60; // 重置為60
          }
        });
      }, 1000);

      return () => {
        clearInterval(countdownInterval);
      };
    } else {
      setCountdown(60);
    }
  }, [verifysent]);
  //檢驗取得的OldData
  //   useEffect(() => {
  //     if (OldData) {
  //       setEmail(OldData.email);
  //       setName(OldData.memberName);
  //     }
  //   }, [OldData]);

  //檢驗再次輸入密碼和輸入密碼是否相同
  useEffect(() => {
    if (
      password === confirmPassword &&
      password !== "" &&
      confirmPassword !== ""
    ) {
      setConfirmPasswordError(false);
      console.log("密碼沒錯");
      console.log("password", "password");
      console.log("confirmPassword", "confirmPassword");
    } else {
      setConfirmPasswordError(true);
      console.log("密碼不相同");
      console.log("password", "password");
      console.log("confirmPassword", "confirmPassword");
    }
  }, [password, confirmPassword]);
  useEffect(() => {
    if (isConnect && islogin) {
      console.log("舊資料就緒 已登入情況的連接準備完成");
      oldmemberConnect();
    }
  }, [isConnect]);

  useEffect(() => {
    if (phoneuniq) {
      console.log("phoneuniq", phoneuniq);
    }
  }, [phoneuniq]);

  //重複的表單
  const renderInputField = (
    id,
    type,
    name,
    classname,
    placeholder,
    value,
    onChange,
    onBlur = null,
    onClick = null,
    minLength,
    maxLength,
    readonly
  ) => (
    <input
      type={type}
      id={id}
      name={name}
      className={classname}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      onClick={onClick}
      minLength={minLength}
      maxLength={maxLength}
      readOnly={readonly}
      autoComplete="new-password"
    />
  );
  const errorInput = (hasData, isValid, id, errormessage, show, notshow) => (
    <div
      className={`error-message ${
        hasData && !isValid ? show || "visible" : notshow || "invisible"
      } d-flex pb-0`}
      id={id}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="19"
        viewBox="0 0 21 19"
        fill="none"
        className="pt-1"
      >
        <path
          d="M0.98903 18.5C0.805697 18.5 0.63903 18.454 0.48903 18.362C0.33903 18.27 0.222364 18.1493 0.139031 18C0.0556972 17.85 0.00969715 17.6873 0.00103049 17.512C-0.00763618 17.3367 0.0383638 17.166 0.139031 17L9.38903 1C9.48903 0.833333 9.61836 0.708333 9.77703 0.625C9.9357 0.541667 10.098 0.5 10.264 0.5C10.4307 0.5 10.5934 0.541667 10.752 0.625C10.9107 0.708333 11.0397 0.833333 11.139 1L20.389 17C20.489 17.1667 20.535 17.3377 20.527 17.513C20.519 17.6883 20.473 17.8507 20.389 18C20.3057 18.15 20.189 18.271 20.039 18.363C19.889 18.455 19.7224 18.5007 19.539 18.5H0.98903ZM2.71403 16.5H17.814L10.264 3.5L2.71403 16.5ZM10.264 15.5C10.5474 15.5 10.785 15.404 10.977 15.212C11.169 15.02 11.2647 14.7827 11.264 14.5C11.264 14.2167 11.168 13.979 10.976 13.787C10.784 13.595 10.5467 13.4993 10.264 13.5C9.9807 13.5 9.74303 13.596 9.55103 13.788C9.35903 13.98 9.26336 14.2173 9.26403 14.5C9.26403 14.7833 9.36003 15.021 9.55203 15.213C9.74403 15.405 9.98136 15.5007 10.264 15.5ZM10.264 12.5C10.5474 12.5 10.785 12.404 10.977 12.212C11.169 12.02 11.2647 11.7827 11.264 11.5V8.5C11.264 8.21667 11.168 7.979 10.976 7.787C10.784 7.595 10.5467 7.49933 10.264 7.5C9.9807 7.5 9.74303 7.596 9.55103 7.788C9.35903 7.98 9.26336 8.21733 9.26403 8.5V11.5C9.26403 11.7833 9.36003 12.021 9.55203 12.213C9.74403 12.405 9.98136 12.5007 10.264 12.5Z"
          fill="#DC3545"
        />
      </svg>
      <p className={`ps-1 danger`}>{errormessage}</p>
    </div>
  );
  if (!firstCaptchaData || !firstCaptchaData.imageData) {
    return <Loading />;
  }
  return (
    <>
      {isRegisterPopupOpen && (
        <Popup
          onNevigate={() => {
            setIsRegisterPopupOpen(false);
            navigate("/login");
          }}
          onClose={() => {
            setIsRegisterPopupOpen(false);
          }}
          icon={"check"}
          title={"已註冊成功"}
          orangebtn={"前往登入"}
          greenbtn={""}
        />
      )}
      <div className={`container ${islogin ? "px-0" : ""}`}>
        <div
          className={`row px-xxl-0 ${
            islogin ? "" : "d-flex justify-content-center"
          }`}
        >
          <div
            className={` d-flex flex-column  ${
              islogin
                ? "px-0 "
                : " col col-lg-6 col-xxl-5 section-card  gap-4 p-4 "
            }`}
          >
            {islogin && (
              <h5 className="pb-3">{`${
                isConnect && loginConnect
                  ? "連接舊會員資料成功"
                  : "輸入舊會員資料"
              } `}</h5>
            )}
            {!islogin && (
              <div className="section-title text-center black border-bottom-light pb-2">
                <h5>{`${
                  isConnect ? "輸入新官網註冊資料 " : "舊會員登入"
                } `}</h5>
              </div>
            )}

            {!isConnect && (
              <div className={``}>
                <form
                  className="d-flex flex-column gap-4"
                  onSubmit={handleFormSubmit}
                >
                  <div>
                    <label htmlFor="account" className="form-label d-block">
                      <h6 className="primary-deep2">
                        帳號(輸入手機號碼或電子郵件)
                      </h6>
                    </label>
                    {renderInputField(
                      "account",
                      "text",
                      "text",
                      "form-input",
                      "輸入手機號碼或電子郵件",
                      account,
                      handleAccountChange,
                      () => {
                        setAccountBlur(true);
                      },
                      () => {
                        setAccountBlur(false);
                      },
                      3,
                      254,
                      isConnect
                    )}
                    {errorInput(
                      account || accountOnBlur,
                      isValidAccount(account),
                      "error-account",
                      "請輸入正確的手機號碼或電子郵件格式",
                      " d-block",
                      "d-none"
                    )}
                    {errorInput(
                      isValidAccount(account) && accountError,
                      !accountOnBlur,
                      "error-account-avaliable",
                      accountError,
                      "d-block",
                      "d-none"
                    )}
                    {errorInput(
                      account || accountOnBlur,
                      !accountNotFound,
                      "error-account",
                      errorMsg,
                      " d-block",
                      "d-none"
                    )}
                  </div>
                  <div className={`${islogin ? "pwd-input" : ""}`}>
                    <label htmlFor="old-pwd" className="form-label">
                      <h6 className="primary-deep2">密碼</h6>
                    </label>

                    <div className="d-flex ">
                      {renderInputField(
                        "old-pwd",
                        showFirstPassword ? "text" : "password",
                        "old-pwd",
                        "form-input  form-pwd",
                        "請輸入密碼",
                        firstPassword,
                        handleFirstPasswordChange,
                        () => {
                          setFirstPasswordBlur(true);
                        },
                        () => {
                          setFirstPasswordBlur(false);
                        }
                      )}

                      <div className="btn see-pwd ">
                        <div
                          className="btn checkbox-icon check showpwd"
                          onClick={toggleFirstPasswordVisibility}
                        >
                          {showFirstPassword ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="19"
                              viewBox="0 0 22 19"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M1.3267 0L0 1.32686L3.32283 4.64929C1.86598 5.81447 0.715658 7.30499 0 8.99996C1.73 13.0973 6 16 11 16C12.1278 16 13.2185 15.8523 14.2513 15.5765L16.9518 18.2766L18.2785 16.9497L1.3267 0ZM12.6749 14.0002L10.4219 11.7475C9.84146 11.6411 9.30305 11.3759 8.87868 10.9799C8.40503 10.5378 8.10612 9.96312 8.02343 9.34933L4.68517 6.01147C3.63979 6.83061 2.78481 7.84738 2.18 8.99996C2.98825 10.5402 4.24331 11.838 5.80248 12.7457C7.36165 13.6533 9.1624 14.1345 11 14.1345C11.5637 14.1345 12.124 14.0892 12.6749 14.0002ZM10.2905 6.27939L13.8592 9.84771C13.9517 9.57602 14 9.29031 14 8.99996C14 8.25735 13.6839 7.54516 13.1213 7.02006C12.5587 6.49496 11.7956 6.19996 11 6.19996C10.7589 6.19996 10.5209 6.22704 10.2905 6.27939ZM19.82 8.99996C19.0728 10.424 17.9436 11.6407 16.5453 12.5335L17.92 13.9081C19.7365 12.6789 21.1645 10.9788 22 8.99996C20.27 4.90262 16 1.99996 11 1.99996C9.50062 1.99996 8.06689 2.26098 6.74761 2.73697L8.24441 4.2336C9.13525 3.99055 10.0628 3.86539 11 3.86539C12.8376 3.86539 14.6383 4.34658 16.1975 5.25425C17.7567 6.16193 19.0117 7.45967 19.82 8.99996Z"
                                fill="#D9D9D9"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="19"
                              viewBox="0 0 22 19"
                              fill="none"
                            >
                              <path
                                d="M11 6.2C11.7956 6.2 12.5587 6.495 13.1213 7.0201C13.6839 7.5452 14 8.25739 14 9C14 9.74261 13.6839 10.4548 13.1213 10.9799C12.5587 11.505 11.7956 11.8 11 11.8C10.2044 11.8 9.44129 11.505 8.87868 10.9799C8.31607 10.4548 8 9.74261 8 9C8 8.25739 8.31607 7.5452 8.87868 7.0201C9.44129 6.495 10.2044 6.2 11 6.2ZM11 2C16 2 20.27 4.90267 22 9C20.27 13.0973 16 16 11 16C6 16 1.73 13.0973 0 9C1.73 4.90267 6 2 11 2ZM2.18 9C2.98825 10.5403 4.24331 11.838 5.80248 12.7457C7.36165 13.6534 9.1624 14.1346 11 14.1346C12.8376 14.1346 14.6383 13.6534 16.1975 12.7457C17.7567 11.838 19.0117 10.5403 19.82 9C19.0117 7.45971 17.7567 6.16197 16.1975 5.2543C14.6383 4.34662 12.8376 3.86544 11 3.86544C9.1624 3.86544 7.36165 4.34662 5.80248 5.2543C4.24331 6.16197 2.98825 7.45971 2.18 9Z"
                                fill="#D9D9D9"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    </div>
                    {errorInput(
                      firstPassword || firstPasswordOnblur,
                      !accountNotFound,
                      "error-account",
                      errorMsg,
                      " d-block",
                      "d-none"
                    )}
                    {errorInput(
                      firstPassword || firstPasswordOnblur,
                      isValidFirstPassword(firstPassword),
                      "error-old-pwd",
                      "此欄位不得為空，或是包含特殊符號",
                      " d-block",
                      "d-none"
                    )}
                  </div>
                  {!isConnect && (
                    <div className={`${islogin ? "form-input px-0 border-0" : ""}`}>
                      <div className={`w-100`}>
                        <div className="captcha-box">
                          <div className="captcha-foriput">
                            <label
                              htmlFor="local-firstCaptcha"
                              className="form-label d-block"
                            >
                              <h6 className="primary-deep2">圖形驗證碼</h6>
                            </label>
                            {renderInputField(
                              "local-firstCaptcha",
                              "text",
                              "firstCaptcha",
                              `${islogin ? "form-input w-100" : "form-input"}`,
                              "輸入圖形驗證碼",
                              firstCaptcha,
                              handleFirstCaptchaChange,
                              () => {
                                setFirstCaptchaBlur(true);
                              },
                              () => {
                                setFirstCaptchaBlur(false);
                              },
                              5,
              5
                            )}
                          </div>
                          {firstCaptchaData.imageData && (
                            <div
                              className="captcha-photo pt-2 cursor-pointer"
                              onClick={() => getCaptcha()}
                            >
                              <img
                                src={firstCaptchaData.imageData}
                                alt="圖形驗證碼"
                              />
                            </div>
                          )}
                        </div>
                        <div className="pt-1 secondary-deep2">
                          <p>點擊圖片可刷新驗證碼</p>
                        </div>
                        {errorInput(
                          firstCaptcha || firstCaptchaOnblur,
                          isSameFirstCaptcha,
                          "error-captcha",
                          "請輸入正確的圖形驗證碼",
                          " d-block",
                          "d-none"
                        )}
                      </div>
                    </div>
                  )}

                  <div>
                    <button
                      className={`${
                        isConnect ? "d-none" : "d-block"
                      } btn primary-btn2 ${islogin ? "form-input" : "w-100"}`}
                      type="submit"
                      // disabled={
                      //   !account ||
                      //   !isValidAccount(account) ||
                      //   !isValidFirstPassword(firstPassword) ||
                      //   //   accountNotFound ||
                      //   !firstCaptcha ||
                      //   !firstCaptchaData ||
                      //   !isSameFirstCaptcha
                      // }
                    >
                      下一步
                    </button>
                    <div className="form-input border-0 primary-deep2 pt-2 d-flex justify-content-end">
                      <Link to="/forgotoldmember">忘記舊會員密碼</Link>
                    </div>
                    <div
                      className={`pt-0 ${
                        !isValidAccount(account) ||
                        !isValidFirstPassword(firstPassword) ||
                        !isSameFirstCaptcha
                          ? "d-block w-100"
                          : "d-none"
                      }`}
                    >
                      <Nodata
                        icon={"dangernotice"}
                        dangerclass={"ps-1"}
                        dangerdescript={`尚未填寫正確的
                                      ${
                                        isValidAccount(account)
                                          ? ""
                                          : !isValidAccount(account) &&
                                            isValidFirstPassword(
                                              firstPassword
                                            ) &&
                                            isSameFirstCaptcha
                                          ? "帳號"
                                          : "帳號、"
                                      }
                                      ${
                                        isValidFirstPassword(firstPassword)
                                          ? ""
                                          : !isValidFirstPassword(
                                              firstPassword
                                            ) &&
                                            isSameFirstCaptcha &&
                                            firstCaptcha
                                          ? "密碼"
                                          : "密碼、"
                                      }
                                      ${
                                        isSameFirstCaptcha && firstCaptcha
                                          ? ""
                                          : !isSameFirstCaptcha || !firstCaptcha
                                          ? "圖形驗證碼"
                                          : ""
                                      }`}
                        bgclass={"bg-white"}
                      />
                    </div>
                    {errorInput(
                      firstPassword || account || accountOnBlur,
                      !accountNotFound,
                      "error-account",
                      errorMsg,
                      " d-block",
                      "d-none"
                    )}
                  </div>
                </form>
                {/* <button onClick={handleNext}>下一步</button> */}
              </div>
            )}

            {OldData && !islogin && (
              <Nodata
                icon={"check"}
                descript={"可取回紅利點數："}
                dangerdescript={OldData.memberBonus}
                descriptsecond={"點"}
              />
            )}
            {OldData && islogin && loginConnect && (
              <Nodata
                icon={"check"}
                descript={"連接舊會員資料成功，可取回紅利點數："}
                dangerdescript={OldData.memberBonus}
                descriptsecond={"點"}
              />
            )}
            {isConnect && !islogin && (
              <div className={` `}>
                <form
                  className="d-flex flex-column gap-4"
                  onSubmit={handleFinishRegister}
                >
                  <div>
                    <label
                      htmlFor="phone-number"
                      className="form-label d-block"
                    >
                      <h6 className="primary-deep2">手機號碼(必填)</h6>
                    </label>
                    {renderInputField(
                      "phone-number",
                      "phone-number",
                      "phone-number",
                      "form-input",
                      "輸入手機號碼(0912345678)",
                      phone,
                      handlePhoneNumberChange,
                      () => {
                        setphoneBlur(true);
                      },
                      () => {
                        setphoneBlur(false);
                      },
                      0,
                      10,
                      phoneuniq
                    )}
                    {errorInput(
                      phone || phoneOnBlur,
                      isValidPhoneNumber(phone),
                      "error-phone-number",
                      "請輸入正確的格式(例如：0912345678)",
                      " d-block",
                      "d-none"
                    )}
                    {errorInput(
                      isValidPhoneNumber(phone) && phonesent,
                      phoneuniq,
                      "error-phone-uniq",
                      "該手機號碼已被註冊",
                      "d-block",
                      "d-none"
                    )}
                  </div>
                  <div>
                    <label htmlFor="email" className="form-label d-block">
                      <h6 className="primary-deep2">信箱(必填)</h6>
                    </label>
                    {renderInputField(
                      "email",
                      "email",
                      "email",
                      "form-input",
                      "請輸入常用信箱",
                      email,
                      handleEmailChange,
                      () => {
                        setEmailonBlur(true);
                      },
                      () => {
                        setEmailonBlur(false);
                      },
                      3,
                      254
                    )}
                    {errorInput(
                      email || emailOnblur,
                      isValidEmail(email),
                      "same-email",
                      "請輸入正確的信箱",
                      "d-block",
                      "d-none"
                    )}
                  </div>
                  <div>
                    <label htmlFor="username" className="form-label d-block">
                      <h6 className="primary-deep2">姓名(必填)</h6>
                    </label>
                    {renderInputField(
                      "username",
                      "text",
                      "username",
                      "form-input",
                      "輸入姓名",
                      name,
                      handleNameChange,
                      () => {
                        setNameBlur(true);
                      },
                      () => {
                        setNameBlur(false);
                      },
                      1,
                      50
                    )}
                    {errorInput(
                      name || nameOnBlur,
                      isValidName(name),
                      "error-username",
                      "請輸入正確的名稱，不得為空且不得包含特殊符號",
                      "d-block",
                      "d-none"
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="register-pwd"
                      id="user-pwd"
                      className="form-label"
                    >
                      <h6 className="primary-deep2">密碼(必填)</h6>
                    </label>
                    <div className="d-flex">
                      {renderInputField(
                        "register-pwd",
                        showPassword ? "text" : "password",
                        "register-pwd",
                        "form-pwd",
                        "請輸入密碼(格式為英文字母大小寫加上數字,共8~20位英數字)",
                        password,
                        handlePasswordChange,

                        () => {
                          setPasswordBlur(true);
                        },
                        () => {
                          setPasswordBlur(false);
                        }
                      )}
                      <div className="btn see-pwd ">
                        <button
                          className="btn checkbox-icon check showpwd"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="19"
                              viewBox="0 0 22 19"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M1.3267 0L0 1.32686L3.32283 4.64929C1.86598 5.81447 0.715658 7.30499 0 8.99996C1.73 13.0973 6 16 11 16C12.1278 16 13.2185 15.8523 14.2513 15.5765L16.9518 18.2766L18.2785 16.9497L1.3267 0ZM12.6749 14.0002L10.4219 11.7475C9.84146 11.6411 9.30305 11.3759 8.87868 10.9799C8.40503 10.5378 8.10612 9.96312 8.02343 9.34933L4.68517 6.01147C3.63979 6.83061 2.78481 7.84738 2.18 8.99996C2.98825 10.5402 4.24331 11.838 5.80248 12.7457C7.36165 13.6533 9.1624 14.1345 11 14.1345C11.5637 14.1345 12.124 14.0892 12.6749 14.0002ZM10.2905 6.27939L13.8592 9.84771C13.9517 9.57602 14 9.29031 14 8.99996C14 8.25735 13.6839 7.54516 13.1213 7.02006C12.5587 6.49496 11.7956 6.19996 11 6.19996C10.7589 6.19996 10.5209 6.22704 10.2905 6.27939ZM19.82 8.99996C19.0728 10.424 17.9436 11.6407 16.5453 12.5335L17.92 13.9081C19.7365 12.6789 21.1645 10.9788 22 8.99996C20.27 4.90262 16 1.99996 11 1.99996C9.50062 1.99996 8.06689 2.26098 6.74761 2.73697L8.24441 4.2336C9.13525 3.99055 10.0628 3.86539 11 3.86539C12.8376 3.86539 14.6383 4.34658 16.1975 5.25425C17.7567 6.16193 19.0117 7.45967 19.82 8.99996Z"
                                fill="#D9D9D9"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="19"
                              viewBox="0 0 22 19"
                              fill="none"
                            >
                              <path
                                d="M11 6.2C11.7956 6.2 12.5587 6.495 13.1213 7.0201C13.6839 7.5452 14 8.25739 14 9C14 9.74261 13.6839 10.4548 13.1213 10.9799C12.5587 11.505 11.7956 11.8 11 11.8C10.2044 11.8 9.44129 11.505 8.87868 10.9799C8.31607 10.4548 8 9.74261 8 9C8 8.25739 8.31607 7.5452 8.87868 7.0201C9.44129 6.495 10.2044 6.2 11 6.2ZM11 2C16 2 20.27 4.90267 22 9C20.27 13.0973 16 16 11 16C6 16 1.73 13.0973 0 9C1.73 4.90267 6 2 11 2ZM2.18 9C2.98825 10.5403 4.24331 11.838 5.80248 12.7457C7.36165 13.6534 9.1624 14.1346 11 14.1346C12.8376 14.1346 14.6383 13.6534 16.1975 12.7457C17.7567 11.838 19.0117 10.5403 19.82 9C19.0117 7.45971 17.7567 6.16197 16.1975 5.2543C14.6383 4.34662 12.8376 3.86544 11 3.86544C9.1624 3.86544 7.36165 4.34662 5.80248 5.2543C4.24331 6.16197 2.98825 7.45971 2.18 9Z"
                                fill="#D9D9D9"
                              />
                            </svg>
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="pt-1 secondary-deep">
                      <p>英文字母大小寫加上數字,共8~20位英數字</p>
                    </div>
                    {errorInput(
                      password || passwordOnblur,
                      isValidPassword(password),
                      "error-pwd",
                      "請輸入正確的密碼(英文字母大小寫加上數字,共8~20位英數字)",
                      " d-block",
                      "d-none"
                    )}
                  </div>
                  <div>
                    <label htmlFor="confirm-pwd" className="form-label d-block">
                      <h6 className="primary-deep2">再次輸入密碼(必填)</h6>
                    </label>
                    {renderInputField(
                      "confirm-pwd",
                      "password",
                      "confirm-pwd",
                      "form-input",
                      "請再次輸入密碼",
                      confirmPassword,
                      handleConfirmPasswordChange,
                      () => {
                        setConfirmPasswordBlur(true);
                      },
                      () => {
                        setConfirmPasswordBlur(true);
                      }
                    )}

                    {errorInput(
                      confirmPassword || confirmPasswordOnBlur,
                      !confirmPasswordError,
                      "error-confirm-pwd",
                      "再次輸入密碼和密碼不相同",
                      "d-block",
                      "d-none"
                    )}
                  </div>
                  {/* <div>
                    <div className="captcha-box">
                      <div className="captcha-foriput">
                        <label htmlFor="captcha" className="form-label d-block">
                          <h6 className="primary-deep2">驗證碼(必填)</h6>
                        </label>
                        {renderInputField(
                          "captcha",
                          "text",
                          "captcha",
                          "form-input",
                          "輸入驗證碼",
                          captcha,
                          handleCaptchaChange,
                          () => {
                            setCaptchaBlur(true);
                          },
                          () => {
                            setCaptchaBlur(false);
                          }
                        )}
                      </div>

                      <div className="captcha-photo pt-2">
                        <button
                          onClick={handlePhoneCheck}
                          className="btn primary-btn"
                          disabled={
                            !isValidPhoneNumber(phone) ||
                            !isValidEmail(email) ||
                            !isValidName(name) ||
                            !isValidPassword(password) ||
                            confirmPasswordError ||
                            verifysent
                          }
                        >
                          <p className="p2">
                            {verifysent
                              ? `發送驗證碼(${countdown})`
                              : "發送驗證碼"}
                          </p>
                        </button>
                      </div>
                    </div>
                    {errorInput(
                      (captcha || captchaOnblur) && phonesent,
                      isSameCaptcha,
                      "error-captcha",
                      "請輸入正確的手機驗證碼",
                      "d-block",
                      "d-none"
                    )}
                    {errorInput(
                      isValidPhoneNumber(phone) && phoneOnBlur,
                      !captchaError,
                      "error-captcha-limit",
                      captchaError,
                      "d-block",
                      "d-none"
                    )}

                    {errorInput(
                      isValidPhoneNumber(phone) && phonesent,
                      phoneuniq,
                      "error-phone-uniq",
                      "該手機號碼已被註冊",
                      "d-block",
                      "d-none"
                    )}
                    <div
                      className={`pt-0 ${
                        !isValidPhoneNumber(phone) ||
                        !isValidEmail(email) ||
                        !isValidName(name) ||
                        !isValidPassword(password) ||
                        confirmPasswordError
                          ? "d-block w-100"
                          : "d-none"
                      }`}
                    >
                      <Nodata
                        icon={"dangernotice"}
                        dangerclass={"ps-1"}
                        dangerdescript={`未填寫正確的${
                          isValidPhoneNumber(phone)
                            ? ""
                            : !isValidPhoneNumber(phone) &&
                              isValidEmail(email) &&
                              isValidName(name) &&
                              isValidPassword(password) &&
                              !confirmPasswordError
                            ? "手機"
                            : "手機、"
                        }${
                          isValidEmail(email)
                            ? ""
                            : !isValidEmail(email) &&
                              isValidName(name) &&
                              isValidPassword(password) &&
                              !confirmPasswordError
                            ? "信箱"
                            : "信箱、"
                        }
                ${
                  isValidName(name)
                    ? ""
                    : !isValidName(name) &&
                      isValidPassword(password) &&
                      !confirmPasswordError
                    ? "姓名"
                    : "姓名、"
                }
                ${
                  isValidPassword(password)
                    ? ""
                    : !isValidPassword(password) && !confirmPasswordError
                    ? "密碼"
                    : "密碼、"
                }
                ${
                  !confirmPasswordError
                    ? ""
                    : !!confirmPasswordError
                    ? "再次輸入密碼"
                    : ""
                }
                欄位，無法發送驗證碼`}
                        bgclass={"bg-white"}
                      />
                    </div>
                  </div> */}
                  <div>
                    <button
                      className={`btn primary-btn2 w-100`}
                      type="submit"
                      // disabled={
                      //   !isValidPhoneNumber(phone) ||
                      //   !isValidEmail(email) ||
                      //   !isValidName(name) ||
                      //   !isValidPassword(password) ||
                      //   confirmPasswordError ||
                      //   !captcha
                      // }
                    >
                      完成註冊
                    </button>
                    {errorInput(
                      isValidPhoneNumber(phone) && phonesent,
                      phoneuniq,
                      "error-phone-uniq",
                      "該手機號碼已被註冊",
                      "d-block",
                      "d-none"
                    )}
                    <div
                      className={`pt-0 ${
                        !isValidPhoneNumber(phone) ||
                        !isValidEmail(email) ||
                        !isValidName(name) ||
                        !isValidPassword(password) ||
                        !!confirmPasswordError
                          ? "d-block w-100"
                          : "d-none"
                      }`}
                    >
                      <Nodata
                        icon={"dangernotice"}
                        dangerclass={"ps-1"}
                        dangerdescript={`尚未填寫正確的${
                          isValidPhoneNumber(phone)
                            ? ""
                            : !isValidPhoneNumber(phone) &&
                              isValidEmail(email) &&
                              isValidName(name) &&
                              isValidPassword(password) &&
                              !confirmPasswordError
                            ? "手機"
                            : "手機、"
                        }${
                          isValidEmail(email)
                            ? ""
                            : !isValidEmail(email) &&
                              isValidName(name) &&
                              isValidPassword(password) &&
                              !confirmPasswordError
                            ? "信箱"
                            : "信箱、"
                        }
                                    ${
                                      isValidName(name)
                                        ? ""
                                        : !isValidName(name) &&
                                          isValidPassword(password) &&
                                          !confirmPasswordError
                                        ? "姓名"
                                        : "姓名、"
                                    }
                                    ${
                                      isValidPassword(password)
                                        ? ""
                                        : !isValidPassword(password) &&
                                          !confirmPasswordError
                                        ? "密碼"
                                        : "密碼、"
                                    }
                                    ${
                                      !confirmPasswordError
                                        ? ""
                                        : !!confirmPasswordError
                                        ? "再次輸入密碼"
                                        : "再次輸入密碼、"
                                    }
`}
                        bgclass={"bg-white"}
                      />
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default OldMemberLogin;
