import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../Context/AuthContext";
import axios from "axios";
import Loading from "../Loading";
import RenderHtml from "../../sharedComponents/RenderHtml";
import { Link } from "react-router-dom";
function CheckoutStep4() {
  const [isLoading, setIsLoading] = useState(true);
  const [redirect, setIsredirect] = useState(false);
  const [orderData, setOrderData] = useState(null);
const [storeData, setStoreData] =useState(null);
  const location = useLocation();
  //會員資料
  const { myAuth, logout } = useContext(AuthContext);
  const mid = parseInt(myAuth.sid);
  const storedToken = myAuth.token;
  //自訂義後端redirect
  ///Login?state=F&redirected=true
  const queryParams = new URLSearchParams(window.location.search);
  //https://localhost:44420/finishorder?state=2023110300001
  const stateValue = queryParams.get("state");
  const navigate = useNavigate();
  const hasState = location.state || stateValue;
  console.log("hasState","hasState")
  localStorage.removeItem("stepTwoStoreData");
  localStorage.removeItem("promoData");
  const getOrderData = () => {
    const requestData = { mid: mid, orderno: hasState };
    axios
      .post("/api/Order/finishOrder", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("發送Order/ToOrder的回應", "response.data");
        console.log("發送Order/ToOrder的回應", "response.data.paymentId");
        console.log("發送Order/ToOrder資訊:", "requestData");
        setOrderData(response.data.orderQuery);
        setStoreData(response.data.storeData)
      })
      .catch((error) => {
        console.error("發送Order/ToOrder的回應有錯誤：", "error");
        console.log("發送Order/ToOrder資訊有錯誤", "requestData");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      });
  };
  //初次載入先loading
  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    if (!hasState) {
      // navigate("/");
    }
    if (hasState && mid && storedToken) {
      console.log("hasState && mid && storedToken")
      getOrderData();

    }
    if (queryParams.get("redirected") === "true") {
      setIsredirect(true);
    }
    console.log("hasState", "hasState");
    setIsLoading(true);
  }, [hasState,mid,storedToken]);

  // console.log("orderData", orderData);
  useEffect(() => {
    if (orderData) {
      setIsLoading(false);
      console.log("orderData", "orderData");
    }
  }, [orderData]);
  if (isLoading || !orderData) {
    <Loading />;
  }
  // if (isLoading || !orderData) {
  //   return<Loading />;
  // }
  return (
    <>
      {/* <h1>state:</h1>
      <h2>{hasState}</h2>
      <h2>{redirect ? "後端拜訪" : "前端拜訪"}</h2>
      <h2>{hasState ? "有接到state資料" : "沒接到state資料"}</h2> */}
      {orderData && (
        <div className="container checkout-4 justify-content-center">
          <div className="checkout-select">
            <div className="order-info">
              <div className="order-check">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="80"
                  height="80"
                  viewBox="0 0 80 80"
                  fill="none"
                >
                  <path
                    d="M40 0C17.9087 0 0 17.9087 0 40C0 62.0913 17.9087 80 40 80C62.0913 80 80 62.0913 80 40C80 17.9087 62.0913 0 40 0ZM40 8.6914C57.2913 8.6914 71.3037 22.7131 71.3037 40C71.3037 57.287 57.2913 71.3037 40 71.3037C22.7087 71.3037 8.69627 57.287 8.69627 40C8.69633 22.7131 22.7087 8.6914 40 8.6914ZM55.3271 21.0059L32.1435 44.1943L24.624 36.6748L17.2461 44.0479L24.7656 51.5674L32.1923 58.9941L39.5655 51.6162L62.7539 28.4326L55.3271 21.0059Z"
                    fill="#C0DAC3"
                  />
                </svg>
                <h6 className="primary-deep">感謝您的訂購，訂單已成立！</h6>
              </div>
              <div className="d-flex flex-column gap-10px ">
                {orderData.paymentId === "2" && storeData ? (
                  <div className="order-detail ">
                    <RenderHtml content={storeData.bankAccount} />
                  </div>
                ) : (
                  <></>
                )}

                <div className="order-detail">
                  <div className="content">
                    <div className="title">
                      <p>訂單編號</p>
                      <p>：</p>
                    </div>
                    <div className="descript-container">
                      <p>{orderData.orderNo}</p>
                    </div>
                  </div>
                  <div className="content">
                    <div className="title">
                      <p>付款方式</p>
                      <p>：</p>
                    </div>
                    <div className="descript-container">
                      <p>{orderData.paymentMethod}</p>
                      {/* <p>{orderData.paymentId}</p> */}
                      {/* {orderData.paymentId === "1"  &&(<p>信用卡付款</p>)}
                      {orderData.paymentId === "2"  &&(<p>銀行轉帳付款</p>)}
                      {orderData.paymentId === "3" &&(<p>貨到付款</p>)} */}
                    </div>
                  </div>
                  <div className="content">
                    <div className="title">
                      <p>總金額</p>
                      <p>：</p>
                    </div>
                    <div className="descript-container">
                      <p>${orderData.orderPrice.toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="popup-footer">
           
            <div className="pt-5">
                <div className="footer-bottom ">
                {orderData.paymentId === "2" && storeData && (
                  <>
                  
                  <p className="danger">※請於三天內繳款，否則訂單將取消。</p>
                  <p className="primary-deep2">轉帳完成後：</p>
                  <p>
                    透過「會員專區」{">"}
                    「訂單資料」的「回傳匯款資料」功能將「匯款日期、轉帳帳號後5碼」回傳至本公司，以便確認付款資訊，如有任何問題歡迎來電洽詢。
                  </p>
                 
                  </>  )} 
                  <div className="d-flex">
                    <p>服務電話：</p>
                    <p>{storeData.contactTel}</p>
                  </div>
                  <div className="d-flex">
                    <p>電子信箱：</p>
                    <p>{storeData.contactEmail}</p>

                  </div>
                </div>
              </div>
            
           
             
            </div>
            <div className="popup-footer bg-white d-flex gap-5 px-0 pt-4 pb-5 pt-xl-4">
            <div className="d-flex w-100 gap-3 gap-xl-4">
                <Link to="/" className="btn primary-btn-big w-100  py-3">
                <h6 className="font-weight-bold ">返回首頁</h6>
                </Link>
                <Link to="/member/order" className="btn primary-btn-big w-100  py-3">
                 
                <h6 className="font-weight-bold ">查詢訂單</h6> 
                </Link>
              </div>
            </div>
            
          </div>
        </div>
      )}
    </>
  );
}

export default CheckoutStep4;
