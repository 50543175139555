import React from 'react'


function Cartanima({img}) {
  // console.log("img",img)
  return (
    <>
      <div className="cartbox">
        <img
          src={img}
          className="cover-image"
          alt="..."
        />
      </div>
      {/* <img
          src={img}
          className="cover-image w-100"
          alt="..."
        /> */}
    </>
  )
}

export default Cartanima