// CollectionCard.js
import React from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setFilteredTag } from "../redux/actions/action";
function getTagByCollectionIcon(prodIcon) {
  
  switch (prodIcon) {
    case 1:
      return (
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 81 30" fill="none">
  <path d="M80.425 0H8.979L0 15L8.979 30H80.425V0Z" fill="#E60012"/>
  <path d="M24.5631 15.5221V25.1381H18.9561V5.80908H24.5631V13.5831H29.1361V5.80908H34.7441V25.1381H29.1361V15.5221H24.5631Z" fill="white"/>
  <path d="M45.9729 5.46924C51.5989 5.46924 54.4119 8.80124 54.4119 15.4662C54.4119 22.1302 51.5469 25.4632 45.8159 25.4632C40.0869 25.4632 37.2209 22.0562 37.2209 15.2392C37.2219 8.72524 40.1389 5.46924 45.9729 5.46924ZM45.8159 7.01224C44.7589 7.01224 44.0449 7.59524 43.6709 8.76124C43.2969 9.92724 43.1109 12.1612 43.1109 15.4662C43.1109 18.7702 43.2979 21.0052 43.6709 22.1702C44.0439 23.3362 44.7589 23.9192 45.8159 23.9192C46.8749 23.9192 47.5879 23.3362 47.9619 22.1702C48.3349 21.0052 48.5209 18.7712 48.5209 15.4662C48.5209 12.1712 48.3349 9.93824 47.9619 8.76824C47.5889 7.59724 46.8749 7.01224 45.8159 7.01224Z" fill="white"/>
  <path d="M70.4549 5.80908V7.74908H65.7119V25.1381H59.7659V7.74908H54.9919V5.80908H70.4549Z" fill="white"/>
  <path d="M7.79998 14.9999C7.79998 15.9299 7.04598 16.6829 6.11598 16.6829C5.18698 16.6829 4.43298 15.9309 4.43298 14.9999C4.43298 14.0689 5.18698 13.3169 6.11598 13.3169C7.04598 13.3169 7.79998 14.0699 7.79998 14.9999Z" fill="white"/>
</svg>
      );
    case 2:
      return (
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 81 30" fill="none">
  <path d="M0 15L8.979 0H80.425V30H8.979L0 15Z" fill="#E60012"/>
  <path d="M73.401 5.80908V7.74908H66.477V13.5971H72.297V15.5371H66.477V23.1971H74.576V25.1381H60.869V5.80908H73.401Z" fill="white"/>
  <path d="M51.7499 5.80908V23.1971H59.6939V25.1381H46.1429V5.80908H51.7499Z" fill="white"/>
  <path d="M39.246 5.80908L44.627 25.1371H38.992L37.845 20.9471H32.025L30.793 25.1371H28.598L34.319 5.80908H39.246ZM32.577 19.0061H37.321L35.041 10.4391L32.577 19.0061Z" fill="white"/>
  <path d="M7.79998 14.9999C7.79998 15.9299 7.04598 16.6829 6.11598 16.6829C5.18698 16.6829 4.43298 15.9309 4.43298 14.9999C4.43298 14.0689 5.18698 13.3169 6.11598 13.3169C7.04598 13.3169 7.79998 14.0699 7.79998 14.9999Z" fill="white"/>
  <path d="M27.182 11.2179L24.577 11.6989C24.69 11.1799 24.746 10.7459 24.746 10.3969C24.746 8.14991 23.382 7.02691 20.654 7.02691C18.502 7.02691 17.426 7.80091 17.426 9.34891C17.426 10.5099 18.629 11.5059 21.036 12.3369C25.681 13.9319 28.003 16.2919 28.003 19.4169C28.003 21.2479 27.352 22.7179 26.049 23.8279C24.746 24.9359 23.014 25.4909 20.852 25.4909C16.066 25.4909 13.673 23.6359 13.673 19.9259C13.673 19.6239 13.697 19.2519 13.744 18.8079L16.449 18.2129C16.241 19.0339 16.137 19.7469 16.137 20.3509C16.137 22.7489 17.511 23.9489 20.258 23.9489C22.457 23.9489 23.557 23.0849 23.557 21.3569C23.557 20.0739 22.24 18.9449 19.606 17.9719C15.802 16.5759 13.9 14.3429 13.9 11.2749C13.9 9.53791 14.535 8.13791 15.804 7.07591C17.074 6.01391 18.743 5.48291 20.81 5.48291C25.058 5.48291 27.182 7.39491 27.182 11.2179Z" fill="white"/>
</svg>
      );
    case 3:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 81 30" fill="none">
        <g clip-path="url(#clip0_2658_20017)">
          <path d="M80.425 0H8.979L0 15L8.979 30H80.425V0Z" fill="#E60012"/>
          <path d="M31.855 5.80908V25.1381H27.564L19.493 12.9031V25.1381H17.61V5.80908H21.164L29.972 19.0201V5.80908H31.855Z" fill="white"/>
          <path d="M47.602 5.80908V7.74908H40.677V13.5971H46.497V15.5371H40.677V23.1971H48.776V25.1381H35.069V5.80908H47.602Z" fill="white"/>
          <path d="M71.6881 5.80908L66.4191 25.1381H62.7661L60.0761 14.5871L57.3431 25.1381H53.6901L48.7061 5.80908H54.3411L57.2721 17.5331L60.2041 5.80908H63.5021L66.3911 17.5751L69.479 5.80908H71.6881Z" fill="white"/>
          <path d="M7.79998 14.9999C7.79998 15.9299 7.04598 16.6829 6.11598 16.6829C5.18698 16.6829 4.43298 15.9309 4.43298 14.9999C4.43298 14.0689 5.18698 13.3169 6.11598 13.3169C7.04598 13.3169 7.79998 14.0699 7.79998 14.9999Z" fill="white"/>
        </g>
        <defs>
          <clipPath id="clip0_2658_20017">
            <rect width="80.425" height="30" fill="white"/>
          </clipPath>
        </defs>
      </svg>
      );
      // case 9:
      //   return (
      //    null
      //   );
    default:
      return null; // 當 prodIcon 不是 1、2、3 時 回傳 null
  }
}

  
function CollectionCard(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //console.log('content', "props");
  const parsedProdIcon = parseInt(props.content.cateIcon) || 0;
  const tag = getTagByCollectionIcon(parsedProdIcon);
   //console.log("parsedProdIcon","parsedProdIcon");// 渲染對應的行銷tag

  const handleChildClick = (navurl) => {
    if (navurl.includes('collections')) {

      const sessionValue = navurl.replace("collections/", "").replace("/", "");
      
      sessionStorage.setItem('collectiontag', sessionValue);

      dispatch(setFilteredTag(null));
      console.log("sessionValue","sessionValue")
      navigate(navurl);
    }
  };
  // console.log('content', props.content.title);
//   console.log('content', props.content.image);

  return (
    <div className='cursor-pointer' onClick={() => handleChildClick(`/collections/${props.content.slug}`)}>
    <div className="col">
    
      <img src={props.content.image}  alt="" className='w-100 ratio ratio-1x1'/>
      <div className="d-flex gap-1 justify-content-center align-items-center">
        <div className='category-tag-svg'>{tag}</div>
      
      <h5 className="py-2">{props.content.title}</h5>
      
      </div>
      
    </div>
  </div>  
  );
}

export default CollectionCard;
