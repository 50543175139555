import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setFilteredTag } from "../redux/actions/action";
import Loading from "./Loading";
import AuthContext from "../Context/AuthContext";
import CartPopup from "../sharedComponents/CartPopup";
import WordSearch from "../sharedComponents/WordSearch";
import axios from "axios";
function NavMenu() {
  const [collapsed, setCollapsed] = useState(true);
  const [searchData, setSearchData] = useState(null);
  const [navData, setNavData] = useState(null);

  const [companyInfo, setCompanyInfo] = useState(null);
  const [filterClick, setFilterClick] = useState(null);
  const [cartlength, setCartLength] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // 搜尋功能
  const [searchExpand, setSearchExpand] = useState(false);
  // 商品
  const selectedOption = useSelector((state) => state.product.productId);

  const [pathnames, setPathnames] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { blogCateSlug, blogSlug } = useParams();
  //redux儲存的filteredTag
  const dispatch = useDispatch();
  const filteredTag = useSelector((state) => state.collection.filteredTag);

  //會員是否登入
  const { myAuth, logout } = useContext(AuthContext);
  useEffect(() => {
    setCollapsed(true);
    if (!location.pathname.includes("/search")) {
      setSearchExpand(false);
    }
  }, [location]);

  useEffect(() => {
    axios
      .get("api/Home/HeadProdCate")
      .then((response) => {
        if (response.status === 200) {
          setSearchData(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data from API:", "error");
      });
  }, []);

  useEffect(() => {
    axios
      .get("api/Home/HeadNavMenu")
      .then((response) => {
        if (response.status === 200) {
          setNavData(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data from API:", "error");
      });
  }, []);

  useEffect(() => {
    axios
      .get("api/Home/StoreData")
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          setCompanyInfo(data);
        }
      })
      .catch(() => {
        console.error("Error fetching data from API");
      });
  }, []);
  //購物車資料

  useEffect(() => {
    const cartData = localStorage.getItem("cart");
    const cartArray = cartData ? JSON.parse(cartData) : [];
    setCartLength(cartArray.length);
  }, [selectedOption]);
  const handleCartPopup = () => {
    setIsPopupOpen(true);
  };

  if (!searchData || !navData) {
    return <Loading />;
  }

  const toggleNavbar = () => {
    setCollapsed((prevCollapsed) => !prevCollapsed);
    setFilterClick(null);
  };
  const handleChildClick = (navurl) => {
    if (navurl.includes("collections")) {
      const sessionValue = navurl.replace("collections/", "").replace("/", "");
      const storedCollectionTag = sessionStorage.getItem("collectiontag");

      if (location.pathname.includes("collections")) {
        const updatedTags = [...filteredTag];
        if (!updatedTags.includes(sessionValue)) {
          updatedTags.push(sessionValue);
          setFilteredTag(updatedTags);
          dispatch(setFilteredTag(updatedTags));
        }
      } else {
        dispatch(setFilteredTag(null));
        navigate(navurl);
      }
    }
  };

  const navigateurl = (navurl) => {
    if (navurl.includes("collections")) {
      const sessionValue = navurl.replace("collections/", "").replace("/", "");
      const storedCollectionTag = sessionStorage.getItem("collectiontag");

      if (location.pathname.includes("collections")) {
        const updatedTags = [...filteredTag];
        console.log("filteredTag", filteredTag);
        console.log("sessionValue", sessionValue);
        if (updatedTags.includes(sessionValue) && updatedTags.length === 1) {
          console.log("updatedTags.length", updatedTags.length);
          return;
        } else if (
          updatedTags.includes(sessionValue) &&
          updatedTags.length !== 1
        ) {
          console.log("updatedTags.length", updatedTags.length);
          return;
        } else {
          dispatch(setFilteredTag(null));
          navigate(navurl);
        }
      } else {
        dispatch(setFilteredTag(null));
        navigate(navurl);
      }
    }
  };
  const handleSearchExpand = () => {
    if (searchExpand) {
      setSearchExpand(false);
    } else {
      setSearchExpand(true);
    }
  };

  const handleFilterClick = (index) => {
    setFilterClick((prevIndex) => (prevIndex === index ? null : index));
    setCollapsed(true);
  };
  const handleAllcollapse = () => {
    setFilterClick(null);
    setCollapsed(true);
  };

  return (
    <header>
      {isPopupOpen && (
        <CartPopup
          onClose={() => {
            setIsPopupOpen(false);
          }}
        />
      )}
      {filterClick === null ? (
        <></>
      ) : (
        <div className={`search-collapse `} onClick={handleAllcollapse}></div>
      )}
      <div className="nav-parent">
        <nav className="navbar navbar-expand-xxl white-bg">
          <div className="container logo-pd">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded={!collapsed}
              aria-label="Toggle navigation"
              onClick={toggleNavbar}
            >
              <svg width="51" height="30" viewBox="0 0 51 30" fill="none">
                <path
                  d="M38.9642 4.63916H12.0356M38.9642 14.9963H12.0356M38.9642 25.3534H12.0356"
                  stroke="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <Link to="/" className="navbar-logo navbar-brand me-0 d-xxl-none">
              {companyInfo && companyInfo.headerMLogo && (
                <img src={companyInfo.headerMLogo} alt="" />
              )}
            </Link>
            <Link
              to="/"
              className="navbar-logo navbar-brand d-none d-xxl-block py-0"
            >
              {companyInfo && companyInfo.headerLogo && (
                <img src={companyInfo.headerLogo} alt="" />
              )}
            </Link>
          </div>
          <div className="nav-container">
            {/* 最上方的分類 */}
            <div className="container-fluid d-none d-xxl-block justify-content-center search-section ms-0">
              <div className="d-flex align-items-center search ">
              {searchExpand ? (
                  <WordSearch
                    searchControl={() => {
                      handleSearchExpand();
                    }}
                  />
                ) : (
                  <div className="search-group me-2 row">
                    {searchData.map((item, index) => (
                      <div
                        key={index}
                        className={`search-btn ${
                          index === 0 ? "btn-left" : "btn-right"
                        } col d-flex align-items-center justify-content-center`}
                      >
                        {index === 0 ? (
                          <>
                            <svg
                              width="21"
                              height="18"
                              viewBox="0 0 21 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="showless"
                            >
                              <g clipPath="url(#clip0_456_14085)">
                                <path
                                  d="M10.25 0.915322C10.4144 0.915322 10.5739 0.946152 10.7285 1.00781C10.883 1.06947 11.0113 1.15168 11.1132 1.25445L16.7859 6.92709C17.012 7.15318 17.125 7.44092 17.125 7.79032C17.125 8.13972 17.012 8.42747 16.7859 8.65355C16.5598 8.87963 16.272 8.99268 15.9226 8.99268C15.5732 8.99268 15.2855 8.87963 15.0594 8.65355L10.25 3.84413L5.44058 8.65355C5.2145 8.87964 4.92676 8.99268 4.57735 8.99268C4.22795 8.99268 3.94021 8.87964 3.71413 8.65355C3.48804 8.42747 3.375 8.13973 3.375 7.79032C3.375 7.44092 3.48804 7.15318 3.71413 6.92709L9.38677 1.25445C9.51009 1.13113 9.64368 1.04357 9.78756 0.991779C9.93143 0.939985 10.0856 0.9145 10.25 0.915322Z"
                                  fill="white"
                                />
                                <path
                                  d="M10.25 8.99271C10.4144 8.99271 10.5739 9.02354 10.7285 9.0852C10.883 9.14686 11.0113 9.22908 11.1132 9.33184L16.7859 15.0045C17.012 15.2306 17.125 15.5183 17.125 15.8677C17.125 16.2171 17.012 16.5049 16.7859 16.7309C16.5598 16.957 16.272 17.0701 15.9226 17.0701C15.5732 17.0701 15.2855 16.957 15.0594 16.7309L10.25 11.9215L5.44058 16.7309C5.2145 16.957 4.92676 17.0701 4.57735 17.0701C4.22795 17.0701 3.94021 16.957 3.71413 16.7309C3.48804 16.5049 3.375 16.2171 3.375 15.8677C3.375 15.5183 3.48804 15.2306 3.71413 15.0045L9.38677 9.33184C9.51009 9.20852 9.64368 9.12097 9.78756 9.06917C9.93143 9.01738 10.0856 8.99189 10.25 8.99271Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_456_14085">
                                  <rect
                                    width="20"
                                    height="16.1547"
                                    fill="white"
                                    transform="translate(0.25 0.915283)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            <svg
                              width="21"
                              height="18"
                              viewBox="0 0 21 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="showmore"
                            >
                              <g clipPath="url(#clip0_510_12213)">
                                <path
                                  d="M10.5 8.99996C10.3356 8.99996 10.1761 8.96913 10.0215 8.90747C9.86697 8.84581 9.73871 8.7636 9.63677 8.66083L3.96413 2.98819C3.73804 2.76211 3.625 2.47436 3.625 2.12496C3.625 1.77556 3.73804 1.48782 3.96413 1.26173C4.19021 1.03565 4.47795 0.922607 4.82735 0.922607C5.17676 0.922607 5.4645 1.03565 5.69058 1.26173L10.5 6.07115L15.3094 1.26173C15.5355 1.03565 15.8232 0.922607 16.1726 0.922607C16.522 0.922607 16.8098 1.03565 17.0359 1.26173C17.262 1.48782 17.375 1.77556 17.375 2.12496C17.375 2.47436 17.262 2.76211 17.0359 2.98819L11.3632 8.66083C11.2399 8.78415 11.1063 8.87171 10.9624 8.9235C10.8186 8.9753 10.6644 9.00078 10.5 8.99996Z"
                                  fill="#FF5C26"
                                />
                                <path
                                  d="M10.5 17.0774C10.3356 17.0774 10.1761 17.0465 10.0215 16.9849C9.86697 16.9232 9.73871 16.841 9.63677 16.7382L3.96413 11.0656C3.73804 10.8395 3.625 10.5518 3.625 10.2024C3.625 9.85295 3.73804 9.56521 3.96413 9.33913C4.19021 9.11304 4.47795 9 4.82735 9C5.17676 9 5.4645 9.11304 5.69058 9.33913L10.5 14.1485L15.3094 9.33913C15.5355 9.11304 15.8232 9 16.1726 9C16.522 9 16.8098 9.11304 17.0359 9.33913C17.262 9.56521 17.375 9.85295 17.375 10.2024C17.375 10.5518 17.262 10.8395 17.0359 11.0656L11.3632 16.7382C11.2399 16.8615 11.1063 16.9491 10.9624 17.0009C10.8186 17.0527 10.6644 17.0782 10.5 17.0774Z"
                                  fill="#FF5C26"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_510_12213">
                                  <rect
                                    width="20"
                                    height="16.1547"
                                    fill="white"
                                    transform="translate(0.5 0.922607)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            <h5 className="py-1 mb-0 h5">{item.catename}</h5>
                          </>
                        ) : (
                          <>
                            <h5 className="py-1 mb-0 h5">{item.catename}</h5>
                            <svg
                              width="21"
                              height="18"
                              viewBox="0 0 21 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="showless"
                            >
                              <g clipPath="url(#clip0_456_14085)">
                                <path
                                  d="M10.25 0.915322C10.4144 0.915322 10.5739 0.946152 10.7285 1.00781C10.883 1.06947 11.0113 1.15168 11.1132 1.25445L16.7859 6.92709C17.012 7.15318 17.125 7.44092 17.125 7.79032C17.125 8.13972 17.012 8.42747 16.7859 8.65355C16.5598 8.87963 16.272 8.99268 15.9226 8.99268C15.5732 8.99268 15.2855 8.87963 15.0594 8.65355L10.25 3.84413L5.44058 8.65355C5.2145 8.87964 4.92676 8.99268 4.57735 8.99268C4.22795 8.99268 3.94021 8.87964 3.71413 8.65355C3.48804 8.42747 3.375 8.13973 3.375 7.79032C3.375 7.44092 3.48804 7.15318 3.71413 6.92709L9.38677 1.25445C9.51009 1.13113 9.64368 1.04357 9.78756 0.991779C9.93143 0.939985 10.0856 0.9145 10.25 0.915322Z"
                                  fill="white"
                                />
                                <path
                                  d="M10.25 8.99271C10.4144 8.99271 10.5739 9.02354 10.7285 9.0852C10.883 9.14686 11.0113 9.22908 11.1132 9.33184L16.7859 15.0045C17.012 15.2306 17.125 15.5183 17.125 15.8677C17.125 16.2171 17.012 16.5049 16.7859 16.7309C16.5598 16.957 16.272 17.0701 15.9226 17.0701C15.5732 17.0701 15.2855 16.957 15.0594 16.7309L10.25 11.9215L5.44058 16.7309C5.2145 16.957 4.92676 17.0701 4.57735 17.0701C4.22795 17.0701 3.94021 16.957 3.71413 16.7309C3.48804 16.5049 3.375 16.2171 3.375 15.8677C3.375 15.5183 3.48804 15.2306 3.71413 15.0045L9.38677 9.33184C9.51009 9.20852 9.64368 9.12097 9.78756 9.06917C9.93143 9.01738 10.0856 8.99189 10.25 8.99271Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_456_14085">
                                  <rect
                                    width="20"
                                    height="16.1547"
                                    fill="white"
                                    transform="translate(0.25 0.915283)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            <svg
                              width="21"
                              height="18"
                              viewBox="0 0 21 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="showmore"
                            >
                              <g clipPath="url(#clip0_510_12213)">
                                <path
                                  d="M10.5 8.99996C10.3356 8.99996 10.1761 8.96913 10.0215 8.90747C9.86697 8.84581 9.73871 8.7636 9.63677 8.66083L3.96413 2.98819C3.73804 2.76211 3.625 2.47436 3.625 2.12496C3.625 1.77556 3.73804 1.48782 3.96413 1.26173C4.19021 1.03565 4.47795 0.922607 4.82735 0.922607C5.17676 0.922607 5.4645 1.03565 5.69058 1.26173L10.5 6.07115L15.3094 1.26173C15.5355 1.03565 15.8232 0.922607 16.1726 0.922607C16.522 0.922607 16.8098 1.03565 17.0359 1.26173C17.262 1.48782 17.375 1.77556 17.375 2.12496C17.375 2.47436 17.262 2.76211 17.0359 2.98819L11.3632 8.66083C11.2399 8.78415 11.1063 8.87171 10.9624 8.9235C10.8186 8.9753 10.6644 9.00078 10.5 8.99996Z"
                                  fill="#FF5C26"
                                />
                                <path
                                  d="M10.5 17.0774C10.3356 17.0774 10.1761 17.0465 10.0215 16.9849C9.86697 16.9232 9.73871 16.841 9.63677 16.7382L3.96413 11.0656C3.73804 10.8395 3.625 10.5518 3.625 10.2024C3.625 9.85295 3.73804 9.56521 3.96413 9.33913C4.19021 9.11304 4.47795 9 4.82735 9C5.17676 9 5.4645 9.11304 5.69058 9.33913L10.5 14.1485L15.3094 9.33913C15.5355 9.11304 15.8232 9 16.1726 9C16.522 9 16.8098 9.11304 17.0359 9.33913C17.262 9.56521 17.375 9.85295 17.375 10.2024C17.375 10.5518 17.262 10.8395 17.0359 11.0656L11.3632 16.7382C11.2399 16.8615 11.1063 16.9491 10.9624 17.0009C10.8186 17.0527 10.6644 17.0782 10.5 17.0774Z"
                                  fill="#FF5C26"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_510_12213">
                                  <rect
                                    width="20"
                                    height="16.1547"
                                    fill="white"
                                    transform="translate(0.5 0.922607)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </>
                        )}
                        <div
                          className={`search-content black text-center showless ${
                            index === 0 ? "left-10px" : "right-10px"
                          }`}
                        >
                          {item.children.map((child, childIndex) => (
                            <div
                              key={childIndex}
                              onClick={() =>
                                handleChildClick(`/collections/${child.slug}`)
                              }
                            >
                              <p className="h6">{child.catename}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {!searchExpand && (
                  <div className="cursor-pointer" onClick={handleSearchExpand}>
                    <svg
                      width="28"
                      height="29"
                      viewBox="0 0 28 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="d-block d-xxl-none"
                    >
                      <path
                        d="M25.2955 28.5546L16.3864 19.6328C15.5909 20.2701 14.6761 20.7746 13.642 21.1463C12.608 21.518 11.5076 21.7039 10.3409 21.7039C7.45076 21.7039 5.005 20.7013 3.00364 18.696C1.00227 16.6907 0.00106061 14.2415 0 11.3483C0 8.45403 1.00121 6.0048 3.00364 4.00058C5.00606 1.99637 7.45182 0.993738 10.3409 0.992676C13.2311 0.992676 15.6768 1.99531 17.6782 4.00058C19.6795 6.00586 20.6808 8.45509 20.6818 11.3483C20.6818 12.5166 20.4962 13.6186 20.125 14.6541C19.7538 15.6897 19.25 16.6058 18.6136 17.4023L27.5625 26.3639C27.8542 26.656 28 27.0145 28 27.4393C28 27.8642 27.8409 28.2359 27.5227 28.5546C27.2311 28.8466 26.8598 28.9927 26.4091 28.9927C25.9583 28.9927 25.5871 28.8466 25.2955 28.5546ZM10.3409 18.5176C12.3295 18.5176 14.0202 17.8203 15.4127 16.4257C16.8053 15.0312 17.5011 13.3387 17.5 11.3483C17.5 9.35683 16.8037 7.66382 15.4111 6.26926C14.0186 4.8747 12.3285 4.17796 10.3409 4.17902C8.35227 4.17902 6.66167 4.8763 5.26909 6.27086C3.87651 7.66541 3.18076 9.35789 3.18182 11.3483C3.18182 13.3398 3.87811 15.0328 5.27068 16.4273C6.66326 17.8219 8.35333 18.5186 10.3409 18.5176Z"
                        fill="#FF5C26"
                      />
                    </svg>
                    <svg
                      width="43"
                      height="44"
                      viewBox="0 0 43 44"
                      fill="none"
                      className="d-none d-xxl-block"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_253_25929)">
                        <path
                          d="M38.8466 42.8272L25.1648 29.1259C23.9432 30.1046 22.5384 30.8793 20.9503 31.4502C19.3622 32.0211 17.6723 32.3065 15.8807 32.3065C11.4422 32.3065 7.68625 30.7668 4.61273 27.6873C1.5392 24.6077 0.00162879 20.8464 0 16.4033C0 11.9585 1.53758 8.19718 4.61273 5.11929C7.68788 2.04139 11.4439 0.501631 15.8807 0.5C20.3191 0.5 24.0751 2.03976 27.1486 5.11929C30.2222 8.19881 31.7597 11.9601 31.7614 16.4033C31.7614 18.1975 31.4763 19.8898 30.9062 21.4801C30.3362 23.0704 29.5625 24.4772 28.5852 25.7006L42.3281 39.463C42.776 39.9116 43 40.4621 43 41.1145C43 41.767 42.7557 42.3378 42.267 42.8272C41.8191 43.2757 41.2491 43.5 40.5568 43.5C39.8646 43.5 39.2945 43.2757 38.8466 42.8272ZM15.8807 27.4132C18.9347 27.4132 21.5309 26.3424 23.6695 24.2008C25.8081 22.0591 26.8766 19.46 26.875 16.4033C26.875 13.3449 25.8057 10.745 23.6671 8.60333C21.5285 6.46169 18.933 5.39168 15.8807 5.39331C12.8267 5.39331 10.2304 6.46414 8.09182 8.60578C5.95322 10.7474 4.88473 13.3466 4.88636 16.4033C4.88636 19.4616 5.95566 22.0616 8.09426 24.2032C10.2329 26.3449 12.8283 27.4149 15.8807 27.4132Z"
                          fill="#FF5C26"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_253_25929">
                          <rect
                            width="43"
                            height="43"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                )}

               
              </div>
            </div>
            <div
              className={`collapse navbar-collapse ${
                collapsed ? "" : "show"
              } pt-xxl-0`}
              id="navbarSupportedContent"
              onClick={handleAllcollapse}
            >
              <ul className="navbar-nav ms-auto pe-5 pe-xxl-0 mb-2 mb-xxl-0">
                {navData.map((item, index) => (
                  <li
                    key={index}
                    className={`nav-item pe-4 ${
                      item.children.length > 0 || !item.navurl
                        ? "d-none d-xxl-block pack-show"
                        : ""
                    }`}
                  >
                    <Link
                      className="navbar-btn h6 active cursor-pointer"
                      to={item.navurl ? item.navurl : "#"}
                      // onClick={() => handleChildClick(item.navurl)}
                      aria-current="page"
                    >
                      <p>{item.navname}</p>
                    </Link>

                    {item.children.length > 0 && (
                      <div className="search-cate primary-deep2 pack-showless">
                        <div className="search-cate-row">
                          {item.children.map((child, childIndex) => (
                            <div key={childIndex} className="search-cate-item">
                              <div
                                // to={child.navurl}
                                className="cursor-pointer"
                                onClick={() => navigateurl(child.navurl)}
                              >
                                <h6>{child.navname}</h6>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </li>
                ))}
                <li className="nav-item pe-4 d-block d-xxl-none">
                  <Link to={"/pages/公司介紹"}>
                    <p>{"公司介紹"}</p>
                  </Link>
                </li>
                <li className="nav-item pe-4 d-block d-xxl-none">
                  <Link to={"/pages/客製說明"}>
                    <p>{"客製說明"}</p>
                  </Link>
                </li>
                <li className="nav-item pe-4 d-block d-xxl-none">
                  <Link to={"/pages/購物須知"}>
                    <p>{"購物須知"}</p>
                  </Link>
                </li>
                <li className="nav-item pe-4 d-block d-xxl-none">
                  <Link to={"/pages/紅利點數說明"}>
                    <p>{"紅利點數說明"}</p>
                  </Link>
                </li>
                <li className="nav-item pe-4 d-block d-xxl-none">
                  <Link to={"/pages/隱私權政策"}>
                    <p>{"隱私權政策"}</p>
                  </Link>
                </li>
                <li className="nav-item pe-4 d-block d-xxl-none">
                  <Link
                    to={myAuth.authorized ? "/" : "/login"}
                    onClick={myAuth.authorized ? logout : null}
                  >
                    <p>{myAuth.authorized ? "登出" : "會員登入"}</p>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className=" d-xxl-none justify-content-center search-section ">
            {/* 搜尋 */}
            <div className="container position-absolute px-2 searct-top">
              <div className="d-flex align-items-center search justify-content-center gap-2  w-100">
               

                <div className="d-block w-100">
                  {searchExpand ? (
                    <WordSearch
                      searchControl={() => {
                        handleSearchExpand();
                      }}
                    />
                  ) : (
                    <div className="search-group row">
                      {searchData.map((item, index) => (
                        <div
                          key={index}
                          className={`search-btn phone-search  ${
                            index === 0 ? "btn-left" : "btn-right"
                          }  ${
                            filterClick === index
                              ? "phone-filter-search-select"
                              : "phone-filter-search"
                          } col d-flex align-items-center justify-content-center`}
                          onClick={() => handleFilterClick(index)}
                        >
                          {index === 0 ? (
                            <>
                              <svg
                                width="21"
                                height="18"
                                viewBox="0 0 21 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className={`${
                                  filterClick === index ? "show" : "hidden"
                                } `}
                              >
                                <g clipPath="url(#clip0_456_14085)">
                                  <path
                                    d="M10.25 0.915322C10.4144 0.915322 10.5739 0.946152 10.7285 1.00781C10.883 1.06947 11.0113 1.15168 11.1132 1.25445L16.7859 6.92709C17.012 7.15318 17.125 7.44092 17.125 7.79032C17.125 8.13972 17.012 8.42747 16.7859 8.65355C16.5598 8.87963 16.272 8.99268 15.9226 8.99268C15.5732 8.99268 15.2855 8.87963 15.0594 8.65355L10.25 3.84413L5.44058 8.65355C5.2145 8.87964 4.92676 8.99268 4.57735 8.99268C4.22795 8.99268 3.94021 8.87964 3.71413 8.65355C3.48804 8.42747 3.375 8.13973 3.375 7.79032C3.375 7.44092 3.48804 7.15318 3.71413 6.92709L9.38677 1.25445C9.51009 1.13113 9.64368 1.04357 9.78756 0.991779C9.93143 0.939985 10.0856 0.9145 10.25 0.915322Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M10.25 8.99271C10.4144 8.99271 10.5739 9.02354 10.7285 9.0852C10.883 9.14686 11.0113 9.22908 11.1132 9.33184L16.7859 15.0045C17.012 15.2306 17.125 15.5183 17.125 15.8677C17.125 16.2171 17.012 16.5049 16.7859 16.7309C16.5598 16.957 16.272 17.0701 15.9226 17.0701C15.5732 17.0701 15.2855 16.957 15.0594 16.7309L10.25 11.9215L5.44058 16.7309C5.2145 16.957 4.92676 17.0701 4.57735 17.0701C4.22795 17.0701 3.94021 16.957 3.71413 16.7309C3.48804 16.5049 3.375 16.2171 3.375 15.8677C3.375 15.5183 3.48804 15.2306 3.71413 15.0045L9.38677 9.33184C9.51009 9.20852 9.64368 9.12097 9.78756 9.06917C9.93143 9.01738 10.0856 8.99189 10.25 8.99271Z"
                                    fill="white"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_456_14085">
                                    <rect
                                      width="20"
                                      height="16.1547"
                                      fill="white"
                                      transform="translate(0.25 0.915283)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                              <svg
                                width="21"
                                height="18"
                                viewBox="0 0 21 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className={`${
                                  filterClick === index ? "hidden" : "show"
                                } `}
                              >
                                <g clipPath="url(#clip0_510_12213)">
                                  <path
                                    d="M10.5 8.99996C10.3356 8.99996 10.1761 8.96913 10.0215 8.90747C9.86697 8.84581 9.73871 8.7636 9.63677 8.66083L3.96413 2.98819C3.73804 2.76211 3.625 2.47436 3.625 2.12496C3.625 1.77556 3.73804 1.48782 3.96413 1.26173C4.19021 1.03565 4.47795 0.922607 4.82735 0.922607C5.17676 0.922607 5.4645 1.03565 5.69058 1.26173L10.5 6.07115L15.3094 1.26173C15.5355 1.03565 15.8232 0.922607 16.1726 0.922607C16.522 0.922607 16.8098 1.03565 17.0359 1.26173C17.262 1.48782 17.375 1.77556 17.375 2.12496C17.375 2.47436 17.262 2.76211 17.0359 2.98819L11.3632 8.66083C11.2399 8.78415 11.1063 8.87171 10.9624 8.9235C10.8186 8.9753 10.6644 9.00078 10.5 8.99996Z"
                                    fill="#FF5C26"
                                  />
                                  <path
                                    d="M10.5 17.0774C10.3356 17.0774 10.1761 17.0465 10.0215 16.9849C9.86697 16.9232 9.73871 16.841 9.63677 16.7382L3.96413 11.0656C3.73804 10.8395 3.625 10.5518 3.625 10.2024C3.625 9.85295 3.73804 9.56521 3.96413 9.33913C4.19021 9.11304 4.47795 9 4.82735 9C5.17676 9 5.4645 9.11304 5.69058 9.33913L10.5 14.1485L15.3094 9.33913C15.5355 9.11304 15.8232 9 16.1726 9C16.522 9 16.8098 9.11304 17.0359 9.33913C17.262 9.56521 17.375 9.85295 17.375 10.2024C17.375 10.5518 17.262 10.8395 17.0359 11.0656L11.3632 16.7382C11.2399 16.8615 11.1063 16.9491 10.9624 17.0009C10.8186 17.0527 10.6644 17.0782 10.5 17.0774Z"
                                    fill="#FF5C26"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_510_12213">
                                    <rect
                                      width="20"
                                      height="16.1547"
                                      fill="white"
                                      transform="translate(0.5 0.922607)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                              {/* 按鈕 */}
                              <h6 className="py-2 mb-0 ">{item.catename}</h6>
                            </>
                          ) : (
                            <>
                              <h6 className="py-2 mb-0 ">{item.catename}</h6>
                              <svg
                                width="21"
                                height="18"
                                viewBox="0 0 21 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className={`${
                                  filterClick === index ? "show" : "hidden"
                                } `}
                              >
                                <g clipPath="url(#clip0_456_14085)">
                                  <path
                                    d="M10.25 0.915322C10.4144 0.915322 10.5739 0.946152 10.7285 1.00781C10.883 1.06947 11.0113 1.15168 11.1132 1.25445L16.7859 6.92709C17.012 7.15318 17.125 7.44092 17.125 7.79032C17.125 8.13972 17.012 8.42747 16.7859 8.65355C16.5598 8.87963 16.272 8.99268 15.9226 8.99268C15.5732 8.99268 15.2855 8.87963 15.0594 8.65355L10.25 3.84413L5.44058 8.65355C5.2145 8.87964 4.92676 8.99268 4.57735 8.99268C4.22795 8.99268 3.94021 8.87964 3.71413 8.65355C3.48804 8.42747 3.375 8.13973 3.375 7.79032C3.375 7.44092 3.48804 7.15318 3.71413 6.92709L9.38677 1.25445C9.51009 1.13113 9.64368 1.04357 9.78756 0.991779C9.93143 0.939985 10.0856 0.9145 10.25 0.915322Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M10.25 8.99271C10.4144 8.99271 10.5739 9.02354 10.7285 9.0852C10.883 9.14686 11.0113 9.22908 11.1132 9.33184L16.7859 15.0045C17.012 15.2306 17.125 15.5183 17.125 15.8677C17.125 16.2171 17.012 16.5049 16.7859 16.7309C16.5598 16.957 16.272 17.0701 15.9226 17.0701C15.5732 17.0701 15.2855 16.957 15.0594 16.7309L10.25 11.9215L5.44058 16.7309C5.2145 16.957 4.92676 17.0701 4.57735 17.0701C4.22795 17.0701 3.94021 16.957 3.71413 16.7309C3.48804 16.5049 3.375 16.2171 3.375 15.8677C3.375 15.5183 3.48804 15.2306 3.71413 15.0045L9.38677 9.33184C9.51009 9.20852 9.64368 9.12097 9.78756 9.06917C9.93143 9.01738 10.0856 8.99189 10.25 8.99271Z"
                                    fill="white"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_456_14085">
                                    <rect
                                      width="20"
                                      height="16.1547"
                                      fill="white"
                                      transform="translate(0.25 0.915283)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                              <svg
                                width="21"
                                height="18"
                                viewBox="0 0 21 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className={`${
                                  filterClick === index ? "hidden" : "show"
                                } `}
                              >
                                <g clipPath="url(#clip0_510_12213)">
                                  <path
                                    d="M10.5 8.99996C10.3356 8.99996 10.1761 8.96913 10.0215 8.90747C9.86697 8.84581 9.73871 8.7636 9.63677 8.66083L3.96413 2.98819C3.73804 2.76211 3.625 2.47436 3.625 2.12496C3.625 1.77556 3.73804 1.48782 3.96413 1.26173C4.19021 1.03565 4.47795 0.922607 4.82735 0.922607C5.17676 0.922607 5.4645 1.03565 5.69058 1.26173L10.5 6.07115L15.3094 1.26173C15.5355 1.03565 15.8232 0.922607 16.1726 0.922607C16.522 0.922607 16.8098 1.03565 17.0359 1.26173C17.262 1.48782 17.375 1.77556 17.375 2.12496C17.375 2.47436 17.262 2.76211 17.0359 2.98819L11.3632 8.66083C11.2399 8.78415 11.1063 8.87171 10.9624 8.9235C10.8186 8.9753 10.6644 9.00078 10.5 8.99996Z"
                                    fill="#FF5C26"
                                  />
                                  <path
                                    d="M10.5 17.0774C10.3356 17.0774 10.1761 17.0465 10.0215 16.9849C9.86697 16.9232 9.73871 16.841 9.63677 16.7382L3.96413 11.0656C3.73804 10.8395 3.625 10.5518 3.625 10.2024C3.625 9.85295 3.73804 9.56521 3.96413 9.33913C4.19021 9.11304 4.47795 9 4.82735 9C5.17676 9 5.4645 9.11304 5.69058 9.33913L10.5 14.1485L15.3094 9.33913C15.5355 9.11304 15.8232 9 16.1726 9C16.522 9 16.8098 9.11304 17.0359 9.33913C17.262 9.56521 17.375 9.85295 17.375 10.2024C17.375 10.5518 17.262 10.8395 17.0359 11.0656L11.3632 16.7382C11.2399 16.8615 11.1063 16.9491 10.9624 17.0009C10.8186 17.0527 10.6644 17.0782 10.5 17.0774Z"
                                    fill="#FF5C26"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_510_12213">
                                    <rect
                                      width="20"
                                      height="16.1547"
                                      fill="white"
                                      transform="translate(0.5 0.922607)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </>
                          )}

                          <div
                            className={`search-content black text-center showless ${
                              filterClick === index ? "show" : "hidden"
                            } ${index === 0 ? "left-10px" : "right-10px"} `}
                          >
                            {item.children.map((child, childIndex) => (
                              <div
                                key={childIndex}
                                onClick={() =>
                                  handleChildClick(`/collections/${child.slug}`)
                                }
                              >
                                <p>{child.catename}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {!searchExpand && (
                  <div
                    className="d-block w-auto cursor-pointer"
                    onClick={handleSearchExpand}
                  >
                    <svg
                      width="28"
                      height="29"
                      viewBox="0 0 28 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M25.2955 28.5546L16.3864 19.6328C15.5909 20.2701 14.6761 20.7746 13.642 21.1463C12.608 21.518 11.5076 21.7039 10.3409 21.7039C7.45076 21.7039 5.005 20.7013 3.00364 18.696C1.00227 16.6907 0.00106061 14.2415 0 11.3483C0 8.45403 1.00121 6.0048 3.00364 4.00058C5.00606 1.99637 7.45182 0.993738 10.3409 0.992676C13.2311 0.992676 15.6768 1.99531 17.6782 4.00058C19.6795 6.00586 20.6808 8.45509 20.6818 11.3483C20.6818 12.5166 20.4962 13.6186 20.125 14.6541C19.7538 15.6897 19.25 16.6058 18.6136 17.4023L27.5625 26.3639C27.8542 26.656 28 27.0145 28 27.4393C28 27.8642 27.8409 28.2359 27.5227 28.5546C27.2311 28.8466 26.8598 28.9927 26.4091 28.9927C25.9583 28.9927 25.5871 28.8466 25.2955 28.5546ZM10.3409 18.5176C12.3295 18.5176 14.0202 17.8203 15.4127 16.4257C16.8053 15.0312 17.5011 13.3387 17.5 11.3483C17.5 9.35683 16.8037 7.66382 15.4111 6.26926C14.0186 4.8747 12.3285 4.17796 10.3409 4.17902C8.35227 4.17902 6.66167 4.8763 5.26909 6.27086C3.87651 7.66541 3.18076 9.35789 3.18182 11.3483C3.18182 13.3398 3.87811 15.0328 5.27068 16.4273C6.66326 17.8219 8.35333 18.5186 10.3409 18.5176Z"
                        fill="#FF5C26"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default NavMenu;
